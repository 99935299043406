/* eslint-disable */
import React from 'react'
import { useTranslation } from '../../../locale/useTranslation';
import { EditPageHeader } from '../../../components/EditPageHeader';
import { useNavigate } from 'react-router-dom';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileGridCard from './MobileGridCard';
import { TimeCellWithOutFrames } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export const DigitalSignMobileContentGroupEditForm = (props) => {

    const { dataItem, onChange, gridData, handleSubmit, companies, selectedCompany, setSelectedCompany, isEnterpriseLogin, setShowContentForm } = props;
    const lang = useTranslation();
    const navigate = useNavigate();
    
    return (
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader
                    title={dataItem?.Name != "" ? dataItem?.Name : lang.content_group_editpage_header}
                    onSubmit={handleSubmit}
                    onCancel={() => navigate(-1)}
                />
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="">{lang.name_label}*</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Name"
                            value={dataItem.Name}
                            onChange={onChange}
                        />
                    </div>
                    {isEnterpriseLogin && <div className="col-12">
                        <label htmlFor="TabView">{lang.company_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={companies}
                            name="Company"
                            textField="Name"
                            dataItemKey="_id"
                            value={selectedCompany}
                            onChange={(e) => setSelectedCompany(e.target.value)}
                        />
                    </div>}
                </div>
                <div className="row">
                    <div className="col mt-4">
                        <input
                            name="Archive"
                            type="checkbox"
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange}
                        />
                        <label className="ml-1">{lang.archive}</label>
                    </div>
                </div>
                <div className="row mb-4 mt-3">
                    <div className="col-12">
                        <AddRoundButton title={lang.add_content_button_tooltip} onClick={() => setShowContentForm(true)} />
                        <div style={{ height: "70vh", overflowY: "auto" }}>
                            {gridData?.map((item, index) => {
                                    return <MobileGridCard
                                        key={index}
                                        title={item?.fileName}
                                        imageUrl={item?.thumbnailUrl ?? "https://media.comicbook.com/files/img/default-movie.png"}
                                        description={item?.resource_type}
                                        bottomRight={TimeCellWithOutFrames({ field: 'duration', dataItem: item })}
                                        height='120px'
                                    />
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
