import React from 'react';

const ActionCard = ({ icon, backgroundColor, title = "", Description = "", buttonName, buttonAction, iconFontSize , titleFontSize, iconColor}) => {
    return (
        <div
            className="d-flex mr-auto justify-content-between align-items-center"
            style={{
                border: "1px solid rgb(212 218 212)",
                backgroundColor: "white",
                padding: "7px",
                textAlign: "center",
                borderRadius: "8px",
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    backgroundColor: backgroundColor || "white",
                    width: "50px",
                    height: "50px",
                    borderRadius: "6px",
                }}
            >
                <i className={`fa fa-${icon || "desktop"}`} style={{ fontSize: `${iconFontSize || "35px"}`, color: iconColor ?? "rgb(239 184 14)" }}></i>
            </div>
            <div style={{ fontSize: "16px", textAlign: "left", marginLeft: "12px", flexGrow: 1 }}>
                <div style={{ fontSize: `${titleFontSize || "16px"}` }}>{title}</div>
                <div style={{ fontSize: "14px", color: "#666", marginTop: "4px" }}>{Description}</div>
            </div>
            <button
                type="button"
                className="btn btn-link"
                style={{ fontSize: "16px" }}
                onClick={buttonAction}
            >
                {buttonName}
            </button>
        </div>
    );
}

export default ActionCard
