/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { TouramentCard } from "./touramentCard";
import * as API from "../../../framework/API/api";
import _ from "underscore";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { TournamentEditForm } from "./TournamentEditForm";
import { ENTITYNAME } from "../../../framework/constant/constant";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import SportsEventEditForm from "../SportsEventEditForm";

export const TournamentList = (props) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const orginalData = useRef([]);
  const tournament = useRef({ SID: 0 });
  const setTournament = (data) => {
    tournament.current = data;
  }
  const [showTournament, setShowTournament] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSportsEventForm, setShowSportsEventForm] = useState(false);


  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {

    setSearchText('');
    setIsLoading(true);
    var res = await API.getLiveEventsTournamentData();
    setIsLoading(false);
    // console.log(res.data);

    if (res.data.length > 0) {
      //adding isSelected

      orginalData.current = res.data;
      res.data[0] = { ...res.data[0], isSelected: true };
      setData(res.data);
      props.onSelection(res.data[0]);
    }
  }

  const onSelection = (index, dataItem) => {
    var copyData = [...data];
    resetSelection(copyData);
    copyData[index] = { ...Object.assign(dataItem, { isSelected: true }) };
    setData(copyData);
    props.onSelection(dataItem);
  }

  const resetSelection = (copyData) => {
    copyData.map((dataItem) => {
      Object.assign(dataItem, { isSelected: false });
    });
  };


  const onSearch = (e) => {

    if (orginalData.current.length == 0) return;

    const keyword = e.target.value?.trim();
    setSearchText(keyword);
    var datakey = Object.keys(orginalData.current[0]);


    var dataStore = [];
    if (keyword != "") {
      datakey.map((item) => {
        var filterData = orginalData.current.filter((obj) => obj[item] && _.isString(obj[item]) && obj[item].toLowerCase().includes(keyword.toLowerCase()));
        if (filterData.length != 0) {
          dataStore.push(...filterData);
        }
      });
      const uniqData = [...new Set(dataStore)];
      setData(uniqData);
      resetSelection(uniqData);
    } else {
      setData(orginalData.current);
      resetSelection(orginalData.current);
      // If the text field is empty, show all users
    }

  };

  const refreshData = (dataItem) => {

    //SELECT NEW ADDED OR UPDATED AND DE-SELECT OTHER
    let localData = data && data.length > 0 ? data.map((x) => {
      delete x.isSelected
      return x
    }) : []
    dataItem = { ...dataItem, isSelected: true };
    var index = localData.findIndex((x) => x.SID == dataItem.SID);

    if (index < 0) {
      setData([...localData, dataItem]);
      return;
    }

    var gridData = [...localData];
    gridData[index] = dataItem;

    setData(gridData);
    props.onSelection(dataItem);

  }

  const onAddTournament = () => {
    console.log('here')
    setTournament({ SID: 0 });
    setShowTournament(true);
  }

  const deleteTournament = (index, dataItem) => {
    console.log(index);
    // SetOttAssets(old => old.filter((item, i) => {
    //   return index !== i;
    // }))
    // if (dataItem.SID !== 0) {
    //   setDeleteOttAssetsSID(old => [...old, dataItem.SID])
    // }
    // toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
    //   position: toast.POSITION.TOP_RIGHT
    // });
  }

  const editTournament = (index, dataItem) => {
    setShowTournament(true);

    dataItem.isSelected && delete dataItem.isSelected;
    setTournament(dataItem);
  }

  const handleSportEventList = (item) => {
    setTournament({ SID : 0 , ...item });
    setShowTournament(true);
  }

  return (
    <div className="side-bar-items marlr p-1">

      {/* title */}
      <div>
        <h1 className="page-title txt-color-bludeDark" style={{ margin: '10px 5px' }}>
          <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
          <span className="h1" style={{ letterSpacing: '0.1px' }}>Tournaments</span>
        </h1>
      </div>

      {/* search */}
      <div className="col-12 mb-2">
        <div className="input-group input-group w-300 ">
          <input
            type="text"
            className="form-control shadow-inset-2 pt-0 pb-0"
            id="filter-icon"
            aria-label="type 2 or more letters"
            placeholder={"Search"}
            onChange={onSearch}
            value={searchText}
          />

          <div className="input-group-append">
            <span className="input-group-text">
              <i style={{ margin: "4px 0px" }} className="fa fa-search" />
            </span>
          </div>
        </div>

      </div>

      <div className="flex-container p-2">
        <AddRoundButton title={'Add Tournament'} name={'Add Tournament'} onClick={onAddTournament} />
        <RefreshButton onClick={loadData} />
        <div className="flex-container-reverse flex-item-auto">
          <span 
            className='float-right cursor-pointer' 
            style={{
              backgroundColor: 'grey',
              margin : "5px 0px",
              color: 'white',
              padding: '4px',
              border: '1px solid #ced4da',
              fontSize: '10px'
            }} 
            onClick={() => { setShowSportsEventForm(true) }}
          >
            {"Sports"}
          </span>


        </div>
      </div>

      {/* card starting */}
      {isLoading ?
        // loading
        <div className="ml-2 mt-2">
          <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
        </div> 
        :
        <div style={{ height: props.height ?? "68vh", overflowY: "auto", overflowX: "hidden" }}>

          {
            data.map((tournamentData, index) => {
              return (
                <TouramentCard id={tournamentData._id} tournamentData={tournamentData} onSelection={onSelection} index={index} onEditDataItem={editTournament} onDeleteDataItem={deleteTournament} />
              );
            })
          }

        </div>

      }

      {showTournament && <TournamentEditForm title={'Tournament'} entityName={ENTITYNAME.Tournament} item={tournament.current} refresh={refreshData} cancelEdit={() => setShowTournament(false)} />}
      {showSportsEventForm && <SportsEventEditForm mediaCategory={props.mediaCategory} cancelEdit={() => setShowSportsEventForm(false)} sportsTournamentList={handleSportEventList}  />}

    </div>
  )
}
