/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import * as API from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import SalesDashboardTile from '../Dashboard/SalesDashboardTile';
import DigitalSignMobileAdminDashboardForm from './mobileView/DigitalSignMobileAdminDashboardForm';
import { utility } from '../../framework/utility/utilityProvider';
import ReactGA from "react-ga4";

const DigitalSignAdminDashboardForm = () => {

    const lang = useTranslation();
    const [cards, setCards] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // calculate height or width in media screen
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to calculate screen orientation and size
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); // Set true for portrait mode
        };

        // Initial check
        updateScreenType();

        // Add event listener for window resize
        window.addEventListener('resize', updateScreenType);

        if (utility.isAutomateB()) {

            // google analytics
            ReactGA.event({
                category: "Page View",
                action: "Dashboard Loaded",
                label: window.location.pathname,
            });

        }
        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        let res = await API.getDigitalSignAdminDashboardData();
        if (!res.success) {
            toast.error(res.message);
        } else {
            setCards(res?.data?.cards);
            setGridData(res?.data?.users ?? []);
        }
        setIsLoading(false);
    }

    return (<>
            { isLoading && <Loader height="100vh" />}
        {!isLoading && isMobile ? <DigitalSignMobileAdminDashboardForm cards={cards} gridData={gridData}/> : <div className='row m-2'>
            <div className='col-12'>
                <div className='flex-container'>
                    <h1 className="page-title txt-color-bludeDark">
                        <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                        <span>{lang.digital_sign_admin_dashboard_page_breadcrumb}</span>
                    </h1>
                </div>
                <div className='row'>
                    {cards.map((card) => {
                        return (
                            <div className='col-4'>
                                <SalesDashboardTile
                                    iconBackColor={card?.backgroundColor ?? "#3c83c9"}
                                    route={card?.route}
                                    count={card?.count}
                                    icon={card?.icon}
                                    status={card?.name}
                                    description={lang.view_details_label}
                                    iconStyle={{ fontSize: '32px', marginTop: '5px', marginLeft: card?.name == "TOTAL COMPANYS" ? '9px' : '2px' }}
                                    showDetails={card?.showDetails ?? false}
                                />
                            </div>
                        )
                    })}
                </div>

                <div className="row shadow">
                    <div className='col-12 mb-3'>
                        <div className='row mb-2'>
                            <div className="col m-1">
                                <div className="float-left mt-1">{lang.last_five_user_login_label}</div>
                            </div>
                        </div>
                        <Grid data={gridData} resizable={true} className='digitalsign-admin-dashboard-custom-grid'>
                            <GridColumn field="name" title="Name" />
                            <GridColumn field="userName" title="User Name" />
                            <GridColumn field="Email" title="Email" />
                            <GridColumn field="countryCode" title="Country Code" />
                            <GridColumn field="PhoneNo" title="PhoneNo" />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>}
    </>);

}

export default DigitalSignAdminDashboardForm;