import { toast } from "react-toastify";
import { UploadFileInChunks } from "./api";

export const uploadFileInChunks = async (file, callBackFunction = async (data) => { }) => {

    const CHUNK_SIZE = 5 * (1024 * 1024); // 1 mb chunk 

    if (!file) {
        await callBackFunction({ success: false, progress: 0, message: "No file selected.", data: null });
        return;
    }

    const fileName = `${Date.now()}-${file.name}`; // Create a unique filename
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let currentChunkIndex = 0;
    let success = true;
    let message = "";
    let url = null;
    let progress = 0;
    var data = null;
    var result = null;
    let uploadToken = null;

    while (success && currentChunkIndex < totalChunks) {
        console.log(currentChunkIndex);
        const start = currentChunkIndex * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        try {
            let res = await UploadFileInChunks(chunk, currentChunkIndex, totalChunks, fileName, data, uploadToken);
            if (res.success) {
                success = true;
                uploadToken = JSON.stringify(res.uploadToken);
                message = res.message;
                data = res.data;
                url = res.data;
                result = res?.result ?? {}
                if (progress < 100) {
                    await callBackFunction({ success: true, progress: progress, message: message, data: url });
                }
            } else {
                success = false;
                message = res.message
                break;
            }
            progress = Math.round((currentChunkIndex / totalChunks) * 100);
        } catch (e) {
            success = false;
            message = e?.message ?? "Error uploading chunk.";
            break;
        }
        if (success) {
            currentChunkIndex++;
        } else {
            message = 'Error uploading chunk.';
            break;
        }
    }

    if (success) {
        await callBackFunction({ success: true, progress: 100, message: message, data: url, result: result });
    } else {
        await callBackFunction({ success: false, progress: progress, message: message, data: null });
    }
    return url;
}