/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE, PRODUCTS, ROLETYPE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { utility } from '../../framework/utility/utilityProvider';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import RoleModuleCollectionSelection from '../../framework/OTT/RoleModuleCollectionSelection';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const RoleEditForm = (props) => {

  const { SID } = useParams();
  const navigate = useNavigate();
  const lang = useTranslation();
  const loc = useLocation();

  const moduleRef = useRef([]);
  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);
  const products = currentUser.RoleType.ID == ROLETYPE.SuperAdmin ? Object.keys(PRODUCTS).map((obj) => { return { _id: PRODUCTS[obj], Description: obj } }) : company.Products.map((obj) => { return { _id: obj, Description: Object.keys(PRODUCTS).find((y) => PRODUCTS[y] == obj) } })

  const [showCollectionSellection, setShowCollectionSelection] = useState(false)
  const [gridData, setGridData] = useState([]);
  const [dataItem, setDataItem] = useState({
    Name: "",
    Description: "",
    Archive: false
  })

  useEffect(() => {
    pageRender()
  }, []);

  const pageRender = async () => {
    await loadCombo();
    if (SID > 0) {
      await loadEditData();
    }
  }

  const loadCombo = async () => {
    const Module = await API.getDataLookup(ENTITYNAME.Module);
    setGridData(Module.data.filter((x) => x.SID == 1));
    moduleRef.current = Module.data
  }

  const loadEditData = async () => {
    const res = await API.getEntity(ENTITYNAME.Role, parseInt(SID));
    if (res.success) {
      setDataItem({
        ...dataItem, ...res.data,
        Products: res.data.Products ? res.data.Products.map((x) => { return { _id: x, Description: Object.keys(PRODUCTS).find((y) => PRODUCTS[y] == x) } }) : [],
      })
      let modules = res.data.Module && res.data.Module.length > 0 ? res.data.Module.map((x) => moduleRef.current.find((y) => y.SID == x)) : []
      setGridData(() => modules ?? [])
    }
    else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  const onchange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const isValid = () => {
    if (dataItem.Name == '' || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Description == '' || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Products == undefined || dataItem.Products.length == 0) {
      toast.error(`${lang.please_select_product_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (gridData == undefined || gridData.length == 0) {
      toast.error(`${lang.please_add_atleast_one_module_role_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true
  }

  const handleSubmit = async (e) => {

    if (isValid()) {
      const localData = {
        SID: dataItem.SID ?? 0,
        Name: dataItem.Name,
        Description: dataItem.Description,
        Products: dataItem.Products && dataItem.Products.length > 0 ? dataItem.Products.map((x) => x?._id) : [],
        Archive: dataItem.Archive ?? false,
        Module: gridData && gridData.length > 0 ? gridData.map((x) => x?.SID) : [],
        checkDuplicate: true,
        query: [["Name", "=", dataItem.Name]]
      }
      console.log(localData);
      const res = await API.saveData(ENTITYNAME.Role, localData);
      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.ROLE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        utility.deleteLocalStorageItem(ENTITYNAME.Role)
        onClose();
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }

  }

  const viewCollectionSelection = (e) => {
    setShowCollectionSelection(!showCollectionSellection);
  }

  const handleGridData = (dataList) => {
    let newGridData = utility.checkDuplicateInData(dataList, gridData, "Name");
    setGridData([...gridData, ...newGridData]);
  }

  const deleteSelectedGridData = (dataItem) => {
    setGridData(gridData.filter((obj) => dataItem.SID != obj.SID));
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const MyCommandCell = (props) => (
    <td style={{ padding: "0px" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedGridData(props.dataItem), () => { })} />
    </td>
  )

  const onClose = () => {
    if (loc.state && loc.state.prevPath) {
      navigate(loc.state.prevPath)
    } else {
      navigate(-1);
    }
  }

  return (<>
    <div className="row m-1">
      <div className="col-12">
        <EditPageHeader title={`${lang.role_editpageheader}  -> ` + (dataItem.Name == "" ? `${lang.new_title_editpageheader}` : dataItem.Name)} onSubmit={handleSubmit} onCancel={onClose} />
        <div className="row mt-2">
          <div className="col-4 form-group">
            <label htmlFor="TabView">{lang.name_label} *</label>
            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={onchange} value={dataItem.Name} />
          </div>
          <div className="col-4 form-group">
            <label htmlFor="TabView">{lang.description_label} *</label>
            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Description"} onChange={onchange} value={dataItem.Description} />
          </div>
          <div className="col-4 form-group">
            <label htmlFor="">{lang.products_label} *</label>
            <MultiSelect
              data={products}
              style={{ border: 'solid lightgrey 1px', backgroundColor: "white" }}
              textField="Description"
              dataItemKey="_id"
              value={dataItem.Products}
              onChange={onchange}
              name={"Products"}
            />
          </div>
        </div>
        <div className='row'>
          <div className="col form-group">
            <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
            <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
          </div>
        </div>
      </div>
      <div className="row m-1">
        <div className="col-12">
          <AddRoundButton onClick={viewCollectionSelection}></AddRoundButton>
          <Grid style={{ height: "60vh", marginTop: "5px", marginBottom: '5px', overflow: "hidden" }} data={gridData}>
            <GridColumn cell={MyCommandCell} width={"40px"} locked={true} />
            <Column field="Name" title={lang.name_column} editable={false} />
          </Grid>
        </div>
      </div>
    </div>
    {showCollectionSellection && <RoleModuleCollectionSelection showProductFilter={true} data={(user.RoleType.ID == ROLETYPE.SuperAdmin || user.RoleType.ID == ROLETYPE.Admin) ? [] : user.Module} perPage={1000} addButtonTitle={lang.select_collection_button_text} title={lang.select_module_collection_header} entityname={ENTITYNAME.Module} setDataList={handleGridData} closeForm={viewCollectionSelection} width={"50vw"} height={"64vh"} />}
  </>)
}

export default RoleEditForm;