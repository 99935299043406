import React, { useEffect, useRef, useState } from 'react';
import { Divider, Slider } from '@mui/material';
import { toast } from 'react-toastify';
import { DIGITALSIGN_TRANSACTION_STATUS, ENTITYNAME } from '../../../framework/constant/constant';
import { razorpayCreateOrder, updateTransactionHistory, verifyPayment } from '../../../framework/API/api_digitalSign';
import * as API from '../../../framework/API/api';

export const DigitalSignChoosePlanForm = (props) => {

    const { userData, onClose, plan={} } = props;
    const isYearlyRef = useRef(false);
    const [isYearly, setIsYearlySelected] = useState(false);
    const [screens, setScreens] = useState(5);
    const [isModified, setIsModified] = useState(false);
    const [pricingMatrix, setPricingMatrix] = useState([]);

    const [currency, setCurrency] = useState("INR"); // Default currency
    const currencyRef = useRef("INR");

    const setCurrencySelected = (currency) => {
        setCurrency(currency);
        currencyRef.current = currency;
    }

    const setIsYearly = (isYearly) => {
        setIsYearlySelected(isYearly);
        isYearlyRef.current = isYearly;
    }
    const [dataItem, setDataItem] = useState({
        screens: 5,
        storage: 5 * 1024,
        tenure: 30,
    })

    useEffect(() => {
        loadPriceMatrix();
        if(Object.keys(plan).length > 0) {
            handlePayment();
        }
    }, []);

    const fetchUserCountry = async () => {
        try {
            const response = await fetch("https://ipapi.co/json/");
            const data = await response.json();
            console.log(data);
            const userCountry = data.country;

            // Set currency based on country
            if (userCountry !== "IN") {
                setCurrencySelected("USD");
            }
        } catch (error) {
            console.error("Error fetching user country:", error);
        }
    };

    const showUSD = () => {
        return currencyRef.current == "USD";
    }

    const loadPriceMatrix = async () => {

        // setting country first
        await fetchUserCountry();

        let pricingMatrixRes = await API.getDataLookup(ENTITYNAME.DigitalSignPricingMatrix, { query: ["Archive", "!=", true] });
        setPricingMatrix(pricingMatrixRes?.data ?? []);

        const price = showUSD() ? pricingMatrixRes.data[0].usd_price ?? 20 : pricingMatrixRes.data[0].price ?? 1250;

        if (pricingMatrixRes?.data?.length > 0) {
            setDataItem({
                ...dataItem,
                ogPerScreen: price,
                perScreen:  price,
                ogPrice: price * 5,
                price: price * 5,
            })
        }
    }

    const getPriceByRange = (screens) => {
        const mtrix = pricingMatrix.find(pm => screens >= pm.minScreen && screens <= pm.maxScreen);
        return mtrix ? (showUSD() ? mtrix.usd_price : mtrix.price) : (showUSD() ? 20 : 1250); // 20 USD and 1250 INR is value if matrix not found
    };

    const setBillingPlan = (screens) => {
        setScreens(screens);

        const pricePerScreen = getPriceByRange(screens);
        const discountedPrice = Math.round(isYearlyRef.current ? pricePerScreen - (pricePerScreen * 0.1) : pricePerScreen).toFixed(2);
        setDataItem((old) => ({
            ...old,
            screens: screens,
            storage: screens * 1024,
            ogPerScreen: pricePerScreen,
            perScreen: discountedPrice,
            ogPrice: (isYearlyRef.current ? (pricePerScreen * screens * 12) : pricePerScreen * screens),
            price: (isYearlyRef.current ? (discountedPrice * screens * 12) : discountedPrice * screens),
            tenure: isYearlyRef.current ? 365 : 30
        }))
    }

    const handlePayment = async () => {

        let planObj = Object.keys(plan).length > 0 ? plan : {
            _id: "",
            SID: 0,
            Name: "Custom",
            Price: dataItem.price,
            Tenure: dataItem.tenure,
            screens: dataItem.screens,
            storage: dataItem.storage
        }
        const response = await razorpayCreateOrder(planObj.Price, planObj, currencyRef.current);
        console.log(response);

        if (!response.success) {
            toast.error(response.message);
            return;
        }

        const { orderID, currency, amount: orderAmount, keyID } = response.data;

        const options = {
            key: keyID, // Replace with your Razorpay key_id
            amount: orderAmount,
            currency: currency,
            name: 'AutomateB',
            description: 'Test Transaction',
            order_id: orderID,
            handler: confirmPayment,
            modal: {
                ondismiss: async function () {
                    // This function will be called when the user cancels the payment
                    await updateTransactionHistory(orderID, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
                    toast.error("Payment Canceled");
                }
            },
            prefill: {
                name: userData.name,
                email: userData.Email,
                contact: userData.PhoneNo
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: 'orange'
            }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };


    const confirmPayment = async (response) => {
        console.log(response);
        let res = await verifyPayment(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
        console.log(res);
        if (!res.success) {
            await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
            toast.error("Payment Failed");
        } else {
            let transactionRes = await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Confirmed);
            if (!transactionRes.success) {
                toast.error("Your payment is successful but unable to update transaction history. Please contact us.");
            }
            else {
                toast.success("Payment Success\n Plan has been added.");
                onClose && onClose();
            }
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row plan-container mb-4">
                    <div className="col-md-6">
                        <div className="plan-header">Customize your plan</div>
                        <div className="plan-subheader">save 10% with yearly payment</div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="toggle-container pull-right">
                            <div className="toggle-button">
                                <div className={`d-flex yearly ${isYearly ? 'active' : ''}`} onClick={() => { setIsYearly(true); setBillingPlan(screens) }} >
                                    YEARLY <span className="discount">-10%</span>
                                </div>
                                <div className={`monthly ${!isYearly ? 'active' : ''}`} onClick={() => { setIsYearly(false); setBillingPlan(screens) }}>
                                    MONTHLY
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider></Divider>
                <div className="row mt-4">
                    <div className="col-md-12 mt-4" style={{ display: 'flex', alignItems: 'baseline' }}>
                        <span className='ScreenCount'>{dataItem.screens}</span>
                        <span style={{ fontSize: '15px' }}>Screens</span>
                    </div>
                    <div className="col-md-12">
                        <Slider
                            min={1}
                            max={100}
                            step={1}
                            value={screens}
                            valueLabelDisplay="auto"
                            onChange={(e) => {
                                if (!isModified) {
                                    setIsModified(true);
                                }
                                setBillingPlan(e.target.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-2">
                        <div className='titleBold'>{Math.ceil(dataItem.storage / 1024)} GB</div>
                        <div className='titleNormal'>Storage</div>
                    </div>
                    <div className="col-md-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className='titleBold'>{currency}</span>
                            <span className='titleBold'>{dataItem.perScreen}</span>
                            {isYearly && <span className='titleBold' style={{ alignItems: 'center', fontSize: '18px', textDecoration: "line-through", color: '#696966', marginRight: '02px' }}> {dataItem.ogPerScreen}</span>}
                        </div>

                        <div className='titleNormal'>per screen/month</div>
                    </div>
                    <div className="col-md-3">
                        <div className='titleBold'>10%</div>
                        <div className='titleNormal'>Save with yearly plan</div>
                    </div>
                </div>
                <div className="row mt-4 mb-0" style={{ backgroundColor: 'rgb(31,33,33)', padding: '10px', borderRadius: '5px' }}>
                    <div className="col-md-9">
                        <div className='titleBold' style={{ marginRight: '10px', color: '#c8cbcf', display: 'flex', alignItems: 'center' }}> <span className='priceBold'>{currency}</span>
                            <span className='priceBold'> {dataItem.price}</span>
                            {isYearly && <span className='priceBold' style={{ textDecoration: 'line-through', color: '#b8b8b6', fontSize: '18px' }} > {dataItem.ogPrice}</span>}
                        </div>
                        <div className='titleNormal' style={{ color: '#b7b9bd' }}>Total Cost/{isYearlyRef.current ? "Year" : "Month"}</div>
                    </div>

                    <div className="col-md-3 pull-right">
                        <button title="Get Started" onClick={() => {
                            handlePayment();
                        }} style={{ fontSize: "16px", backgroundColor: "#FF6600", borderRadius: "4px", height: "50px", width: "100%", marginLeft: "0px" }} type="submit" className={`btn-square btn-success`} >
                            &nbsp; Pay Now &nbsp;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}