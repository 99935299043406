/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from '../../../locale/useTranslation';
import BossDialog from '../../../components/BossDialog';
import ActionButton from '../../../framework/forms/helpercomponents/buttons/ActionButton';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';
import MobileGridCard from './MobileGridCard';
import { utility } from '../../../framework/utility/utilityProvider';
import { LocalDateTimeColumn } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell';

const DigitalSignMobileAsrunReport = (props) => {
    const [loadingStatus, setLoadingStatus] = useState(false);
    const lang = useTranslation();
    const { title, cancelEdit, dataItem, onChange, loadData, downloadExcel, gridData } = props;

    const handleLoadData = async () => {
        setLoadingStatus(true); 
        try {
            await loadData(); 
        } catch (error) {
            console.error("Error loading data:", error);
        } finally {
            setLoadingStatus(false); 
        }
    };

    return (
        <>
            <BossDialog
                title={title}
                onClose={cancelEdit}
                width={"95%"}
            >
                <div className="d-flex" style={{ marginLeft: '-12px' }}>
                    <ActionButton
                        name={lang.show_button_text}
                        onClick={handleLoadData}
                        className="btn btn-primary"
                    />
                    <RoundButton
                        icon="download"
                        onClick={() => downloadExcel()}
                        title={lang.download_button_tooltip}
                        className="btn btn-secondary"
                        style={{ marginLeft: '10px' }}
                    />
                </div>

                {/* Date pickers */}
                <div className='row mt-2'>
                    <div className="col-6">
                        <label htmlFor='fromDate'>{lang.from_date_label} *</label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="fromDate"
                            value={dataItem?.fromDate}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor='toDate'>{lang.to_date_label} *</label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="toDate"
                            value={dataItem?.toDate}
                            onChange={onChange}
                        />
                    </div>
                </div>

                {/* Grid Section */}
                <div className='mt-2' style={{ height: "70vh", overflowY: "auto" }}>
                    {loadingStatus ? (
                        <div  className='border' style={{height: "65vh",display: 'flex',alignItems: 'center',justifyContent: 'center',width: '100%',fontWeight: "500",fontSize: "16px",color: "gray"}}>Loading Data...
                        </div>
                    ) : gridData.length === 0 ? (
                        <div className='border'  style={{height: "65vh",display: 'flex',alignItems: 'center',justifyContent: 'center',width: '100%',fontWeight: "500",color: "grey" }} >{lang.no_record_found_error_message}
                        </div>
                    ) : (
                        gridData.map((item, index) => (
                            <MobileGridCard
                                key={index}
                                imageUrl={item?.contentThumbnailUrl ?? "https://media.comicbook.com/files/img/default-movie.png"}
                                title={item?.contentName}
                                description={
                                    <>
                                        Date:{" "}
                                        <span style={{ color: "gray" }}>
                                            {utility.convertMilisecondsToDateString(item?.time)}
                                        </span>
                                    </>
                                }
                                label1={
                                    <>
                                        ScreenGroup:{" "}
                                        <span style={{ color: "gray", verticalAlign: "middle" }}>
                                            {item?.screenGroupName}
                                        </span>
                                    </>
                                }
                                label2={
                                    <>
                                        PlaylistName:{" "}
                                        <span style={{ color: "gray", verticalAlign: "middle" }}>
                                            {item?.playlistName}
                                        </span>
                                    </>
                                }
                                bottomRight={
                                    <p
                                        className="m-0 p-0"
                                        style={{ fontSize: "12px", color: "gray" }}
                                    >
                                        <LocalDateTimeColumn dataItem={item} field="time" format="HH:mm:ss" />
                                    </p>
                                }
                                height="130px"
                            />
                        ))
                    )}
                </div>
            </BossDialog>
        </>
    );
};

export default DigitalSignMobileAsrunReport;
