/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from "../forms/CollectionSelection";
import * as API from '../API/api'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { utility } from "../utility/utilityProvider";
import { CommandCell } from "../forms/helpercomponents/CustomGridCells/CommandCell"
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons";
import { CardList } from "../../components/CardList";
import { ASSETTYPE, ENTITYNAME, LOGEVENT, MODULE, RAILTYPE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../forms/helpercomponents/buttons/AddRoundButton";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../ConfirmAlert";
import { DropdownWithDefaultValue } from "../forms/helpercomponents/dropdown/DropdownWithDefaultValue";
import { OttEditForm } from "./OttEditForm";
import OttAssetCollectionSelection from "./OttAssetCollectionSelection";
import { Switch } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DateOnlyCell } from "../forms/helpercomponents/CustomGridCells/DateCell";
import EditRoundButton from "../forms/helpercomponents/buttons/EditRoundButton";
import DocumentEditForm from "./DocumentEditForm";
import socket from "../socket/socket";
import InfoRoundButton from "../../components/InfoRoundButton";
import RoundButton from "../forms/helpercomponents/buttons/RoundButton";
import BossDialog from "../../components/BossDialog";

export const MyOttAssetContext = React.createContext({
  OttAssets: [],
  SetOttAssets: () => { }

});

export const RailsEditForm = (props) => {

  const location = useLocation();
  const RAILSOTTASSETDELETE = 'RailsOttAssetDelete';
  const NEWRAILSOTTASSETDELETE = 'NewRailsOttAssetDelete';

  const navigate = useNavigate();
  const { SID } = useParams();
  const lang = useTranslation();

  let CustomEditForm = props.customEditForm

  const [dataItem, setDataItem] = useState({
    SID: SID,
    OttRailTypeSID: 1,
    Publish: false,
    IsLive: false,
    height: 200,
    TBA: false,
  });


  const [selectionCollectionOpen, setSelectionCollectionOpen] = useState(false);
  const [OttAssets, SetOttAssets] = useState([]);
  const [platformOpen, setPlatformOpen] = useState(false);
  const [platformList, setPlatformList] = useState([]);
  const [railType, setRailType] = useState([]);
  const [assetLimit, setAssetLimit] = useState(1);
  const [openAsset, setOpenAsset] = useState(true);
  const [addAsset, setAddAsset] = useState(false);
  const [deleteOttAssetsSID, setDeleteOttAssetsSID] = useState([]);
  const [showOttAssetEditForm, setShowOttAssetEditForm] = useState(false);
  const [editFormAsset, setEditFormAsset] = useState({});
  const [assetOpenForm, setAssetOpenForm] = useState(false);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [campaignsGridData, setCampaignGridData] = useState([]);
  const [pagesGridData, setPagesGridData] = useState([]);
  const [showCampaignCollection, setShowCampaignCollection] = useState(false);
  const [showPagesCollection, setShowPagesCollection] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showRailDetails, setShowRailDetails] = useState(false);
  const [selectedRailsTypeImageUrl, setSelectedRailsTypeImageUrl] = useState(null);

  const addOttAssetRef = useRef();
  const setAddOttAssetRef = (data) => {
    addOttAssetRef.current = data;
  }

  let isFromCopy = location.state?.copy;

  useEffect(() => {
    loadcombo();
    if (SID > 0) {
      loadEditData();
    }
  }, [])

  const loadcombo = async () => {
    let railsType = await API.getDataLookup(ENTITYNAME.OttRailType, { sort: { Description: 1 } });
    setRailType(railsType.data);
  }

  const loadEditData = async () => {
    let tempPlatformList = [];
    let ottAssetTypes = Object.keys(ASSETTYPE).map((item, index) => {
      return { "Description": item, "ID": ASSETTYPE[item] }
    });

    let linkData = {
      link_collection: 'ott_assets',
      link_field: 'ott_assets'
    }

    let res = await API.getEntity(props.entityname, parseInt(SID), linkData);

    if (res.success) {

      let data = {
        ...res.data,
        Title: isFromCopy ? 'copy of ' + res.data.Title : res.data.Title,
        DisplayTitle: isFromCopy ? 'copy of ' + res.data.DisplayTitle : res.data.DisplayTitle,
        height: res.data.height ?? 200,
        TBA: res.data.TBA === 1 ? true : false,
        Archive: res.data.Archive ?? false,
      }

      //reset id to add as new entry
      if (isFromCopy) {
        data.SID = 0;
        delete data['_id']
      }
      //to get data of platform 
      let platforms = await API.getDataLookup(ENTITYNAME.Platform);
      if (res.data.PlatformSIDs?.length > 0) {
        let platformSIDsArray = res.data.PlatformSIDs.split(',');
        platformSIDsArray.map(async (id) => {
          let platformn = platforms.data.filter((item) => item.SID == id);
          if (platformn.length > 0) {
            tempPlatformList.push(platformn[0]);
          }

        })
      }

      setPlatformList((old) => tempPlatformList);
      setDataItem(data);

      let assests = data.ott_assets.filter((item) => {
        if (item.Archive != true) {
          return {
            ...item,
            ottAssetType: ottAssetTypes.find((a) => a.ID == item.OttAssetTypeSID)?.Description
          }
        }
      });

      SetOttAssets(assests);

      setAssesLimitFunction(data.OttRailTypeSID);

      let campaignsRes = await API.getData(ENTITYNAME.OttCampaign, { sort: { Description: 1 }, query: ['Archive', '=', false] });
      let campaignFilterData = campaignsRes.data.filter((obj) => {
        if (obj.ott_rails && obj.ott_rails.length > 0 && obj.Rails.includes(res.data._id)) {
          return obj;
        }
      });
      setCampaignGridData(campaignFilterData);

      let pagesRes = await API.getData(ENTITYNAME.Page, { sort: { Name: 1 }, query: ['Archive', '=', false] });
      let pagesFilterData = pagesRes.data.filter((obj) => {
        if (obj.ott_rails && obj.ott_rails.length > 0 && obj.ott_rails.includes(res.data._id)) {
          return obj;
        }
      })
      setPagesGridData(pagesFilterData);

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleChange = (e) => {

    if (e.target.name === "OttRailTypeSID") {
      setAssesLimitFunction(e.target.value.SID);
      setDataItem({ ...dataItem, [e.target.name]: e.target.value.SID });
    } else if (e.target.name === "Publish") {
      setDataItem({ ...dataItem, Publish: !dataItem.Publish });
    } else if (e.target.name === "IsLive") {
      setDataItem({ ...dataItem, IsLive: !dataItem.IsLive });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const setAssesLimitFunction = async (selectedRailTypeSID) => {

    let railsTypeLookup = await API.getDataLookup(ENTITYNAME.OttRailType);

    railsTypeLookup.data.forEach((obj) => {
      if (selectedRailTypeSID == obj.SID) {
        setAssetLimit(obj.AssetLimit);
      }
    })
  }

  const onPlateformSelection = () => {
    selectionCollectionOpen ? setSelectionCollectionOpen(false) : setSelectionCollectionOpen(true);
    setPlatformOpen(false);
  }

  const handleCollectionData = (dataList) => {

    let newData = utility.checkDuplicateInData(dataList, showCampaignCollection ? campaignsGridData : showPagesCollection ? pagesGridData : platformList, showCampaignCollection ? "Name" : showPagesCollection ? "Name" : "Description")
    console.log(newData);

    showCampaignCollection ? setCampaignGridData([...campaignsGridData, ...newData]) : showPagesCollection ? setPagesGridData([...pagesGridData, ...newData]) : setPlatformList([...platformList, ...newData]);

  }

  const getPlatformString = () => {
    //to set the selected platforms SID into string to save
    let plafromSIDs = []

    platformList.forEach((obj) => {
      plafromSIDs.push(obj.SID);
    })
    return plafromSIDs.join(',');

  }

  const isValid = () => {

    if (dataItem.Title == undefined || dataItem.Title == "") {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    if (dataItem.DisplayTitle == undefined || dataItem.DisplayTitle == "") {
      toast.error(`${lang.please_enter_title_display_rails_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    if (OttAssets.length == 0 && assetLimit != 0) {
      toast.error(`${lang.atleast_attach_one_ottassets_rails_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    if (((typeof dataItem.OttRailTypeSID == "number" ? dataItem.OttRailTypeSID : dataItem.OttRailTypeSID.SID) == RAILTYPE.Document) && (dataItem.doc == undefined || dataItem.doc == "")) {
      toast.error(`${lang.please_upload_documents_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    if (platformList.length == 0) {
      toast.error(`${lang.atleast_attach_atleast_one_platform_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    return true
  }

  const handleSubmit = async (e) => {

    if (!isValid()) return;

    let saveData = {
      SID: parseInt(dataItem.SID) ?? 0,
      OttRailTypeSID: dataItem.OttRailTypeSID,
      Title: dataItem.Title,
      DisplayTitle: dataItem.DisplayTitle,
      Publish: dataItem.Publish ?? false,
      PlatformSIDs: getPlatformString() || dataItem.PlatformSIDs,
      AssetLimit: assetLimit,
      height: parseInt(dataItem.height),
      TBA: dataItem.TBA ?? false,
      Archive: dataItem.Archive ?? false,
      ott_assets: OttAssets.length > 0 ? OttAssets.map(a => a._id) : [],
      IsLive: dataItem.IsLive ?? false,
      doc: dataItem.doc
    }

    if (dataItem._id && dataItem._id.length != 0) {
      saveData._id = dataItem._id
    }

    let res = await API.saveData(ENTITYNAME.Rails, saveData);
    console.log(res);

    if (campaignsGridData.length > 0) {
      for (let i = 0; i < campaignsGridData.length; i++) {
        const element = campaignsGridData[i];
        let campaignSIDs = {
          Rails: [...new Set([...element.Rails, saveData._id])],
          _id: element._id
        };
        let campaignRes = await API.saveData(ENTITYNAME.OttCampaign, campaignSIDs);
        console.log(campaignRes.data);
        if (campaignRes.success) {
          let logData = { event: LOGEVENT.UPDATE_OTT_CAMPAIGN, module: MODULE.OTT_CAMPAIGN, data: campaignRes.data, message: campaignRes.message };
          API.SaveLogs(logData);
          utility.deleteLocalStorageItem(ENTITYNAME.OttCampaign);
        }
      }
    }
    if (pagesGridData.length > 0) {
      for (let i = 0; i < pagesGridData.length; i++) {
        const element = pagesGridData[i];
        let pagesSIDs = {
          ott_rails: [...new Set([...element.ott_rails, saveData._id])],
          _id: element._id
        };
        let pagesRes = await API.saveData(ENTITYNAME.Page, pagesSIDs);
        console.log(pagesRes.data);
        if (pagesRes.success) {
          let logData = { event: LOGEVENT.UPDATE_PAGE, module: MODULE.RAILS, data: pagesRes.data, message: pagesRes.message };
          API.SaveLogs(logData);
          utility.deleteLocalStorageItem(ENTITYNAME.Page);
        }
      }
    }
    if (res.success) {
      let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_RAILS : LOGEVENT.UPDATE_RAILS, module: MODULE.RAILS, data: { ...res.data, campaign: campaignsGridData, pages: pagesGridData }, message: res.message };
      API.SaveLogs(logData);
      toast.success('Saved successfully', {
        position: toast.POSITION.TOP_RIGHT
      })
      socket.emit(SOCKET_EVENTS.onSocketData, {
        action: SOCKET_ACTION.PLATFORM_RAIL_REFRESH,
        module: MODULE.PLATFORM,
        data: null,
        // user: { name: userData.name, _id: userData._id }
      });
      if (isFromCopy || saveData?.SID == 0) {
        onClose();
      }

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onPlatformDelete = (dataItem) => {
    console.log("delete platform started");

    setPlatformList((old) =>
      old
        .filter(
          (item) =>
            dataItem.SID !== item.SID)
    );
    console.log("delete platform end");
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  };


  const MyPlatformDeleteCommandCell = (props) => (
    <CommandCell
      {...props}
      title={lang.delete_button_tooltip}
      icon={'trash'}
      btnColor={'white'}
      onClick={() => ConfirmDeleteAlert(() => onPlatformDelete(props.dataItem), () => { })}
    />
  );


  const ondeleteAllOttAsset = (index, dataItem) => {
    console.log(OttAssets.length)
    SetOttAssets([]);

  }

  const deleteOttAsset = (index, dataItem) => {
    console.log(index);
    SetOttAssets(old => old.filter((item, i) => {
      return index !== i;
    }))
    if (dataItem.SID !== 0) {
      setDeleteOttAssetsSID(old => [...old, dataItem.SID])
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editAssets = (index, dataItem) => {
    setAssetOpenForm(true);
    setEditFormAsset(dataItem);
  }

  const draggedAssetItem = useRef();
  const draggedOverAsset = useRef();
  const selectedDraggedItem = useRef();

  const onDragStart = (e, position, selectedDataItem) => {
    draggedAssetItem.current = position;
    selectedDraggedItem.current = selectedDataItem;
  };

  const onDragEnter = (e, position, containerName) => {
    console.log(containerName + position);

    draggedAssetItem.current = null;
    draggedOverAsset.current = null;
    selectedDraggedItem.current = null;

    if (containerName === "Destination") {
      draggedOverAsset.current = position;
    }
  };
  const moveUpDown = (index, dataItem, isMoveUp) => {
    if (index === 0 && isMoveUp) return;
    if (!isMoveUp && index === OttAssets.length - 1) return;

    const copyAssets = [...OttAssets];
    copyAssets.splice(index, 1);
    isMoveUp ? copyAssets.splice(index - 1, 0, dataItem) : copyAssets.splice(index + 1, 0, dataItem);

    SetOttAssets(copyAssets);
  }
  const onDropOnAssetsFromAssets = (e) => {
    console.log(draggedOverAsset);

    if (draggedOverAsset.current == null) return;

    const copyAssets = [...OttAssets];
    copyAssets.splice(draggedAssetItem.current, 1);
    copyAssets.splice(draggedOverAsset.current, 0, selectedDraggedItem.current);
    draggedAssetItem.current = null;
    draggedOverAsset.current = null;
    SetOttAssets(copyAssets);
  }

  const sideFixMenu = [
    {
      icon: 'play',
      title: `${lang.sidefixmenu_rails_asset_tooltip}`,
      onClick: () => { setOpenAsset(old => !old) }
    },
  ]

  const addOttAsset = (index, isAddUp) => {
    setAddAsset(true);

    setAddOttAssetRef({
      Index: index,
      IsAddUp: isAddUp
    })
  }

  const handleAssetLibrary = (dataList) => {
    let data = [...OttAssets];
    let index = addOttAssetRef.current.Index;
    if (addOttAssetRef.current.IsAddUp) {
      SetOttAssets([...data.slice(0, index), ...dataList, ...data.slice(index, data.length)])
    } else {
      SetOttAssets([...data.slice(0, index + 1), ...dataList, ...data.slice(index + 1, data.length)])
    }
  }

  const getAssetMenu = (index, dataItem) => {
    return [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          { label: `${lang.edit_actionbutton_option}`, icon: 'pi pi-fw pi-pencil', command: () => editAssets(index, dataItem) },
          { label: `${lang.delete_actionbutton_option}`, icon: 'pi pi-fw pi-trash', command: () => deleteOttAsset(index, dataItem) },
          { label: `${lang.moveup_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-up ', command: () => moveUpDown(index, dataItem, true) },
          { label: `${lang.movedown_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-down', command: () => moveUpDown(index, dataItem, false) },
          { label: `${lang.add_above_option}`, icon: 'pi pi-arrow-circle-up', command: () => addOttAsset(index, true) },
          { label: `${lang.add_below_option}`, icon: 'pi pi-arrow-circle-down', command: () => addOttAsset(index, false) }
        ]
      }
    ]
  }

  const handleAssetCancelEdit = () => {
    setAssetOpenForm(false);
  };

  const handleSubmitAssets = async (dataItem, dataToSave) => {

    dataToSave = dataToSave == null ? dataItem : dataToSave;

    let UpdatedData = {
      ...dataToSave,
      SID: !dataToSave.OttAssetSID ? dataToSave.SID : dataToSave.OttAssetSID,
    }

    delete UpdatedData.OttAssetSID;

    let res = await API.saveData(ENTITYNAME.OttAsset, UpdatedData);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let updatedOttAssets = OttAssets.map((asset) => {
      if (asset.OttAssetSID ? asset.OttAssetSID == res.data.SID : asset.SID == res.data.SID) {
        return res.data;
      }
      return asset;
    })
    SetOttAssets(updatedOttAssets);
    utility.deleteLocalStorageItem(ENTITYNAME.OttAsset);
    setAssetOpenForm(false);
  }

  const customeModelFields = {
    Title: "Title",
    Description: "Description",
    PosterURL: "PotraitImageUrl",
    ThirdLine: "ottAssetType",
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const editCampaign = (props) => {
    let data = props.dataItem;
    window.open(`/home/ottCampaign/${data.SID}`, '_blank');
  }

  const MyCampaignCommandCell = (props) => {
    return (
      <div className="mt-1" style={{ display: "flex" }}>
        <EditRoundButton onClick={() => editCampaign(props)} />
      </div>
    );
  }

  const editPage = (props) => {
    let data = props.dataItem;
    window.open(`/home/Page/${data.SID}`, '_blank');
  }

  const MyPageCommandCell = (props) => {
    return (
      <div className="mt-1" style={{ display: "flex" }}>
        <EditRoundButton onClick={() => editPage(props)} />
      </div>
    );
  }

  const handleDocumentSelected = (dataList) => {
    console.log(dataList);
    setDataItem({ ...dataItem, doc: dataList[0].doc });
  }

  const getRailTypeImageUrl = async () => {
    let title = utility.getKeyByValue(RAILTYPE, dataItem.OttRailTypeSID);
    let res = await API.getData(ENTITYNAME.OttPosters, { query: ['Title', '=', title?.toString()] });
    if (res.success) {
      setSelectedRailsTypeImageUrl(res.data[0]?.ImageUrl);
    } else {
      toast.info(lang.no_image_found_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onDeleteAllData = () => {
    if (OttAssets.length == 0) return;
    ConfirmAlert(() => SetOttAssets([]), () => { }, lang.confirm_delete_all_label, lang.are_you_sure_do_you_want_to_delete_all_label);
  }

  const onClose = () => {
    utility.deleteLocalStorageItem(RAILSOTTASSETDELETE);
    utility.deleteLocalStorageItem(NEWRAILSOTTASSETDELETE);
    if (location.state && location.state.prevPath) {
      navigate(location.state.prevPath)
    } else {
      navigate("/home/Rails")
    }
  }

  return (
    <>
      <FixedSidebarWithIcons menuModel={sideFixMenu} />
      <div className="myFlexContainer">
        {/* Asset */}
        {assetLimit != 0 && openAsset && <CardList onDeleteAllData={onDeleteAllData} showDeleteAll={true} customeModelFields={customeModelFields} MenuModel={getAssetMenu} showSearch={true} containerName="Destination" cardData={OttAssets} onDeleteDataItem={deleteOttAsset} close={() => setOpenAsset(false)} title={lang.cardlist_rails_title} onAddClick={() => { addOttAsset(OttAssets.length - 1, false) }} onDeleteClick={() => ConfirmDeleteAlert(() => ondeleteAllOttAsset(), () => { })} onDragStart={onDragStart} onDragEnter={onDragEnter} onDrop={onDropOnAssetsFromAssets} inTileView={true} showTileView={false} columnEntityName={ENTITYNAME.OttAsset} />}
        {/* Add Asset Library */}
        {assetLimit != 0 && addAsset && <OttAssetCollectionSelection title={lang.select_assets_collection_header} entityname={ENTITYNAME.OttAsset} closeForm={() => setAddAsset(false)} setDataList={handleAssetLibrary} width={"60vw"} height={"64vh"} />}

        {/* edit form */}
        <div className="main-layout">
          <Form
            initialValues={dataItem}
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={`${lang.rails_title_editpageheader} -> ` + (dataItem.Title ? dataItem.Title : `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={onClose} />
                <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", height: '85vh' }}>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.title_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Title" value={dataItem.Title || ""} onChange={(e) => handleChange(e)} />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.display_title_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="DisplayTitle" value={dataItem.DisplayTitle || ""} onChange={(e) => handleChange(e)} />
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-5">
                          <label htmlFor="OttRailType">{lang.rails_type_label}</label>
                          <DropdownWithDefaultValue
                            data={railType}
                            name="OttRailTypeSID"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.OttRailTypeSID}
                            onChange={(e) => handleChange(e)}
                          />
                          {<p>{lang.rails_note_label} {assetLimit === -1 ? "unlimited assets" : assetLimit + " asset"} </p>}
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.rails_type_info_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { setShowRailDetails(true); getRailTypeImageUrl(); }}><i className="fa-solid fa-eye fa-xs"></i>
                          </button>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="Height">{lang.height_label}</label>
                            <input type="number" className="form-control form-control-sm" name="height" value={dataItem.height} onChange={(e) => handleChange(e)} min={1} />
                          </div>
                        </div>
                      </div>

                      {((typeof dataItem.OttRailTypeSID == "number" ? dataItem.OttRailTypeSID : dataItem.OttRailTypeSID.SID) == RAILTYPE.Document) && <div className="row">
                        <div className="col-5">
                          <label htmlFor="">{lang.upload_documents_label}</label>
                          <input
                            name={'doc'}
                            className="form-control form-control-sm"
                            type={'text'}
                            value={dataItem.doc}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                        <div className='col-1' style={{ marginTop: '20px', marginLeft: '-15px' }}>
                          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ height: "30px" }} onClick={() => setShowDocuments(true)}>
                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                          </button>
                          <button type="button" title='Create Document' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", marginLeft: '5px' }} onClick={() => setShowAddDocument(true)}>  <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>}

                      <div className="row mt-4">
                        <div className="col-2">
                          <Field
                            name={"TBA"}
                            component={Checkbox}
                            label={lang.tba_label}
                            onChange={(e) => handleChange(e)}
                            checked={dataItem.TBA}
                          />
                        </div>

                        <div className="col-3">
                          <div className="form-check">
                            <Field
                              name={"Archive"}
                              component={Checkbox}
                              label={lang.archive}
                              onChange={(e) => handleChange(e)}
                              checked={dataItem.Archive}
                            />
                          </div>
                        </div>
                        <div className="col-3">

                          <Field
                            name={"Publish"}
                            component={Switch}
                            label={lang.publish_label}
                            onLabel={lang.rails_p_label}
                            offLabel={lang.rails_up_label}
                            size={"small"}
                            // onChange={(e) => setPublish(e.value)}
                            onChange={(e) => handleChange(e)}
                            checked={dataItem.Publish}
                          />
                          <span className={dataItem.Publish ? " pl-1 text-success" : " pl-1 text-danger"}>{dataItem.Publish ? `${lang.published_label}` : `${lang.unpublished_label}`}</span>
                        </div>
                        {/* <div className="col">
                          <Field
                            name={"IsLive"}
                            component={Switch}
                            label={lang.islive_label}
                            onLabel={lang.rails_l_label}
                            offLabel={lang.rails_uat_label}
                            size={"small"}
                            onChange={(e) => handleChange(e)}
                            // onChange={(e) => setLive(e.value)}
                            checked={dataItem.IsLive}
                          />
                          <span className={dataItem.IsLive ? " pl-1 text-success" : " pl-1 text-danger"}>{dataItem.IsLive ? `${lang.live_label}` : `${lang.uat_label}`}</span>
                        </div> */}


                      </div>
                    </div>


                  </div>
                  <div className="row mt-3" style={{ margin: "5px" }}>

                    <div className="col-12 mb-4">
                      <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleTabChange} aria-label="tabs example">
                            <Tab label={lang.platform_label} value="1" />
                            {SID != 0 && <Tab label={lang.campaign_label} value="2" />}
                            {SID != 0 && <Tab label={lang.pages_label} value="3" />}
                          </TabList>
                        </Box>
                        <TabPanel value={"1"}>
                          <div className="row">
                            <div className="col-12">
                              <AddRoundButton onClick={() => { setSelectionCollectionOpen(true); setPlatformOpen(true); }} />
                              <Grid data={platformList} style={{ height: "25vh", marginTop: "5px" }}>
                                <GridColumn cell={MyPlatformDeleteCommandCell} width={"60px"} />
                                <GridColumn field="Description" title={lang.description_column} editable={false} />
                                <GridColumn field="Archive" title={lang.archive} />
                              </Grid>
                            </div>
                          </div>
                        </TabPanel>
                        {SID != 0 && <TabPanel value={"2"}>
                          <div className="row">
                            <div className="col-12">
                              <AddRoundButton onClick={() => setShowCampaignCollection(true)} />
                              <Grid data={campaignsGridData} style={{ height: "25vh", marginTop: "5px" }}>
                                <GridColumn cell={MyCampaignCommandCell} width={"40px"} />
                                <GridColumn field="Name" title={lang.name_column} editable={false} />
                                <GridColumn field="FromDate" title={lang.from_date_column} cell={DateOnlyCell} editable={false} />
                                <GridColumn field="ToDate" title={lang.to_date_column} cell={DateOnlyCell} editable={false} />
                              </Grid>
                            </div>
                          </div>
                        </TabPanel>}
                        {SID != 0 && <TabPanel value={"3"}>
                          <div className="row">
                            <div className="col-12">
                              <AddRoundButton onClick={() => setShowPagesCollection(true)} />
                              <Grid data={pagesGridData} style={{ height: "25vh", marginTop: "5px" }}>
                                <GridColumn cell={MyPageCommandCell} width={"40px"} />
                                <GridColumn field="Name" title={lang.name_column} editable={false} />
                                <GridColumn field="DisplayName" title={lang.display_name_column} editable={false} />
                              </Grid>
                            </div>
                          </div>
                        </TabPanel>}
                      </TabContext>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
        {(platformOpen || showCampaignCollection || showPagesCollection) && <CollectionSelection entityname={showCampaignCollection ? ENTITYNAME.OttCampaign : showPagesCollection ? ENTITYNAME.Page : ENTITYNAME.Platform} title={showCampaignCollection ? lang.select_campaign_header_dialog : showPagesCollection ? lang.select_pages_header_dialog : lang.platform_label} setDataList={handleCollectionData} closeForm={() => showCampaignCollection ? setShowCampaignCollection(false) : showPagesCollection ? setShowPagesCollection(false) : onPlateformSelection()} width={"45vw"} height={"64vh"} />}
        {showOttAssetEditForm && <CustomEditForm
          {...props} //to get other props from customEditForm
          cancelEdit={(setShowOttAssetEditForm(false))}
          onSubmit={handleSubmit}
        //  item={editItem}
        />}
        {assetOpenForm && <OttEditForm {...props} item={editFormAsset} cancelEdit={handleAssetCancelEdit} onSubmit={handleSubmitAssets} />}

        {showDocuments && <CollectionSelection addButtonTitle={lang.select_button_text} entityname={ENTITYNAME.Document} title={lang.select_documents_header_dialog} setDataList={handleDocumentSelected} closeForm={() => setShowDocuments(false)} width={"45vw"} mode="single" />}

        {showAddDocument && <DocumentEditForm item={{ SID: 0 }} cancelEdit={() => setShowAddDocument(false)} />}

        {showRailDetails &&
          <BossDialog
            title={lang.rails_type_details_dialog_header}
            onClose={() => setShowRailDetails(false)}
            width="400px"
            height={dataItem.OttRailTypeSID == RAILTYPE.FastChannel ? "700px" : "auto"}
          >
            <div className="row">
              <div className="col-12">
                <div className="mb-2" >{utility.getKeyByValue(RAILTYPE, dataItem.OttRailTypeSID)} : </div>
                <img src={selectedRailsTypeImageUrl} style={{ width: '100%', height: 'auto' }} />
              </div>
            </div>
          </BossDialog>}
      </div>
    </>)
};
