/* eslint-disable */
import React from 'react'
import BossDialog from '../../../components/BossDialog'
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton'
import MobileGridCard from './MobileGridCard'
import { utility } from '../../../framework/utility/utilityProvider'
import { useTranslation } from '../../../locale/useTranslation'
import { LocalDateTimeColumn } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell'

const DigitalSignMobileAsrunErrorReport = (props) => {
    const lang = useTranslation();
    const { title, cancelEdit, downloadExcel, gridData , loadingStatus} = props;

    return (
        <>
            <BossDialog
                title={title}
                onClose={cancelEdit}
                width={"96vw"}
                padding={"0px"}
            >
                <div className='row'>
                    <div className='col-12'>
                        <RoundButton
                            icon="download"
                            onClick={() => downloadExcel()}
                            title={lang.download_button_tooltip}
                            className="btn btn-secondary"
                        />
                    </div>
                </div>
                <div className='mt-2' style={{ height: "70vh", overflowY: "auto" }}>
                    {gridData.length == 0 ?
                        <div className='border' style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}> {loadingStatus}
                        </div>
                        : 
                        gridData.map((item, index) => (
                            <MobileGridCard
                                key={index}
                                title={utility.convertMilisecondsToDateString(item?.time)}
                                description={
                                    <>
                                        Error:{" "}
                                        <span style={{ color: "gray", verticalAlign: "middle" }}>
                                            {utility.subString(item?.error, 120)}
                                        </span>
                                    </>
                                }
                                topRight={
                                    <p
                                        className="m-0 p-0"
                                        style={{ fontSize: "12px", color: "gray" }}
                                    >
                                        <LocalDateTimeColumn dataItem={item} field="time" format="HH:mm:ss" />
                                    </p>
                                }
                                height="137px"
                            />
                        ))
                        }
                </div>

            </BossDialog>
        </>
    );
};

export default DigitalSignMobileAsrunErrorReport;

