/* eslint-disable */
import React from 'react'
import BossDialog from '../../../components/BossDialog'
import MobileGridCard from './MobileGridCard'
import { LocalDateTimeColumn } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell'
import { useTranslation } from '../../../locale/useTranslation'
import { Loader } from '../../../framework/forms/helpercomponents/Loader'

const DigitalSignMobileLastPlayItem = (props) => {
    const lang = useTranslation()
    const { title, cancelEdit, isLoading, gridData } = props
    return (
        <>
            <BossDialog title={title} onClose={cancelEdit} width={"95%"}>
                <div className="mt-2" style={{ height: "70vh", overflowY: "auto" }}>
                    {isLoading ? (
                        <Loader height="60vh" />
                    ) : gridData.length === 0 ? (
                        <div  className="border"  style={{height: "65vh",display: "flex",alignItems: "center",justifyContent: "center",width: "100%",fontWeight: "500",color: "grey" }}>{lang.no_record_found_error_message}
                        </div>
                    ) : (
                        gridData.map((item, index) => (
                            <MobileGridCard
                                key={index}
                                title={item?.content?.fileName}
                                description={
                                    <>
                                        Screen Group:{" "}
                                        <span style={{ color: "gray", verticalAlign: "middle" }}>
                                            {item?.screenGroup?.Name}
                                        </span>
                                    </>
                                }
                                label1={
                                    <>
                                        Playlist Name:{" "}
                                        <span style={{ color: "gray", verticalAlign: "middle" }}>
                                            {item?.playlist?.Name}
                                        </span>
                                    </>
                                }
                                bottomRight={
                                    <p
                                        className="m-0 p-0"
                                        style={{ fontSize: "12px", color: "gray" }}
                                    >
                                        <LocalDateTimeColumn
                                            dataItem={item}
                                            field="time"
                                            format="HH:mm:ss"
                                        />
                                    </p>
                                }
                                height="94px"
                            />
                        ))
                    )}
                </div>
            </BossDialog>

        </>
    )
}

export default DigitalSignMobileLastPlayItem
