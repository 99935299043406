import {
  memo,
  useEffect,
  useRef,
  useState,
  useCallback,
  createRef,
  startTransition,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Scheduler,
  WeekView,
  DayView,
  SchedulerViewSlot,
  SchedulerHeader,
  TimeHeaderCell,
  WorkWeekView,
  DateHeaderCell,
  SchedulerProportionalViewItem
} from "@progress/kendo-react-scheduler";
import { ContextMenu } from "primereact/contextmenu";
import * as API from "../../framework/API/api";
import CustomPlanningItem from "./CustomPlanningItem";

import { utility } from "../../framework/utility/utilityProvider";
import PlanningEditForm from "./PlanningEditForm";
import { PlanningHelper } from "./helper/PlanningHelper";
import { toast } from "react-toastify";
import {
  APIURL,
  DAYS,
  ENTITYNAME,
  LOCALSTORAGE_KEY,
  LOGEVENT,
  MODULE,
  OFFSET,
  PLANNINGTYPE,
  PLANNING_DELETE_OPTION,
  PLATFORMTYPE,
  PUBLISH_TO,
  SLOT_DIVISIONS,
  SLOT_TIMES,
  SOCKET_ACTION,
  SOCKET_EVENTS,
  TX_MODE,
  TX_STATUS,
} from "../../framework/constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useNavigate } from "react-router-dom";

import useSocket from "../../framework/socket/useSocket";
import { EditPageHeader } from "../../components/EditPageHeader";
import moment from "moment";
import { ConfirmAlert } from "../../ConfirmAlert";
import MediaFastSegmentationPopup from "../Segment/MediaFastSegmentationPopup";
import PlanningInsertDummyPopup from "./PlanningInsertDummyPopup";
import PlanningEditDummyPopup from "./PlanningEditDummyPopup";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import PlanningInsertProgramGroup from "./PlanningInsertProgramGroup";
import { Day } from "@progress/kendo-date-math";
import CheckLists from "../CheckList/CheckLists";
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { MediaDealRights_columns, MediaEpisodeSegment_columns } from "../../framework/utility/customColumns";
import HeaderLockPopup from "./headerLockPopup";
import BossDialog from "../../components/BossDialog";

//custom model field
const customModelFields = {
  id: "_id",
  title: "Title",
  description: "Description",
  start: "StartDate",
  end: "EndTime",
};
const group = {
  resources: ["Channels"],
};

const MyDays = [
  { Name: "Monday", ID: 1 },
  { Name: "Tuesday", ID: 2 },
  { Name: "Wednesday", ID: 3 },
  { Name: "Thursday", ID: 4 },
  { Name: "Friday", ID: 5 },
  { Name: "Saturday", ID: 6 },
  { Name: "Sunday", ID: 7 },
]

const MILLISECONDSINADAY = 86400000;

const Planning_prformance = (props, ref) => {

  const { filterChannels, date, isPrimeTime, onShowMedia, onShowExhibitions, showCtrlADialog, showDeleteMenu, onShowLoopPopup, onCheckList, showCheckList, setStartType } = props;

  var allowCtrlAPopup = false;
  const SLOT_COLOR = '#CFD2CF';
  const SLOT_LOCK_COLOR = '#c8e3d4';
  let getWeekDate = utility.getWeekStartEndDates(date);

  let preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  let offSetHours = isPrimeTime ? 0 : new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
  let offSetTime = offSetHours * 3600000;

  const getOffsetTime = () => {
    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = isPrimeTime ? 0 : new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    return offSetHours * 3600000;
  }

  const primeStartTime = isPrimeTime ? moment(preferenceData?.data[0]?.value?.TimeZone?.PrimeStartTime).utc().format('HH:mm') ?? "00:00" : "00:00";
  const primeEndTime = isPrimeTime ? moment(preferenceData?.data[0]?.value?.TimeZone?.PrimeEndTime).utc().format('HH:mm') ?? "23:59" : "23:59";

  const lang = useTranslation();

  //time for slot duration in planner

  // const SLOT_TIMES = [5, 15, 30, 60, 120, 180]
  var selectedSlotDuration = utility.getSeassionValue(LOCALSTORAGE_KEY.SELECTED_PLANNING_SLOT_DURATION) ?? 60;
  // alert(selectedSlotDuration)
  const [slotDuration, setSlotDurationData] = useState(selectedSlotDuration);
  const [slotDivision, _setSlotDivision] = useState(SLOT_TIMES.indexOf(selectedSlotDuration));
  const [txStatusData, setTxStatusData] = useState([])

  const [zoomInZoomOutValue, _setZoomInZoomOutValue] = useState(null);

  const setSlotDivision = (value) => {
    _setSlotDivision(value);
    _setZoomInZoomOutValue(value);
  }
  const setZoomInZoomOutValue = (value) => {
    let roundedValue = Math.round(value);
    let isSlotDurationDivisible = slotDuration % roundedValue == 0;
    if (isSlotDurationDivisible) {
      _setZoomInZoomOutValue(value);
      _setSlotDivision(roundedValue);
    } else {
      _setZoomInZoomOutValue(value);
      _setSlotDivision(roundedValue + 1);
    }
    var scrollDiv = document.getElementsByClassName('k-scheduler-layout k-scheduler-layout-flex k-scheduler-day-view').item(0);
    if (scrollDiv) {
      setTimeout(() => {
        scrollDiv.scrollBy(0, 1);
      }, 500);
    }
  }


  const viewRef = useRef('week');
  const [view, setView] = useState('week');

  const setViewData = (data) => {
    viewRef.current = data;
    setView(data);
  }

  const focusedPlanningItemPropRef = useRef({});
  const setFocusedPlanningItemPropRef = (data) => {
    focusedPlanningItemPropRef.current = data;
  };

  const defaultWeekStartData = Object.keys(Day).filter(key => key.length > 1).map(key => ({
    _id: Day[key],
    Description: key,
    bgColor: "white",
  }))[1];
  const [weekStartDay, setWeekStartDay] = useState(defaultWeekStartData);
  const weekStartDayRef = useRef(defaultWeekStartData);

  const setWeekStartDayData = (data) => {
    weekStartDayRef.current = data;
    setWeekStartDay(data);
  };



  const navigate = useNavigate();
  const slotDivisions = SLOT_DIVISIONS;

  //ALL THE POPUP STATES IN SINGLE STATES
  const defaultShowPopup = {
    fastSegmentation: false,
    updateSynopsis: false,
    insertDummyPopup: false,
    programReplacement: false,
    updateTXStatus: false,
    editDummyPopup: false,
    insertProgramGroup: false,
    updateComment: false,
    mediaDealRightPopup: false,
    showEditSlotDurationPopup: false,
    segmentationPopup: false
  }

  const enableSegmentSelectionPopup = true;

  const [showPopups, setShowPopups] = useState(defaultShowPopup);
  const [customSlotDuration, setCustomSlotDuration] = useState("00:00:00:00");

  const synopsisRef = useRef();
  const setSynopsisRef = (data) => {
    synopsisRef.current = data
  }

  const txStatusRef = useRef();
  const [txStatus, _setTxStatus] = useState({});
  const setTxStatus = (data) => {
    _setTxStatus(data);
    txStatusRef.current = data
  }

  const commentRef = useRef();
  const [comment, _setComment] = useState({});
  const setComment = (data) => {
    _setComment(data);
    commentRef.current = data
  }

  const programToReplaceRef = useRef({});
  const setProgramToReplace = (data) => {
    programToReplaceRef.current = data;
  };

  const slotDurationRef = useRef(selectedSlotDuration);
  const setSlotDuration = (duration) => {
    slotDurationRef.current = duration;
    setSlotDurationData(duration);
  };

  const isPlanningLoading = useRef(false);


  const plannerBlankAreaMenuModel = [
    {
      label: `${lang.paste_button_text}`,
      icon: "pi pi-fw pi-book",
      command: (e) => {
        onPaste();
      },
    },
    {
      label: `${'Paste Strand'}`,
      icon: "pi pi-fw pi-book",
      command: (e) => {
        onPasteStrand();
      },
    },
    {
      label: `${lang.insert_program_group_label_planning}`,
      icon: "pi pi-fw pi-upload",
      command: (e) => {
        onInsertProgramGroup(true);
      },
    },
    {
      label: 'Lock / Unlock', icon: 'pi pi-fw pi-lock',
      command: async () => {
        let selectedChannel = filterChannels[selectedSlotRef.current.channelIndex];
        let channel = {
          _id: selectedChannel._id,
          name: selectedChannel.name
        }
        let date = selectedSlotRef.current.SlotDateTime.setUTCHours(0, 0, 0, 0);
        onLockUnlock(channel, date);

      }
    },

    // INSERT MOVIE DUMMY
    {
      label: `${lang.insert_dummy_label_planning}`,
      icon: "pi pi-fw pi-clone",
      command: (e) => {
        onInsertDummy(e);
      },
    },
  ];

  const onLockUnlock = async (channel, date) => {

    let isLocked = lockedPlaylistRef.current.some(data => data.channel._id == channel._id && data.date == date);

    await utility.lockUnlockPlaylist(channel, date, MODULE.PLANNING, !isLocked);

    let channelSID = filterChannels.filter(data => data._id == channel._id)[0].SID;
    let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.SCHEDULE_LOCK_UPDATE,
      module: MODULE.SCHEDULING,
      data: {
        selectedChannelSID: channelSID, selectedScheduleDate: date
      },
      user: { name: userData.name, _id: userData._id }
    });
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_REFRESH, module: MODULE.PLANNING, data: { scheduleScheduleStartDate: date, scheduleScheduleEndDate: date, channelSID: channelSID } });

    let logData = { event: isLocked ? LOGEVENT.UNLOCK_PLANNING : LOGEVENT.LOCK_PLANNING, module: MODULE.PLANNING, data: userData, message: isLocked ? LOGEVENT.UNLOCK_PLANNING : LOGEVENT.LOCK_PLANNING };
    API.SaveLogs(logData);
    refreshPlanning();
  }

  const onLockUnlockItem = async (planningItem) => {
    let isPlanningItemLocked = planningItem?.isLocked ?? false;

    let res = await API.canLockUnlockItem(planningItem._id, !isPlanningItemLocked);
    let currHeaderIndex = displayData.sort((a, b) => a.SlotDateTime - b.SlotDateTime).findIndex(data => data._id == planningItem._id);

    let nextHeaderStartTime = (currHeaderIndex + 1) < displayData.length ? displayData[currHeaderIndex + 1].SlotDateTime : null;
    
    if (res.success) {
      if (isPlanningItemLocked) {
        const result = await API.unlockItem(planningItem, res.data);
        if (result.success) {
          toast.success(`Planning item unlocked successfully`, { position: toast.POSITION.TOP_RIGHT });
          let logData = { event: LOGEVENT.UNLOCK_PLANNING_ITEM, module: MODULE.PLANNING, data:{ headerStartTime: planningItem.SlotDateTime, nextHeaderStartTime: nextHeaderStartTime, header: planningItem, segments: res.data }, message: LOGEVENT.UNLOCK_PLANNING_ITEM };
          API.SaveLogs(logData);
          setDisplayData(old => old.map(data => data._id == planningItem._id ? { ...data, isLocked: !isPlanningItemLocked } : data));
        } else {
          toast.error(result.message, { position: toast.POSITION.TOP_RIGHT });
        }
        return;
      } else {
        // if can lock then show popup
        setHeaderLockPopup({
          show: true,
          segments: res.data,
          header: planningItem,
          nextHeaderStartTime: nextHeaderStartTime
        });
        console.log(res.data);
      }
    } else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  const refreshSchedule = (channel, date) => {

    let channelSID = channel.SID && channel.SID > 0 ? channel.SID : filterChannels.filter(data => data._id == channel._id)[0]?.SID ?? 0;

    if (channelSID == 0) return;

    let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.SCHEDULE_REFRESH,
      module: MODULE.SCHEDULING,
      data: {
        selectedChannelSID: channelSID, selectedScheduleDate: date
      },
      user: { name: userData.name, _id: userData._id }
    });
  }

  const plannerSelectedItemsRef = useRef([]);
  const setPlannerSelectedItems = (data) => {
    plannerSelectedItemsRef.current = data;
    props.onSelectedPlanningItem(data);
  };

  const plannerOnItemMenuModel = [
    {
      label: `${lang.open_schedule_button_text_planning}`,
      icon: "fa fa-upload ml-1 pr-1 p-menuitem-icon",
      command: () => {

        if (!utility.hasModuleRights(MODULE.SCHEDULING)) {
          toast.error(`${lang.no_rights_scheduling_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
        if (focusedPlanningItemPropRef.current) {
          // utility.setValue(LOCALSTORAGE_KEY.publishData, { ...focusedPlanningItemPropRef.current.dataItem, start_time: focusedPlanningItemPropRef.current.start_time ?? '00:00' });
          utility.setSessionValue(
            LOCALSTORAGE_KEY.publishData,
            focusedPlanningItemPropRef.current.dataItem
          );
          navigate("/home/Scheduling");
        } else {
          console.log("not selected item");
          alert("Pleaseselect planning item");
        }
      },
    },
    {
      label: `${lang.edit_actionbutton_option}`,
      icon: "pi pi-fw pi-plus",
      command: () => {
        focusedPlanningItemPropRef.current.onDoubleClick();
      },
    },
    {
      label: `${lang.delete_actionbutton_option}`,
      icon: "pi pi-fw pi-trash",
      command: () => {
        showDeleteMenu();
      },
    },
    {
      label: `${lang.cut_label}`,
      icon: "pi pi-fw pi-times",
      command: () => {
        if (plannerSelectedItemsRef.current.length == 0) {
          setPlannerSelectedItems([
            focusedPlanningItemPropRef.current.dataItem,
          ]);
        }

        onCut();
      },
    },
    {
      label: `${lang.copy_button_text}`,
      icon: "pi pi-fw pi-copy",
      command: () => {
        if (plannerSelectedItemsRef.current.length == 0) {
          setPlannerSelectedItems([
            focusedPlanningItemPropRef.current.dataItem,
          ]);
        }

        onCopy();
      },
    },
    {
      label: lang.show_media_label,
      icon: "pi pi-fw pi-eye",
      command: () => {
        onShowMedia(focusedPlanningItemPropRef.current.dataItem.mediaEpisode);
      }
    },
    {
      label: `${lang.apply_recursive_button_text_planning}`,
      icon: "pi pi-fw pi-bars",

      command: () => {
        setPlannerSelectedItems([focusedPlanningItemPropRef.current.dataItem]);
        props.onSelectApplyRecursive();
        // setPlannerSelectedItems([]);
      },
    },
    {
      label: `${lang.apply_distribution_button_text_planning}`,
      icon: "pi pi-fw pi-sliders-h",

      command: () => {
        setPlannerSelectedItems([focusedPlanningItemPropRef.current.dataItem]);
        props.onSelectDistributePlanningAcrossChannels();
      },
    },
    {
      label: `Loop`,
      icon: "pi pi-fw pi-circle",

      command: () => {
        onShowLoopPopup(focusedPlanningItemPropRef.current.dataItem);
      },
    },
    {
      label: lang.lock_unlock_label,
      icon: "pi pi-fw pi-lock",
      command: () => {
        let selectedChannel = focusedPlanningItemPropRef.current.dataItem.Channel;
        let channel = {
          _id: selectedChannel._id,
          name: selectedChannel.name
        }
        let date = focusedPlanningItemPropRef.current.start.setUTCHours(0, 0, 0, 0);
        onLockUnlock(channel, date);
      },
    },
    {
      label: lang.lock_unlock_label + ' Item',
      icon: "pi pi-fw pi-lock",
      command: () => {
        onLockUnlockItem(focusedPlanningItemPropRef.current.dataItem);
      },
    },
    {
      label: `${lang.history_button_text_planning}`,
      icon: "pi pi-fw pi-history",
      command: () => {
        setPlannerSelectedItems([focusedPlanningItemPropRef.current.dataItem]);
        props.onSelectShowHistory();
      },
    },
    //update synopsis
    {
      label: `${lang.update_synopsis}`,
      icon: "pi pi-fw pi-align-center",
      command: () => {
        setSynopsisRef(focusedPlanningItemPropRef.current.dataItem.SlotSynopsis ?? '');
        setShowPopups({ ...defaultShowPopup, updateSynopsis: true })
      },
    },
    {
      label: lang.show_exhibitions_label,
      icon: "pi pi-fw pi-book",
      command: () => {
        setPlannerSelectedItems([focusedPlanningItemPropRef.current.dataItem]);
        onShowExhibitions();
      }
    },
    //FAST SEGMENTATION
    {
      label: `${lang.fast_segmentation_actionbutton_menutext}`,
      icon: 'pi pi-fw pi-bolt',
      command: () => { setShowPopups({ ...defaultShowPopup, fastSegmentation: true }) }
    },

    //REPLACE
    {
      label: `${lang.replace_actionbutton_menutext}`,
      icon: "pi pi-fw pi-sync",
      command: () => { setShowPopups({ ...defaultShowPopup, programReplacement: true }) }
    },


    // update custom duartion
    {
      label: `Set Custom Duration`,
      icon: "pi pi-fw pi-pencil",
      command: () => {
        setCustomSlotDuration(utility.convertMilisecondsToStringWithFrames(focusedPlanningItemPropRef.current?.dataItem?.customDuration ?? focusedPlanningItemPropRef.current?.dataItem?.mediaEpisode?.Duration ?? 0));
        setShowPopups({ ...defaultShowPopup, showEditSlotDurationPopup: true })
      }
    },

    {
      label: `Clear Custom Duration`,
      icon: "pi pi-fw pi-calendar-times",
      command: () => {
        onClearCustomDuration();
      }
    },

    //Update Comments
    {
      label: `${lang.add_comment_actionbutton_menutext}`,
      icon: "pi pi-fw pi-comment",
      command: () => {
        setComment(focusedPlanningItemPropRef.current?.dataItem?.Comment ?? '');
        setShowPopups({ ...defaultShowPopup, updateComment: true })
      }
    },

    //EDIT DUMMY 
    {
      label: `${lang.edit_dummy_actionbutton_menutext}`,
      icon: "fa-solid fa-fw fa-pen-to-square",
      command: () => { onEditDummy() }
    },
    {
      label: lang.show_logs_label,
      icon: "pi pi-fw pi-external-link",
      command: () => {
        setPlannerSelectedItems([focusedPlanningItemPropRef.current.dataItem]);
        let path = '/home/AuditLog' + "?module=" + MODULE.PLANNING + "&Header_id=" + focusedPlanningItemPropRef.current.dataItem?._id + "&title=" + focusedPlanningItemPropRef.current.dataItem?.mediaEpisode?.Title
        window.open(path, "_blank");
      }
    },
    //Mark Change Flag
    {
      label: `Mark as Changed `,
      icon: "pi pi-sync",
      command: () => {
        onMarkChangeFlag(true);
      }
    },
    //Clear Change Flag
    {
      label: `Mark as Not Changed `,
      icon: "pi pi-undo",
      command: () => {
        onMarkChangeFlag(false);
      }
    },
    //Update Tx Status
    {
      label: `${lang.update_txstatus_actionbutton_menutext}`,
      icon: "fa fa-solid fa-satellite-dish",
      command: () => {
        setTxStatus(focusedPlanningItemPropRef.current?.dataItem?.TXStatus);
        setShowPopups({ ...defaultShowPopup, updateTXStatus: true })
      }
    },
    // Important
    {
      label: focusedPlanningItemPropRef?.current?.dataItem?.isImportant ? "Remove As Important " : "Set As Important ",
      icon: "fa fa-exclamation-triangle",
      command: () => {
        handleSetImportant(focusedPlanningItemPropRef?.current?.dataItem?.isImportant ? false : true);
      }
    },
  ];

  //const visibleAreaRef = useRef({ startHour: offSetTime, endHour: offSetTime + 3 });
  const visibleAreaRef = useRef({ startHour: offSetTime, endHour: offSetTime + 3 });

  const localPlanningData = useRef([]);
  const dataRef = useRef([]);
  const setData = (d) => {
    dataRef.current = d;

    let newDisplayData = PlanningHelper.getDisplayData(d, visibleAreaRef.current.startHour, visibleAreaRef.current.endHour);
    setDisplayData(newDisplayData);
  }

  const localPlanningUIRefresh = () => {
    setData(localPlanningData.current);
  }
  const [displayData, setDisplayData] = useState([]);
  const [headerLockPopup, setHeaderLockPopup] = useState({
    show: false,
    segments: [],
    header: null,
    nextHeaderStartTime: null
  });
  const refForBlankArea = useRef();
  const refForItem = useRef();
  const schedulerViewSlotRef = useRef();
  const MyScheduler = createRef();
  const [publishStatusData, setPublishStatusData] = useState([]);
  const [zoom, setZoom] = useState(20);
  // to activate weekday range
  // const [weekDayRange, setWeekDayRange] = useState({
  //   start: MyDays[0],
  //   end: MyDays[MyDays.length - 1],
  // });

  const selectedSlotRef = useRef({ SlotDateTime: null, channelIndex: 0 });
  const setSelctedSlot = (data) => {
    selectedSlotRef.current = data;
    //_setSelctedSlot(data);
  };


  const plannerCopiedItemsRef = useRef([]);
  const setPlannerCopiedItems = (data) => {
    console.log(data);
    plannerCopiedItemsRef.current = data;
    props.onSetPlannerCopiedItems(data);
  };

  const lastDroppedItemRef = useRef({});
  const setLastDroppedItem = (data) => {
    lastDroppedItemRef.current = data;
  };

  const lockedPlaylistRef = useRef([]);
  const setLockedPlaylist = (data) => {
    lockedPlaylistRef.current = data;
  };



  const resources = [
    {
      name: "Channels",
      data: filterChannels.map((channel) => {
        return {
          text: channel.name,
          value: channel.SID,
          color: channel.Color != null ? channel.Color : "#7FB77E",
        };
      }),
      field: "ChannelSID",
      valueField: "value",
      textField: "text",
      colorField: "color",
    },
  ];

  //for context menu and planner styling
  useEffect(() => {
    var schElem = document.getElementById("PlannerID");
    if (schElem) {
      schElem.style.height = "100%";
      schElem.addEventListener("contextmenu", onContextMenu);
      schElem.addEventListener("mouseenter", () => allowCtrlAPopup = true);
      schElem.addEventListener("mouseleave", () => allowCtrlAPopup = false);
    }

    var scrollDiv = document.getElementsByClassName('k-scheduler-layout k-scheduler-layout-flex k-scheduler-day-view').item(0);
    scrollDiv.addEventListener("scroll", onScroll);


    //Scewhduler Drop
    let schedulerElement = MyScheduler.current.element;
    schedulerElement.addEventListener("dragover", handleDragOver);
    document.addEventListener("copy", onCopy);
    document.addEventListener("cut", onCut);
    document.addEventListener("paste", onPaste);
    document.addEventListener("keyup", onEscape);
    document.addEventListener("keydown", onKeyDown);
    // setTimeZone();
    return () => {
      if (schElem) {
        schElem.removeEventListener("contextmenu", onContextMenu);
        schElem.removeEventListener("mouseenter", () => allowCtrlAPopup = true);
        schElem.removeEventListener("mouseleave", () => allowCtrlAPopup = false);
      }

      //Deregister
      scrollDiv.removeEventListener("scroll", onScroll);
      schedulerElement.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("cut", onCut);
      document.removeEventListener("paste", onPaste);
      document.removeEventListener("keyup", onEscape);
      document.removeEventListener("keydown", onKeyDown);


    };
  }, [slotDivision, zoomInZoomOutValue, slotDuration, view]);

  useEffect(() => {
    // setTimeZone();
  }, [slotDuration]);


  //socket
  const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

    if (socketData.module != MODULE.PLANNING) return;

    console.log(filterChannels);
    console.log(socketData.data);

    // in add, update object comes and in delete array comes.
    if (socketData.action == SOCKET_ACTION.PLANNING_UPDATE || socketData.action == SOCKET_ACTION.PLANNING_NEW_DROP) {
      if (!filterChannels.some(x => x.SID == socketData.data.Channel.SID)) return;
    }

    switch (socketData.action) {
      case SOCKET_ACTION.PLANNING_REFRESH:
        if (canRefreshPlanning(socketData)) refreshPlanning();
        break;
      case SOCKET_ACTION.PLANNING_NEW_DROP:
        console.log(socketData.data)
        let planningObjectForUI = socketData.data;
        planningObjectForUI.Style = PlanningHelper.getPlanningColorPreference(planningObjectForUI);
        let startDateTime = PlanningHelper.getSlotTimeBySelectedStartType(planningObjectForUI, props.startType, offSetTime);
        let Duration = PlanningHelper.getSelectedDurationType(planningObjectForUI, props.durationType, slotDurationRef.current);
        let endDateTime = new Date(startDateTime.getTime() + Duration);


        startTransition(() =>
          setData([
            ...dataRef.current,
            {
              ...planningObjectForUI,
              StartDate: startDateTime,
              EndTime: endDateTime
            },
          ])
        );
        break;
      case SOCKET_ACTION.PLANNING_UPDATE:
        update_planning(socketData.data);
        break;
      case SOCKET_ACTION.PLANNING_DELETE:
        setData(dataRef.current.filter((item) => socketData.data.find((current) => current._id === item._id) === undefined));
      default:
        break;
    }
  });

  const canRefreshPlanning = (socketData) => {

    try {

      // if user is not working on socket triggered channel
      if (!filterChannels.some(x => x.SID == socketData.data.channelSID)) {
        return false;
      }

      // validating date range here for socket trigger
      // START
      let startDate = (new Date(date)).setUTCHours(0, 0, 0, 0);
      let endDate = (new Date(date)).setUTCHours(0, 0, 0, 0);

      if (viewRef.current == "work-week") { //for day view      
        startDate = startDate + offSetTime;
        endDate = endDate + offSetTime + MILLISECONDSINADAY;
      }
      else { //for week view      
        startDate = (moment(date).utc().startOf("week").add(weekStartDayRef.current._id, "days").toDate().setUTCHours(0, 0, 0, 0)) + offSetTime;
        endDate = (moment(date).utc().endOf("week").add(weekStartDayRef.current._id, "days").toDate().setUTCHours(0, 0, 0, 0)) + offSetTime + MILLISECONDSINADAY;
      }

      const socketStartDate = socketData.data.scheduleScheduleStartDate;
      const socketEndDate = socketData.data.scheduleScheduleEndDate;

      if ((startDate <= socketStartDate && endDate >= socketEndDate) ||
        (startDate >= socketStartDate && startDate <= socketEndDate) ||
        (endDate >= socketStartDate && endDate <= socketEndDate) ||
        (startDate >= socketStartDate && endDate <= socketEndDate)) {
        return true;
      }
      // END
      return false;

    } catch (error) {
      return true;
    }
  }


  const setTimeZone = () => {
    let elements = document.getElementsByClassName("k-major-cell");
    let startMilisecond = offSetHours * 3600000;
    let gapMiliSecond = (24 * 60 / (elements.length) * 60000);

    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      let date = new Date(startMilisecond);
      let hours = date.getUTCHours();
      let min = date.getUTCMinutes();
      hours = hours >= 24 ? hours - 24 : hours;
      element.innerHTML = String(hours).padStart(2, "0") + ":" + String(min).padStart(2, "0");
      startMilisecond += gapMiliSecond;
    }

  };

  useEffect(() => {
    setPlanningPreference();
  }, [props.doLoad, isPrimeTime]);

  //for loading data
  useEffect(() => {
    loadCombo();
    refreshPlanning();
  }, [date, filterChannels, props.doLoad, props.durationType, isPrimeTime, props.startType]);

  //for loading data
  useEffect(() => {
    if (props.planningGroupItems?.MediaEpisodes) {
      loadPlanningGroupItem();
    }
  }, [props.planningGroupItems]);

  const loadCombo = async () => {
    let txStatusRes = await API.getDataLookup(ENTITYNAME.TXStatus, { sort: { SID: 1 } });
    setTxStatusData(txStatusRes.data);
  }

  const refreshPlanning = () => {
    loadPlanningData();
    loadPublishStatusData();
    setPlannerSelectedItems([]);
    // setTimeZone();
    getLockedPlaylist();
  }

  const setPlanningPreference = () => {
    setWeekStartDayData(preferenceData?.data[0]?.value?.weekStartDay ?? Object.keys(Day).filter(key => key.length > 1).map(key => ({
      _id: Day[key],
      Description: key,
      bgColor: "#FFFF00",
    }))[1])

    let selectedSlotDuration = (utility.getSeassionValue(LOCALSTORAGE_KEY.SELECTED_PLANNING_SLOT_DURATION) ?? preferenceData?.data[0]?.value?.SlotDuration) ?? 60

    setSlotDuration(selectedSlotDuration);
    let index = SLOT_TIMES.indexOf(selectedSlotDuration);
    setSlotDivision(slotDivisions[index]);
    setViewData(preferenceData?.data[0]?.value?.View?.View ?? 'week');
    setStartType(preferenceData?.data[0]?.value?.StartType ?? 'Nominal');
    //resetPlanningGridHeight();

  }

  const loadPlanningGroupItem = async () => {
    var startTime = selectedSlotRef.current.SlotDateTime.getTime();

    for (
      let index = 0;
      index < props.planningGroupItems.MediaEpisodes.length;
      index++
    ) {
      let mediaEpisode = props.planningGroupItems.MediaEpisodes[index];
      props.setStatus(`${lang.creating_label}` + mediaEpisode.AssetId);
      await addPlanningItem(
        new Date(startTime),
        filterChannels[selectedSlotRef.current.channelIndex],
        mediaEpisode,
        null
      );
      startTime = startTime + mediaEpisode.Duration;
    }
    props.setStatus(lang.successfully_created_group_label);
  };

  const loadPublishStatusData = async () => {
    const res = await API.getData(ENTITYNAME.PublishStatus);
    if (res.success) {
      setPublishStatusData(res.data);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useImperativeHandle(ref, () => {
    return {
      selectAllPlanningData() {
        console.log(dataRef.current);
        setPlannerSelectedItems(dataRef.current);
        toast.success("All items selected", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },

      selectAllPlanningDataForSelectedDate() {
        if (selectedSlotRef.current.SlotDateTime == null && plannerSelectedItemsRef.current.length == 0) {
          toast.error(
            "First select a slot to select all planning items for that date",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return;
        }
        let selectedDate = plannerSelectedItemsRef.current.length > 0 ? new Date(plannerSelectedItemsRef.current[0].SlotDateTime).setUTCHours(0, 0, 0, 0) : selectedSlotRef.current.SlotDateTime.setUTCHours(0, 0, 0, 0);
        console.log(selectedDate);
        let filteredData = dataRef.current.filter(x => x.SlotDateTime >= selectedDate && x.SlotDateTime < (selectedDate + (24 * 60 * 60 * 1000)));
        setPlannerSelectedItems(filteredData);
        toast.success("All items selected for " + moment(selectedDate).format('ll'), {
          position: toast.POSITION.TOP_RIGHT,
        });
      },

      selectItemsOfSimilarEpisodes() {
        if (plannerSelectedItemsRef.current && plannerSelectedItemsRef.current.length > 0) {
          let selectedEpisode = plannerSelectedItemsRef.current[0].mediaEpisode;
          let filteredData = dataRef.current.filter(x => x.mediaEpisode.AssetId == selectedEpisode.AssetId);
          setPlannerSelectedItems(filteredData);
          toast.success("All items selected for " + selectedEpisode.AssetId, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error("Please select a planning item", {
          });

        }
      },

      selectAllEpisodesOfSelectedSeries() {
        if (plannerSelectedItemsRef.current && plannerSelectedItemsRef.current.length > 0) {
          let selectedEpisode = plannerSelectedItemsRef.current[0].mediaEpisode;
          if (selectedEpisode.Series_id && selectedEpisode?.Series_id == null) {
            toast.error("Please select a series", {
            });
            return;
          }
          let filteredData = dataRef.current.filter(x => x.mediaEpisode?.Season_id == selectedEpisode?.Season_id);
          setPlannerSelectedItems(filteredData);
          toast.success("All items selected for " + selectedEpisode.Season_id, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error("Please select a planning item", {
          });

        }
      },

      async deletePlanningItemFromMenu(option = PLANNING_DELETE_OPTION.DELETE_SELECTED) {
        // 0 -> Delete Selected
        // 1 -> Delete Slot and Push Forward in the Strand
        // 2 -> Delete Episode and Pull Backward in the Strand   
        // 3 -> Delete All the Events Forward in the Strand
        // 4 -> Delete Episode in future from selected slot forward
        if (plannerSelectedItemsRef.current.length == 0) {
          toast.error('Please first select planning item');
          return;
        }

        if (option == PLANNING_DELETE_OPTION.DELETE_SELECTED) {
          await deletePlanningItem();
        } else {
          let selectedItem = plannerSelectedItemsRef.current[0];
          if (!selectedItem.hasOwnProperty('RecursionParentProgramSchedule_id') || (selectedItem.hasOwnProperty('RecursionParentProgramSchedule_id') && selectedItem.RecursionParentProgramSchedule_id.length == 0)) {
            toast.error(`Selected item is not a part of strand`,
              { position: toast.POSITION.TOP_RIGHT }
            );
            return;
          }
          props.setStatus('Deleting Planning...');
          let res = await API.deletePlanningByOptions(option, selectedItem);
          if (!res.success) {
            toast.error(res?.message ?? 'Error');
          }
        }
      },

      getSelectedSlotDuration() {
        return slotDuration;
      }



    };
  }, [dataRef.current]);


  const onKeyDown = (e) => {

    if (e && e.target && e.target.localName == "input") {
      return;
    }

    if (e.ctrlKey && (e.key === 'a' || e.key === 'A') && e.target.localName != "input" && allowCtrlAPopup) {
      e.preventDefault();
      showCtrlADialog();
      return;
    }
    if (e.key == 'F12' || e.keyCode == 123) {
      e.preventDefault();
      if (plannerSelectedItemsRef.current.length == 1) {
        utility.setSessionValue(
          LOCALSTORAGE_KEY.publishData,
          plannerSelectedItemsRef.current[0]
        );
        navigate("/home/Scheduling");
      } else {
        console.log("not selected item");
        alert("Please select one planning item to open schedule");
      }
    }
    //for delete key
    else if (e.key == 'Delete' || e.keyCode == 46) {
      e.preventDefault();

      if (plannerSelectedItemsRef.current == undefined || plannerSelectedItemsRef.current.length == 0) {
        toast.info(
          'First select planner items you want to delete',
        )
        return;
      }

      showDeleteMenu();
    }
  }
  const onEscape = (e) => {
    if (e.keyCode == 27 || e.key == "Escape") {
      setPlannerCopiedItems([]);
    }
  }


  const onCut = async () => {
    onCopy();
    await deletePlanningItem();

  }
  const onCopy = () => {
    setPlannerCopiedItems([...plannerSelectedItemsRef.current]);
    console.log("copied" + plannerSelectedItemsRef.current.length);
  };


  const onPasteStrand = async () => {
    if (plannerCopiedItemsRef.current.length == 0 || plannerCopiedItemsRef.current.length > 1) {
      toast.error(
        'First copy planner items you want to paste in strand',
      )
      return;
    }
    let selectedItem = plannerCopiedItemsRef.current[0];
    console.log(selectedItem);
    if (!selectedItem.hasOwnProperty('RecursionParentProgramSchedule_id') || (selectedItem.hasOwnProperty('RecursionParentProgramSchedule_id') && selectedItem.RecursionParentProgramSchedule_id.length == 0)) {
      toast.error(`Selected item is not a part of strand`,
        { position: toast.POSITION.TOP_RIGHT }
      );
      return;
    }
    props.setStatus('Pasting Strand...');
    let res = await API.copyStrandAndPaste(selectedItem, selectedSlotRef.current.SlotDateTime.getTime() + offSetTime);
    if (!res.success) {
      toast.error(res?.message ?? 'Error');
    }
    props.setStatus('Pasted Strand');
    // refresh data
    refreshPlanning();

  }


  const onPaste = async (e, isCut = false) => {
    console.log(e);

    if (e && e.target && e.target.localName == "input") {
      return;
    }
    console.log(plannerCopiedItemsRef.current);
    let fetchSegmentFromDB = !isCut;
    if (plannerCopiedItemsRef.current.length == 0) {
      toast.error(
        `${lang.first_copy_planner_items_you_want_to_paste_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setPlannerSelectedItems([]);
      return;
    }
    console.log(selectedSlotRef.current);
    if (selectedSlotRef.current.SlotDateTime == null) {
      toast.error(
        `${lang.please_select_area_on_planner_where_to_paste_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    var plannerCopiedItemsOrdered = plannerCopiedItemsRef.current.sort(
      (a, b) => a.SlotDateTime - b.SlotDateTime
    );

    var startDateTime = selectedSlotRef.current.SlotDateTime;
    var selectedChannelIndex = selectedSlotRef.current.channelIndex;
    plannerCopiedItemsOrdered.map(async (item, index) => {
      if (!isCut) {
        delete item["SID"];
        delete item["_id"];
      }
      if (index > 0) {
        var previousSlotItem = plannerCopiedItemsOrdered[index - 1];
        var startDateTimeMillisecond =
          startDateTime.getTime() +
          (item.SlotDateTime - previousSlotItem.SlotDateTime);
        startDateTime = new Date(startDateTimeMillisecond);
      }
      var selectedChannel = getSelectedChannel(selectedChannelIndex);

      console.log("selectedChannel");
      console.log(selectedChannel);

      //CASE : LIVE EVENT COPY / PASTE DO NOT CREATE SEGMENTATION
      let txStatus = item?.TXStatus?.Description == 'Live' ? { SID: 2, Description: 'Repeat' } : undefined;
      if (
        item?.mediaEpisode?.IsLive &&
        item?.Segment_id != null &&
        (item?.segments?.length > 0 || item?.mediaEpisode?.segments?.length > 0)
      ) {

        let currentSegment = item?.segment ? item?.segment : item?.mediaEpisode?.segments.find((x) => x._id == item?.Segment_id)

        if (currentSegment?.SegmentType?.TXMode?.Name == utility.getKeyByValue(TX_MODE, TX_MODE.LIVE)) {

          fetchSegmentFromDB = false;
          item.Segment_id = null;

        }
      }

      await addPlanningItem(
        startDateTime,
        selectedChannel,
        item.mediaEpisode,
        item.MediaDealRight,
        fetchSegmentFromDB, // if copy then we need to pull segments
        "",
        0,
        item.Segment_id,
        txStatus
      );

      props.setStatus(`${lang.pasted_label}${index + 1}/${plannerCopiedItemsRef.current.length}${lang.items_label}`);

      //IF COPIED ITEM IS MORE THAN 50 DO SILENT REFRESH WHILE PASTING
      if(plannerCopiedItemsOrdered.length > 50){

        //SILENT REFRESH
        console.log("SILENT REFRESH")
        let channel = focusedPlanningItemPropRef.current.dataItem.Channel;
        let date = new Date(focusedPlanningItemPropRef.current.dataItem.SlotDateTime).setUTCHours(0, 0, 0, 0);
        refreshSchedule(channel, date);
        
      }

    });
  };

  const getSelectedChannel = (selectedChannelIndex) => {
    let filteredChannelLocal = [...filterChannels];
    return filteredChannelLocal[selectedChannelIndex];
  };

  var timeout = null;
  const onScroll = (e) => {

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const cellHeight = document.getElementsByClassName('k-scheduler-cell').length > 0 ? document.getElementsByClassName('k-scheduler-cell').item(0).clientHeight : 73;
      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target.clientHeight;
      const slotHeight = (slotDivision) * cellHeight;
      const firstVisibleSlotIndex = Math.floor(scrollTop / slotHeight);
      const lastVisibleSlotIndex = Math.floor((scrollTop + clientHeight) / slotHeight);

      let primeStartTimeInMillisecond = moment(primeStartTime, "HH:mm").get('hours') * 60 * 60 * 1000;
      let primeEndTimeInMillisecond = moment(primeEndTime, "HH:mm").get('hours') * 60 * 60 * 1000;
      if (!isPrimeTime) {
        primeStartTimeInMillisecond = 0;
        primeEndTimeInMillisecond = 0;
      }

      let startDateTime = new Date((firstVisibleSlotIndex * slotDuration * 60000) + primeStartTimeInMillisecond).getUTCHours();
      let endDateTime = new Date((lastVisibleSlotIndex * slotDuration * 60000) + primeEndTimeInMillisecond).getUTCHours();

      visibleAreaRef.current = { startHour: startDateTime, endHour: endDateTime };
      console.log(visibleAreaRef.current);
      let newDisplayData = PlanningHelper.getDisplayData(dataRef.current, startDateTime, endDateTime);

      setDisplayData(newDisplayData);
    }, 200);
  }

  //this function calls when we drop media into planning
  const handleDragOver = (e) => {
    e.preventDefault();
    // to revert to normal comment everything except e.preventDefault();
    if (e.target.dataset.slotStart) {
      let timeString = PlanningHelper.getPlanningDropTime(offSetHours, e.target.dataset.slotStart);

      let oldElem = document.getElementById("drag-ghost-del");
      let elem = document.getElementById("drag-ghost");
      let slot = e.srcElement.getBoundingClientRect();
      let duration = (parseInt(oldElem.title ?? 0) / 60000) / slotDurationRef.current;

      let height = (slot.height * (duration * (slotDivision))) + "px";

      if (!elem) {
        elem = document.createElement("div");
        elem.id = "drag-ghost";
        elem.textNode = "Dragging";
        elem.style.position = "fixed";
        elem.style.width = slot.width + "px";
        elem.style.height = height;
        elem.style.backgroundColor = "#3F497F";
        elem.style.whiteSpace = "pre-line";
        elem.style.zIndex = 10000;
        elem.style.transition = "0.1s";
        elem.style.opacity = 0.6;
        elem.style.pointerEvents = "none";
        elem.style.userSelect = "none";
        elem.style.cursor = "pointer";
        document.body.appendChild(elem);
      }
      elem.innerHTML = timeString + '\r\n' + oldElem.innerText;
      elem.style.left = slot.left + "px";
      elem.style.top = slot.top + "px";
      elem.width = slot.width + "px";
      elem.height = height;

    }
  };

  const onContextMenu = (e) => {
    //blank area
    if (e.target.className.includes("k-scheduler-cell k-slot-cell")) {
      e.preventDefault();
      let slotStartDate = new Date(parseInt(e.target.dataset.slotStart));
      let channelGroup = parseInt(e.target.dataset.slotGroup);
      schedulerViewSlotEvent(slotStartDate, channelGroup);

      refForBlankArea.current.show(e);
      refForItem.current.hide(e);
    }
    //planner item
    if (
      e.target.className.includes("k-event") ||
      e.target.className.includes("customItem")
    ) {
      e.preventDefault();
      refForItem.current.show(e);
      refForBlankArea.current.hide(e);
    }
    //resize handle
    if (e.target.className.includes("k-resize-handle")) {
      e.preventDefault();
    }
  };

  const deletePlanningItem = async () => {
    if (plannerSelectedItemsRef.current.length < 1) {
      toast.error(
        `${lang.please_select_content_to_delete_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      return;
    }

    var dataItemArray = [];
    dataItemArray = plannerSelectedItemsRef.current;

    var deleteIds = [];
    var deletedPlanItemsForLog = [];
    //ui delete
    let newData = dataRef.current.filter((data) => {
      if (!dataItemArray.some((item) => item._id == data._id)) {
        return data;
      } else {
        deletedPlanItemsForLog.push(data);
        deleteIds.push({
          _id: data._id,
          MediaDealRight_id: data.MediaDealRight_id,
          Channel: data.Channel,
          date: new Date(data.SlotDateTime).setUTCHours(0, 0, 0, 0)
        });
      }
    });
    setData(newData);
    //api delete
    deleteIds.map((obj) => {
      API.deletePlanning(obj).then((response) => {
        if (response.success && response.data) {

          //UPDATE LIVE EVENT STATUS WHILE DELETING LIVE EVENT
          let deleteItem = dataItemArray.find((x) => x._id == obj._id);
          if (deleteItem != null) {
            handlePublishUnPublishLiveEvent({
              scheduleMatchDataItem: deleteItem.mediaEpisode,
              slotDateTime: deleteItem.SlotDateTime,
              publish: false
            });
          }

          if (response.data.dealRightHeaders && response.data.dealRightHeaders.length > 0) {
            updateExhibitionAndRepeatOnUI(response.data.dealRightHeaders);
          }

        } else if (!response.success) {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    });
    deletedPlanItemsForLog.map((item) => {
      let logData = { event: LOGEVENT.DELETE_PLANNING, module: MODULE.PLANNING, data: item, message: lang.delete_planning_log_message };
      API.SaveProgramScheduleHeaderLogs(logData);
    })

    if (deleteIds.length > 0) {
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_DELETE, module: MODULE.PLANNING, data: deleteIds })
      let uniqueDeleteItems = utility.removeDuplicate(deleteIds, "date");

      uniqueDeleteItems.map((item) => {
        refreshSchedule(item.Channel, item.date);
      })


    }

    setPlannerSelectedItems([]);
  };

  const loadPlanningData = async () => {
    props.setStatus(<>{lang.planning_loading_label} <i className="fa fa-spin fa-spinner fa-xl" /></>);
    isPlanningLoading.current = true;

    // Default For Day View
    let startDate = (new Date(date)).setUTCHours(0, 0, 0, 0);
    let endDate = (new Date(date)).setUTCHours(0, 0, 0, 0);
    console.log(viewRef.current);

    if (viewRef.current == "work-week") {
      //for day view
      startDate = startDate + offSetTime;
      endDate = endDate + offSetTime + MILLISECONDSINADAY;
    }
    // else if(view == "month"){
    //   //for month
    //   startDate.setDate(0);//this brings the last date of previous month
    //   endDate.setDate(32);//this brings the first date of next month or 2nd date in case the month is of 31 days
    // }
    else {
      //for week view
      console.log(weekStartDayRef.current);
      startDate = ((moment(date).utc().startOf("week").add(weekStartDayRef.current._id, "days").toDate().setUTCHours(0, 0, 0, 0)) + offSetTime) - MILLISECONDSINADAY;
      endDate = (moment(date).utc().endOf("week").add(weekStartDayRef.current._id, "days").toDate().setUTCHours(0, 0, 0, 0)) + offSetTime + MILLISECONDSINADAY;
    }

    var payload = {
      startDate: startDate,
      endDate: endDate,
      channels: filterChannels.map((channel) => channel.SID),
    };

    props.setPayload(payload)

    console.log(payload);

    let response = await API.getPlanning(payload);

    PlanningHelper.loadPlanningPrefrence();

    console.log(response);

    if (response.success) {
      if (response.data && response.data.length > 0) {
        let planningData = response.data.map((obj) => {
          var startDateTime = PlanningHelper.getSlotTimeBySelectedStartType(obj, props.startType, offSetTime);
          var Duration = PlanningHelper.getSelectedDurationType(
            obj,
            props.durationType,
            slotDurationRef.current
          );
          var endDateTime = new Date(startDateTime.getTime() + Duration);

          return {
            ...obj,
            Title: obj.customTitle ? obj.customTitle : obj.mediaEpisode.Title,
            Description: obj.customTitle ? obj.customTitle : obj.mediaEpisode.Description,
            StartDate: startDateTime,
            EndTime: endDateTime,
            SlotDate: new Date(obj.ScheduleDate),
            ChannelSID: obj.Channel.SID,
            Style: PlanningHelper.getPlanningColorPreference(obj),
            InvalidReason: PlanningHelper.getInvalidReason(obj),
          };
        });
        setData(planningData);
        localPlanningData.current = planningData;
      }
      else {
        console.log(response.data);
        setData([]);
      }

      props.setStatus(lang.planning_loaded_label);
      isPlanningLoading.current = false;
    } else {
      isPlanningLoading.current = false;
      props.setStatus(lang.planning_loading_error_label);
      toast.error(`${lang.error_fetching_planning_data_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
      // alert("Error fetching planning data");
    }
    let scrollTop = document.getElementsByClassName('k-scheduler-layout k-scheduler-layout-flex k-scheduler-day-view').item(0).scrollTop;
    let clientHeight = document.getElementsByClassName('k-scheduler-layout k-scheduler-layout-flex k-scheduler-day-view').item(0).clientHeight;
    onScroll({ target: { scrollTop: scrollTop, clientHeight: clientHeight } });
  };


  const resetPlanningGridHeight = () => {

    console.log("Resetting Height");

    setData(dataRef.current.map((item) => {
      let duration = PlanningHelper.getSelectedDurationType(item, props.durationType, slotDurationRef.current);
      var endDateTime = new Date(item.SlotDateTime + duration);

      let updatedItem = {
        ...item,
        EndTime: endDateTime,
      };
      return updatedItem;
    })
    );
  }

  const getLockedPlaylist = async () => {
    const lockPlaylist = await utility.getLockedPlaylist(date, MODULE.PLANNING);
    if (lockPlaylist.success) {
      console.log(lockPlaylist);
      setLockedPlaylist(lockPlaylist.data);
    }
  }

  //changes date in scheduler
  const onDateChange = (event) => {
    props.onDateChange(event.value);
  };

  //function to update data when handledrop item dropped
  const onUpdateFromPlanningEditForm = useCallback((updatedData) => {
    //need to update the item

    setData(dataRef.current.map((item) => {
      if (item._id == updatedData._id) {
        console.log(updatedData)
        let duration = PlanningHelper.getSelectedDurationType(updatedData, props.durationType, slotDurationRef.current);

        let updatedItem = {
          ...item,
          Title: updatedData.customTitle ? updatedData.customTitle : item.Title,
          Segment_id: updatedData.Segment_id,
          SlotDateTime: updatedData.SlotDateTime,
          ScheduleDate: updatedData.SlotDateTime,
          StartDate: PlanningHelper.getSlotTimeBySelectedStartType({ ...updatedData, ScheduleDate: updatedData.SlotDateTime }, props.startType, getOffsetTime()),
          EndTime: PlanningHelper.getSlotEndTimeBySelectedStartType({ ...updatedData, ScheduleDate: updatedData.ScheduleDate }, props.startType, getOffsetTime(), duration),
          ActualDuration: updatedData.ActualDuration,
          NominalDuration: updatedData.NominalDuration,
        };
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_UPDATE, module: MODULE.PLANNING, data: { ...updatedItem, SlotDateTime: updatedData.SlotDateTime - getOffsetTime() } })
        let channel = updatedItem.Channel;
        let date = new Date(updatedItem.SlotDateTime).setUTCHours(0, 0, 0, 0);

        refreshSchedule(channel, date);

        // Log Capture on edit
        let logData = { event: LOGEVENT.PLANNING_UPDATE, module: MODULE.PLANNING, data: updatedItem, message: lang.edited_using_form_log_message };
        API.SaveProgramScheduleHeaderLogs(logData);

        return updatedItem

      }
      else {
        return item;
      }
    })
    );



  }, []);

  //To get data from custom form by adding new prop
  const CustomFormWithAdditionalProps = (fieldRenderProps) => {
    return (
      <div>
        <PlanningEditForm
          {...fieldRenderProps}
          onUpdateFromPlanningEditForm={onUpdateFromPlanningEditForm}
          module={MODULE.PLANNING}
        />
      </div>
    );
  };

  //function to handle multi select with shift key
  const handleShiftMultiSelect = (dataItem) => {

    if (plannerSelectedItemsRef.current.length > 0) {

      var selectedItemIndex = dataRef.current.findIndex((item) => item._id == dataItem._id);
      var lastSelectedItemIndex = dataRef.current.findIndex(
        (item) => item._id == plannerSelectedItemsRef.current[plannerSelectedItemsRef.current.length - 1]._id
      );

      let minSlotDateTime = dataRef.current[lastSelectedItemIndex].SlotDateTime > dataRef.current[selectedItemIndex].SlotDateTime ? dataRef.current[selectedItemIndex].SlotDateTime : dataRef.current[lastSelectedItemIndex].SlotDateTime;
      let maxSlotDateTime = dataRef.current[lastSelectedItemIndex].SlotDateTime > dataRef.current[selectedItemIndex].SlotDateTime ? dataRef.current[lastSelectedItemIndex].SlotDateTime : dataRef.current[selectedItemIndex].SlotDateTime;

      var selectedItems = dataRef.current.filter((item) => item.SlotDateTime >= minSlotDateTime && item.SlotDateTime <= maxSlotDateTime && item.ChannelSID == dataItem.ChannelSID)

      setPlannerSelectedItems([...selectedItems]);
    } else {
      setPlannerSelectedItems([dataItem]);
    }
  }

  const onPlannerItemSelect = (event, dataItem) => {
    if (event.syntheticEvent.ctrlKey || event.syntheticEvent.metaKey) {
      //if already then it should be deselct and delete from
      if (
        plannerSelectedItemsRef.current.some((item) => item._id == dataItem._id)
      ) {
        var plannerSelectedItemscopy = [...plannerSelectedItemsRef.current];
        let index = plannerSelectedItemscopy.indexOf(dataItem);
        plannerSelectedItemscopy.splice(index, 1);
        setPlannerSelectedItems([...plannerSelectedItemscopy]);
      } else {
        setPlannerSelectedItems([...plannerSelectedItemsRef.current, dataItem]);
      }
    }
    else if (event.syntheticEvent.shiftKey) {
      handleShiftMultiSelect(dataItem)
    }
    else {
      setPlannerSelectedItems([dataItem]);
    }
  };

  const CustomViewSlot = useCallback((props) => {
    if (props.isAllDay) {
      return null;
    }

    let isWithinVisibleArea = PlanningHelper.isValidDisplaySlotDateTime((props.start.getTime()), new Date(props.end.getTime()), visibleAreaRef.current.startHour, visibleAreaRef.current.endHour);

    if (!isWithinVisibleArea) {
      return null;
    }

    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    var channelSID = props.group.resources[0].value;
    const channel = filterChannels.filter(channel => channel.SID == channelSID)[0];
    var date = new Date(new Date(props.start).setUTCHours(0, 0, 0, 0)).getTime();
    var lockStatus;
    if (filterChannels.length == 0) {
      lockStatus = { isSelfLocked: false, canModify: true, fromSchedule: false, lockedBy: {} };
    } else {
      lockStatus = utility.canModify(lockedPlaylistRef.current, MODULE.PLANNING, channel?._id, date);
    }
    var style = {
      ...props.style,
      minHeight: zoom,
    };
    // if the user locked it it can edit
    if (lockStatus.isSelfLocked) {
      style.backgroundColor = lockStatus.fromSchedule ? SLOT_LOCK_COLOR : SLOT_COLOR;
    }
    // if other user locked it he can't edit
    if (!lockStatus.canModify) {
      style.backgroundColor = lockStatus.fromSchedule ? SLOT_LOCK_COLOR : SLOT_COLOR;
      style.pointerEvents = "none";
    }
    return (
      <div
        title={lockStatus.canModify ? null : `Locked by ${lockStatus.fromSchedule ? lockStatus.lockedBy.name + ' from Schedule' : lockStatus.lockedBy.name}`}
        style={
          lockStatus.canModify ? { width: "100%" } : { width: "100%", cursor: "no-drop" }
        }
        onDrop={
          lockStatus.canModify
            ? (e) => {
              handleDropItem(e);
            }
            : () => {
              // setStatusMsg('control with schedule')

              toast.error(
                'Locked by ' + lockStatus.lockedBy.name,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
        }
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <SchedulerViewSlot
          onClick={(e) =>
            schedulerViewSlotEvent(
              e.target.props.start,
              e.target.props.group.index
            )
          }
          {...props}
          style={style}
        />
      </div>
    );

  }, [visibleAreaRef.current]);

  const schedulerViewSlotEvent = (startTime, channelIndex) => {
    setPlannerSelectedItems([]);

    setSelctedSlot({ SlotDateTime: startTime, channelIndex: channelIndex });

    console.log({ SlotDateTime: startTime, channelIndex: channelIndex });
  };

  const handlePublishUnPublishLiveEvent = async ({ programScheduleId, scheduleMatchDataItem, slotDateTime, channel, publish }) => {

    console.log(scheduleMatchDataItem)
    console.log(slotDateTime)

    //HANDLE REPEAT COPY PASTE AND DELETE CASE
    if (!scheduleMatchDataItem.IsLive) return;

    let payload = {
      MediaEpisode_id: scheduleMatchDataItem._id,
      ProgramSchedule_id: publish ? programScheduleId : "",
      Channel: publish ? { _id: channel._id, SID: channel.SID, FullChannelName: channel.FullChannelName } : {},
      SlotDateTime: publish ? slotDateTime : 0,
      Publish: publish
    }

    var res = await API.publishUnPublishLiveEvent(payload);

    if (res.success) {
      toast.success(`Match ${publish ? 'publish' : 'un-published'} successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      props.refreshLiveEvent();
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.LIVE_EVENT_REFRESH, module: MODULE.LIVE_EVENTS, data: null })

    } else {
      console.log(res)
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }


  };

  const handleDropItem = async (e) => {
    if (isPlanningLoading.current) {
      toast.error("Planning is still loading, Please wait....", {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    //start
    let start = e.target.getAttribute("data-slot-start");
    let startDate = new Date(parseInt(start));
    let dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
    //dragged media draggedItem
    let mediaEpisode = dataTransfer.dataItem;
    //channel
    let selectedChannelIndex = e.target.getAttribute("data-slot-group");
    let filteredChannelLocal = [...filterChannels];
    let selectedChannel = filteredChannelLocal[selectedChannelIndex];

    if (!PlanningHelper.isMediaEpisodeValidForThisChannel(mediaEpisode, selectedChannel)) {
      toast.error("Media Not Valid For Selected Channel", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    //VALIDATE LIVE EVENT DROP HERE
    if (mediaEpisode.IsLive) {

      let response = await PlanningHelper.isValidLiveEvent(mediaEpisode, parseInt(start) + offSetTime);
      console.log(response)

      if (response.isValid) {
        checkMediaDealRightsAndAddPlanningItem(mediaEpisode, startDate, selectedChannel, { SID: TX_STATUS.Live, Description: utility.getKeyByValue(TX_STATUS, TX_STATUS.Live) })
      } else {
        if (response.confirmPopup) {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        checkMediaDealRightsAndAddPlanningItem(mediaEpisode, startDate, selectedChannel)
      }

    } else {
      checkMediaDealRightsAndAddPlanningItem(mediaEpisode, startDate, selectedChannel)
    }

  };


  const checkMediaDealRightsAndAddPlanningItem = (mediaEpisode, startDate, selectedChannel, txStatus) => {
    console.log("Called Check MediaDealRights & Add Planning Item");
    var MediaDealRight = PlanningHelper.getMediaDealRight(
      mediaEpisode,
      startDate,
      selectedChannel
    );

    // here if media rights is more then one then it will return false and the popup to choose the media right
    setLastDroppedItem({ startDate, selectedChannel, mediaEpisode, MediaDealRight });
    if (MediaDealRight == false) {
      setShowPopups({ ...defaultShowPopup, mediaDealRightPopup: true })
    } else {
      addPlanningItem(startDate, selectedChannel, mediaEpisode, MediaDealRight, false, '', 0, null, txStatus);
    }

  }

  const addSelectedMediaDealRight = (dataItem) => {
    if (dataItem.length > 0) {
      const selectedMediaDealRight = dataItem[0];
      addPlanningItem(
        lastDroppedItemRef.current.startDate,
        lastDroppedItemRef.current.selectedChannel,
        lastDroppedItemRef.current.mediaEpisode,
        selectedMediaDealRight
      );
      setLastDroppedItem({});
    } else {
      toast.error(
        `${lang.select_atleast_one_mediadealright_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const onCancelPopup = () => {
    setShowPopups(defaultShowPopup);
    setLastDroppedItem({});
  };

 

  const addSelectedSegment = (dataItem) => {
    if (dataItem.length > 0) {
      const selectedSegment = dataItem[0];
      addPlanningItem(
        lastDroppedItemRef.current.startDate,
        lastDroppedItemRef.current.selectedChannel,
        lastDroppedItemRef.current.mediaEpisode,
        lastDroppedItemRef.current.MediaDealRight,
        false,
        '',
        0,
        selectedSegment._id
      );
      onCancelPopup()
    } else {
      toast.error(
        `${lang.select_atleast_one_mediadealright_planning_performance_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const addPlanningItem = async (
    startDate,
    selectedChannel,
    mediaEpisode,
    MediaDealRight,
    fetchSegmentFromDB = false,
    customTitle = '',
    customDuration = 0,
    segment_id = null,
    txStatus = { SID: 3, Description: 'Transmission' }
  ) => {

    console.log(segment_id)
    const planningObjectForUI = PlanningHelper.saveScheduleEntity(
      mediaEpisode,
      selectedChannel,
      MediaDealRight,
      startDate,
      offSetTime,
      slotDurationRef.current,
      customTitle,
      customDuration,
      segment_id,
      txStatus
    );

    // fetching day part for validation
    const validDayPart = await PlanningHelper.getValidDayPartForTimeBand(planningObjectForUI);

    //CHECK VALID SEGMENT
    if (enableSegmentSelectionPopup && !segment_id && mediaEpisode.segments && mediaEpisode.segments.length > 0) {
      let defaultSegment = mediaEpisode.segments.find((segment) => segment.IsDefault);
      if (defaultSegment) {
        //VALID FOR THIS CHANNEL
        if (defaultSegment.Channels && defaultSegment.Channels.some((x) => x.SID == selectedChannel.SID)) {
          //VALID FOR THIS DAY PART
          if (
            validDayPart &&
            validDayPart.RestrictedPgRating &&
            validDayPart.RestrictedPgRating.some((x) => x._id == defaultSegment.PgRating._id)
          ) {
            setShowPopups({ ...defaultShowPopup, segmentationPopup: true })
            return;
          }
        }
      }
    }

    // Adding Temporarily
    startTransition(() =>
      setData([
        ...dataRef.current,
        {
          ...planningObjectForUI,
          SID: planningObjectForUI.Temp_id,
          _id: planningObjectForUI.Temp_id,
          Title: planningObjectForUI.customTitle && planningObjectForUI.customTitle.length > 0 ? planningObjectForUI.customTitle : planningObjectForUI.Title,
          EndTime: planningObjectForUI.customDuration && planningObjectForUI.customDuration > 0 ? new Date(planningObjectForUI.StartDate.getTime() + parseInt(planningObjectForUI.customDuration)) : planningObjectForUI.EndTime
        },
      ])
    );

    // Now Saving
    var res = await API.savePlanning(
      PlanningHelper.prepareDataforbackend({ ...planningObjectForUI }),
      mediaEpisode.segments, false, fetchSegmentFromDB, mediaEpisode.SID, validDayPart
    );

    console.log(mediaEpisode.segments);

    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // NEW LOGIC
      startTransition(() =>
        setData(dataRef.current.map((item) => {
          if (item.SID && item.SID == planningObjectForUI.Temp_id) {
            item._id = res.data.header._id;
            item.SID = res.data.header.SID;
            item.Segment_id = res.data.header.Segment_id ?? null;
            item.Style = PlanningHelper.getPlanningColorPreference(item);
            item.Temp_id = undefined;

            //update actual duration on selcted segmented
            if (
              res.data.header.Segment_id &&
              props.durationType == "ActualDuration"
              && mediaEpisode.segments != undefined && mediaEpisode.segments.length > 0
            ) {
              let segment = mediaEpisode.segments.find((seg) => seg._id == res.data.header.Segment_id);

              if (segment != undefined) {
                let actualDuration = segment.ActualDuration;
                const roundOffDur = PlanningHelper.getRoundOffDuration(actualDuration, PlanningHelper.getRoundOffMultiplesBySlotDuration(slotDurationRef.current))
                item.EndTime = new Date(
                  item.StartDate.getTime() + roundOffDur
                );
                item.ActualDuration = actualDuration;
              }
            }

            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_NEW_DROP, module: MODULE.PLANNING, data: item })
            let channel = item.Channel;
            let date = new Date(item.SlotDateTime).setUTCHours(0, 0, 0, 0);
            refreshSchedule(channel, date);

            // Log Capture on Save
            let logData = { event: LOGEVENT.PLANNING_NEW_ADD, module: MODULE.PLANNING, data: item, message: lang.add_new_planning_item_log_message };
            API.SaveProgramScheduleHeaderLogs(logData);

          }
          return item;
        })
        )
      );

      // if media is attached to a deal rright, we will update UI for updated exhibition and repeat
      if (res.data.dealRightHeaders && res.data.dealRightHeaders.length > 0) {
        updateExhibitionAndRepeatOnUI(res.data.dealRightHeaders);
      }

      //UPDATE LIVE EVENT STATUS IF LIVE EVENT DROPPED
      if (mediaEpisode.IsLive) {
        await handlePublishUnPublishLiveEvent({
          programScheduleId: res.data.header._id,
          scheduleMatchDataItem: mediaEpisode,
          slotDateTime: startDate.getTime() + offSetTime,
          channel: selectedChannel,
          publish: true
        })
      }
    }
  };



  const MyCustomPlanningItem = (props) => {
    const isSelected = plannerSelectedItemsRef.current.some(item => item._id == props.dataItem._id)
    const newProps = { ...props, selected: isSelected }
    return (
      <CustomPlanningItem
        {...newProps}
        onPlannerItemSelect={onPlannerItemSelect}
        setFocusedPlanningItemPropRef={setFocusedPlanningItemPropRef}
        lockedPlaylist={lockedPlaylistRef.current}
      />
    );
  };
  //this function calls when in planner we add delete or added something
  const handleDataChange = useCallback(
    async ({ created, updated, deleted }) => {
      console.log("calling handleDataChange");
      //COMMENTING THIS CODE NOW -- ITEM WILL BE REMOVE FROM UI AND THEN API RESPONSE SHOWS "CANNOT BE DELETE" PLEASE UNCOMMENT IF FACE ANY ISSUE -- DHEERAJ 
      // setData(dataRef.current
      //   .filter(
      //     (item) =>
      //       deleted.find((current) => current._id === item._id) === undefined
      //   )
      //   .map((item) => {
      //     let itemLocal = updated.find((current) => current._id === item._id);

      //     if (itemLocal) {
      //       item = {
      //         ...itemLocal,
      //         ScheduleDate: itemLocal.StartDate.getTime() + offSetTime,
      //         SlotDateTime: itemLocal.StartDate.getTime() + offSetTime,
      //       };
      //     }
      //     return item;
      //   })
      //   .concat(
      //     created.map((item) => ({
      //       ...item,
      //       SID: utility.randomInt(1, 100),
      //       Style: PlanningHelper.getPlanningColorPreference(item),
      //     }))
      //   )
      // );

      //to send to other planniner      
      if (deleted.length > 0) {
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_DELETE, module: MODULE.PLANNING, data: deleted })
        let date = new Date(deleted[0].SlotDateTime).setUTCHours(0, 0, 0, 0);
        let channel = deleted[0].Channel;
        refreshSchedule(channel, date);
      }

      //saving in database
      if (updated.length > 0) {
        for (let update of updated) {
          // Start Date and SlotDate Time was not reflecting on edit form on real time. so changing this.

          var swallowCopy = [...dataRef.current];

          if (update.mediaEpisode.IsLive) {
            let response = await PlanningHelper.isValidLiveEvent(update.mediaEpisode, update.StartDate.getTime() + offSetTime);

            console.log(response)

            if (!response.isValid) {
              update.TXStatus = update.TXStatus.SID == TX_STATUS.Live ? { SID: TX_STATUS.Transmission, Description: utility.getKeyByValue(TX_STATUS, TX_STATUS.Transmission) } : update.TXStatus
            } else {
              update.TXStatus = { SID: TX_STATUS.Live, Description: utility.getKeyByValue(TX_STATUS, TX_STATUS.Live) }
            }


          }

          //Since date was not converting so seeting date as milisecond
          update = { ...update, SlotDateTime: update.StartDate.getTime(), ScheduleDate: update.StartDate.getTime() };
          console.log(update);
          update_planning(update);
          socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_UPDATE, module: MODULE.PLANNING, data: update })
          let channel = update.Channel;
          let date = new Date(update.SlotDateTime).setUTCHours(0, 0, 0, 0);
          refreshSchedule(channel, date);


          var saveData = {
            _id: update._id,
            SID: update.SID,
            ScheduleDate: update.StartDate.getTime() + offSetTime,
            SlotDateTime: update.StartDate.getTime() + offSetTime,
            MediaDealRight_id: update.MediaDealRight_id,
            Channel: {
              _id: update.Channel?._id,
              SID: update.Channel?.SID,
              FullChannelName: update.Channel?.FullChannelName
            },
            PlanningType: update.PlanningType,
            ExhibitionNumber: update.ExhibitionNumber,
            RepeatNumber: update.RepeatNumber,
            TXStatus: update.TXStatus
          };
          console.log(update);
          const validDayPart = await PlanningHelper.getValidDayPartForTimeBand(saveData);
          console.log(validDayPart);
          API.savePlanning(saveData, update.mediaEpisode.segments, false, false, 0, validDayPart).then(
            (response) => {
              if (response.success) {
                // Log Capture on Update


                if (
                  response.data.dealRightHeaders &&
                  response.data.dealRightHeaders.length > 0
                )
                  updateExhibitionAndRepeatOnUI(response.data.dealRightHeaders);

                let logData = { event: LOGEVENT.PLANNING_UPDATE, module: MODULE.PLANNING, data: { ...update, ...saveData }, message: 'Edited using drag drop' };
                API.SaveProgramScheduleHeaderLogs(logData);
              } else {
                if (swallowCopy && swallowCopy.length > 0) setData(swallowCopy);
                toast.error(response.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          );
        }
      }

      if (deleted.length > 0) {
        for (let deleteItem of deleted) {
          var swallowCopy = [...dataRef.current];

          console.log("Deleting");
          API.deletePlanning({
            _id: deleteItem._id,
            MediaDealRight_id: deleteItem.MediaDealRight_id,
          }).then((delresponse) => {
            console.log("delresponse");
            console.log(delresponse);
            if (delresponse.success && delresponse.data) {
              setData(dataRef.current.filter((item) => deleted.find((current) => current._id === item._id) === undefined));

              //UPDATING LIVE EVENT SCHEDULE STATUS
              handlePublishUnPublishLiveEvent({
                scheduleMatchDataItem: deleteItem.mediaEpisode,
                slotDateTime: deleteItem.SlotDateTime,
                publish: false
              });

              if (delresponse.data.dealRightHeaders && delresponse.data.dealRightHeaders.length > 0) {
                updateExhibitionAndRepeatOnUI(delresponse.data.dealRightHeaders);
              }

            } else if (!delresponse.success) {
              if (swallowCopy && swallowCopy.length > 0) setData(swallowCopy);
              toast.error(delresponse.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
        }
      }
      console.log(created);
      console.log(updated);
      console.log(deleted);
    },
    [dataRef.current]
  );


  const update_planning = (update) => {
    console.log(update);
    // const newData = [...data];
    // var index = newData.findIndex((item) => item._id == update._id);
    // if (index > 0) {

    //   let duration = PlanningHelper.getSelectedDurationType(newData[index],props.durationType, slotDurationRef.current);
    //   var endDateTime = new Date(update.SlotDateTime + duration),


    //   newData[index] = {
    //     ...newData[index],
    //     IsValid: PlanningHelper.isMediaEpisodeValidForThisChannel(update.mediaEpisode, update.SlotDateTime, update.Channel),
    //     Style: PlanningHelper.getPlanningColorPreference(newData[index]),
    //     SlotDateTime: update.SlotDateTime,
    //     StartDate: new Date(update.SlotDateTime),
    //     EndTime: endDateTime,
    //   };
    //   setData(newData);
    // }

    console.log(update);


    setData(dataRef.current.map((item) => {
      if (item._id == update._id) {

        let duration = PlanningHelper.getSelectedDurationType(update, props.durationType, slotDurationRef.current);
        console.log("Duration");
        console.log(duration);
        console.log(update);

        // fetching realtime as offsetTime variable was not updating, if we change preference hours by not refresh tab
        // i have made it let from const and also created a function to realtime pull preference from local storage to use in update time.
        // this is a rare case but still noticed and handled, by vishal on 24 oct 2024
        const ot = getOffsetTime();

        let updatedItem = {
          ...item,
          IsValid: PlanningHelper.isMediaEpisodeValidForThisChannel(update.mediaEpisode, update.Channel),
          Style: PlanningHelper.getPlanningColorPreference({ ...item, ...update }),
          Segment_id: update.Segment_id,
          SlotDateTime: update.SlotDateTime + ot,
          ScheduleDate: update.ScheduleDate,
          StartDate: PlanningHelper.getSlotTimeBySelectedStartType({ ...update, SlotDateTime: update.SlotDateTime + ot, ScheduleDate: update.ScheduleDate + ot }, props.startType, ot),
          EndTime: PlanningHelper.getSlotEndTimeBySelectedStartType({ ...update, SlotDateTime: update.SlotDateTime + ot, ScheduleDate: update.ScheduleDate + ot  }, props.startType, ot, duration),
          ActualDuration: update.ActualDuration,
          NominalDuration: update.NominalDuration,
          TXStatus: update.TXStatus
        };

        console.log(updatedItem);
        return updatedItem

      }
      else {
        return item;
      }
    })
    );



  }

  const updateExhibitionAndRepeatOnUI = (updatedHeaders) => {
    setData(dataRef.current.map((item) => {
      const dealrightUpdatedHeader = updatedHeaders.find(
        (x) => item && item._id && x._id.toString() == item._id.toString()
      );
      if (dealrightUpdatedHeader) {
        item.ExhibitionNumber = dealrightUpdatedHeader.ExhibitionNumber;
        item.RepeatNumber = dealrightUpdatedHeader.RepeatNumber;
        item.PlanningType = dealrightUpdatedHeader.PlanningType;
        item.Style = PlanningHelper.getPlanningColorPreference(item);
        item.InvalidReason = PlanningHelper.getInvalidReason(item);
      }
      return item;
    })
    );
  };

  const onUpdateSynopsis = async () => {

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      SlotSynopsis: synopsisRef.current
    });

    setShowPopups(defaultShowPopup)

    if (updateRes.success) {
      refreshPlanning();

      var updatedData = { ...focusedPlanningItemPropRef.current.dataItem, OldSlotSynopsis: focusedPlanningItemPropRef.current.dataItem.SlotSynopsis, UpdatedSlotSynopsis: synopsisRef.current };
      let logData = { event: LOGEVENT.UPDATE_PLANNING_SYNOPSIS, module: MODULE.PLANNING, data: updatedData, message: LOGEVENT.UPDATE_PLANNING_SYNOPSIS };
      API.SaveProgramScheduleHeaderLogs(logData);
    }



  }

  const onUpdateTxStatus = async () => {

    let focusedMediaEpisode = focusedPlanningItemPropRef.current.dataItem;

    if (focusedMediaEpisode.mediaEpisode.IsLive && txStatus.SID == TX_STATUS.Live) {
      let response = await PlanningHelper.isValidLiveEvent(focusedMediaEpisode.mediaEpisode, focusedMediaEpisode.SlotDateTime);

      //VALIDATION
      if (!response.isValid) {
        toast.error(`${response.message}`,
          { position: toast.POSITION.TOP_RIGHT }
        );
        return;
      }
    }

    // this validation is applied in planning and scheduling, please make sure you change at both places for any code change
    if (txStatusRef.current.SID == TX_STATUS.Live || txStatusRef.current.SID == TX_STATUS.SDD) {

      var response = await API.canChangeTxStatusToLiveOrDalayed(focusedMediaEpisode.SlotDateTime, focusedMediaEpisode.MediaEpisode_id);

      if (!response.success) {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        return;
      }
    }

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedMediaEpisode._id,
      TXStatus: { SID: txStatusRef.current.SID, Description: txStatusRef.current.Description }
    });

    setShowPopups(defaultShowPopup)


    if (updateRes.success) {
      refreshPlanning();

      var updatedData = { ...focusedPlanningItemPropRef.current.dataItem, OldTxStatus: focusedMediaEpisode?.TXStatus, UpdatedTxStatus: { SID: txStatusRef.current.SID, Description: txStatusRef.current.Description } };
      let logData = { event: LOGEVENT.UPDATE_PLANNING_TX_STATUS, module: MODULE.PLANNING, data: updatedData, message: LOGEVENT.UPDATE_PLANNING_TX_STATUS };
      API.SaveProgramScheduleHeaderLogs(logData);
    }

  }

  const onClearCustomDuration = async () => {
    if (!focusedPlanningItemPropRef.current.dataItem.customDuration) {
      toast.info('Custom duration not set', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    let res = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      customDuration: null
    });
    if (res.success) {
      toast.success('Slot duration updated successfully', { position: toast.POSITION.TOP_RIGHT });
      refreshPlanning();
    } else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  const onMarkChangeFlag = async (isChanged = true) => {

    let res = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      isChanged: isChanged
    });

    if (res.success) {
      toast.success('Change flag updated successfully', { position: toast.POSITION.TOP_RIGHT });
      refreshPlanning();
    } else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }

  }

  const onEditCustomSlotDuration = async () => {
    let res = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      customDuration: utility.convertStringWithFramesToMilliseconds(customSlotDuration)
    });
    if (res.success) {
      toast.success('Slot duration updated successfully', { position: toast.POSITION.TOP_RIGHT });
      refreshPlanning();
    } else {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setShowPopups(defaultShowPopup);
  }

  const handleSetImportant = async (isImportant = false) => {
    let res = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      isImportant: isImportant
    });
    console.log(res);
    
    if(res.success){
      toast.success(`${lang.important_update_successfully_message}`,{position: toast.POSITION.TOP_RIGHT});
      refreshPlanning();
    }else {
      toast.error(res.message, {position: toast.POSITION.TOP_RIGHT});
    }
  }

  const onUpdateProgramScheduleComment = async () => {

    var updateRes = await API.saveData(ENTITYNAME.ProgramSchedule, {
      _id: focusedPlanningItemPropRef.current.dataItem._id,
      Comment: commentRef.current
    });

    setShowPopups(defaultShowPopup);

    if (updateRes.success) {
      refreshPlanning();

      var updatedData = { ...focusedPlanningItemPropRef.current.dataItem, OldComment: focusedPlanningItemPropRef.current?.dataItem?.Comment ?? "", UpdatedComment: commentRef.current };
      let logData = { event: LOGEVENT.UPDATE_PLANNING_TX_STATUS, module: MODULE.PLANNING, data: updatedData, message: LOGEVENT.UPDATE_PLANNING_TX_STATUS };
      API.SaveProgramScheduleHeaderLogs(logData);
    }

  }

  const onChangeSynopsis = (e) => {
    setSynopsisRef(e.target.value);
    // console.log(synopsisRef)
  }

  const loadSavedFastSegments = () => {
    console.log('here planning main');
    refreshPlanning();
  }

  const onInsertDummy = (e) => {
    if (e && e.target && e.target.localName == "input") {
      return;
    }

    console.log(selectedSlotRef.current);
    if (selectedSlotRef.current.SlotDateTime == null) {
      toast.error(
        `${lang.please_select_area_on_planner_to_insert_dummy_planning_performance_error_message}`,
        { position: toast.POSITION.TOP_RIGHT }
      );
      return;
    }

    setShowPopups({ ...defaultShowPopup, insertDummyPopup: true })
  }

  const onEditDummy = () => {

    if (!focusedPlanningItemPropRef.current.dataItem.mediaEpisode.IsDummy) {
      toast.error(`This is not a dummy media`, { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    setShowPopups({ ...defaultShowPopup, editDummyPopup: true })
  }



  //programe replacement
  //----real time upated pending
  const replaceProgram = async (newMediaEpisode, mediaDealRight_id = null) => {

    if (newMediaEpisode == undefined || newMediaEpisode._id == undefined) {
      toast.error(`${lang.replacement_media_lost_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const saveData = {
      SID: 0,
      Channel: focusedPlanningItemPropRef.current.dataItem.Channel,
      MediaEpisode_id: newMediaEpisode._id,
      ScheduleDate: focusedPlanningItemPropRef.current.dataItem.SlotDateTime,
      SlotDateTime: focusedPlanningItemPropRef.current.dataItem.SlotDateTime,
      Segment_id: null,
      ParentProgramSchedule_id: null,
      IsValid: true,
      MediaDealRight_id: mediaDealRight_id,
    };

    var response = await API.ReplaceProgram(saveData, focusedPlanningItemPropRef.current.dataItem); // true for loadchildren
    console.log(response);

    if (response.success) {

      //add
      const planningObjectForUI = PlanningHelper.saveScheduleEntity(
        newMediaEpisode,
        focusedPlanningItemPropRef.current.dataItem.Channel,
        null,
        new Date(focusedPlanningItemPropRef.current.dataItem.SlotDateTime - offSetTime), // we will reduce offset time instead
        0, // for replacement we will send 0 as offset time
        slotDurationRef.current,
        '',
        0
      );

      //real time ui temp add and delete existing
      startTransition(() =>
        setData(dataRef.current.map((data) => {
          if (data._id == focusedPlanningItemPropRef.current.dataItem._id) {

            console.log('here');
            return {
              ...planningObjectForUI,
              SID: planningObjectForUI.Temp_id,
              _id: planningObjectForUI.Temp_id,
              Title: planningObjectForUI.customTitle && planningObjectForUI.customTitle.length > 0 ? planningObjectForUI.customTitle : planningObjectForUI.Title,
              EndTime: planningObjectForUI.customDuration && planningObjectForUI.customDuration > 0 ? new Date(planningObjectForUI.StartDate.getTime() + parseInt(planningObjectForUI.customDuration)) : planningObjectForUI.EndTime
            }
          } else {
            return data;
          }
        })
        )
      );

      let channel = focusedPlanningItemPropRef.current.dataItem.Channel;
      let date = new Date(focusedPlanningItemPropRef.current.dataItem.SlotDateTime).setUTCHours(0, 0, 0, 0);
      refreshSchedule(channel, date);


      let logData = { event: LOGEVENT.PROGRAM_REPLACEMENT, data: focusedPlanningItemPropRef.current.dataItem, message: LOGEVENT.PROGRAM_REPLACEMENT, module: MODULE.PLANNING };
      API.SaveProgramScheduleHeaderLogs(logData);

    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const programReplacement = async (replaceDataItem) => {

    if (focusedPlanningItemPropRef.current.dataItem == {}) {
      toast.error(lang.please_select_planning_item_planning_wrapper_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (replaceDataItem.length == 0) {
      toast.error(lang.select_one_program_to_replace_with_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    var mediaEpisode = replaceDataItem[0];

    // checking rights
    const dealRights = await PlanningHelper.getMediaEpisodeDealRights(mediaEpisode);

    if (!PlanningHelper.isMediaEpisodeValidForThisChannel({ ...mediaEpisode, MediaDealRights: dealRights }, focusedPlanningItemPropRef.current.dataItem.Channel)) {
      toast.error(`${lang.media_not_valid_for_selected_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var validMediaDealRight = PlanningHelper.getMediaDealRight(
      { ...mediaEpisode, MediaDealRights: dealRights },
      focusedPlanningItemPropRef.current.dataItem.SlotDateTime,
      focusedPlanningItemPropRef.current.dataItem.Channel
    );

    // here if media rights is more then one then it will return false and the popup to choose the media right
    if (validMediaDealRight == false) {
      setProgramToReplace(mediaEpisode);
      setShowPopups({ ...defaultShowPopup, mediaDealRightPopup: true })
    } else {
      replaceProgram(mediaEpisode);
    }

  };

  //INSERT PROGRAM GROUP
  const onInsertProgramGroup = (e) => {
    if (e && e.target && e.target.localName == "input") {
      return;
    }

    if (selectedSlotRef.current.SlotDateTime == null) {
      toast.error(
        `${lang.please_select_area_on_planner_to_insert_program_group_planning_performance_error_message}`,
        { position: toast.POSITION.TOP_RIGHT }
      );
      return;
    }

    setShowPopups({ ...defaultShowPopup, insertProgramGroup: true })
  }


  const customTimeHeaderCell = (props) => {
    // debugger
    let startMilisecond = offSetHours * 3600000;
    let date = new Date(props.date.getTime() + startMilisecond);
    let hours = date.getUTCHours();
    let min = date.getUTCMinutes();
    hours = hours >= 24 ? hours - 24 : hours;
    let timeString = String(hours).padStart(2, "0") + ":" + String(min).padStart(2, "0");

    return (
      <TimeHeaderCell
        {...props}
        format={timeString}
      />
    );
  }
  // to activate weekday range
  // const weekDayRangeChange = (e) => {
  //   if (e.target.name === "start" && e.target.value.ID > weekDayRange.end.ID) {
  //     toast.error("Start day should be less than end day");
  //   }
  //   else if (e.target.name === "end" && e.target.value.ID < weekDayRange.start.ID) {
  //     toast.error("End day should be greater than start day");
  //   }
  //   else {
  //     setWeekDayRange({
  //       ...weekDayRange,
  //       [e.target.name]: e.target.value
  //     })
  //   }
  // }


  //scheduler header
  const header = (props) => {
    return (
      <SchedulerHeader>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          {/* today */}
          {props.children[0]}
          {/* calendar */}
          {/* {props.children[1]} */}
          {/* slot duratioon */}
          <div style={{ position: "relative", top: "-3px" }}>
            {lang.slot_duration_title_label + ':'}

            <DropDownList
              value={slotDuration + " min"}
              style={{
                width: "100px",
                marginLeft: "20px",
              }}
              data={SLOT_TIMES}
              onChange={(e) => {
                // setData([]);
                setSlotDuration(e.value);
                resetPlanningGridHeight();
                // accordingly find index of suitable slot division
                const index = SLOT_TIMES.indexOf(e.value);
                setSlotDivision(slotDivisions[index]);

                //SET 
                utility.setSessionValue(LOCALSTORAGE_KEY.SELECTED_PLANNING_SLOT_DURATION, e.value);
                refreshPlanning()
                // localPlanningUIRefresh()

              }}
            />
            {/* ZOOM */}
            <label style={{ marginLeft: "60px" }}>Zoom:</label>
            <Slider
              style={{ position: "relative", top: "9px" }}
              buttons={false}
              defaultValue={zoomInZoomOutValue}
              min={1}
              max={8}
              onChange={(e) => {
                startTransition(() => {
                  setZoomInZoomOutValue(e.value);
                })
              }}
            >
              {/* {Array.from({ length: 8 }, (_, i) => i + 1).map((value, index) => (
                <SliderLabel position={index + 1}>
                  {value}
                </SliderLabel>
              ))} */}
            </Slider>
          </div>
          {/*  to activate weekday range */}
          {/* {view == "week" && <> <div>
            Start Week Day
            <DropDownList
              name="start"
              data={MyDays}
              textField="Name"
              value={weekDayRange.start}
              onChange={weekDayRangeChange}
              style={{ width: "auto" }}
            />
          </div>
            <div>
              End Week Day
              <DropDownList
                name="end"
                data={MyDays}
                textField="Name"
                value={weekDayRange.end}
                onChange={weekDayRangeChange}
                style={{ width: "auto" }}
              />
            </div>
          </>
          } */}
          {/* week day */}
          {props.children[3]}
        </div>
      </SchedulerHeader>
    );
  };
  return (
    <>
      <Scheduler
        id="PlannerID"
        data={displayData}
        date={date}
        onDateChange={onDateChange}
        editable={{
          select: true,
          remove: true,
          drag: true,
          resize: false,
          add: false,
          edit: true,
        }}
        timezone="Etc/UTC"
        form={CustomFormWithAdditionalProps}
        defaultView={view}
        view={view}
        item={MyCustomPlanningItem}
        modelFields={customModelFields}
        footer={() => <></>}
        ref={MyScheduler}
        resources={resources}
        group={group}
        header={(props) => header(props)}
        viewSlot={CustomViewSlot}
        onDataChange={handleDataChange}
        onViewChange={(props) => {
          setViewData(props.value);
          refreshPlanning();
        }}
        style={{ overflowX: "auto" }}
      >
        {/* week view to only have monday wednesday nd friday*/}
        <WeekView
          title="Week"
          // to activate weekday range
          dateRange={{
            // get neareset monday near to the date
            start: moment(date).utc().startOf("week").add(weekStartDay._id, "days").toDate(),
            end: moment(date).utc().endOf("week").add(weekStartDay._id, "days").toDate(),
            // start: moment(date).utc().startOf("week").add(Day.Monday, "days").toDate(),
            // end: moment(date).utc().startOf("week").add(Day.Sunday, "days").toDate(),
            // zonedStart: moment(date).utc().startOf("week").add(Day.Monday, "days").toDate(),
            // zonedEnd: moment(date).utc().startOf("week").add(Day.Sunday, "days").toDate(),
          }}
          style={{ width: "fit-content" }}
          dateHeaderCell={(pro) => <DateHeaderCell {...pro} style={{ ...pro.style, backgroundColor: (pro.children._owner?.index ?? 0) > 4 ? weekStartDay?.bgColor ?? "white" : "white" }} format={'d-MMM-yy, E'} />}
          timeHeaderCell={customTimeHeaderCell}
          slotDivisions={slotDivision}
          slotDuration={slotDuration}
          showWorkHours={false}
          startTime={primeStartTime}
          endTime={primeEndTime}
          viewItem={SchedulerProportionalViewItem}
        />
        {/* day view */}
        <WorkWeekView
          title={'Day'}
          dateRange={{
            start: moment(date).utc().startOf("day").toDate(),
            end: moment(date).utc().endOf("day").toDate(),
            zonedStart: moment(date).utc().startOf("day").toDate(),
            zonedEnd: moment(date).utc().endOf("day").toDate(),
          }}
          numberOfDays={1}
          style={{ width: "fit-content" }}
          dateHeaderCell={(pro) => <DateHeaderCell {...pro} format={'d-MMM-yy, E'} />}
          timeHeaderCell={customTimeHeaderCell}
          slotDivisions={slotDivision}
          slotDuration={slotDuration}
          showWorkHours={false}
          startTime={primeStartTime}
          endTime={primeEndTime}
          viewItem={SchedulerProportionalViewItem}
        />
        {/* Month View */}
        {/* <DayView
          title="Month"
          dateRange={{
            start: moment(date).utc().startOf("month").toDate(),
            end: moment(date).utc().endOf("month").toDate(),
            zonedStart: moment(date).utc().startOf("month").toDate(),
            zonedEnd: moment(date).utc().endOf("month").toDate(),
          }}
          numberOfDays={moment(date).daysInMonth()}
          style={{ width: "fit-content" }}
          timeHeaderCell={customTimeHeaderCell}
          slotDivisions={slotDivision}
          slotDuration={slotDuration}
          showWorkHours={false}
          startTime={primeStartTime}
          endTime={primeEndTime}
        /> */}
      </Scheduler>

      <ContextMenu model={plannerBlankAreaMenuModel} ref={refForBlankArea} />
      <ContextMenu model={plannerOnItemMenuModel} ref={refForItem} />

      {showPopups.mediaDealRightPopup && (

        <div className="row" style={{ marginLeft: "-02px", width: "570px" }}>
          <div className="col-12">
            <CollectionSelection
              title={
                lang.please_choose_one_publishing_rights_collection_header
              }
              entityname={ENTITYNAME.MediaDealRights}
              //SEND CUSTOM COLUMNS TO THIS
              customColumns={MediaDealRights_columns}
              wherestatement={[[
                "media_id",
                "=",
                lastDroppedItemRef.current.mediaEpisode._id,
              ], ["PlatformType.SID", '=', PLATFORMTYPE[0].SID]]} // 0 indes is Linear
              setDataList={addSelectedMediaDealRight}
              mode={"single"}
              closeForm={onCancelPopup}
            />
          </div>
        </div>

      )}

      {showPopups.segmentationPopup && (

        <div className="row" style={{ marginLeft: "-02px", width: "570px" }}>
          <div className="col-12">
            <CollectionSelection
              title={
                lang.please_select_segment_header
              }
              data
              entityname={ENTITYNAME.MediaEpisodeSegment}
              //SEND CUSTOM COLUMNS TO THIS
              customColumns={MediaEpisodeSegment_columns}
              // wherestatement={["MediaEpisodeSID", "=", lastDroppedItemRef.current.mediaEpisode.SID]}
              wherestatement={[
                ["MediaEpisodeSID", "=", lastDroppedItemRef.current.mediaEpisode.SID],
                ["Channels.SID", "in", filterChannels.map((x) => x.SID)],
              ]}

              setDataList={addSelectedSegment}
              mode={"single"}
              closeForm={onCancelPopup}
              note={"* Segments valid for this channel only"}
            />
          </div>
        </div>

      )}

      {showPopups.updateSynopsis && (
        <BossDialog
          title={lang.update_synopsis}
          width={"500px"}
          onClose={() => setShowPopups(defaultShowPopup)}
        >
          <>
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="TabView">{lang.synopsis_label}</label>
              </div>
              <div className="col-12 form-group">
                <textarea className="pl-2"
                  type="text"
                  // value={synopsisRef.current}
                  style={{ border: 'solid lightgrey 1px', height: '100px', width: '450px' }}
                  onChange={onChangeSynopsis}
                />
              </div>
            </div>
            <EditPageHeader title={""} onSubmit={onUpdateSynopsis} onCancel={() => setShowPopups(defaultShowPopup)} showTitle={false} />
          </>
        </BossDialog>
      )}

      {showPopups.fastSegmentation && <MediaFastSegmentationPopup mediaEpisodeEntity={focusedPlanningItemPropRef.current.dataItem.mediaEpisode} programScheduleEntity={focusedPlanningItemPropRef.current.dataItem} refreshSegments={loadSavedFastSegments} onClose={() => setShowPopups(defaultShowPopup)} source={'planning'} />}

      {showPopups.insertDummyPopup && <PlanningInsertDummyPopup selectedSlot={selectedSlotRef.current} addPlanningItem={addPlanningItem} getSelectedChannel={getSelectedChannel} onClose={() => { setShowPopups(defaultShowPopup) }} />}

      {showPopups.editDummyPopup && <PlanningEditDummyPopup selectedPlanningItem={focusedPlanningItemPropRef.current.dataItem} onUpdateFromPlanningEditForm={onUpdateFromPlanningEditForm} getSelectedChannel={getSelectedChannel} onClose={() => setShowPopups(defaultShowPopup)} />}

      {showPopups.insertProgramGroup && <PlanningInsertProgramGroup selectedSlotDateTime={selectedSlotRef.current.SlotDateTime.getTime()} channel={filterChannels[selectedSlotRef.current.channelIndex]} onClose={() => setShowPopups(defaultShowPopup)} status={props.setStatus} offSetTime={offSetTime} refreshPlanning={refreshPlanning} />}

      {showPopups.programReplacement && (<>
        <ContractCollectionSelection
          title={`${lang.select_program_to_replace_with_collection_title}`}
          entityname={ENTITYNAME.MediaEpisode}
          wherestatement={["MediaCategory.isLong", "=", true]}
          setDataList={programReplacement}
          hideSeriesSeason={true}
          mode={"single"}
          closeForm={() => setShowPopups(defaultShowPopup)}
          width={"50vw"} />
      </>
      )}


      {showPopups.updateTXStatus && (
        <BossDialog
          title={lang.update_txstatus_actionbutton_menutext}
          width={"300px"}
          onClose={() => setShowPopups(defaultShowPopup)}
        >
          <>
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="TabView">{lang.tx_status_label}</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={txStatusData}
                  name="txStatus"
                  textField="Description"
                  dataItemKey="SID"
                  value={txStatus}
                  onChange={(e) => { setTxStatus(e.target.value) }}
                />
              </div>
            </div>
            <EditPageHeader title={""} onSubmit={onUpdateTxStatus} onCancel={() => setShowPopups(defaultShowPopup)} showTitle={false} />
          </>
        </BossDialog>
      )}

      {showPopups.showEditSlotDurationPopup && (
        <BossDialog
          title={'Set Custom Duration'}
          width={"300px"}
          onClose={() => setShowPopups(defaultShowPopup)}
        >
          <>
            <EditPageHeader title={""} onSubmit={onEditCustomSlotDuration} onCancel={() => setShowPopups(defaultShowPopup)} showTitle={false} />

            <div className="form-group">
              <label htmlFor="">{lang.duration_label} *</label>
              <TimePickerWithFormat
                name="Duration"
                value={customSlotDuration}
                onChange={(e) => {
                  setCustomSlotDuration(e.target.value);
                }}
                required
              />

            </div>
          </>
        </BossDialog>
      )}

      {showPopups.updateComment && (
        <BossDialog
          title={comment.length > 0 ? lang.update_comment_actionbutton_menutext : lang.add_comment_actionbutton_menutext}
          width={"300px"}
          onClose={() => setShowPopups(defaultShowPopup)}
        >
          <>
            <div className="row">
              <div className="col-12 form-group" style={{ marginBottom: 0 }}>
                <label htmlFor="TabView">{lang.comment_label}</label>
              </div>
              <div className="col-12 form-group">
                <textarea className="pl-2"
                  type="text"
                  value={comment}
                  style={{ border: 'solid lightgrey 1px', height: '50px', width: '270px' }}
                  onChange={(e) => { setComment(e.target.value) }}
                />
              </div>
            </div>
            <EditPageHeader title={""} onSubmit={onUpdateProgramScheduleComment} onCancel={() => setShowPopups(defaultShowPopup)} showTitle={false} />
          </>
        </BossDialog>
      )}

      {showCheckList &&
        <CheckLists
          closeForm={onCheckList}
          data={dataRef.current.filter((item) => item.SlotDateTime >= getWeekDate.startDate && item.SlotDateTime <= getWeekDate.endDate)}
          module={MODULE.PLANNING}
        />
      }

      {headerLockPopup.show && <HeaderLockPopup onClose={(refresh = false) => {
        setHeaderLockPopup({ show: false, segments: [], header: null, nextHeaderStartTime: null });
        if (refresh) {
          refreshPlanning();
        }
      }} segments={headerLockPopup.segments} header={headerLockPopup.header} nextHeaderStartTime={headerLockPopup.nextHeaderStartTime} />
      }
    </>
  );
};

export default memo(forwardRef(Planning_prformance));
