import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { CONTENT_TYPE } from '../../../framework/constant/constant';
import { useTranslation } from '../../../locale/useTranslation';
import BossDialog from '../../../components/BossDialog';

const ContentPreviewPopup = (props) => {

    const lang = useTranslation();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Function to calculate screen orientation and size
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); // Set true for portrait mode
        };

        // Initial check
        updateScreenType();

        // Add event listener for window resize
        window.addEventListener('resize', updateScreenType);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    return (
        <BossDialog
            title={lang.preview_menu}
            onClose={props.onClose}
            width={isMobile ? "95vw" : "22.5vw"}
        >
            <div className="row" style={{ margin: "5px 10px" }}>
                {props.selectedItem?.content_type == CONTENT_TYPE.Image && <img src={props.selectedItem?.url} style={{ width: '100%', height: 'auto' }} />}
                {props.selectedItem?.content_type == CONTENT_TYPE.Video && <video width="100%" autoPlay controls>
                    <source src={props.selectedItem?.url} type="video/mp4" />
                </video>}
                {(props.selectedItem?.content_type == CONTENT_TYPE.YouTube || props.selectedItem?.content_type == CONTENT_TYPE.Stream) && <ReactPlayer url={props.selectedItem?.playback_url} controls width="100%" playing />}
            </div>
        </BossDialog>
    )
}

export default ContentPreviewPopup