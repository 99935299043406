/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import * as API_Digital_Sign from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Popup } from "@progress/kendo-react-popup";
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import MyStatusCell from '../../components/MyStatusCell';
import InfoRoundButton from '../../components/InfoRoundButton';
import { useNavigate, useParams } from 'react-router-dom';
import { utility } from '../../framework/utility/utilityProvider';
import SalesDashboardTile from '../Dashboard/SalesDashboardTile';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { COMPANYTYPE, ENTITYNAME, LOCALSTORAGE_KEY, SOCKET_ACTION, SOCKET_EVENTS } from '../../framework/constant/constant';
import digiSocket from '../../framework/socket/digiSocket';
import useDigiSocket from '../../framework/socket/useDigiFSocket';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import DigitalSignMobileDashboardForm from './mobileView/DigitalSignMobileDashboardForm';
import ReactGA from "react-ga4";
import ActionCard from '../../components/ActionCard';

const DigitalSignDashboardForm = (props) => {

    const lang = useTranslation();
    const [cards, setCards] = useState([]);
    const [screenData, setScreenData] = useState([]);
    const [playlistData, setPlaylistData] = useState([]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const urlParams = new URLSearchParams(location?.search ?? "");
    const companyId = urlParams?.get('companyId');
    const [selectedCompany, setSelectedCompany] = useState(companyId ? { _id: companyId } : { _id: 0, Name: "All" });
    const [companyCount, setCompanyCount] = useState(0);
    const [companyPlans, setCompanyPlans] = useState([]);
    const [screenCount, setScreenCount] = useState(0);
    const [contentCount, setContentCount] = useState(0);
    const [playlistCount, setPlaylistCount] = useState(0);

    // calculate height or width in media screen
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); // Set true for portrait mode
        };
        updateScreenType();
        window.addEventListener('resize', updateScreenType);

        if (utility.isAutomateB()) {
            ReactGA.event({
                category: "Page View",
                action: "Dashboard Loaded",
                label: window.location.pathname,
            });

        }
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    const anchor = useRef();
    const navigate = useNavigate();

    useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        switch (socketData.action) {
            case SOCKET_ACTION.DIGI_SCREEN_ON:
            case SOCKET_ACTION.DIGI_SCREEN_OFF:
                loadData();
                break;
            default:
                break;
        }
    })

    const onChange = (e) => {
        if (e.target.name == "Company") {
            setSelectedCompany(e.target.value);
            loadData({ company_id: e.target.value._id });
        }
    };
    useEffect(() => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
        return () => {
            digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
        }
    }, []);

    useEffect(() => {
        loadData({ company_id: selectedCompany._id });
    }, []);

    const loadData = async ({ company_id = 0 }) => {
        setIsLoading(true);
        let companiesFilter = (company_id && company_id != 0) ? [company_id] : companies?.length > 0 ? companies.map(x => x?._id) : [];
        let res = await API_Digital_Sign.getDigitalSignDashboardData(companiesFilter, isEnterpriseLogin);
        console.log(res);
        if (!res.success) {
            toast.error(res.message);
        } else {
            setCards(res.data.cards);
            setScreenData(res?.data?.screens?.length > 0 ? res.data.screens.map(d => {
                d.activeAgo = utility.timeAgo(d?.activeAgo ?? 0);
                return d;
            }) : []);
            setPlaylistData(res?.data?.playlists ?? []);
            setCompanyCount(res?.data?.companyCount ?? 0);
            setCompanyPlans(res?.data?.companyPlans ?? []);
            setScreenCount(res?.data?.screenCount ?? 0);
            setContentCount(res?.data?.contentCount ?? 0);
            setPlaylistCount(res?.data?.playlistCount ?? 0);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor.current && !anchor.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [show]);

    const Mystatuscell = (props) => {
        const field = props.field
        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";
        return <MyStatusCell title={status} tileBackColor={tileBackColor} />
    }

    return (<>
        {isLoading && <Loader height="100vh" />}
        {!isLoading && isMobile ? (
            <DigitalSignMobileDashboardForm companyId={selectedCompany?._id} playlistData={playlistData} cards={cards} popup={show} screenData={screenData} isEnterpriseLogin={isEnterpriseLogin} companyPlans={companyPlans} companies={companies} field="active" screenCount={screenCount} contentCount={contentCount} playlistCount={playlistCount}/>) : (
            <>
                <div>
                    {screenCount == 0 ? <ActionCard icon={cards?.icon ?? "desktop"} iconColor="#20c997" title={lang.please_create_screen_first_label} Description={lang.screen_create_description_label} buttonAction={() => navigate("/home/digitalsignScreen", { state: { openNewEditForm: true } })} buttonName={lang.add_screen_button_label} /> :
                    contentCount == 0 ? <ActionCard icon={cards?.icon ?? "clipboard"} iconColor="#25A3F2" title={lang.please_create_content_label} Description={lang.content_create_description_label} buttonAction={() => navigate("/home/digitalSignContent", { state: { openNewEditForm: true } })} buttonName={lang.add_content_button_label} /> :
                    playlistCount === 0 ? <ActionCard icon={cards?.icon ?? "play"} iconColor="#FC0256" title={lang.please_create_playlist_label} Description={lang.playlist_create_description_label} buttonAction={() => navigate("/home/Playlist", { state: { openNewEditForm: true } })} buttonName={lang.add_playlist_button_label} iconFontSize={"41px"} /> : ""}
                </div>


                <div className='row m-2'>
                    <div className='col-12'>
                        <div className='flex-container'>
                            <h1 className="page-title txt-color-bludeDark" style={{ marginTop: "5px" }}>
                                <i className="fa fa-table marlr" style={{ marginTop: "10px", fontSize: "20px", color: "GrayText" }} ></i>
                                <span>{lang.digital_sign_dashboard_page_breadcrumb}</span>
                            </h1>
                            <div className="flex-container-reverse flex-item-auto" >
                                <button style={{ margin: '26px 0px', borderRadius: '18px', fontSize: '12px' }} ref={anchor} className="btn btn-primary cursor-pointer mt-2" onClick={() => setShow(!show)} > <i className="fa fa-arrow-down ml-1"></i> {lang.create_button_text} </button>
                                <Popup show={show} anchor={anchor.current} popupClass={"popup-content"} style={{ width: '13vw' }}>
                                    <div className='cursor-pointer ml-2  mt-3' onClick={() => navigate("/home/digitalsignScreen", { state: { openNewEditForm: true } })}>
                                        <i className={`fa fa-desktop fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                        {lang.new_screen_button_title}
                                    </div><hr />
                                    <div className='cursor-pointer ml-2' onClick={() => navigate("/home/digitalSignContent", { state: { openNewEditForm: true } })}>
                                        <i className={`fa fa-clipboard fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                        {lang.new_content_button_title}
                                    </div><hr />
                                    <div className='cursor-pointer ml-2' onClick={() => navigate("/home/Playlist", { state: { openNewEditForm: true } })}>
                                        <i className={`fa fa-play fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                        {lang.new_playlist_button_title}
                                    </div><hr />
                                    <div className='cursor-pointer ml-2 mb-2' onClick={() => navigate("/home/digitalSignScreenGroup", { state: { openNewEditForm: true } })}>
                                        <i className={`fa fa-object-ungroup fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                        {lang.new_screen_group_button_title}
                                    </div>
                                </Popup>
                                {isEnterpriseLogin && <div className='col-3' style={{ fontSize: '12px', marginTop: '-9px' }} >
                                    <label htmlFor="TabView">{lang.company_label}</label>
                                    <span style={{ fontSize: '15px', color: 'blue' }} className='float-right cursor-pointer mr-1' onClick={() => navigate("/home/digitalSignCompany")}>{companyCount}</span>
                                    <DropDownList
                                        style={{ backgroundColor: "white" }}
                                        data={[
                                            { _id: 0, Name: "All" },
                                            ...companies
                                        ]}
                                        name="Company"
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={selectedCompany}
                                        onChange={onChange}
                                    />
                                </div>}
                            </div>
                        </div>
                        <div className='row mt-4'>
                            {cards.map((card) => {
                                return (
                                    <div className={'col-3'}>
                                        <SalesDashboardTile
                                            iconBackColor={card?.backgroundColor ?? "#3c83c9"}
                                            route={card?.route}
                                            filter={{ companyId: selectedCompany?._id }}
                                            count={card?.count}
                                            icon={card?.icon}
                                            status={card?.name}
                                            description={lang.view_details_label}
                                            iconStyle={{ fontSize: '32px', marginTop: '5px', marginLeft: card?.icon == "tv" ? '2px' : '8px' }}
                                            showDetails={card?.showDetails ?? false}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="row shadow mt-3">
                            <div className={isEnterpriseLogin ? 'col-6 mb-3' : 'col-12 mb-3'}>
                                <div className='row mb-2'>
                                    <div className="col m-1">
                                        <div className="float-left mt-1">
                                            {lang.screen_players_label}
                                            <InfoRoundButton message="Total Screen-1 * Last 5 Screen" />
                                        </div>
                                        <div className='float-right mt-1'>
                                            <RoundButton title={lang.add_more_label} icon='external-link' onClick={() => window.open("/home/digitalsignScreen", "_blank")} />
                                        </div>
                                    </div>
                                </div>
                                <Grid
                                    data={screenData}
                                    resizable={true}
                                    className='digitalsign-dashboard-custom-grid'
                                >
                                    <GridColumn field="SerialNumber" title="Serial Number" />
                                    <GridColumn field="Name" title="Name" />
                                    <GridColumn field="screenGroup.Name" title="Screen Group" />
                                    <GridColumn field="activeAgo" title="Active ago" />
                                    <GridColumn field="active" title="Status" cell={Mystatuscell} />
                                </Grid>
                            </div>
                            {isEnterpriseLogin && <div className='col-6 mb-3'>
                                <div className='row mb-2'>
                                    <div className="col m-1">
                                        <div className="float-left mt-1">{lang.plans_label}</div>
                                    </div>
                                </div>
                                <div className='digitalsign-dashboard-custom-grid'>
                                    <table style={{ marginTop: '20px' }} className="table table-bordered">
                                        <thead className='border'>
                                            <tr className='border'>
                                                <th className='border'>Company</th>
                                                <th className='border'>Name</th>
                                                <th className='border'>Tenure</th>
                                                <th className='border'>Screens</th>
                                                <th className='border'>Balance Screens</th>
                                                <th className='border'>Storage</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '14px' }}>
                                            {companyPlans.map((x, index) => (
                                                <tr key={index}>
                                                    <td>{companies?.length > 0 ? companies.find(c => c._id == x.company_id)?.Name : ''}</td>
                                                    <td style={{ width: '40px' }}>{x.plan.Name}</td>
                                                    <td>{x.plan.Tenure}</td>
                                                    <td>{x.plan.screens}</td>
                                                    <td>{x.balanceScreens}</td>
                                                    <td>{x.plan.storage}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </>
        )}
    </>);
};


export default DigitalSignDashboardForm;