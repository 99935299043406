/* eslint-disable */
import React, { useState, useRef } from 'react';
import { utility } from '../../../framework/utility/utilityProvider';
import { Menu } from 'primereact/menu';

const MobileGridCard = ({ title = '', description = '', label1, label2, topRight, bottomRight, width = '100%', height = 'auto', imageUrl, style = {}, label1Style = {}, label2Style = {}, contextMenuItem, showContextMenu=false, handleItemClick, data }) => {

    const menuRef = useRef(null);

    const onEllipsisClick = (event) => {
        event.preventDefault();
        event.stopPropagation(); 
        menuRef.current.toggle(event);
    };

    return (
        <div
            className="mb-3 mt-2"
            style={{
                width: width,
                height: height,
                position: 'relative',
                border: '1px solid #ced4da',
                boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                borderRadius: '8px',
                overflow: 'hidden',
                ...style,
            }}
            onClick={(e) => {e.preventDefault(); handleItemClick && handleItemClick(data)}}
        >
            <div style={{ display: 'flex' }}>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
                        }}
                        alt={title}
                        style={{ width: '30%', height: height, objectFit: 'cover', borderRadius: '8px' }}
                    />
                )}
                <div className="p-2">
                    <p
                        className="p-0 m-0 mb-1"
                        style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#000000',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {utility.subString(title, 20)}
                    </p>
                    <p
                        className="p-0 m-0 mb-1"
                        style={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            color: 'rgb(75 66 66)',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {description}
                    </p>
                    {label1 && (
                        <p
                            className="p-0 mb-1"
                            style={{
                                ...label1Style,
                                fontSize: '12px',
                                fontWeight: 'normal',
                                color: 'grey',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {label1}
                        </p>
                    )}
                    {label2 && (
                        <p
                            className="p-0 mb-1"
                            style={{
                                ...label2Style,
                                fontSize: '12px',
                                fontWeight: 'normal',
                                color: 'grey',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {label2}
                        </p>
                    )}
                    <div
                        className="pt-2 pr-2 d-flex"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                        }}
                    >
                        {topRight}
                        {showContextMenu && <i
                            className="fa fa-ellipsis-v pl-3 pr-2 pt-1 cursor-pointer"
                            aria-hidden="true"
                            onClick={onEllipsisClick}
                        ></i>}
                        <Menu
                            model={contextMenuItem}
                            popup
                            ref={menuRef}
                        />
                    </div>
                    <div
                        className="pr-2 pb-1"
                        style={{
                            color: 'gray',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            fontSize: 'small',
                        }}
                    >
                        {bottomRight}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileGridCard;
