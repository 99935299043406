/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { DELIVERYSTATUSENUM, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { utility } from "../../framework/utility/utilityProvider";

const AmazonDeliveryGroup = (props) => {

  const [dataItem, setDataItem] = useState({ Description: '', MediaEpisodes: [], IsPublished: false, Archive: false });
  const [mediaEpisodeOpen, setMediaEpisodeOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const navigate = useNavigate();
  const { SID } = useParams();
  const lang = useTranslation();
  const loc = useLocation();

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == "IsPublished") {
      setDataItem({ ...dataItem, IsPublished: !dataItem.IsPublished });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const onMediaEpisodeSelection = () => {
    mediaEpisodeOpen ? setMediaEpisodeOpen(false) : setMediaEpisodeOpen(true);
  }

  const loadEditData = async () => {

    let linkData = {
      link_collection: 'mediaepisode',
      link_field: 'MediaEpisodes'
    }

    var res = await API.getEntity(ENTITYNAME.AmazonDeliveryGroup, parseInt(SID), linkData);
    console.log(res.data)
    if (res.success) {
      setDataItem(res.data);
      setGridData(res.data.MediaEpisodes);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onDelete = (data) => {
    console.log(data);
    var filtered = gridData.filter((obj) => obj._id != data._id);
    setGridData(filtered);
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const MyDeleteCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onDelete(props.dataItem), () => { })} />
    </div>
  );

  const handleSelectedData = (dataList) => {
    var newData = utility.checkDuplicateInData(dataList, gridData, "Title");
    setGridData([...gridData, ...newData]);
  }

  const isValid = () => {
    if (dataItem.Description == '' || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (gridData.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_media_episode_group_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSave = async () => {

    if (!isValid()) return;
    const saveData = {
      SID: parseInt(SID),
      Description: dataItem.Description,
      IsPublished: dataItem.IsPublished ?? false,
      MediaEpisodes: gridData.length > 0 ? gridData.map(x => x._id) : [],
      Archive: dataItem.Archive ?? false,
      checkDuplicate: true,
      query: [["Description", "=", dataItem.Description]]
    };
    var res = await API.saveData(ENTITYNAME.AmazonDeliveryGroup, saveData);
    console.log(res);
    if (res.success) {
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.AMAZONDELIVERYGROUP, data: res.data, message: res.message };
      API.SaveLogs(logData);
      setDataItem({});
      setGridData([]);
      onClose();
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };


  const onScheduleGenerated = async () => {
    var saveData = gridData.map(x => {
      return {
        MediaEpisode: { _id: x._id, Title: x.Title, AssetId: x.AssetId, MediaCategory: x.MediaCategory.Description, EpisodeNumber: x.EpisodeNumber },
        User: { _id: utility.getValue(LOCALSTORAGE_KEY.user_id) },
        DeliveryStatus: DELIVERYSTATUSENUM[0],
        TimeStamp: Date.now(),
        MetaDataChecklistResult: '',
        AssetChecklistResult: '',
        AmazonDeliveryGroup: { _id: dataItem._id, Description: dataItem.Description },
        Archive: false,
      }
    });
    var res = await API.saveData(ENTITYNAME.OttPackageDeliverySchedule, saveData);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    toast.success(`${lang.schedule_delivered_successfully_success_message}`,
      { position: toast.POSITION.TOP_RIGHT }
    );
  };

  const onClose = () => {
    if (loc.state && loc.state.prevPath) {
      navigate(loc.state.prevPath)
    } else {
      navigate(-1);
    }
  }

  return <>
    <div className="row m-1">
      <div className="col-12">
        <div className="row mt-1 mb-1" style={{ marginLeft: '-25px' }}>
          <div className="col">
            <ActionButton title={lang.save_button_tooltip} name={lang.save_button_text} btnColor={'info'} onClick={onSave} />
            {SID > 0 && <ActionButton title={lang.schedule_delivery_button_tooltip} name={lang.schedule_delivery_button_tooltip} btnColor={'info'} onClick={onScheduleGenerated} />}
            <CancelButton onClick={onClose}></CancelButton>
          </div>
        </div>
        <div className="row form-group mt-2">
          <div className="col-3">
            <label htmlFor="">{lang.description_label} *</label>
            <input type="text" className="form-control form-control-sm" name="Description" value={dataItem.Description} onChange={onChange} />
          </div>
          {SID < 1 && <div className="col-3 mt-4">
            <input name="IsPublished" type="checkbox" value={dataItem.IsPublished} checked={dataItem.IsPublished} onChange={onChange} />
            <label className="ml-1">{lang.published_label}</label>
          </div>}
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <input name="Archive" type="checkbox" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
            <label className="ml-1">{lang.archive}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AddRoundButton title={lang.add_media_episode_button_tooltip} onClick={() => setMediaEpisodeOpen(true)} />
            <Grid data={gridData} style={{ height: "64vh", marginTop: "10px", marginBottom: '40px' }}>
              <GridColumn field="" title="" editable={false} cell={MyDeleteCommandCell} width={'40px'} />
              <GridColumn field="Title" title={lang.title_column_column} editable={false} />
              <GridColumn field="AssetId" title={lang.assetId_column_column} editable={false} />
              <GridColumn field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
              <GridColumn field="EpisodeNumber" title={lang.episode_number_column} editable={false} />
              <GridColumn field="SeasonNumber" title={lang.season_number_column} editable={false} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
    {mediaEpisodeOpen && <CollectionSelection addButtonTitle={lang.select_collection_button_text} title={lang.select_media_episode_collection_header} entityname={ENTITYNAME.MediaEpisode} setDataList={handleSelectedData} wherestatement={['MediaCategory.isLong', '=', true]} closeForm={onMediaEpisodeSelection} width={"52vw"} />}
  </>
}
export default AmazonDeliveryGroup;