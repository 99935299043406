/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton"
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { PlatformCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { DateCell, DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { COLUMNSTYPE, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, LOGEVENT, OPERATORS } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ScheduleFilter } from "../Scheduling/SchedulingFilter";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import PublishingCell from "../../framework/forms/helpercomponents/CustomGridCells/PublishingCell";
import { ConfirmAlert } from "../../ConfirmAlert";
import BossDialog from "../../components/BossDialog";
import ReactPlayer from "react-player";
import ShowScheduleForm from "./ShowScheduleForm";
import ShortFormMediaEpisodeHistory from "../Scheduling/ShortFormMediaEpisodeHistory";

export const MediaEpisodeInterstitialCollection = (props) => {
    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showImport = props.showImport ?? false;
    let showExport = props.showExport ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showEdit = props.showEdit ?? true;
    let showDelete = props.showDelete ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement;
    let andWhereStatement = props.andWhereStatement;
    let sort = props.sort ?? { SID: 1 };
    let customColumns = props.customColumns ?? [];
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    let entityName = props.entityname;
    let showPreferenceButton = props.showPreferenceButton ?? true;
    let hideArchiveProps = props.hideArchive ?? false;
    let showFilterTemplate = props.showFilterTemplate ?? true;
    let module = props?.module;

    const urlParams = new URLSearchParams(location?.search ?? "");
    const paramQuery = {
        curPage: parseInt(urlParams.get('page')) || 1,
        index: parseInt(urlParams.get('index')) || 0
    }

    //Add this for media management preference column
    const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const [warningDialog, setWarningDialog] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive, setHideArchive] = useState(hideArchiveProps);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [template, setTemplate] = useState([]);
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [showShedule, setShowShedule] = useState(false)
    const [showHistory, setShowHistory] = useState(false);

    const historyRef = useRef();
    const setHistoryRef = (data) => {
        historyRef.current = data
    }

    let blankFilterDataItem = {
        MediaCategory: [],
        MediaCategoryType: [],
        Duration: { ...OPERATORS[0], Condition: 0 },
        Brand: [],
        Product: [],
    }
    const [filterDataItem, setFilterDataItem] = useState(blankFilterDataItem);
    const [filterTemplate, setFilterTemplate] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [showFilterDropdown, setShowFilterDropDown] = useState(false);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [showTemplateDropDown, setShowTemplateDropDown] = useState(false);
    const [isFilterCleared, setIsFilterCleared] = useState(true);

    const [page, setPage] = useState({
        skip: (paramQuery.curPage - 1) * page_take,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const loc = useLocation();
    const lang = useTranslation();
    const gridRef = useRef(null);
    const selectedItemsRef = useRef([]);
    const setSelectedItemRef = (items) => {
        selectedItemsRef.current = items;
    }

    //Grid Export
    const _export = useRef(null);

    let href = loc.state ? loc.state.href : false;
    if (href) {
        wherestatement = loc.state.wherestatement
    }

    useEffect(() => {
        setShowArchived(false);
        loadcombo();
        setGridSort([initialSort]);
        setPage({ skip: (paramQuery.curPage - 1) * page_take, take: page_take });
        loaddata({ per_page: page_take, current_page: paramQuery.curPage }, wherestatement, andWhereStatement, searchText, false, filterTemplate);
    }, [props.entityname, props.gridData, props.title]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText == null) return;
        let sortEvent = gridSort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: (paramQuery.curPage - 1) * page_take, take: page_take });
            loaddata({ per_page: page_take, current_page: paramQuery.curPage, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
        }
    }, [searchText]);

    useEffect(() => {
        if (gridRef.current && paramQuery.index >= 0) {
            let updateIndex = paramQuery.index > page_take ? paramQuery.index - ((paramQuery.curPage - 1) * page_take) : paramQuery.index;
            const gridElement = gridRef.current.element || gridRef.current.wrapper || gridRef.current;
            const contentArea = gridElement.querySelector('.k-grid-content');
            if (!contentArea) return;
            const rows = contentArea.querySelectorAll('tr');
            if (updateIndex < rows.length) {
                const targetRow = rows[updateIndex];
                const rowOffsetTop = targetRow.offsetTop;
                const contentAreaHeight = contentArea.clientHeight;
                const scrollPosition = rowOffsetTop - contentAreaHeight / 2 + targetRow.clientHeight / 2;
                contentArea.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [paramQuery.index, gridData]);

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, filters) => {

        setIsLoading(true);

        if (props.gridColumns) {
            setDataColumns(props.gridColumns);
            setGridData(props.gridData);
            setFilterData(props.gridData);
            setIsLoading(false);
        } else {
            try {
                let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
                let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
                let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
                let json = await API.getEntitiesWithSearchForShortMedia(props.entityname, { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort }, columns, searchText, filters);
                setGridData(json?.data ?? []);
                setFilterData(json?.data ?? []);
                setTotal(json?.pagination?.total);
                //if custmoe colum is coming from compemnent level then no need  a column from Server API
                customColumns.length > 0
                    ? setDataColumns(customColumns)
                    : setDataColumns(columns);

                // console.log(columns)
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
                setIsLoading(false);
                setIsNoRecords(true);
            }
        }
    };

    const loadcombo = async () => {
        let templateRes = await API.getDataLookup(ENTITYNAME.SchedulingFilterTemplate, { sort: { Name: 1 } });
        let filtered = templateRes.data
        if (props.mediaCategory && templateRes.data.length > 0) {
            filtered = templateRes.data.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == props.mediaCategory) || x.FilterModel.MediaCategoryType.some((y) => y.MediaCategorySID == props.mediaCategory))
        }
        setTemplate([...filtered]);
    }

    const enterEdit = (item) => {
        console.log(item);
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            let current_page = page.skip / page.take + 1;
            let prevPath = item.prevPath ?? 0;
            navigate(`${item.SID}?page=${current_page + prevPath}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false, prevPath: loc.pathname + `?page=${current_page + prevPath}` } });
        }
    };

    const handleNavigate = (item) => {
        if (item?.Module) {
            window.open(item.path, "MyTargetWindowName");
        } else {
            navigate(`${item.path}`, { state: item });
        }
    }

    const history = (item) => {
        setHistoryRef(item);
        setShowHistory(item.history);
    };

    //Setting Preference

    //onSubmit from EDIRFORM
    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        var isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var res = await API.saveData(props.entityname, dataToSave);
        console.log(res);
        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        utility.deleteLocalStorageItem(props.entityname)
        //to load grid with new data
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        console.log(dataItem);
        onConfirmDelete(dataItem)
    };

    const updateMediaArchive = async (dataItem, logMsg) => {

        let resArchiveUpdated = await API.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: logMsg ? logMsg : resArchiveUpdated.message };
            API.SaveLogs(logData);
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
            utility.deleteLocalStorageItem(entityName);
            toast.success(`${!dataItem.Archive ? "Archived" : "Un-Archived"} successfully!!`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const handleArchive = async (dataItem) => {

        if (!dataItem.Archive) {
            let res = await API.canArchiveMediaEpisode(dataItem._id);
            if (res.success) {
                ConfirmAlert(
                    () => {
                        updateMediaArchive(dataItem, `${dataItem.AssetId} is ${res.message} when archived`)
                    },
                    () => { return; },
                    "Confirm",
                    `${res.message}. It will remain Scheduled. Do you still want to Archive?`
                )
            } else {
                updateMediaArchive(dataItem)
            }
        } else {
            updateMediaArchive(dataItem)
        }

    }

    const onCloseUserPrefence = () => {
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
        setShowPreferences(!showPreferences);
    }

    const onCommercialPreview = (data) => {

        let validPathExtension = utility.previewCommercial(data.AssetId)
        if (!validPathExtension.success) {
            toast.error(validPathExtension.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        //const convertedPath = validpathandExtension.data.replace(/\\\\/g, '\\');
        console.log(validPathExtension.data);
        setSelectedItem({ ...selectedItem, data: validPathExtension.data })
        setShowPreview(true);
    }

    const onShowSchedule = (item) => {
        setSelectedItemRef(item)
        setShowShedule(true)
    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            history={history}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            dataColumns={dataColumns}
            onDelete={handleDelete}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            toNavigate={handleNavigate}
            myMenuModel={myMenuModel}
            openNewEditForm={openNewEditForm}
            module={module}
            onCommercialPreview={onCommercialPreview}
            onShowSchedule={onShowSchedule}
        />
    );

    const MyCustomToggleButton = (props) => (
        <MyToggleButton
            {...props}
            entityName={entityName}
            size={"small"}
        />
    );

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const Mystatuscell = (props) => (
        <div>
            <MyStatusCell title={props.dataItem.CurrentStatus} titleColor="#333" tileBackColor="#72eda9" />
        </div>
    )

    const handleErrorDialogClose = () => {
        setDeleteError(!deleteError);
        setDeleteErrorMessage("");
    }
    const errorHandler = (message) => {
        setWarningDialog(!warningDialog);
        setDeleteError(true);
        setDeleteErrorMessage(message);
    }

    const onConfirmDelete = async (dataItem) => {
        const res = await API.deleteData(props.entityname, dataItem.SID);
        console.log("Loading");
        if (!res.success) {
            console.log(res.message);
            errorHandler(res.message);
            return;
        }
        let current_page = page.skip / page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, '', showArchived, filterTemplate);
        utility.deleteLocalStorageItem(props.entityname);
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        // if page change then route change as well 
        const url = new URL(window.location);
        url.searchParams.set('page', current_page);
        url.searchParams.set('index', 0);
        window.history.pushState({}, '', url);
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
        setPage(e.page);
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        let sortEvent = gridSort[0] ?? initialSort;
        if (!showArchived) {
            loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true, filterTemplate);
        } else {
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false, filterTemplate);
        }
    }

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
        setGridSort([initialSort]);
    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var res = await API.exportGridExcel(gridData, dataColumns);
        console.log(res.data);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const toGetFilteredData = (data) => {

        setIsLoading(true);
        let finalData = {
            ...data,
        }
        setFilterDataItem(finalData);

        let finalFilterQuery = [];

        if (Object.keys(finalData).length != 0) {
            let mediaCategoryFilter = finalData.MediaCategory.length != 0 ? [["MediaCategory._id", 'in', finalData.MediaCategory.map((item) => item._id)]] : [];
            let mediaCategoryTypeFilter = finalData.MediaCategoryType.length != 0 ? [["MediaCategoryType._id", 'in', finalData.MediaCategoryType.map((item) => item._id)]] : [];
            let productFilter = finalData.Product.length != 0 ? [["Product._id", 'in', finalData.Product.map((item) => item._id)]] : [];
            let brandFilter = finalData.Brand.length != 0 ? [["Brand._id", 'in', finalData.Brand.map((item) => item._id)]] : [];
            let durationFilter = finalData.Duration.Condition > 0 ? ["Duration", `${finalData.Duration.Operator}`, finalData.Duration.Condition] : []

            finalFilterQuery = [...mediaCategoryFilter, ...mediaCategoryTypeFilter, ...productFilter, ...brandFilter, durationFilter];
        }

        setFilterTemplate(finalFilterQuery);
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, finalFilterQuery)
    }

    const onClearTemplate = () => {
        wherestatement = props.wherestatement;
        setFilterTemplate({});
        setSelectedTemplate({ _id: 0, Name: 'All', FilterModel: {} });
        loadcombo();
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    }

    const onFilterTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
        console.log(e.target.value)
        let filterDataItem = {
            MediaCategory: e.target.value.FilterModel.MediaCategory,
            MediaCategoryType: e.target.value.FilterModel.MediaCategoryType,
            Brand: e.target.value.FilterModel.Brand,
            Product: e.target.value.FilterModel.Product,
            Duration: { ...e.target.value.FilterModel.Duration, Condition: e.target.value.FilterModel.Duration.Condition },
            IsDummy: e.target.value.FilterModel.IsDummy ?? false,
            CreatedFrom: e.target.value.FilterModel.CreatedFrom,
            CreatedTo: e.target.value.FilterModel.CreatedTo,
        }
        toGetFilteredData(filterDataItem);
    }

    const onShowFilterTemplate = () => {

        console.log(showFilterDropdown)

        if (showFilterDropdown) {
            toGetFilteredData({});
            setIsFilterCleared(true);
            setSelectedTemplate({});
        }

        setShowFilterDropDown(!showFilterDropdown);
    }

    const onClickAdvancedFilter = (edit = false) => {
        console.log(edit)
        console.log(selectedTemplate)
        if (edit && selectedTemplate && Object.keys(selectedTemplate).length == 0) {
            toast.error(lang.please_select_template_to_edit_error_message, { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        setFilterPopupOpen(true);
        setShowTemplateDropDown(edit);
        setSelectedTemplate(edit ? selectedTemplate : {});
        setFilterDataItem({});
    }

    let timeOut;
    const handleColumnResize = (event) => {
        let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
        try {
            if (event.index == 0) return;
            if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
            let column = dataColumns[event.index - lockedCol];
            let payload = {
                entityName: column?.entityName,
                name: column?.name,
                width: event.newWidth
            };
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                API.updateUserPreferenceColumnWidth(payload);
            }, 700);
        } catch (error) {
            console.error("Error handling column resize:", error);
        }
    };

    const onVideoError = () => {
        toast.error(lang.video_not_found_error_message, { position: toast.POSITION.TOP_RIGHT });
        setShowPreview(false);
    }

    return (
        <div>
            {showTitle && (
                <>
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{myTitle === "" ? props.entityname : myTitle}</span>
                    </h1>
                </>
            )}
            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}
                    {showAdd && (
                        <AddRoundButton onClick={() => enterEdit({ SID: 0 })}></AddRoundButton>
                    )}
                </div>

                <div className="flex-container-reverse flex-item-auto">

                    {showFilterDropdown && <div className="input-group w-300 martb">
                        <DropDownList
                            style={{ width: selectedTemplate && Object.keys(selectedTemplate).length > 0 ? '200px' : '250px' }}
                            data={template}
                            name={"Template"}
                            textField="Name"
                            dataItemKey="_id"
                            value={selectedTemplate}
                            onChange={onFilterTemplateChange}
                            title="Filter Template"
                        />
                        {selectedTemplate && Object.keys(selectedTemplate).length > 0 && <button title="Edit Filter Template" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter(true)} aria-label="Edit Template">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={{ backgroundColor: "white" }}>
                                    <i style={{ margin: "5px 0px", color: "black" }} className="fa fa-pencil" />
                                </span>
                            </div>
                        </button>}
                        <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={isFilterCleared ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                    <i style={{ margin: "5px 0px", color: isFilterCleared ? "black" : "white" }} className="fa fa-list" />
                                </span>
                            </div>
                        </button>
                    </div>
                    }


                    <div className="input-group w-300 martb" style={{ margin: '10px 5px 10px 0px' }}>
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        {showFilterTemplate ? <button title={showFilterDropdown ? "Clear Filters" : "Filters"} style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "0px" }} onClick={onShowFilterTemplate} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={!showFilterDropdown ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                    <i style={{ margin: "4px 0px", color: !showFilterDropdown ? "black" : "white" }} className="fa fa-filter" />
                                </span>
                            </div>
                        </button> : <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>}
                    </div>
                    {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
                        <PreferenceRoundButton
                            icon={"gear"}
                            title={lang.preference_button_tooltip}
                            onClick={() => { setShowPreferences(!showPreferences) }}
                        />
                    </div>}
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    {showImport && (
                        <div className="mt-1" style={{ paddingTop: "12px" }}>
                            <RoundButton
                                icon={"upload"}
                                btnColor={"warning"}
                                style={{ margin: "0px 03px" }}
                                // iconColor={"white"}
                                title={lang.import_file_label}
                                onClick={() => navigate('/home/DataMigration', { state: { mediaCategory: props.mediaCategory } })} //route navigate to data migration module
                            /></div>
                    )}
                    {showExport && (
                        <div className="m-1" style={{ paddingTop: "12px" }}>
                            <RoundButton
                                icon={"download"}
                                btnColor={"info"}
                                style={{ margin: "0px 03px", color: 'white' }}
                                // iconColor={"white"}
                                title={lang.export_button_tooltip}
                                onClick={downloadExcel}
                            /></div>
                    )}
                    {!hideArchive && <div className="d-inline mt-3 mr-2">
                        <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                    </div>}
                </div>
            </div>
            {isLoading && <Loader />}

            {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
            {!isLoading && !isNoRecords && <ExcelExport data={filterData} ref={_export}>
                <Grid
                    style={props.gridstyle ?? {
                        height: "76vh",
                    }}
                    ref={gridRef}
                    sort={gridSort}
                    sortable={sortable}
                    onSortChange={onSortChange}
                    data={filterData}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    pageable={isPageable}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    onColumnResize={handleColumnResize}
                >
                    {/* Edit Row */}

                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
                    )}

                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateOnlyCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.type === COLUMNSTYPE.datetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                            //we are not returning any value for SID column
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.toggle) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyCustomToggleButton}
                                    title={column.label}
                                    width={85}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.checkbox) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={CheckboxCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.href) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={HrefCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.enum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={EnumCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.platform) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={PlatformCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.status) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={Mystatuscell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.mediadealrights) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={PublishingCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>
            </ExcelExport>}

            {openForm && (
                <CustomEditForm
                    {...props} //to get other props from customEditForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    refresh={() => loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate)} //to refresh the collection when data added
                    item={editItem}
                    dataColumns={dataColumns}
                />
            )}

            {deleteError &&
                <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
            {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

            {filterPopupOpen && <ScheduleFilter
                data={filterDataItem}
                showTemplate={showTemplateDropDown}
                toGetFilterDataItem={toGetFilteredData}
                isFilterCleared={setIsFilterCleared}
                closeFilterPopup={() => { setFilterPopupOpen(false); loadcombo() }}
                height={"38px"}
                disableMediaCategory={true}
                onClear={onClearTemplate}
                mediaCategory={props.mediaCategory}
                templateData={template}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate._id == 0 ? null : selectedTemplate}
                loadcombo={loadcombo}
            />}
            {showPreview && <BossDialog
                title={lang.preview_menu}
                onClose={() => setShowPreview(false)}
                width="50vw"
            >
                <div className="row">
                    <ReactPlayer url={selectedItem.data} onError={onVideoError}
                        controls width="100%" playing />
                </div>
            </BossDialog>}

            {showShedule && <ShowScheduleForm data={selectedItemsRef.current} onClose={() => setShowShedule(false)} />}
            {showHistory && <ShortFormMediaEpisodeHistory selectedData={historyRef.current} closeForm={() => setShowHistory(false)}isLong = {false}/>}


        </div>
    );
};
