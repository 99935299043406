import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../locale/useTranslation'
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { toast } from 'react-toastify';
import * as API from '../../framework/API/api_digitalSign';
import { FILEURL } from '../../framework/constant/constant';
import moment from 'moment';
import { LocalDateOnlyCell, LocalDateTimeColumn } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import BossDialog from '../../components/BossDialog';
import DigitalSignMobileAsrunReport from './mobileView/DigitalSignMobileAsrunReport';


const DigitalSignAsrunReport = (props) => {
    const lang = useTranslation();
    const today = moment();

    const [dataItem, setDataItem] = useState({ fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), toDate: today.format('YYYY-MM-DD') })
    const [gridData, setGridData] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message)
    const [searchText, setSearchText] = useState("");
    const filterSearchRef = useRef();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait);
        };
        updateScreenType();
        window.addEventListener('resize', updateScreenType);
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const isValid = () => {
        if (dataItem.fromDate == undefined || dataItem.fromDate == "") {
            toast.error(lang.please_enter_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if (dataItem.toDate == undefined || dataItem.toDate == "") {
            toast.error(lang.please_enter_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if (dataItem.toDate < dataItem.fromDate) {
            toast.error(lang.to_date_should_be_greater_than_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        return true;
    }

    const loadData = async () => {
        
        if (!isValid()) return;
        setGridData([]);
        setLoadingStatus(lang.data_loading_error_message);
        let filterData = {
            fromDate: moment(dataItem.fromDate).startOf('day').toDate().getTime(),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            isExport: false,
            serialNumber: props?.item?.SerialNumber ?? ""
        };
        console.log(filterData);

        const res = await API.downloadDigitalSignAsrun(filterData)
        console.log(res);

        if (res.success) {
            if (res.data.length == 0) {
                setGridData([]);
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                return;
            }
            setGridData(res.data);
            filterSearchRef.current = res.data; 
            setLoadingStatus(`${lang.data_load_successfully_message}`);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setGridData([]);
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }
        setSearchText(filterData)
        filterSearchRef.current = filterData;
        console.log(filterSearchRef.current);

    }

    const downloadExcel = async () => {
        if (gridData.length == 0) {
            toast.info(lang.no_data_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let filterData = {
            fromDate: moment(dataItem.fromDate).startOf('day').toDate().getTime(),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            isExport: true,
            serialNumber: props?.item?.SerialNumber ?? ""
        };

        var res = await API.downloadDigitalSignAsrun(filterData);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const onSearch = (searchText) => {
        const keyword = searchText ? searchText.toLowerCase() : "";
        const originalData = filterSearchRef.current || gridData;
        console.log(originalData);

        if (!keyword) {
            setGridData(originalData);
            return;
        }
        const filteredData = originalData.filter((item) => {
            const screenGroupNameMatch = item.screenGroupName?.toLowerCase().includes(keyword);
            const contentNameMatch = item.contentName?.toLowerCase().includes(keyword);
            const playlistNameMatch = item.playlistName?.toLowerCase().includes(keyword);
            const serialNumberMatch = item.serialNumber?.toLowerCase().includes(keyword);

            return screenGroupNameMatch || contentNameMatch || playlistNameMatch || serialNumberMatch;
        });

        setGridData(filteredData);
    };

    const LocalTimeOnlyCell = (props) => (
        <LocalDateTimeColumn {...props} format="HH:mm:ss" />
    );


    return (
        <>
            {isMobile ? <DigitalSignMobileAsrunReport title={'(' + props?.item?.Name + ')' + " - " + lang.asrun_report_dialog_header} cancelEdit={props.cancelEdit} dataItem={dataItem} onChange={onChange} loadData={loadData} downloadExcel={downloadExcel} onSearch={onSearch} gridData={gridData} /> :
                <BossDialog
                    title={'(' + props?.item?.Name + ')' + " - " + lang.asrun_report_dialog_header}
                    onClose={props.cancelEdit}
                    width={"1000px"}
                >
                    <div className="row m-1">
                        <div className="col-12">
                            <div className="flex-container">
                                <div className="">
                                    <label className="form-label">{lang.from_date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="fromDate" value={dataItem.fromDate} onChange={onChange} style={{ width: '200px' }} />
                                </div>
                                <div className="ml-3">
                                    <label className="form-label">{lang.to_date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="toDate" value={dataItem.toDate} onChange={onChange} style={{ width: '200px' }} />
                                </div>
                                <div className="mt-4 ml-3">
                                    <ActionButton name={lang.show_button_text} onClick={loadData} style={{ marginTop: '-5px', marginRight: "10px" }} />
                                    <RoundButton icon='download' onClick={() => downloadExcel()} title={lang.download_button_tooltip} />
                                </div>
                                <div className="flex-container-reverse flex-item-auto mt-2">
                                    <div className="input-group input-group w-300 martb" >
                                        <input
                                            type="text"
                                            className="form-control shadow-inset-2 pt-0 pb-0"
                                            id="searchInput"
                                            placeholder={lang.search_button_text}
                                            onChange={(e) => onSearch(e.target.value)}
                                            onFocus={(e) => {
                                                if (props.setOutSideFocus) {
                                                    props.setOutSideFocus(true);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (props.setOutSideFocus) {
                                                    props.setOutSideFocus(false);
                                                }
                                            }}
                                        />
                                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Grid data={gridData} resizable style={{ height: '70vh' }}>
                                        <GridNoRecords>{loadingStatus}</GridNoRecords>
                                        <Column field="time" cell={LocalDateOnlyCell} title={lang.date_column} width={150} />
                                        <Column field="time" cell={LocalTimeOnlyCell} title={lang.time_column} width={150} />
                                        <Column field="screenGroupName" title={lang.screen_group_column} width={150} />
                                        <Column field="contentName" title={lang.content_column} width={150} />
                                        <Column field="playlistName" title={lang.playlist_column} width={150} />
                                        <Column field="serialNumber" title={lang.serial_number_column} width={150} />
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </BossDialog>
            }
        </>
    )
}

export default DigitalSignAsrunReport
