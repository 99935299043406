/* eslint-disable */
import React from 'react';
import { useTranslation } from '../../../locale/useTranslation';
import { useNavigate } from 'react-router-dom';
import MobileGridCard from './MobileGridCard';

const DigitalSignMobileAdminDashboardForm = (props) => {

    const { cards = [], gridData = [] } = props;
    const lang = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <div className='d-flex'>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                    <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px" }}></i>
                    <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}>{lang.digital_sign_admin_dashboard_page_breadcrumb}</span>
                </div>
            </div>

            <div>
                {cards.map((card, index) => (
                    <div className="d-flex justify-content-center align-items-center align-self-center align-content-around flex-wrap mt-3" key={index} >
                        <div className="p-3" style={{ border: "1px solid #ced4da", boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", borderRadius: "8px", width: "100%" }}>
                            {/* Header Section */}
                            <div className="d-flex justify-content-between align-items-center">
                                {/* Icon Section */}
                                <div className="d-flex justify-content-center align-items-center"
                                    style={{ backgroundColor: card?.backgroundColor ?? "#6f42c1", width: "70px", height: "60px", borderRadius: "14px" }}>
                                    <i className={`fa fa-${card?.icon ?? "desktop"} text-white`} style={{ fontSize: "25px" }} ></i>
                                </div>
                                {/* Text Section */}
                                <div className="text-end">
                                    <small className="text-muted" style={{ fontSize: "14px" }}> {card?.name} </small>
                                    <h4 className="mb-0 text-right" style={{ fontWeight: "bold", fontSize: 'large' }}>
                                        {String(card?.count).includes('/') ? (
                                            <>
                                                {String(card?.count).split('/')[0]}/
                                                <span style={{ display: 'inline-block', fontSize: 'large' }}>
                                                    {String(card?.count).split('/')[1]}
                                                </span>
                                            </>
                                        ) : (<span className="text-right" style={{ color: "black", fontWeight: "bold", fontSize: 'large' }}>{card?.count}</span>)}
                                    </h4>
                                </div>
                            </div>
                            {/* Divider */}
                            <hr className="my-3" />
                            {/* Footer Section */}
                            {card?.showDetails && (
                                <div className="d-flex justify-content-between align-items-center p-3" style={{ border: "1px solid #ced4da", backgroundColor: "#fff", borderRadius: "8px" }}  onClick={() => navigate(card?.route)}>
                                    <span className="text-muted" style={{ fontSize: 'large' }}>{card?.description ?? "View Details"} </span>
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{ backgroundColor: card?.backgroundColor ?? "#6f42c1", width: "30px", height: "30px", borderRadius: "40px", }}
                                        onClick={() => navigate(card?.route)}
                                    >
                                        <i className="fa fa-arrow-right text-white"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div className='mt-2 mb-5'>
                <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word" }}>{lang.last_five_user_login_label}</span>
                {
                    gridData.map((item, index) => {
                        return <MobileGridCard
                            key={index}
                            title={item.name}
                            description={item?.userName}
                            label1={item?.Email}
                            label2={item?.PhoneNo}
                            height="110px"
                        />
                    })
                }
            </div>
        </>
    );
}

export default DigitalSignMobileAdminDashboardForm
