/* eslint-disable */
import React from 'react';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { utility } from '../../../framework/utility/utilityProvider';
import { PLAYLIST_STATUS } from '../../../framework/constant/constant';
import MobileGridCard from './MobileGridCard';
import { useNavigate } from 'react-router-dom';
import MyStatusCell from '../../../components/MyStatusCell';
import { ContentArrayImageCell } from '../../../framework/forms/helpercomponents/CustomGridCells/ContentArrayImageCell';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';

export const DigitalSignMobileHeaderPlaylistCollection = (props) => {

    const { gridData = [], showTitle = true, showAdd = true, showBack = true, createNewPlaylist, createNewPlaylistWithCompany, isEnterpriseLogin, isLoading, onEnterEdit, handleDelete, handlePlaylistRefresh, handlePlaylistAction } = props;
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {showTitle && (
                <div className='d-flex ml-1' style={{ alignItems: "center", flexWrap: "wrap" }} >
                    <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px", }}></i>
                    <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}>Playlist</span>
                </div>
            )}
            <div className="flex-container mt-2 mb-2">
                {showBack && (
                    <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                )}
                {showAdd && (
                    <AddRoundButton onClick={isEnterpriseLogin ? createNewPlaylistWithCompany : createNewPlaylist} />
                )}
            </div>
            {isLoading ? <Loader /> :
                <div className='mb-5' style={{ flex: 1, overflowY: "auto" }}>
                    {gridData.length == 0 ?
                        <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                            <AddRoundButton onClick={isEnterpriseLogin ? createNewPlaylistWithCompany : createNewPlaylist} /> Add Playlist
                        </div>
                        : gridData.map((item, index) => {
                            console.log(item);
                            const contextMenuItem = [
                                {
                                    label: 'Edit',
                                    command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit(item) },
                                    icon: 'pi pi-pencil'
                                },
                                {
                                    label: 'Play',
                                    command: (e) => { e.originalEvent.stopPropagation(); handlePlaylistAction(item) },
                                    icon: 'pi pi-play'
                                },
                                {
                                    label: 'Refresh',
                                    command: (e) => { e.originalEvent.stopPropagation(); handlePlaylistRefresh(item) },
                                    icon: 'pi pi-refresh'
                                },
                                {
                                    label: 'Delete',
                                    command: (e) => { e.originalEvent.stopPropagation(); handleDelete(item) },
                                    icon: 'pi pi-trash'
                                }
                            ]
                            return <MobileGridCard
                                key={index}
                                title={item.Name}
                                topRight={<MyStatusCell titleFontSize='10px' titleHeight='20px' style={{ width: '60px', position: "relative" }} title={utility.convertCamelCaseToWords(utility.getKeyByValue(PLAYLIST_STATUS, item.Status))} tileBackColor="#508D4E" />}
                                description={item?.screenGroup?.map(x => x?.Name)?.join(', ')}
                                label2={<ContentArrayImageCell field="playlistItem" dataItem={item} />}
                                label2Style={{ marginLeft: '-10px', marginTop: '10px' }}
                                contextMenuItem={contextMenuItem}
                                showContextMenu={true}
                                data={item}
                                handleItemClick={onEnterEdit}
                                height='128px'
                            />
                        })}
                </div>
            }
        </div>
    )
}
