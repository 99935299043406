import React from 'react'

export const OverScreenPopup = (props) => {
    const { message = " Please wait... ", showLoader=true } = props;
    return (
        <div style={{ zIndex: 101, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0,  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.9)'  }}>
            {showLoader && <i className="fa fa-spinner fa-spin fa-2xl" />}
            <div>{message}</div>
        </div>
    )
}
