/* eslint-disable */
import Card from 'react-bootstrap/Card';
import { CardMenuButton } from '../framework/forms/helpercomponents/buttons/CardMenuButton';
import classNames from 'classnames';
import { useTranslation } from '../locale/useTranslation';
import { ConfirmDeleteAlert } from '../ConfirmAlert';
import { utility } from '../framework/utility/utilityProvider';
import 'material-symbols';
import { OTT_POSTER_TYPE } from '../framework/constant/constant';


const MediaCard = (props) => {

    const { index, onDragStart,  onDrop, dataItem, onDeleteDataItem, onEditDataItem, setHoveredDataItemPostion,  customeModelFields, posterURL, posterStyle} = props;
    
    const modelFields = {
        Title: "Title",
        Description: "Description",
        PosterURL: "PotraitImageUrl",
        ThirdLine: ""
      };

    const FinalModelFeilds  = customeModelFields ?? modelFields 

   // const { StartDate, EndDate, VideoPlaybackUrl } = dataItem;

    const Title = dataItem[FinalModelFeilds.Title];
    const Description = dataItem[FinalModelFeilds.Description];
    var PotraitImageUrl = FinalModelFeilds.PosterURL ? dataItem[FinalModelFeilds.PosterURL] ?? utility.getPosterUrl(dataItem.Posters,OTT_POSTER_TYPE.Portrait) : "";
    const AssetId = dataItem.AssetId;
    const ThirdLine = dataItem[FinalModelFeilds.ThirdLine] ?? "";
    var tooltipText="";
    if(props.title=="SEASON" || props.title=="EPISODE" ){
        tooltipText= Title + '\n' + AssetId;
    }else if(props.title=="Rails" || props.title=="Rail Library"){
        tooltipText= Title;
    }
    else{
        tooltipText = Title + '\n' + Description + '\n';
    }
   
    const lang=useTranslation();

    return (<div
        className='grabbable'
        draggable
        onDragStart={(e)=>{


    ///ToDo: Need to create group element    
                // const groupElement = document.createElement("div");
                // groupElement.style.position = "absolute";
                // groupElement.style.top = "0";
                // groupElement.style.left = "0";
                // groupElement.style.zIndex = "-100";
                // groupElement.style.display = "flex";
                // groupElement.style.flexDirection = "column";
                // groupElement.style.width = "280px"




                // var crt = e.currentTarget.cloneNode(true);
                // var crt2 = e.currentTarget.cloneNode(true);
                // var crt3 = e.currentTarget.cloneNode(true);



                // groupElement.appendChild(crt)
                // groupElement.appendChild(crt2)
                // groupElement.appendChild(crt3)

                // document.getElementById("root").appendChild(groupElement);

                // e.dataTransfer.setDragImage(groupElement, 0, 0);

                // window.setTimeout(function() {
                //     groupElement.parentNode.removeChild(groupElement);
                // }, 10);
          
       

        }}
        onDrag={(e) => {      
            onDragStart(e,index, dataItem)
          
        }}
        onDragEnter={(e) => setHoveredDataItemPostion(index, dataItem)} //onDragEnter(e,index)}
        onDragEnd={(e) => 
        {
                e.currentTarget.classList.remove("dragging");
            onDrop(e);
        }
        }
        onDragOver={(e) => { e.preventDefault() }}
    >
        <Card  className={classNames("m-1", dataItem.isSelected ?  "media-card-selected": "media-card")} onClick={(e)=>props.onSelection(index, dataItem)}>
            <div style={{ position: 'absolute', right: "10px", top: '0px', display: 'flex', zIndex: "99" }}>
                {!props.MenuModel  && onEditDataItem && <p className="ponter-cursor" title={lang.edit_button_tooltip} onClick={() => onEditDataItem(index, dataItem)} ><i className="fa fa-pen text-primary" /></p>}
                {!props.MenuModel && onDeleteDataItem && <p className="ponter-cursor" title={lang.delete_button_tooltip} onClick={()=>ConfirmDeleteAlert(()=>onDeleteDataItem(index, dataItem),()=>{})}><i className="fa fa-trash text-danger" /></p>}
              
              {props.MenuModel &&  <CardMenuButton {...props}/>}
           
         
            </div>
            <div className='row'>
                
                {props.ottMenu ? <div className="col-4" style={{ objectFit: "cover", height: "99px", borderRadius: "20px" }}><span className="material-symbols-outlined m-3" style={{fontSize: '45px'}}>{PotraitImageUrl?.includes('http') ? 'disabled_by_default' : PotraitImageUrl}</span></div> :  <Card.Img onError={(e) => { e.target.onerror = null; e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';}}  src={PotraitImageUrl} draggable={false} className="col-5" style={{ objectFit: "cover", height: "99px", borderRadius: "20px" }} />}
                <Card.Body title={tooltipText} className='col-7 pl-0'>
                    <div style={{ fontSize: "15px", fontWeight: '500' }}>{utility.subString(Title, 18)}</div>
                    {
                    props.title =="SEASON" || props.title =="EPISODE" ? 
                    <Card.Text className='pl-0 pt-0'>{utility.subString(AssetId, 20)}</Card.Text>
                    :<Card.Text className='pl-0 pt-0'>{Description != null && utility.subString(Description, 20)}</Card.Text>
                    
                    
                    }
                 
                  <div style={{ fontSize: "10px"}}>{utility.subString(ThirdLine, 18)}</div>
                </Card.Body>
            </div>
        </Card>
    </div>
    );
}
export default MediaCard;

// https://rootstack.com/en/blog/how-do-i-use-drag-and-drop-react
