/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, COMPANYTYPE } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell, HyperLinkCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { PlatformCell, arrayEnumCell, daysCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { colorCell } from "../../framework/forms/helpercomponents/CustomGridCells/ColorCell";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import socket from "../../framework/socket/socket";
import { utility } from "../../framework/utility/utilityProvider";
import DigitalSignMobileAdminCompanyCollection from "../DigitalSign/mobileView/DigitalSignMobileAdminCompanyCollection";
import { OverScreenPopup } from "../../framework/forms/helpercomponents/alert/OverScreenPopup";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const CompanyCollection = (props) => {
    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showExport = props.showExport ?? true;
    let showArchive = props.showArchive ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showEdit = props.showEdit ?? true;
    let showDelete = props.showDelete ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement ?? [];
    let sort = props.sort ?? { SID: 1 };
    let customColumns = props.customColumns ?? [];
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    let entityName = props.entityname;
    let showPreferenceButton = props.showPreferenceButton ?? true;
    let hideArchiveProps = props.hideArchive ?? false;
    let enableMutliSelect = props.enableMutliSelect ?? false;
    let module = props?.module;
    let showLogs = props.showLogs ?? false;
    let doLoad = props.isLoading ?? true;

    //Add this for media management preference column
    const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive] = useState(hideArchiveProps);
    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [isOverScreenLoadingPopup, setIsOverScreenLoadingPopup] = useState(false);
    const [languageFilter, setLanguageFilter] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [language, setLanguage] = useState({ _id: 0, Description: "All" })

    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const gridRef = useRef(null);

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const loc = useLocation();
    const lang = useTranslation();

    let href = loc.state ? loc.state.href : false;
    if (href) {
        wherestatement = loc.state.wherestatement;
    }

    const urlParams = new URLSearchParams(location?.search ?? "");
    const paramQuery = {
        curPage: parseInt(urlParams.get('page')) || 1,
        index: parseInt(urlParams.get('index')) || 0
    }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait);
        };
        updateScreenType();
        window.addEventListener('resize', updateScreenType);
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    useEffect(() => {
        setShowArchived(false);
        doLoad ? setIsLoading(true) : setIsLoading(false);
        setPage({ skip: (paramQuery.curPage - 1) * page_take, take: page_take });
        loaddata({ per_page: page_take, current_page: paramQuery.curPage }, wherestatement, searchText, false, languageFilter);
    }, [props.entityname, props.gridData, props.title, props.wherestatement]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText == null) return;
        let sortEvent = gridSort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: (paramQuery.curPage - 1) * page_take, take: page_take });
            loaddata({ per_page: page_take, current_page: paramQuery.curPage, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
        }
    }, [searchText]);

    useEffect(() => {
        if (gridRef.current && paramQuery.index >= 0) {
            let updateIndex = paramQuery.index > page_take ? paramQuery.index - ((paramQuery.curPage - 1) * page_take) : paramQuery.index;
            const gridElement = gridRef.current.element || gridRef.current.wrapper || gridRef.current;
            const contentArea = gridElement.querySelector('.k-grid-content');
            if (!contentArea) return;
            const rows = contentArea.querySelectorAll('tr');
            if (updateIndex < rows.length) {
                const targetRow = rows[updateIndex];
                const rowOffsetTop = targetRow.offsetTop;
                const contentAreaHeight = contentArea.clientHeight;
                const scrollPosition = rowOffsetTop - contentAreaHeight / 2 + targetRow.clientHeight / 2;
                contentArea.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [paramQuery.index, gridData]);


    const loaddata = async (pagination, wherestatement, searchText, archive, languageFilter) => {

        let languageRes = await API.getDataLookup(ENTITYNAME.Language, { query: ["Archive", "!=", true], sort: { SID: 1 } })
        languageRes.data.unshift({ _id: 0, Description: "All" })
        setLanguages(languageRes.data);

        if (props.gridColumns) {
            setDataColumns(props.gridColumns);
            setGridData(props.gridData);
            setIsLoading(false);
        } else {
            try {
                let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
                let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
                let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
                let json = await API.getCompanyData(
                    props.entityname,
                    { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
                    columns,
                    searchText,
                    languageFilter
                );
                setGridData(json?.data ?? []);
                setTotal(json?.pagination?.total);
                customColumns.length > 0
                    ? setDataColumns(customColumns)
                    : setDataColumns(columns);
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
                setIsLoading(false);
            }
        }
    };

    const onKillUserSession = (userData) => {
        delete userData["Module"];
        console.log(userData);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
        toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            let param = item.SID;
            let current_page = page.skip / page.take + 1;
            let prevPath = item.prevPath ?? 0;
            let state = {
                copy: item.copy ? true : false,
                quickEdit: item.quickEdit ? true : false,
                prevPath: loc.pathname + `?page=${current_page + prevPath}`,
                paths: [loc.pathname],
                pagination: `?page=${current_page + prevPath}`
            }
            navigate(`${param}?page=${current_page + prevPath}`, { state: state });
        }
    };

    const handleNavigate = (item) => {
        if (item?.Module) {
            window.open(item.path, "MyTargetWindowName");
        } else {
            navigate(`${item.path}`, { state: item });
        }
    }

    //onSubmit from EDIRFORM
    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API.saveData(props.entityname, dataToSave);
        console.log(res);
        if (dataItem?.IsDefault) {
            await API.setAsDefault(entityName, dataItem?._id)
        }
        if (res.success) {
            if (showLogs) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: module, data: res.data, message: res.message };
                API.SaveLogs(logData);
            }
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname)
        //to load grid with new data
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, showArchived);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        onConfirmDelete(dataItem);
    };

    const handleArchive = async (dataItem) => {
        console.log("Here");
        if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
            var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
                _id: dataItem._id,
                archive: !dataItem.Archive,
                entityName: entityName
            })
        } else {
            var resArchiveUpdated = await API.saveData(entityName, {
                ...dataItem,
                Archive: !dataItem.Archive
            })
        }

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            if (module) {
                let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
                API.SaveLogs(logData);
            }
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const onCloseUserPrefence = () => {
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
        setShowPreferences(!showPreferences);

    }

    const setAsDefault = async (data) => {

        var setAsDefaultRes = await API.setAsDefault(entityName, data._id);
        if (setAsDefaultRes.success) {
            let sortEvent = gridSort[0] ?? initialSort;
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            toast.error(setAsDefaultRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const showDetails = (dataItem) => {
        navigate(`/home/digitalSignDashboard?companyId=${dataItem._id}`);
    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            showLogs={showLogs}
            history={history}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            dataColumns={dataColumns}
            onDelete={handleDelete}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            toNavigate={handleNavigate}
            myMenuModel={myMenuModel}
            module={module}
            openNewEditForm={openNewEditForm}
            onKillUserSession={onKillUserSession}
            setAsDefault={setAsDefault}
            showDetails={showDetails}
        />
    );

    const MyCustomToggleButton = (props) => (
        <MyToggleButton
            {...props}
            entityName={entityName}
            size={"small"}
        />
    );

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const Mystatuscell = (props) => (
        <div>
            <MyStatusCell title={props.dataItem.CurrentStatus} tileBackColor="#72eda9" />
        </div>
    )

    const onConfirmDelete = async (dataItem) => {
        console.log(dataItem);
        setIsOverScreenLoadingPopup(true);
        const res = await API.deleteCompanyData(dataItem?._id);
        if (!res.success) {
            toast.error(res.message);
        }
        utility.deleteLocalStorageItem(ENTITYNAME.Company);
        let current_page = page.skip / page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, '', showArchived, languageFilter);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        toast.success(res.message);
        setIsOverScreenLoadingPopup(false);
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        const url = new URL(window.location);
        url.searchParams.set('page', current_page);
        url.searchParams.set('index', 0);
        window.history.pushState({}, '', url);
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
        setPage(e.page);
        cancelSelectedItem()
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        let sortEvent = gridSort[0] ?? initialSort;
        if (!showArchived) {
            loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, true, languageFilter);
        } else {
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, false, languageFilter);
        }
        cancelSelectedItem();
    }

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, searchText, showArchived, languageFilter);
        setGridSort([initialSort]);
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived, languageFilter);
    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API.exportGridExcel(gridData, dataColumns);
        console.log(res.data);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };


    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const onArchiveButtonClick = async () => {
        if (selectedItemsRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        for (let i = 0; i < selectedItemsRef.current.length; i++) {
            const item = selectedItemsRef.current[i];
            await handleArchive(item);
        }
        cancelSelectedItem();
    }

    const onEnterEdit = () => {
        enterEdit({ SID: 0 });
        cancelSelectedItem();
    }

    const cancelSelectedItem = () => {
        setSelectedItem([]);
        setSelectedState({});
    }

    let timeOut;
    const handleColumnResize = (event) => {
        let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
        try {
            if (event.index == 0) return;
            if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
            let column = dataColumns[event.index - lockedCol];
            let payload = {
                entityName: column?.entityName,
                name: column?.name,
                width: event.newWidth
            };
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                API.updateUserPreferenceColumnWidth(payload);
            }, 700);
        } catch (error) {
            console.error("Error handling column resize:", error);
        }
    };

    const onLanguageChange = (e) => {
        setLanguage(e.target.value);
        if(e.target.value?._id != 0){
            let languageFilterQuery = [["Language._id", "=", e.target.value._id]];
            setLanguageFilter(languageFilterQuery);
            loaddata({ per_page: page.take, current_page: page.skip / page.take + 1, orderby: "SID", direction: "asc" }, wherestatement, searchText, showArchived, languageFilterQuery);
        }else{
            setLanguageFilter([]);
            loaddata({ per_page: page.take, current_page: page.skip / page.take + 1, orderby: "SID", direction: "asc" }, wherestatement, searchText, showArchived, []);
        }
    }

    return (
        <>
            {isMobile ? <DigitalSignMobileAdminCompanyCollection onEnterEdit={onEnterEdit} showAdd={showAdd} showBack={showBack} myTitle={myTitle} columns={dataColumns} handleDelete={handleDelete} showTitle={showTitle} isLoading={isLoading} gridData={gridData} /> : <>
                {showTitle && (
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{myTitle === "" ? props.entityname : myTitle}</span>
                    </h1>
                )}
                <div className="flex-container">
                    <div className="martb mar">
                        {showBack && (
                            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                        )}
                        {showAdd && (
                            <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
                        )}
                        {showArchive && enableMutliSelect && selectedItemsRef.current.length != 0 && (
                            <ActionButton title={showArchived ? lang.un_archive_label : lang.archive} name={showArchived ? lang.un_archive_label : lang.archive}
                                onClick={onArchiveButtonClick} />
                        )}
                    </div>

                    <div className="flex-container-reverse flex-item-auto">
                        <div className="input-group input-group w-300 martb" >
                            <input
                                type="text"
                                className="form-control shadow-inset-2 pt-0 pb-0"
                                id="searchInput"
                                placeholder={lang.search_button_text}
                                onChange={onSearch}
                                onFocus={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(true);
                                    }
                                }}
                                onBlur={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(false);
                                    }
                                }}
                            />
                            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                    </span>
                                </div>
                            </button>
                        </div>
                        {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
                            <PreferenceRoundButton
                                icon={"gear"}
                                title={lang.preference_button_tooltip}
                                onClick={() => { setShowPreferences(!showPreferences) }}
                            />
                        </div>}
                        <div className="mt-1" style={{ paddingTop: "12px" }}>
                            <RefreshButton
                                onClick={refreshCollection}
                            />
                        </div>
                        {showExport && (
                            <div className="m-1" style={{ paddingTop: "12px" }}>
                                <RoundButton
                                    icon={"download"}
                                    btnColor={"info"}
                                    style={{ color: 'white' }}
                                    title={lang.export_button_tooltip}
                                    onClick={downloadExcel}
                                /></div>
                        )}
                        {!hideArchive && <div className="d-inline mt-3 mr-2">
                            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                        </div>}
                        <div className="d-inline mr-2 col-2" style={{ marginTop: '-9px' }}>
                            <label htmlFor="OttRailType">{lang.language_label}</label>
                            <DropDownList
                                data={languages}
                                style={{ backgroundColor: 'white' }}
                                name="language"
                                textField="Description"
                                dataItemKey="_id"
                                value={language}
                                onChange={onLanguageChange}
                            />
                        </div>
                    </div>
                </div>
                {isLoading && <Loader />}
                {!isLoading &&
                    <Grid
                        style={props.gridstyle ?? {
                            height: "76vh",
                        }}
                        ref={gridRef}
                        sort={gridSort}
                        sortable={sortable}
                        onSortChange={onSortChange}
                        rowRender={gridRowRender}
                        filterable={filterable}
                        pageable={isPageable}
                        skip={page.skip}
                        take={page.take}
                        onPageChange={pageChange}
                        total={total}
                        resizable={true}
                        onColumnResize={handleColumnResize}
                        data={
                            gridData.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: enableMutliSelect,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        dataItemKey={DATA_ITEM_KEY}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                    >

                        {/* Multi Select Checkbox row */}
                        {enableMutliSelect && (<GridColumn
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={
                                gridData.findIndex(
                                    (item) => !selectedState[idGetter(item)]
                                ) === -1
                            }
                            locked={true}
                            filterable={false}
                        />
                        )}

                        {/* Edit Row */}
                        {showCommandCell && (
                            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                        )}

                        {/* Rows Loop */}
                        {dataColumns.map((column, index) => {
                            if (column.type === COLUMNSTYPE.date) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={DateOnlyCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={true}
                                    />
                                );
                                // }else if (column.name === COLUMNSTYPE.time) {
                            } else if (column.type === COLUMNSTYPE.datetime) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={DateCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={true}
                                    />
                                );
                                // }else if (column.name === COLUMNSTYPE.time) {
                            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={TimeCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={true}
                                    />
                                );
                            } else if (column.name === "SID") {
                                return;
                                //we are not returning any value for SID column
                            } else if (column.type === COLUMNSTYPE.image) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={ImageCell}
                                        title={column.label}
                                        width={column.width ?? 100}
                                        sortable={false}
                                    />
                                );
                            } else if (column.type === COLUMNSTYPE.select) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={MyDatalookupCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.toggle) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={MyCustomToggleButton}
                                        title={column.label}
                                        width={85}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.checkbox) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={CheckboxCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.array) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={ArrayCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format ?? "Description"} //display item in object
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.href) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={HrefCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.hyperlink) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={HyperLinkCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.enum) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={EnumCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format ?? "Description"} //display item in object
                                        sortable={true}
                                    />
                                );
                            } else if (column.type === COLUMNSTYPE.platform) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={PlatformCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.status) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={Mystatuscell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.localdatetime) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={LocalDateTimeColumn}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={true}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.icon) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={IconCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.arrayenum) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={arrayEnumCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.color) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={colorCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                        sortable={false}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.day) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={daysCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                        sortable={false}
                                    />
                                );
                            }
                            else {
                                return (
                                    column.type !== COLUMNSTYPE.hidden && (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            title={column.label}
                                            width={column.width ?? 200}
                                            sortable={true}
                                        />
                                    )
                                );
                            }
                        })}
                    </Grid>}
            </>}
            {openForm && (
                <CustomEditForm
                    {...props} //to get other props from customEditForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    refresh={refreshCollection}//to refresh the collection when data added
                    item={editItem}
                    dataColumns={dataColumns}
                />
            )}
            {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}
            {isOverScreenLoadingPopup && <OverScreenPopup />}
        </>
    );
};