/* eslint-disable */
import React from 'react'
import { BASEURL } from '../../../framework/constant/baseurl';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { useTranslation } from '../../../locale/useTranslation';
import MobileCardLayout from './MobileCardLayout';
import { useNavigate } from 'react-router-dom';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import MyStatusCell from '../../../components/MyStatusCell';
import moment from 'moment';

const DigitalSignMobileScreenForm = (props) => {

    const { gridData = [], myTitle, showAdd=true, onShowActivateScreenPopup, showBack=true, isLoading, onEnterEdit, handleDelete, handleRestartScreen, handleNewGroupCreate, handleAttachGroup , handleAsrunErrorReport,  handleAsrunReport, handleShowRestartHistory, handleLastPlayItem, handlePurchaseScreen, handleTransferScreen } = props;
    const lang = useTranslation();
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div className='d-flex ml-2' style={{ alignItems: "center", flexWrap: "wrap" }} >
                <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px", }}></i>
                <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}> {myTitle} </span>
            </div>
            <div className="flex-container mt-2 mb-2 ml-1">
                {showBack && (
                    <BackRoundButton onClick={() => navigate(-1)} />
                )}
                {showAdd && (
                    <AddRoundButton onClick={onShowActivateScreenPopup} title={lang.save_button_tooltip} />
                )}
            </div>
            {isLoading ? <Loader />
            : <div className='mb-5 ml-2 mr-2' style={{ flex: 1, overflowY: "auto" }}>
                { gridData.length == 0 ? 
                    <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                        <AddRoundButton onClick={onShowActivateScreenPopup} /> Add New Screen
                    </div> 
                : gridData.map((screen) => {
                    const showActiveSreen = screen?.ExpiryDate < new Date().getTime() || screen?.ExpiryDate < moment().add(10, 'days').valueOf();
                    const contextMenuItem = [
                        {
                            label: 'Edit',
                            command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit(screen) },
                            icon: 'pi pi-pencil'
                        },
                        {
                            label: 'Attach Group',
                            command: (e) => { e.originalEvent.stopPropagation(); handleAttachGroup(screen) },
                            icon: 'pi pi-link'
                        },
                        {
                            label: 'New Group From Screen',
                            command: (e) => { e.originalEvent.stopPropagation(); handleNewGroupCreate(screen) },
                            icon: 'pi pi-link'
                        },
                        {
                            label: 'Show last Play Item',
                            command: (e) => { e.originalEvent.stopPropagation(); handleLastPlayItem(screen) },
                            icon: 'pi pi-external-link'
                        },
                        {
                            label: 'Show Asrun Report',
                            command: (e) => { e.originalEvent.stopPropagation(); handleAsrunReport(screen) },
                            icon: 'fa-solid fa fa-tasks'
                        },
                        {
                            label: 'Show Error Log',
                            command: (e) => { e.originalEvent.stopPropagation(); handleAsrunErrorReport(screen) },
                            icon: 'pi pi-exclamation-triangle'
                        },
                        {
                            label: 'Show Restart History',
                            command: (e) => { e.originalEvent.stopPropagation(); handleShowRestartHistory(screen) },
                            icon: 'pi pi-history'
                        },
                        {
                            label: 'Delete',
                            command: (e) => { e.originalEvent.stopPropagation(); ConfirmDeleteAlert(() => handleDelete(screen), () => { }) },
                            icon: 'pi pi-trash'
                        },
                        {
                            label: 'Restart Screen',
                            command: (e) => { e.originalEvent.stopPropagation(); handleRestartScreen(screen) },
                            icon: 'pi pi-power-off'
                        },
                        {
                            label: 'Transfer Screen',
                            command: (e) => { e.originalEvent.stopPropagation(); handleTransferScreen(screen) },
                            icon: 'pi pi-sort-alt',
                        },
                        showActiveSreen && {
                            label: 'Activate Screen',
                            command: (e) => { e.originalEvent.stopPropagation(); handlePurchaseScreen(screen) },
                            icon: 'pi pi-eject'
                        }
                    ].filter(Boolean);
                    const status = screen?.ExpiryDate < new Date().getTime() ? "Expired" : screen?.ExpiryDate < moment().add(10, 'days').valueOf() ? `${Math.floor((screen?.ExpiryDate - new Date().getTime()) / (1000 * 60 * 60 * 24))} Days Left` : "";
                    const tileBackColor = screen?.ExpiryDate < new Date().getTime() ? "red" : "blue";                    
                    return <MobileCardLayout
                        title={screen.Name}
                        description={screen.screenGroup?.[0]?.Name}
                        image={BASEURL + '/' + screen.screenShotUrl + '?' + new Date().getTime() ?? null}
                        style={{ margin: '30px' }}
                        activeAgo={screen.activeAgo}
                        badge={screen?.screenDetail?.ModelNo === "AFTT" ? require("../../../img/amazonfiretv.png") : require("../../../img/android1.png")}
                        contextMenuItem={contextMenuItem}
                        showContextMenu={true}
                        topLeft={<span
                            className={`fa-fade dot ${ screen.active ? "bg-success" : "bg-danger"} d-inline-block`}
                            style={{ width: "15px", height: "15px", borderRadius: "50%" }}
                        />}
                        bottomRight={<span onClick={(e) => { e.stopPropagation(); handlePurchaseScreen(screen)}} ><MyStatusCell style={{ padding: "2px 5px 0px 3px", }} titleHeight="20px" titleFontSize="10px" title={status} tileBackColor={tileBackColor} /></span>}
                        handleItemClick={onEnterEdit}
                        item={screen}
                        modelNo={screen?.screenDetail?.ModelNo}
                    />
                })}
            </div>}
        </div>
    );
};

export default DigitalSignMobileScreenForm
