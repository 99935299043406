/* eslint-disable */
import React from 'react'
import { Card } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';

const SalesDashboardTile = (props) => {
  const showDetails = props?.showDetails ?? true;
  const navigate = useNavigate();
  return (
    <Card >
      <div className="salesCardContent">
        <div className='row' style={{ marginBottom: "-10px" }}>
          <div className='col-2'>
            <div className="salesDashboard_card" style={{ backgroundColor: props.iconBackColor }} />
            <i className={`fa-sharp fa-solid fa-${props.icon} salesDashboardicon_card fa-2x`} style={props?.iconStyle}></i>
          </div>
          <div className='col-10 textAndCount mt-4'>
            <span className="salesDashboard_text">{props.status}</span><br></br>
            <span className="salesDashboard_Count">
              {String(props.count).includes('/') ? (
                <>
                  {String(props.count).split('/')[0]}/
                  <span style={{ fontSize: '17px', display: 'inline-block', marginTop: '10px' }}>
                    {String(props.count).split('/')[1]}
                  </span>
                </>
              ) : (
                props.count
              )}
            </span>
          </div>
        </div>
        {showDetails && <>
          <Divider className="col-11 ml-3" layout="horizontal" />
          <div className='row pl-3' style={{ marginTop: "-13px" }} onClick={(e) => { e.preventDefault(); navigate(props?.route ?? '#', { state: props?.filter }) }}>
            <div className='col-9'>
              <span className="salesDashboard_title">&nbsp;&nbsp;{props.description}</span>
            </div>
            <div className='col-3'>
              <a style={{ textDecorationLine: "none", cursor: "pointer", textAlign: 'right' }} onClick={(e) => { e.preventDefault(); navigate(props?.route ?? '#', { state: props?.filter }) }}>            <div className="salesDashboard_BottonIconcard mr-3" style={{ backgroundColor: props?.iconBackColor, float: 'right' }}>
                <i className={`fa fa-arrow-circle-right bottomDetailIcon`} style={{ bottom: props?.bottom }}></i></div>
              </a>
            </div>
          </div>
        </>}
      </div>
    </Card>

  )
}

export default SalesDashboardTile