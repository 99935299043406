import React from 'react'
import { useNavigate } from 'react-router-dom';

export const AppIcon = (props) => {
    const { path, title, appStyle={}, iconName, iconStyle={} } = props;
    const navigate = useNavigate();
    return (
        <>
            <div
                className="d-flex justify-content-center align-items-center mb-2"
                style={{
                    ...appStyle,
                    width: "80px",
                    height: "80px",
                    borderRadius: "15px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                }}
                onClick={() => navigate(path, { state: props?.state })}
            >
                <i className={`fa fa-${iconName}`} style={{ ...iconStyle, fontSize: "40px" }}></i>
            </div>
            <p style={{ fontSize: "14px", fontWeight: "500", color: "#333", margin: "0", textAlign: 'center' }}>{title}</p>
        </>
    )
}
