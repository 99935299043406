/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { utility } from "../../framework/utility/utilityProvider";
import { myTimeCell } from "../../framework/forms/helpercomponents/inline grid cell/MyTimeCell";

function BreakFormatEditForm(props) {

    const { SID } = useParams();
    const loc = useLocation();

    let blankDataItem = {
        Name: "",
        Channel: {},
        SlotDuration: "00:00:00:00",
        TotalBreakDuration: "00:00:00:00",
        TimeRangeFrom: "00:00:00:00",
        TimeRangeTo: "23:59:59:59",
        SegmentCount: 0,
        IncludePreOpening: false,
        IncludeClosing: false,
        Archive: false,
        BreakFormatDetail: [],

    }

    const [channel, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [editID, setEditID] = React.useState(null);
    const rowClick = (event) => {
        setEditID(event.dataItem.BreakNumber);
    };
    const navigate = useNavigate();
    const lang = useTranslation();

    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadEditData = async () => {
        let res = await API.getEntity(ENTITYNAME.BreakFormat, parseInt(SID));

        if (res.success) {
            setDataItem({
                ...res.data,
                SID: loc.state.copy ? 0 : parseInt(SID),
                Name: loc?.state?.copy ? "Copy of " + res.data.Name : res.data.Name,
                SlotDuration: utility.convertMilisecondsToStringWithFrames(res.data.SlotDuration),
                TotalBreakDuration: utility.convertMilisecondsToStringWithFrames(res.data.TotalBreakDuration),
                TimeRangeFrom: utility.convertMilisecondsToStringWithFrames(res.data.TimeRangeFrom),
                TimeRangeTo: utility.convertMilisecondsToStringWithFrames(res.data.TimeRangeTo),
            })
            setGridData(() => res.data.BreakFormatDetail)
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else if (e.target.name == "IncludePreOpening") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        }
        else if (e.target.name == "IncludeClosing") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == '' || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.SlotDuration.includes("_") || dataItem.SlotDuration == '00:00:00:00') {
            toast.error(`${lang.invalid_slot_duration_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.TotalBreakDuration.includes("_") || dataItem.TotalBreakDuration == '00:00:00:00') {
            toast.error(`${lang.invalid_total_break_duration_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.TimeRangeFrom.includes("_")) {
            toast.error(`${lang.invalid_time_range}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.TimeRangeTo.includes("_") || dataItem.TimeRangeTo == '00:00:00:00') {
            toast.error(`${lang.invalid_time_range}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.SegmentCount < 1 || dataItem.SegmentCount == undefined) {
            toast.error(`${lang.segment_count_should_be_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.SlotDuration < dataItem.TotalBreakDuration) {
            toast.error(`${lang.slot_duration_should_Be_greater_than_total_break_duration_breakformat_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.length == 0) {
            toast.error(`${lang.break_not_distribute_breakformat_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        let tempCountCheck = parseInt(dataItem.SegmentCount - 1) + (dataItem.IncludePreOpening ? 1 : 0) + (dataItem.IncludeClosing ? 1 : 0);
        if (gridData.length != tempCountCheck) {
            toast.error(`${lang.break_count_segment_count_does_not_match_breakformat_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onSave = async () => {

        if (isValid()) {
            const saveData = {
                SID: loc.state.copy ? 0 : parseInt(SID),
                Name: dataItem.Name,
                Channel: {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName,
                },
                SlotDuration: utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration),
                TotalBreakDuration: utility.convertStringWithFramesToMilliseconds(dataItem.TotalBreakDuration),
                TimeRangeFrom: utility.convertStringWithFramesToMilliseconds(dataItem.TimeRangeFrom),
                TimeRangeTo: utility.convertStringWithFramesToMilliseconds(dataItem.TimeRangeTo),
                SegmentCount: dataItem.SegmentCount,
                IncludePreOpening: dataItem.IncludePreOpening ?? false,
                IncludeClosing: dataItem.IncludeClosing ?? false,
                Archive: dataItem.Archive ?? false,
                BreakFormatDetail: gridData
            }
            loc.state.copy && delete saveData._id
            let res = await API.saveData(ENTITYNAME.BreakFormat, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.BREAKFORMAT, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.BreakFormat);
                onClose();
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const calculateBreakDuration = function (slotDuration, segmentCount) {

        const [hours, minutes, seconds, msec] = slotDuration.split(':');
        // let frame = parseInt(msec)/40;
        let hoursToMilliseconds = parseInt(hours) * 3600000;
        let minutesToMilliseconds = parseInt(minutes) * 60000;
        let secondsToMilliseconds = parseInt(seconds) * 1000;

        let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + (parseInt(msec) * 40);

        let result = segmentCount === 0 ? new Date('2000', '1', '1', '0', '0', '0') : utility.convertMilisecondsToStringWithFrames(convertedMilliseconds / segmentCount);
        return result;
    }

    const isValidAutoDistribute = () => {

        if (dataItem.SlotDuration.includes("_") || dataItem.SlotDuration == '00:00:00:00') {
            toast.error(`${lang.invalid_slot_duration_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.TotalBreakDuration.includes("_") || dataItem.TotalBreakDuration == '00:00:00:00') {
            toast.error(`${lang.invalid_total_break_duration_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if ((dataItem.SegmentCount <= 1 && !dataItem.IncludePreOpening && !dataItem.IncludeClosing) || dataItem.SegmentCount == undefined) {
            toast.error(`${lang.segment_count_should_be_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const autoDistribute = function (e) {

        if (!isValidAutoDistribute()) return;
        let breakNumber = dataItem.SegmentCount - 1;

        if (breakNumber) {

            //  if(dataItem.IncludePreOpening) breakNumber++;
            if (dataItem.IncludeClosing) breakNumber++;

            let breakDetails = [];
            let totalBreakDuration = dataItem.TotalBreakDuration;
            let actualBreakDuration = calculateBreakDuration(totalBreakDuration, breakNumber)  //to generate the function to calculate segment duration in utility folder
            let convertCommercialDuration = utility.convertStringWithFramesToMilliseconds(actualBreakDuration) / 2;
            let commercialDuration = utility.convertMilisecondsToStringWithFrames(convertCommercialDuration);
            let convertPromoDuration = utility.convertStringWithFramesToMilliseconds(actualBreakDuration) / 2;
            let promoDuration = utility.convertMilisecondsToStringWithFrames(convertPromoDuration);

            if (dataItem.IncludePreOpening) {
                for (let cnt = 0; cnt <= breakNumber; cnt++) {

                    breakDetails.push({
                        BreakNumber: `${cnt}`,
                        BreakDuration: actualBreakDuration,
                        CommercialDuration: commercialDuration,
                        PromoDuration: promoDuration,
                    });

                }
            }
            else {
                for (let cnt = 1; cnt <= breakNumber; cnt++) {

                    breakDetails.push({
                        BreakNumber: `${cnt}`,
                        BreakDuration: actualBreakDuration,
                        CommercialDuration: commercialDuration,
                        PromoDuration: promoDuration,
                    });

                }
            }

            setGridData(breakDetails);
        }

    }

    const itemChange = (event) => {
        const inEditID = event.dataItem.BreakNumber;
        const field = event.field || "";
        const newData = gridData.map((item) => {

            if (item.BreakNumber === inEditID) {
                item[field] = event.value;
            }

            if (field === "BreakDuration") {
                if (item.BreakNumber === event.dataItem.BreakNumber) {
                    let totalBreakDuration = utility.convertStringWithFramesToMilliseconds(event.dataItem.BreakDuration) - utility.convertStringWithFramesToMilliseconds(event.dataItem.PromoDuration);
                    item['CommercialDuration'] = utility.convertMilisecondsToStringWithFrames(totalBreakDuration);
                }
            }
            if (field === "CommercialDuration") {
                if (item.BreakNumber === event.dataItem.BreakNumber) {
                    let totalBreakDuration = utility.convertStringWithFramesToMilliseconds(event.dataItem.BreakDuration) - utility.convertStringWithFramesToMilliseconds(event.dataItem.CommercialDuration);
                    item['PromoDuration'] = utility.convertMilisecondsToStringWithFrames(totalBreakDuration);
                }
            }
            if (field === "PromoDuration") {
                if (item.BreakNumber === event.dataItem.BreakNumber) {
                    let totalBreakDuration = utility.convertStringWithFramesToMilliseconds(event.dataItem.BreakDuration) - utility.convertStringWithFramesToMilliseconds(event.dataItem.PromoDuration);
                    item['CommercialDuration'] = utility.convertMilisecondsToStringWithFrames(totalBreakDuration);
                }
            }
            return item;
        });
        setGridData(newData);
    }

    const onClose = () => {
        if(loc.state && loc.state.prevPath){
            navigate(loc.state.prevPath)
        } else {
            navigate(-1);
        }
    }

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={(SID > 0) ? `${lang.edit_break_format_editpage_header}` : `${lang.create_break_format_editpage_header}`} onSubmit={onSave} onCancel={onClose} />
                <div className="row mt-2">
                    <div className="col-6">
                        <label>{lang.name_label} *</label>
                        <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={onChange} value={dataItem.Name} />
                    </div>
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <label htmlFor="">{lang.slot_duration_title_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="SlotDuration"
                            value={dataItem.SlotDuration}
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.total_break_duration_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="TotalBreakDuration"
                            value={dataItem.TotalBreakDuration}
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <label htmlFor="">{lang.timerangefrom_column} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="TimeRangeFrom"
                            value={dataItem.TimeRangeFrom}
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.timerangeto_column} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="TimeRangeTo"
                            value={dataItem.TimeRangeTo}
                            onChange={(e) => onChange(e)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        <label>{lang.segment_count_label} *</label>
                        <input className="form-control form-control-sm pl-3" type="number" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"SegmentCount"} onChange={onChange} value={dataItem.SegmentCount} />
                    </div>
                    <div className="col-9 mt-4">
                        <div className="row">
                            <div className="form-group ml-3">
                                <input type={"checkbox"} name={"IncludePreOpening"}
                                    value={dataItem.IncludePreOpening}
                                    checked={dataItem.IncludePreOpening}
                                    onChange={onChange} />
                                <label className='ml-1'>{lang.include_pre_opening_label}</label>
                            </div>
                            <div className="form-group ml-3">
                                <input type={"checkbox"} name={"IncludeClosing"}
                                    value={dataItem.IncludeClosing}
                                    checked={dataItem.IncludeClosing}
                                    onChange={onChange} />
                                <label className='ml-1'>{lang.include_closing_label}</label>
                            </div>
                            <div className="form-group ml-3">
                                <input type={"checkbox"} name={"Archive"}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange} />
                                <label className='ml-1'>{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <ActionButton title={lang.auto_distribute_button_text} name={lang.auto_distribute_button_text} onClick={autoDistribute} />
                        <Grid
                            data={gridData.map((item) => ({
                                ...item,
                                inEdit: item.BreakNumber === editID,
                            }))}
                            editField="inEdit"
                            onRowClick={rowClick}
                            dataItemKey={'BreakNumber'}
                            onItemChange={itemChange} style={{ height: "50vh", margin: '10px 0px 70px 0px', overflow: "hidden" }}>
                            <Column field="BreakNumber" title={lang.break_number_column} width={'170px'} editable={false} />
                            <Column field="BreakDuration" title={lang.break_duration_column} cell={myTimeCell} editable={true} />
                            <Column field="CommercialDuration" title={lang.commercial_duration_column} cell={myTimeCell} editable={true} />
                            <Column field="PromoDuration" title={lang.promo_duration_Column} cell={myTimeCell} editable={true} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default BreakFormatEditForm;