import { Splitter } from "@progress/kendo-react-layout";
import React, { startTransition, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ENTITYNAME,
  LOCALSTORAGE_KEY,
  LOGEVENT,
  MEDIACATEGORIES,
  MEDIACATEGORYTYPES,
  MILLISECONDSINADAY,
  MODULE,
  OFFSET,
  SCHEDULETYPE,
  SOCKET_ACTION,
  SOCKET_EVENTS,
} from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import ScheduleFooter from "../ScheduleFooter";
import { SchedulingSecondaryEvent } from "./SchedulingSecondaryEvent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ScheduleMediaCollectionNew } from "./ScheduleMediaCollectionNew";
import { ScheduleHeaderNew } from "./ScheduleHeaderNew";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as API from "../../../framework/API/api";
import { toast } from "react-toastify";
import { SchedulePreference } from "../SchedulePreference";
import { SchedulingHelper } from "./../helper/SchedulingHelper";
import moment from "moment";
import { Collection } from "../../../framework/forms/Collection";
import { ScheduleBookedCommercialCollection } from "../ScheduleBookedCommercialCollection";
import useSocket from "../../../framework/socket/useSocket";
// import { Calendar } from "@progress/kendo-react-dateinputs";
import { Calendar } from 'primereact/calendar';
import PlanningVersionTab from "../../Planning/PlanningVersionTab";
import { SchedulingAnnouncement } from "./SchedulingAnnouncement";
import { TimePickerWithFormat } from "../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { TextBox } from "@progress/kendo-react-inputs";
import SchedulingMainTree from "./SchedulingMainTree";
import ScheduleCreateFastPlaylist from "../ScheduleCreateFastPlaylist";
import BossDialog from "../../../components/BossDialog";

const SchedulingWrapperNew = () => {
  const [clientInfo, setClientInfo] = useState({});
  const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
  const offsetTime = offSetHours * 3600000;

  const defaultTabNumber = utility.hasModuleRight(MODULE.SCHEDULE_MEDIA)
    ? "1"
    : utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL)
      ? "2"
      : "3";
  const defaultShowFilter =
    utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === undefined ||
    utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === null;
  var localStorageScheduleFilterData = utility.getSeassionValue(
    LOCALSTORAGE_KEY.publishData
  );
  const [showFilter, setShowFilter] = useState(defaultShowFilter);
  const [showLiveEventPopup, setShowLiveEventPopup] = useState(false);
  const [liveEventPopupData, setLiveEventPopupData] = useState({
    title: "Live",
    duration: "00:03:00:00",
  });
  const [copyPasteDataItem, setCopyPasteDataItem] = useState(0);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [prototypeTitle, setPrototypeTitle] = useState("");
  const [expandToggle, setExpandToggle] = useState(false);
  ///miliseconds date
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(
    localStorageScheduleFilterData
      ? new Date(
        moment(
          new Date(localStorageScheduleFilterData.SlotDateTime - offsetTime)
        )
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      ).getTime()
      : new Date(
        moment(new Date(new Date()))
          .utc()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      ).getTime()
  ); ///means set todays date
  //alert(localStorageScheduleFilterData.Channel);

  const [selectedChannel, setSelectedChannel] = useState(
    localStorageScheduleFilterData ? localStorageScheduleFilterData.Channel : {}
  );
  const [tabNumber, setTabNumber] = useState(defaultTabNumber);
  const [tab, setTab] = useState('1');
  const [ChannelList, setChannelList] = useState([]);
  const [doLoad, setDoLoad] = useState(false);
  const [schedulePreference, setschedulePreference] = useState(false);

  const [doSilentLoad, setDoSilentLoad] = useState(false);
  const onScheduleSilentRefresh = () => {
        setDoSilentLoad(!doSilentLoad);
  }
    

  const [mediaCategoryTypePreferenceData, setMediaCategoryTypePreferenceData] =
    useState([]);

  const [bookingDataColumns, setBookingDataColumns] = useState([]);
  const isSchduleLoading = useRef(false);
  const [bookingData, setBookingData] = useState([]);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [outSideFocus, _setOutSideFocus] = useState(false);
  const outSideFocusRef = useRef(outSideFocus);
  const setOutSideFocus = (value)=>{
    _setOutSideFocus(value);
    outSideFocusRef.current = value;
  }
  const [lock, setLock] = useState(false);
  const [lockedInPlanning, setLockedInPlanning] = useState(false);
  const [lockStatus, setLockStatus] = useState({ canModify: true, lockedBy: { _id: 0, name: "none" }, isSelfLocked: false });
  const [isBreakOnly, setIsBreakOnly] = useState(localStorageScheduleFilterData ? localStorageScheduleFilterData?.Channel?.isBreakOnly ?? false : false);
  const [showBreakOnly, setShowBreakOnly] = useState(false);
  const [showGoToSchedulePopup, setShowGoToSchedulePopup] = useState(false);
  const [isDraftMode, _setIsDraftMode] = useState(false);
  const isDraftModeRef = useRef(false);
  const navigate = useNavigate();
  const setIsDraftMode = (value) => {
    isDraftModeRef.current = value;
    _setIsDraftMode(value);
  }

  const [selectedStateDurationCalculation, setSelectedStateDurationCalculation] = useState({
    Total: 0,
    Segment: 0,
    Commercial: 0,
    Promo: 0,
    Music: 0,
    Filler: 0
  });
  const [statusInfo, setStatusInfo] = useState({
    parentCount: 0,
    commercialCount: 0,
    overRunUnderRunCount: 0,
    playlistStartTime: 0,
    playlistEndTime: 0,
  });

  const [schedulerData, setSchedulerData] = useState([]);
  const [defaultPrototypes,setDefaultPrototypes] = useState([]);
  const [showScheduleFastPlaylist, setShowScheduleFastPlaylist] = useState(false);
  const [reportData, _setReportData] = useState([]);
  const setFastplaylistReportData = (data) => {
    _setReportData(data);
  }

  const [scheduleTimeInfo, setScheduleTimeInfo] = useState({
    StartDateTime: 0,
    EndDateTime: 0,
    PreviousScheduleEndDateTime: 0,
  });

  const [selectedMedia, setSelectedMedia] = useState([]);
  {
    /* default collapsed*/
  }
  const [verticalPanes, setVerticalPanes] = React.useState([
    {},
    { size: "30%", collapsible: true, collapsed: true },
  ]);

  const lang = useTranslation();
  const scheduleRef = useRef();

  //splitter variable and onChange function
  const [horizontalPanes, setHorizontalPanes] = React.useState([
    {},
    {
      size: "25%",
      collapsible: true,
    },
  ]);

  let userData = utility.getValue(LOCALSTORAGE_KEY.userData);

  useEffect(() => {
    let mySecondaryEventSplitter = document.getElementsByClassName("verticalSplitterSecondaryEvents").item(0).getElementsByClassName("k-splitbar k-splitbar-vertical").item(0);
    if (mySecondaryEventSplitter && !mySecondaryEventSplitter.innerHTML.includes('Secondary Events')) {
      mySecondaryEventSplitter.prepend('Secondary Events & Announcements');
    }

    let myMediaLibraryEventSplitter = document.getElementsByClassName("horizontalSplitterSecondaryEvents").item(0)?.getElementsByClassName("k-splitbar k-splitbar-horizontal").item(0);
    if (myMediaLibraryEventSplitter && !myMediaLibraryEventSplitter.innerHTML.includes('Media Library')) {
      myMediaLibraryEventSplitter.style.minWidth = '15px';
      const mediaLibraryDiv = document.createElement('div');
      mediaLibraryDiv.textContent = 'Media Library';
      mediaLibraryDiv.style.transform = 'rotate(90deg)';
      mediaLibraryDiv.style.fontSize = '13px';
      mediaLibraryDiv.style.margin = '-84px';
      myMediaLibraryEventSplitter.prepend(mediaLibraryDiv);
    }
  }, []);

  useEffect(() => {
    loadClientInfo();
    loadChannels();
    loadMediaCategoryTypeForSchedulePreference();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, []);

  const handleKeyDown = (e) => {
    if(outSideFocusRef.current) return;
    
    // refresh F5
    if (e.keyCode == 116 || e.key == "F5") {
      e.preventDefault();
      onScheduleRefresh();
      console.log(`You pressed refresh`);
      return;
    }
    //F12
    if (e.keyCode == 123 || e.key == "F12") {
      e.preventDefault();
      onOpenPlanning();
      return;
    }

    if (e.ctrlKey && e.key == "h") {
      e.preventDefault();
      scheduleRef.current.onShowGlobalReplacement();
      console.log(`You pressed find and replace`);
      return;
    }
    // ctrl +
    console.log(e.key)
    if (e.ctrlKey && e.key == "=") {
      e.preventDefault();
      scheduleRef.current.onExpand({ target: { value: true } });
      console.log(`You pressed ctrl +`);
      return;
    }
    // ctrl -
    if (e.ctrlKey && e.key == "-") {
      e.preventDefault();
      scheduleRef.current.onExpand({ target: { value: false } });
      console.log(`You pressed ctrl -`);
      return;
    }


  }



  useEffect(() => {
    updateLockedStatus();
    loadBookingCommercialdata();
    setPrototypeTitle(selectedChannel.SID);
    loadDefaultPrototypes(selectedChannel.SID)

  }, [selectedChannel, selectedScheduleDate]);


  //socket
  const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

    if (socketData.module != MODULE.SCHEDULING && selectedChannel.SID != socketData.data.selectedChannelSID, socketData.data.selectedScheduleDate != selectedScheduleDate) return; //no need to filter active user from module 

    switch (socketData.action) {
      case SOCKET_ACTION.SCHEDULE_LOCK_UPDATE:
        updateLockedStatus();
        break;
      default:
        break;
    }
  });

  const loadClientInfo = async () => {
    const clientInfoRes = await API.getDataLookup(ENTITYNAME.ClientInfo, { sort: { SID: 1 } });
    setClientInfo(clientInfoRes.data[0]);
  }

  const loadChannels = () => {
    API.getValidChannels().then((json) => {
      //setting channel List
      setChannelList(
        json.data.map((d) => ({ ...d, name: d.FullChannelName }))
      );

      //if no channel in local strorage and then select first channel
      if (json.data.length > 0) {
        let channel = !localStorageScheduleFilterData?.Channel ? json.data[0] : json.data.find((item) => item.SID == localStorageScheduleFilterData?.Channel.SID);
        setSelectedChannel({ ...channel, name: channel.FullChannelName });
      }

    });
  };

  const loadMediaCategoryTypeForSchedulePreference = async () => {
    let mediaCategoryTypeData =
      await SchedulingHelper.loadMediaCategoryTypeForSchedulePreference();
    setMediaCategoryTypePreferenceData(mediaCategoryTypeData);
  };

  const loadDefaultPrototypes = async (channelSID) => {

    let defaultPrototypesRes = await API.loadSecondaryEventDefaultPrototypes(channelSID);

    if(defaultPrototypesRes.success){
      
      setDefaultPrototypes([{ Prototypes : defaultPrototypesRes.data } ]);

    } else {

      console.log(defaultPrototypesRes);
      
    }

  }

  const onVerticalSplitterChange = (event) => {
    setVerticalPanes(event.newState);
  };

  const onHorizontalSplitterChange = (event) => {
    setHorizontalPanes(event.newState);
  };

  const handleFilterDialog = () => {
    console.log("coming Here Filter");
    setShowFilter(!showFilter);
  };

  const handleLiveEventPopup = () => {
    setShowLiveEventPopup(!showLiveEventPopup);
  };
  const onConfirmLiveEvent = async () => {
    handleLiveEventPopup();
    console.log(liveEventPopupData);
    let dataRes = await API.getLiveEvent(liveEventPopupData.title, utility.convertStringWithFramesToMilliseconds(liveEventPopupData.duration));
    if (dataRes.success) {
      scheduleRef.current.onLiveEventInsertInSchedule(dataRes.data);
    } else {
      toast.error(dataRes.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    console.log(dataRes)
    setLiveEventPopupData({
      title: "Live",
      duration: "00:03:00:00"
    });
  }

  const handlePopupConfirm = async () => {

    if (!selectedChannel || selectedChannel.SID < 0) {
      toast.error("Please select Channel", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //for fresh channel
    utility.setSessionValue(
      LOCALSTORAGE_KEY.publishData,
      {
        Channel: selectedChannel,
        SlotDateTime: selectedScheduleDate + offsetTime
      }
    );

    onScheduleRefresh();
    handleFilterDialog();
    setExpandToggle(false)
  };

  const onScheduleRefresh = () => {
    setDoLoad(!doLoad);
  };

  const onExecuteCreateFastPlaylist = async (payload) => {

    setFastplaylistReportData([]);
    scheduleRef.current.onFillUnderOverRun(scheduleTimeInfo, true, payload);

  };

  const onExportFastPlaylistReport = async () => {
    
    toast.success(reportData.length)

  };

  const setSelectedDateandChannel = async (scheduleDate, channel) => {

    setSelectedChannel(channel);
    setSelectedScheduleDate(scheduleDate);

      utility.setSessionValue(
        LOCALSTORAGE_KEY.publishData,
        {
          Channel: channel,
          SlotDateTime: scheduleDate + offsetTime
        }
      );
    
  };

  const onScheduleRefreshWithSocket = () => {
    
    if ((selectedChannel?.SID??0) == 0) return;

    let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.SCHEDULE_REFRESH,
      module: MODULE.SCHEDULING,
      data: {
        selectedChannelSID: selectedChannel.SID, selectedScheduleDate: selectedScheduleDate
      },
      user: { name: userData.name, _id: userData._id }
    });
  };

  const onDateChange = async (isNext) => {
    if (isSchduleLoading.current) {
      toast.info("Please wait .. Schedule is already loading", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let oneDayMilisecond = MILLISECONDSINADAY;
    console.log(oneDayMilisecond);
    var newDate = isNext
      ? selectedScheduleDate + oneDayMilisecond
      : selectedScheduleDate - oneDayMilisecond;
    setSelectedScheduleDate(newDate);
    onScheduleRefresh();

    //setting local storage if date changed
    utility.setSessionValue(
      LOCALSTORAGE_KEY.publishData,
      {
        Channel: selectedChannel,
        SlotDateTime: newDate + offsetTime
      }
    );
    setExpandToggle(false);
  };

  const onSchedulePreference = () => {
    setschedulePreference(() => !schedulePreference);
  };
  const onIsSchduleLoading = (data) => {
    isSchduleLoading.current = data;
  };

  const GridRowRenderPrototypes = (tr, props) => {
    const trProps = {
      draggable: true,
      onDragStart: (e) => {
        e.dataTransfer.clearData();
        var propsData = [props.dataItem];
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({ src: ENTITYNAME.Prototype, propsData })
        );
      },
      // onDrop: (e) => handleDropPrototype(e, props.dataItem),
      onDragOver: (e) => {
        e.preventDefault();
      },
    };
    return React.cloneElement(tr, { ...trProps }, tr.props.children);
  };

  const loadBookingCommercialdata = React.useCallback(async () => {
    setIsBookingLoading(true);
    setBookingData([]);
    var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
    var columns = await API.getFormData("schedulebooking", currentUserId);

    setBookingDataColumns(columns);

    console.log("cominghere In Booked Commercials");
    let data = await SchedulingHelper.loadBookingCommercialdata(
      selectedScheduleDate,
      selectedChannel.SID
    );
    if (data) {
      setBookingData(data);
    }
    setIsBookingLoading(false);
  }, [selectedScheduleDate, selectedChannel]);

  const deleteUsedBooking = React.useCallback((dataItems = []) => {

    //NEWCODE
    setBookingData((old) =>
      old
        .filter(
          (item) =>
            dataItems.find((current) => current._id === item._id) === undefined
        )
    );


  },
    [bookingData]
  );

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const setSelectedStateItem = (item) => {
    setSelectedState(item);
    calculateDurationForFooter(item);
  }

  const calculateDurationForFooter = (item) => {

    var totalDuration = 0;
    var promoDuration = 0;
    var segmentDuration = 0;
    var commercialDuration = 0;
    var fillerDuration = 0;
    var musicDuration = 0;

    if (item.length > 1) {
      item.map((x) => {
        if (x.Type == SCHEDULETYPE.OverRun || x.Type == SCHEDULETYPE.UnderRun) { return; }
        //total duration
        totalDuration = totalDuration + x.Duration;

        //duration for segment
        if (x.Type == SCHEDULETYPE.Segment) {
          segmentDuration = segmentDuration + x.Duration;
        }

        //duration for Promo
        if (x.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Interstitial) {
          promoDuration = promoDuration + x.Duration;
        }

        //duration for Commercial
        if (x.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial) {
          commercialDuration = commercialDuration + x.Duration;
        }

        //duration for Music
        if (x.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Filler) {
          fillerDuration = fillerDuration + x.Duration;
        }

        //duration for Filler
        if (x.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Music) {
          musicDuration = musicDuration + x.Duration;
        }

      })
    }
    setSelectedStateDurationCalculation({
      Total: totalDuration,
      Segment: segmentDuration,
      Commercial: commercialDuration,
      Promo: promoDuration,
      Music: musicDuration,
      Filler: fillerDuration
    })

  }

  const onScheduleLock = async () => {

    const channel = {
      _id: selectedChannel._id,
      name: selectedChannel.name,
    }

    if (lock && (!lockStatus.isSelfLocked)) {
      toast.info("Schedule is locked by " + lockStatus.lockedBy.name, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }

    let result = await utility.lockUnlockPlaylist(channel, selectedScheduleDate, MODULE.SCHEDULING, !lock);
    console.log(result);
    console.log(lock);


    let logData = { event: lock ? LOGEVENT.UNLOCK_SCHEDULE : LOGEVENT.LOCK_SCHEDULE, module: MODULE.SCHEDULING, data: userData, message: lock ? LOGEVENT.UNLOCK_SCHEDULE : LOGEVENT.LOCK_SCHEDULE };
    API.SaveLogs(logData);


    setLockStatus({ ...lockStatus, lockedBy: { ...lockStatus.lockedBy, name: 'You' }, isSelfLocked: !lock });

    !lock && toast.info("Schedule is locked", {
      position: toast.POSITION.TOP_RIGHT,
    });

    setLock(!lock);
    refreshLocalStatusforOtheruser();
  }

  const refreshLocalStatusforOtheruser = () => {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.SCHEDULE_LOCK_UPDATE,
      module: MODULE.SCHEDULING,
      data: {
        selectedChannelSID: selectedChannel.SID, selectedScheduleDate: selectedScheduleDate
      },
      user: { name: user.name, _id: user._id }
    });
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_REFRESH, module: MODULE.PLANNING, data: {scheduleScheduleStartDate: selectedScheduleDate, scheduleScheduleEndDate: selectedScheduleDate, channelSID: selectedChannel.SID} });
  }

  const updateLockedStatus = async () => {

    const lockPlaylist = await utility.getLockedPlaylist(selectedScheduleDate, MODULE.SCHEDULING);
    if (lockPlaylist.success) {
      var canModifySchedule = utility.canModify(lockPlaylist?.data, MODULE.SCHEDULING, selectedChannel?._id, selectedScheduleDate);
      var canModifyHeader = utility.canModify(lockPlaylist?.data, MODULE.PLANNING, selectedChannel?._id, selectedScheduleDate);

      setLockedInPlanning(!canModifyHeader.canModify);
      //if cannot modify then it should be locked.
      //set lock is for lock icon on schedule header
      setLock(!canModifySchedule.canModify || canModifySchedule.isSelfLocked);
      setLockStatus({ ...canModifySchedule, canModifyHeader: canModifyHeader.canModify });
      console.log(canModifySchedule);
    }
  }

  const onShowMedia = (mediaEpisode) => {
    let link = "/home/MediaEpisode/" + mediaEpisode.MediaCategory.Description + "/" + mediaEpisode.AssetId;
    window.open(link, "_blank");
  }

  const onOpenPlanning = () => {
    // we will save here in local storage.
    var data = { planningdate: new Date(moment(new Date(selectedScheduleDate)).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })), channels: [selectedChannel] }
    SchedulingHelper.setPlanningPublishDataInLocalStorage(data);

    // Now routing
    navigate("/home/planning");

  }

  return (
    <>
      <div className="ScheduleWrapper">
        <ScheduleHeaderNew
          handleFilterDialog={handleFilterDialog}
          onDateChange={onDateChange}
          clientInfo={clientInfo}
          date={new Date(selectedScheduleDate)}
          selectedChannel={selectedChannel}
          channelList={ChannelList}
          onScheduleRefresh={onScheduleRefresh}
          onDeleteAllFooter={() => {
            scheduleRef.current.onDeleteAllFooter();
          }}
          onSchedulePreference={onSchedulePreference}
          onDeleteAllSecondary={() => {
            scheduleRef.current.onDeleteAllSecondary();
          }}
          onAppleStyleSheet={() => {
            scheduleRef.current.onAppleStyleSheet();
          }}
          onShowGlobalReplacement={() =>
            scheduleRef.current.onShowGlobalReplacement()
          }
          onSetDoOnCheckList={() => scheduleRef.current.onSetDoOnCheckList()}
          onExportSchedule={(automation, selectedChannel, doSftp, isPartial, isDeliver) => scheduleRef.current.onExportSchedule(automation, selectedChannel, doSftp, isPartial, isDeliver)}
          onExportAnnouncement={() => scheduleRef.current.onExportAnnouncement()}
          statusInfo={statusInfo}
          onFillUnderOverRun={() => scheduleRef.current.onFillUnderOverRun(scheduleTimeInfo, false, {})}
          onSearch={(e) => {
            scheduleRef.current.onSearch(e);
          }}
          onAutomaticBreakDistribution={(e) => {
            scheduleRef.current.onAutomaticBreakDistribution();
          }}
          onAutoPlotPromo={() => {
            scheduleRef.current.onAutoPlotPromoInSchedule(selectedMedia);
          }}
          scheduleTimeInfo={scheduleTimeInfo}
          schedulerData={schedulerData}
          selectedState={selectedState}
          lock={lock}
          onScheduleLock={onScheduleLock}
          lockStatus={lockStatus}
          lockedInPlanning={lockedInPlanning}
          onAutoFillBookedCommercial={() => scheduleRef.current.onAutoFillBookedCommercial(bookingData.filter((x) => x?.mediaEpisode?.MediaCategoryType?.SID != MEDIACATEGORYTYPES.Announcement))}
          onReturnSlotDateTimeToPlannedNominalTime={() => scheduleRef.current.onReturnSlotDateTimeToPlannedNominalTime()}
          showBreakOnly={showBreakOnly}
          setShowBreakOnly={setShowBreakOnly}
          setExpandToggle={setExpandToggle}
          onResetAutoFillPlaylistChildren={() => { scheduleRef.current.onResetAutoFillPlaylistChildren(); }}
          onResetSchedule={() => { scheduleRef.current.onResetSchedule(); }}
          setOutSideFocus={setOutSideFocus}
          setShowGoToSchedulePopup={setShowGoToSchedulePopup}
          onHeaderExpandChange={(e) => {
            scheduleRef.current.onExpand(e);
          }}
          onCut={() => {
            scheduleRef.current.onCut();
          }}
          onCopy={() => {
            scheduleRef.current.onCopy();
          }}
          onPaste={() => {
            scheduleRef.current.onPaste();
          }}
          onDelete={() => {
            scheduleRef.current.onDelete();
          }}
          deleteSelectedSecondaryEvent={() => scheduleRef.current.deleteSelectedSecondaryEvent()}
          showSecondaryEventOverviewDialogProp={() => scheduleRef.current.showSecondaryEventOverviewDialogProp()}
          showInsertBreakPopup={(e) => scheduleRef.current.showInsertBreakPopup(e)}
          onRevertVirtualSegmentation={() => scheduleRef.current.onRevertVirtualSegmentation()}
          handleLiveEventPopup={() => handleLiveEventPopup()}
          handleScheduleDraftPopup={() => scheduleRef.current.handleSaveScheduleDraftPopup()}
          loadDraftdata={(draftSchedule) => scheduleRef.current.loadDraftdata(draftSchedule)}
          setIsDraftMode={setIsDraftMode}
          isDraftMode={isDraftMode}
          onOpenPlanning={onOpenPlanning}
          onMatchPreviousDayEndTime={(scheduleTimeInfo) => scheduleRef.current.onMatchPreviousDayEndTime(scheduleTimeInfo)}
          onCreateFastPlaylist={() => setShowScheduleFastPlaylist(true)}
          onManageUnderOverRunForSelectedHeader={(e) => {
            scheduleRef.current.onManageUnderOverRunForSelectedHeader();
          }}
          autoFillPlaylistWithMusic={() => {
            scheduleRef.current.autoFillPlaylistWithMusic();
          }}
        ></ScheduleHeaderNew>

        <Splitter
          panes={horizontalPanes}
          className="horizontalSplitterSecondaryEvents"
          onChange={onHorizontalSplitterChange}
          style={{ height: "95%", marginTop: "-15px" }}
        >
          <Splitter
            className="verticalSplitterSecondaryEvents"
            panes={verticalPanes}
            orientation={"vertical"}
            onChange={onVerticalSplitterChange}
          >
            {/* parent grid */}

            {
              <SchedulingMainTree
                ref={scheduleRef}
                date={new Date(selectedScheduleDate)}
                statusInfo={statusInfo}
                setStatusMessage={setStatusMessage}
                selectedScheduleDate={selectedScheduleDate}
                selectedChannelSID={selectedChannel.SID}
                setSelectedState={setSelectedStateItem}
                selectedChannel={selectedChannel}
                setCopyPasteDataItem={setCopyPasteDataItem}
                doLoad={doLoad}
                setSelectedHeader={setSelectedHeader}
                deleteUsedBooking={deleteUsedBooking}
                loadBookingCommercialdata={loadBookingCommercialdata}
                bookedCommercialData={bookingData}
                outSideFocus={outSideFocus}
                setStatusInfo={setStatusInfo}
                onIsSchduleLoading={onIsSchduleLoading}
                scheduleTimeInfo={scheduleTimeInfo}
                setScheduleTimeInfo={setScheduleTimeInfo}
                setSchedulingData={setSchedulerData}
                handleLiveEventPopup={handleLiveEventPopup}
                lockStatus={lockStatus}
                isBreakOnly={isBreakOnly}
                showBreakOnly={showBreakOnly}
                setExpandToggle={setExpandToggle}
                showGoToSchedulePopup={showGoToSchedulePopup}
                setShowGoToSchedulePopup={setShowGoToSchedulePopup}
                setIsDraftMode={setIsDraftMode}
                isDraftMode={isDraftMode}
                handleTabChange = {handleTabChange}
                doSilentLoad={doSilentLoad}
                setSelectedDateandChannel={setSelectedDateandChannel}
                setFastplaylistReportData={setFastplaylistReportData}
              />
            }

            {/* Secondary Evennts  and Announcements*/}
            <Box sx={{ width: "97%", typography: "body1" }}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    onChange={(event, newValue) => {
                      setTab(`${newValue}`);
                    }}
                    aria-label="lab API tabs example"
                  >
                    <Tab label={lang.secondary_events_label} value="1" />
                    <Tab label={lang.announcement_label} value="2" />
                    <Tab label={lang.default_prototype_sub_menu} value="3" />
                  </TabList>
                </Box>
                <TabPanel value={"1"}>
                  <SchedulingSecondaryEvent selectedState={selectedState?.length > 0 ? selectedState[0] : {}} showTimeLineView={true} />
                </TabPanel>
                <TabPanel value={"2"}>
                  <SchedulingAnnouncement selectedState={selectedState} onDeleteAnnouncement={(announcement) => scheduleRef.current.onDeleteAnnouncement(announcement)} />
                </TabPanel>
                <TabPanel value={"3"}>
                  <SchedulingSecondaryEvent selectedState={defaultPrototypes?.length > 0 ? defaultPrototypes[0] : {}} hideTriggerStartEnd = {true} />

                </TabPanel>
              </TabContext>
            </Box>
          </Splitter>

          {/* Side Tab For Media Library Booking and Prototypes */}
          <Box sx={{ width: "97%", typography: "body1" }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  {utility.hasModuleRight(MODULE.SCHEDULE_MEDIA
                  ) && <Tab icon={<i className="fas fa-clapperboard"></i>} title={lang.media_label} value="1" style={{ minWidth: '60px' }} />}
                  {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL
                  ) && <Tab icon={<i className="fas fa-bookmark"></i>} title={lang.booked_commercial_label} value="2" style={{ minWidth: '60px' }} />}
                  {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL
                  ) && <Tab icon={<i className="fas fa-bullhorn"></i>} title={lang.booked_announcement_label} value="3" style={{ minWidth: '60px' }} />}
                  {utility.hasModuleRight(MODULE.SCHEDULE_PROTOTYPE
                  ) && (<Tab icon={<i className="fas fa-book"></i>} title={lang.prototypes_label} value="4" style={{ minWidth: '60px' }} />)}
                  <Tab icon={<i className="fas fa-cogs"></i>} title={lang.version_label} value="5" style={{ minWidth: '60px' }} />
                </TabList>
              </Box>
              {utility.hasModuleRight(MODULE.SCHEDULE_MEDIA) && (
                <TabPanel value={"1"}>
                  <ScheduleMediaCollectionNew
                    title={lang.media_library_dialog_header}
                    entityname={ENTITYNAME.MediaEpisode}
                    setOutSideFocus={setOutSideFocus}
                    selectedScheduleDate={selectedScheduleDate}
                    selectedChannelSID={selectedChannel.SID}
                    setSelectedMedia={setSelectedMedia}
                    page_take={20}
                    showTitle={true}
                  />
                </TabPanel>
              )}

              {/* Booking Grid */}
              {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL) && (
                <TabPanel value={"2"}>
                  <ScheduleBookedCommercialCollection
                    bookingDataColumns={bookingDataColumns}
                    setOutSideFocus={setOutSideFocus}
                    bookingData={bookingData.filter((x) => x?.mediaEpisode?.MediaCategoryType?.SID != MEDIACATEGORYTYPES.Announcement)}
                    loadBookingCommercialdata={loadBookingCommercialdata}
                    isBookingLoading={isBookingLoading}
                  />
                </TabPanel>
              )}

              {/* Booking Grid */}
              {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL) && (
                <TabPanel value={"3"}>
                  <ScheduleBookedCommercialCollection
                    bookingDataColumns={bookingDataColumns}
                    setOutSideFocus={setOutSideFocus}
                    bookingData={bookingData.filter((x) => x?.mediaEpisode?.MediaCategoryType?.SID == MEDIACATEGORYTYPES.Announcement)}
                    loadBookingCommercialdata={loadBookingCommercialdata}
                    isBookingLoading={isBookingLoading}
                  />
                </TabPanel>
              )}

              {utility.hasModuleRight(MODULE.SCHEDULE_PROTOTYPE) && (
                <TabPanel value={"4"}>
                  <div
                    style={{ height: "100%", width: "100%!important", marginTop: "-15px", marginRight: "-30px", marginLeft: '-15px'}}
                  >
                    <Collection
                      setOutSideFocus={setOutSideFocus}
                      isLoading={false}
                      entityname={ENTITYNAME.Prototype}
                      hideArchive={true}
                      gridstyle={{ position: "absolute", left: 0, top: "133px", right: 0, bottom: 0}}
                      title={prototypeTitle}
                      showCommandCell={false}
                      showAdd={false}
                      showPreferenceButton={true}
                      showBack={false}
                      gridRowRender={GridRowRenderPrototypes}
                      showTitle={false}
                      wherestatement={["Channel.SID", "=", selectedChannel.SID]}
                    />
                  </div>
                </TabPanel>
              )}
              <TabPanel value="5" style={{ height: "100%", padding: 0 }} >
                <PlanningVersionTab plannerSelectedItem={selectedHeader} onShowMedia={onShowMedia} onScheduleRefreshWithSocket={onScheduleRefreshWithSocket} fromPlanning={false} source={'scheduling'} onScheduleRefresh={onScheduleSilentRefresh} />
              </TabPanel>
            </TabContext>
          </Box>
        </Splitter>
      </div>
      <ScheduleFooter
        onPreferenceFilter={() => { }}
        copyPasteDataItem={copyPasteDataItem}
        selectedState={selectedState}
        statusInfo={statusInfo}
        selectedHeader={selectedHeader}
        statusMessage={statusMessage}
        expandToggle={expandToggle}
        onHeaderExpandChange={(e) => {
          scheduleRef.current.onExpand(e);
        }}
        searchWord={""}
        onScheduleRefresh={onScheduleRefresh}
        selectedStateDurationCalculation={selectedStateDurationCalculation}
      />

      {showFilter && (
        <BossDialog
          title={lang.select_channels_dialog_header}
          onClose={handleFilterDialog}
          width={350}
        >
          <div>
            <label>{lang.channel_label} :</label>
          </div>

          <div className="mb-3">
            <DropDownList
              data={ChannelList}
              textField="name"
              dataItemKey="SID"
              value={selectedChannel}
              onChange={(event) => {
                console.log(event.value);
                setSelectedChannel(event.value);
                setIsBreakOnly(event.value?.isBreakOnly ?? false);
              }}
            />
          </div>
          <div>
            <label>{lang.date_label}:</label>
          </div>
          <div>
            <Calendar
              inline
              // value={new Date(selectedScheduleDate)}
              value={new Date(utility.convertMilisecondsToDateTimeString(selectedScheduleDate))}
              onChange={(event) => {
                let utcTime = utility.getLocalDateTimeToUtcMiliseconds(
                  event.value
                );

                console.log(utcTime);
                setSelectedScheduleDate(utcTime);
              }}
              style={{ width: '100%' }}
            />
          </div>

          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleFilterDialog}
            >
              {lang.cancel_button_text}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handlePopupConfirm}
            >
              {lang.confirm_button_text}
            </button>
          </DialogActionsBar>
        </BossDialog>
      )}

      {showLiveEventPopup && (
        <BossDialog
          title={'Insert Live Event'}
          onClose={handleLiveEventPopup}
          width={350}
        >
          {/* dropdown */}
          <div>
            <label>Title :</label>
            <TextBox defaultValue={liveEventPopupData.title} onChange={(e) => {
              startTransition(() => {
                setLiveEventPopupData({ ...liveEventPopupData, title: e.target.value });
              });
            }} />
          </div>
          <br />
          <div>
            <label>Duration :</label>
            <TimePickerWithFormat value={liveEventPopupData.duration} onChange={(e) => {
              startTransition(() => {
                //replace _ with 0
                let value = e.value.replace(/_/g, '0');
                setLiveEventPopupData({ ...liveEventPopupData, duration: value });
              });
            }} />
          </div>


          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleLiveEventPopup}
            >
              {lang.cancel_button_text}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onConfirmLiveEvent}
            >
              {lang.confirm_button_text}
            </button>
          </DialogActionsBar>
        </BossDialog>
      )}

      {schedulePreference && (
        <SchedulePreference
          closeForm={onSchedulePreference}
          mediaCategoryTypePreferenceData={mediaCategoryTypePreferenceData}
          entityname={ENTITYNAME.UserPreference}
          updateSchedulingPreferenceStyle={onScheduleRefresh}
        />
      )}

      {showScheduleFastPlaylist && (
        <ScheduleCreateFastPlaylist
          closeForm={() => setShowScheduleFastPlaylist(false)}
          selectedChannel={selectedChannel}
          selectedScheduleDate={selectedScheduleDate}
          onScheduleRefresh={onScheduleRefresh}
          onExecute={onExecuteCreateFastPlaylist}
          onExportFastPlaylistReport={onExportFastPlaylistReport}
        />
      )}
    </>
  );
};

export default SchedulingWrapperNew;
