/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { Grid, GridColumn as Column, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, SCHEDULETYPE, SCHEDULECOLORPREFERENCE, MEDIACATEGORYTYPES, MEDIACATEGORIES, LOCALSTORAGE_KEY, FORMATVARIATIONTYPE, LOGEVENT, MODULE } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { EditPageHeader } from "../../components/EditPageHeader";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ScheduleMediaCollection } from "../Scheduling/ScheduleMediaCollection";
import { orderBy } from "@progress/kendo-data-query";
import { ContextMenu } from 'primereact/contextmenu';
import { utility } from "../../framework/utility/utilityProvider";
import { ScheduleTypeCell } from "../../framework/forms/helpercomponents/CustomGridCells/ScheduleTypeCell";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { SchedulingHelper } from "../Scheduling/helper/SchedulingHelper";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { ConfirmAlert } from "../../ConfirmAlert";
import { Collection } from "../../framework/forms/Collection";
import SecondaryEventIcon from "@mui/icons-material/Image";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { SchedulingSecondaryEvent } from "../Scheduling/Scheduling_Performance/SchedulingSecondaryEvent";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { FormatVariationCollection } from "./FormatVariationCollection";
import BossDialog from "../../components/BossDialog";


const Format = () => {

    const blankDataItem = {
        Name: "",
        Channel: undefined,
        SegmentType: undefined,
        Archive: false,
        FormatDetail: [],
        BreakFormat: { Name: "" },
        IsDefault: false,
    }
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [editDuration, setEditDuration] = useState('00:00:00:00');
    const [channel, setChannel] = useState([]);
    const [segmentType, setSegmentType] = useState([]);
    const [tabNumber, setTabNumber] = useState("1");
    const [horizontalPanes, setHorizontalPanes] = useState([
        {},
        {
            size: "30%",
            collapsible: true,
        },
    ]);
    const [verticalPanes, setVerticalPanes] = useState([
        {},
        { size: "30%", collapsible: true, collapsed: true },
    ]);
    const [schedulerData, setSchedulerData] = useState([]);
    const [selectedState, _setSelectedState] = useState([]);
    const selectedStateRef = useRef([]);
    const setSelectedState = (data) => {
        selectedStateRef.current = data
        _setSelectedState(data);
    }
    const [mediaLibraryPayload, setMediaLibraryPayload] = useState({});
    const [showEditSlotDuration, setShowEditSlotDuration] = useState(false);
    const [footerDetails, setFooterDetails] = useState({
        totalDuration: "00:00:00:00",
        otherDuration: "00:00:00:00",
        actualDuration: "00:00:00:00"
    })

    const [statusMessage, setStatusMessage] = useState("");
    const [prototypeTitle, setPrototypeTitle] = useState("");
    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    const [openBreakFormatSelection, setOpenBreakFormatSelection] = useState(false);
    window.addEventListener('isMinified', () => {
        setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    });

    const navigate = useNavigate();
    const { SID } = useParams();
    const lang = useTranslation();
    const loc = useLocation();

    useEffect(() => {
        let mySecondaryEventSplitter = document.getElementsByClassName("verticalSplitterSecondaryEvents").item(0).getElementsByClassName("k-splitbar k-splitbar-vertical").item(0);
        if (mySecondaryEventSplitter && !mySecondaryEventSplitter.innerHTML.includes('Secondary Events')) {
            mySecondaryEventSplitter.prepend('Secondary Events');
        }
    }, []);

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const cm = useRef();//FOR SCHEDULER RIGHT CLICK ON CHILDREN ELEMENT : CONTEXT MENU
    const dataItemForContextMenuRef = useRef();

    const loadcombo = async () => {

        let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        let segmentRes = await API.getDataLookup(ENTITYNAME.SegmentType, { sort: { TypeName: 1 } });
        setSegmentType(segmentRes.data);

    }

    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex]
        console.log(dataItem);

        if (ctrlKey) {
            let alreadySelected = selectedStateRef.current.some(item => item._id == dataItem._id);

            if (alreadySelected) {
                setSelectedState(selectedStateRef.current.filter(item => item._id != dataItem._id));

            } else {
                setSelectedState([...selectedStateRef.current, dataItem]);
            }
        } else {
            let alreadySelected = selectedStateRef.current.length == 1 && selectedStateRef.current[0]?._id == dataItem._id ? true : false
            if (alreadySelected) {
                setSelectedState([]);
            } else {
                setSelectedState([dataItem]);
            }
        }
    };

    const loadEditData = async () => {
        var res = await API.getEntity(ENTITYNAME.Format, parseInt(SID));
        console.log(res.data);
        if (res.success) {
            setDataItem({
                ...blankDataItem,
                ...res.data,
                BreakFormat: res.data.BreakFormat ? res.data.BreakFormat : { Name: "" },
                SlotDuration: res.data.SlotDuration ? utility.convertMilisecondsToStringWithFrames(res.data.SlotDuration) : '00:00:00:00',
            });

            setMediaLibraryPayload({
                channelSID: res.data.Channel.SID
            })
            setPrototypeTitle(res.data.Channel.SID)

            console.log(res.data.FormatDetail);
            setSchedulerData(getRippleChildrens(res.data.FormatDetail));
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const GridRowRenderMediaLibrary = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.MediaEpisode, ...props.dataItem }));
            },
            onDragOver: e => { e.preventDefault() }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const handleGenerateSegmentClick = async (e) => {
        // FilterByDate
        e.preventDefault();
        if (dataItem.SegmentType != undefined && dataItem.Channel != undefined) {

            toast.error(dataItem.SegmentType, {
                position: toast.POSITION.TOP_RIGHT
            });
            setMediaLibraryPayload({
                channelSID: dataItem.Channel.SID
            })

            generateSegment();
        }
        else toast.error(`${lang.please_select_segment_type_and_channel_format_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const generateSegment = function (e) {

        if (!dataItem.SlotDuration || utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration) == 0) {
            toast.error(`Enter slot duration`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let segmentCount = dataItem.SegmentType.SegmentCount;
        console.log(segmentCount);
        if (segmentCount) {

            let segmentDetails = [];
            // let episodeDuration = utility.convertMilisecondsToStringWithFrames(1 * 60 * 60 * 1000);
            let episodeDuration = dataItem.SlotDuration;
            //to generate the function to calculate segment duration in utility folder
            let actualSegmentDuration = calculateSegmentDuration(episodeDuration, segmentCount)

            let durationAsString = actualSegmentDuration;
            let durationAsMiliSec = utility.convertStringWithFramesToMilliseconds(durationAsString)

            let slotDateTime = 0;

            for (let cnt = 1; cnt <= segmentCount; cnt++) {

                const scheduleSegment = {
                    _id: cnt + "-segment", // giving a unique ID for selection
                    BreakNo: cnt,
                    MediaEpisode_id: '',
                    ParentProgramSchedule_id: 'parent',
                    ScheduleDate: slotDateTime,
                    SlotDateTime: slotDateTime,
                    Channel: (dataItem?.Channel && Object.keys(dataItem?.Channel)?.length > 0) ? { _id: dataItem?.Channel?._id, SID: dataItem?.Channel?.SID, FullChannelName: dataItem?.Channel?.FullChannelName } : {},
                    Duration: durationAsMiliSec,
                    TcIn: slotDateTime,
                    TcOut: slotDateTime + durationAsMiliSec,
                    Type: SCHEDULETYPE.Segment,
                    mediaEpisode: { Title: "Segment " + cnt }
                };

                segmentDetails.push(scheduleSegment);
                slotDateTime = scheduleSegment.TcOut;

            }

            setSchedulerData(segmentDetails);
            console.log(segmentDetails);
            getRippleChildrens(segmentDetails)


        } else {
            toast.error(`${lang.please_select_segment_type_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }
    const calculateSegmentDuration = function (episodeDuration, segmentCount) {

        const [hours, minutes, seconds, msec] = episodeDuration.split(':');
        // let frame = parseInt(msec)/40;
        let hoursToMilliseconds = parseInt(hours) * 3600000;
        let minutesToMilliseconds = parseInt(minutes) * 60000;
        let secondsToMilliseconds = parseInt(seconds) * 1000;

        let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + (parseInt(msec) * 40);

        let result = segmentCount === 0 ? new Date('2000', '1', '1', '0', '0', '0') : utility.convertMilisecondsToStringWithFrames(convertedMilliseconds / segmentCount);
        return result;
    }

    const GridRowRenderSchedule = (tr, props) => {

        const type = props.dataItem.Type;

        let style = SCHEDULECOLORPREFERENCE.children;
        if (type == SCHEDULETYPE.Segment) style = SCHEDULECOLORPREFERENCE.segment;

        const trProps = {
            style: style,
            draggable: true,
            onDragStart: (e) => {
                const propsData = [props.dataItem];

                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.ProgramSchedule, propsData, dragIndex: props.dataIndex }));
            },
            onDrop: (e) => { OnDrop(e, props.dataItem, props.dataIndex + 1) },
            onDragOver: (e) => { e.preventDefault() },
            onContextMenu: (e) => {
                cm.current.show(e);
                dataItemForContextMenuRef.current = props.dataItem;
                setSelectedState([...selectedStateRef.current.filter(item => item._id != props.dataItem._id), props.dataItem]);
            },
            onWheel: (e) => { cm.current.hide(e) }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const GridRowRenderPrototypes = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                const propsData = [props.dataItem];

                e.dataTransfer.clearData();
                e.dataTransfer.setData(
                    "text/plain",
                    JSON.stringify({ src: ENTITYNAME.Prototype, propsData, dragIndex: props.dataIndex })
                );
            },
            onDrop: (e) => { OnDrop(e, props.dataItem, props.dataIndex + 1) },
            onDragOver: (e) => {
                e.preventDefault();
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const getRippleChildrens = (childrens) => {

        let actualDuration = 0;
        let totalDuration = 0;
        let otherDuration = 0;

        const rippledChildren = childrens.map((item) => {
            // Calculate SlotDateTime
            let startTime = 0;
            item.SlotDateTime = startTime;
            startTime += item.Duration;

            // Calculate total duration
            actualDuration += parseInt(item.Duration);

            if (item.Type == SCHEDULETYPE.Segment) {
                totalDuration += parseInt(item.Duration);
            }

            // Calculate total other duration for non-Segment types
            if (item.Type !== SCHEDULETYPE.Segment) {
                otherDuration += parseInt(item.Duration);
            }
            item.Prototypes = item.Prototypes ? item.Prototypes : []

            return item;
        });

        setFooterDetails({
            totalDuration: totalDuration,
            otherDuration: otherDuration,
            actualDuration: actualDuration
        })

        return rippledChildren;
    };

    const handleDropPrototype = async (destinationDataItem, dataToSave) => {
        console.log("handleprototypeDrop");
        let segments = schedulerData.filter((x) => x.Type == SCHEDULETYPE.Segment).sort((a, b) => a.BreakNo - b.BreakNo);

        //not allowed for Break
        if (destinationDataItem.IsBreak) {
            toast.error(lang.prototype_drop_not_allowed_for_break_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        var isValidPrototype = SchedulingHelper.IsValidPrototypeForFormat(
            dataToSave,
            destinationDataItem,
            segments[segments.length - 1].BreakNo,
            setStatusMessage
        );

        if (isValidPrototype) {
            let item = { ...destinationDataItem }
            let finalProtoType = {
                ...dataToSave,
                Channel: { _id: dataToSave.Channel?._id, SID: dataToSave.Channel?.SID, FullChannelName: dataToSave.Channel?.FullChannelName },
                ProtoTypeSource: { _id: dataToSave.ProtoTypeSource?._id, SID: dataToSave.ProtoTypeSource?.SID, Name: dataToSave.ProtoTypeSource?.Name }
            };
            delete finalProtoType.SecondaryEventDynamicRules;
            delete finalProtoType.SecondaryEventRestriction;
            delete finalProtoType.modBy;
            delete finalProtoType.modOn;

            item.Prototypes = [...item.Prototypes, finalProtoType];

            let localScheduleData = [...schedulerData];
            console.log(item)
            let index = schedulerData.findIndex((x) => x._id == item._id);
            localScheduleData[index] = item;

            setSchedulerData(localScheduleData);
            setStatusMessage(() => dataToSave.Name + " Applied Successfully !!");
        } else {
            console.log(isValidPrototype)
            console.log('invalid');
            return;
        }

    };

    const OnDrop = async (e, dataItem, dropIndex) => {
        e.preventDefault();

        var dragItem = JSON.parse(e.dataTransfer.getData("text"));

        const src = dragItem.src;
        const isFooter =
            dataItem.Type == SCHEDULETYPE.UnderRun ||
            dataItem.Type == SCHEDULETYPE.OverRun;

        //not allowed for segments
        if (src == ENTITYNAME.Prototype && isFooter) {
            toast.error(`${lang.prototype_drop_not_allowed_for_headers_and_footers_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        if (src == ENTITYNAME.Prototype) {
            handleDropPrototype(dataItem, dragItem.propsData[0]);
            return;
        }

        if (dropIndex == 1) {

            ConfirmAlert(
                () => { // on Click yes
                    DropItemInSchedule(e, dataItem, 0, dragItem, src, 0);
                },
                () => {
                    DropItemInSchedule(e, dataItem, 1, dragItem, src, dataItem.BreakNo);
                }, // on click no
                'Drop Position ?', //title
                'Press YES to drop item above destination or click NO to drop below destination' // message
            );
        }
        else {
            DropItemInSchedule(e, dataItem, dropIndex, dragItem, src);
        }

    }

    const DropItemInSchedule = async (e, dataItem, dropIndex, dragItem, src, brkNo) => {

        let dragIndex;
        //if item dragged without selecting
        if (dragItem.propsData) {
            dragIndex = dragItem.dragIndex;
            dragItem = dragItem.propsData;
        }
        //if multiple items were dragged on selecting
        else {
            dragItem = dragItem.selectedState;
        }

        e.dataTransfer.clearData();

        //not allowed for segments
        if (src == ENTITYNAME.ProgramSchedule && dragItem[0]?.Type == SCHEDULETYPE.Segment) {
            toast.error(`${lang.not_allowed_for_segments_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        //data to save
        const scheduleData = dragItem.map((data, index) => {
            return {
                _id: dropIndex + index + new Date(), //to make it unique
                BreakNo: brkNo,
                // if internal drag then media episode id will be dragged mediaepisode id
                MediaEpisode_id: src == ENTITYNAME.MediaEpisode ? data._id : data.MediaEpisode_id,
                ParentProgramSchedule_id: data.ParentProgramSchedule_id,
                ScheduleDate: data.ReleaseDate,
                SlotDateTime: dataItem.SlotDateTime + dataItem.Duration,
                Channel: data.Channel,
                TcIn: data.TcIn,
                TcOut: data.TcOut,
                VariationType: data.VariationType,
                Booking_id: src == ENTITYNAME.Booking ? data._id : ''
            };
        })
        var dataToSaveInSchedulerGrid = scheduleData.map((data, index) => {
            let dropValue = dragItem[index];
            if (src == ENTITYNAME.MediaEpisode) {
                let shortData = {
                    _id: dropValue?._id,
                    SID: dropValue?.SID,
                    Description: dropValue?.Description,
                    Title: dropValue?.Title,
                    Duration: dropValue?.Duration,
                    TcIn: dropValue?.TcIn,
                    TcOut: dropValue?.TcOut,
                    MediaCategory: dropValue?.MediaCategory,
                    MediaCategoryType: dropValue?.MediaCategoryType,
                    AssetId: dropValue?.AssetId,
                    IsBreak: dropValue?.IsBreak,
                    Artist: dropValue?.Artist,
                    TXReady: dropValue?.TXReady,
                }
                return {
                    ...data, mediaEpisode: shortData, Duration: dragItem[index].Duration
                }
            } else if (src == ENTITYNAME.Variation) {
                return {
                    ...data,
                    mediaEpisode: {
                        Title: dragItem[index].Name,
                        AssetId: "XXXXXXX",
                        SID: dragItem[index].SID,
                    },
                    ScheduleDate: 0,
                    SlotDateTime: 0,
                    Channel: data?.Channel,
                    Duration: 1000,
                    TcIn: 0,
                    TcOut: 1000,
                    Variation_id: dragItem[index]._id,
                    VariationType: FORMATVARIATIONTYPE.Variation,
                }
            }
            else {
                let shortMediaEpisodeData = {
                    _id: dropValue?.mediaEpisode?._id,
                    SID: dropValue?.mediaEpisode?.SID,
                    Description: dropValue?.mediaEpisode?.Description,
                    Title: dropValue?.mediaEpisode?.Title,
                    Duration: dropValue?.mediaEpisode?.Duration,
                    TcIn: dropValue?.mediaEpisode?.TcIn,
                    TcOut: dropValue?.mediaEpisode?.TcOut,
                    MediaCategory: dropValue?.mediaEpisode?.MediaCategory,
                    MediaCategoryType: dropValue?.mediaEpisode?.MediaCategoryType,
                    AssetId: dropValue?.mediaEpisode?.AssetId,
                    IsBreak: dropValue?.mediaEpisode?.IsBreak,
                    Artist: dropValue?.mediaEpisode?.Artist,
                    TXReady: dropValue?.mediaEpisode?.TXReady,
                }
                return {
                    ...data, ParentProgramSchedule_id: dataItem.ParentProgramSchedule_id ?? dataItem._id, mediaEpisode: { shortMediaEpisodeData }, Duration: dragItem[index].Duration,
                }
            }
        });

        //we need to save and delete dragged item from scheduler when dragged internally
        if (src == ENTITYNAME.ProgramSchedule) {

            if (dataItem?.Type == SCHEDULETYPE.UnderRun || dataItem?.Type == SCHEDULETYPE.OverRun || dragItem[0]?.Type == SCHEDULETYPE.UnderRun || dragItem[0]?.Type == SCHEDULETYPE.OverRun) {
                //because dropped on itself or dropped on under run or overun dataItem
                return
            }

            //-----------save & delete----------------            

            //different parents
            setSchedulerData(old => {
                // deleting              
                old.splice(dragIndex, 1);
                //saving
                old.splice(dropIndex, 0, ...dataToSaveInSchedulerGrid);

                old = getRippleChildrens(old);

                return [...old];
            });


        } else {

            setSchedulerData(old => {

                schedulerData.splice(dropIndex, 0, ...dataToSaveInSchedulerGrid);

                old = getRippleChildrens(old);

                return [...old];
            })

        }

    }

    const formatDeleteItem = async (dataItems) => {

        if (dataItems.length == 0) return;

        if (dataItems.some(x => x.Type == SCHEDULETYPE.Segment)) {
            toast.error(`${lang.cannot_delete_segments_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let newSchedulerData = schedulerData.filter(item => {
            if (dataItems.some(x => x._id == item._id)) {
                return false
            }
            return true
        });

        setDataItem((oldState) => ({ ...oldState, FormatDetail: newSchedulerData }));
        setSchedulerData(getRippleChildrens(newSchedulerData));
    }

    const editItemDuration = async (item) => {

        if (item.Type != SCHEDULETYPE.Segment) {
            toast.error(lang.can_edit_duration_of_segment_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let itemIndex = schedulerData.indexOf(item);
        let newData = [...schedulerData];
        newData[itemIndex] = {
            ...item,
            Duration: utility.convertStringWithFramesToMilliseconds(editDuration)
        }
        console.log(newData)
        // setDataItem({ ...dataItem, FormatDetail: getRippleChildrens(newData) });
        setSchedulerData(getRippleChildrens(newData))

        setEditDuration('00:00:00:00');
        setShowEditSlotDuration(false);
    }

    const onChange = (e) => {
        if (e.target.name == "Channel") {
            setMediaLibraryPayload({ channelSID: e.target.value.SID });
            setPrototypeTitle(e.target.value.SID)
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } if (e.target.name == "IsDefault") {
            setDataItem({ ...dataItem, IsDefault: !dataItem.IsDefault });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const createInsertBreakData = (breakData, dropIndex, string) => {
        const scheduleData = breakData.map((data, index) => {
            return {
                _id: `${dropIndex}-${string}-${index} ` + new Date(), //to make it unique
                BreakNo: dataItem.BreakNo,
                // if internal drag then media episode id will be dragged mediaepisode id
                MediaEpisode_id: data._id,
                ParentProgramSchedule_id: data.ParentProgramSchedule_id,
                ScheduleDate: data.ReleaseDate,
                SlotDateTime: dataItem.SlotDateTime + dataItem.Duration,
                Channel: data.Channel,
                TcIn: data.TcIn,
                TcOut: data.TcOut,
                Booking_id: ''

            };
        })
        let dataToSaveInSchedulerGrid = scheduleData.map((data, index) => {
            return {
                ...data, ParentProgramSchedule_id: dataItem.ParentProgramSchedule_id ?? dataItem._id, mediaEpisode: { ...breakData[index] }, Duration: breakData[index].Duration,
            }

        });

        return dataToSaveInSchedulerGrid;

    }

    const onAutomaticBreakDistribution = async () => {
        console.log(dataItem)

        if (!dataItem.SlotDuration || utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration) == 0) {
            toast.error(`Please enter Slot Duration`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const parentSegments = schedulerData.filter(x => x.Type == SCHEDULETYPE.Segment);

        //SKIP : if breaks are already attached 
        const alreadyBreakAttached = schedulerData.some(x => x.IsBreak == true);
        if (alreadyBreakAttached) return;

        console.log(parentSegments)


        //FETCH : break formats
        var breakFormats = await API.getData(ENTITYNAME.BreakFormat, {
            query: [
                // ['SlotDuration', '=', utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration)],
                ['SegmentCount', '=', `${parentSegments.length}`]
            ]
        });

        //INSERT : breaks after every segment
        if (breakFormats.data.length == 0) {
            toast.error(`No ${dataItem.SegmentType.SegmentCount} Segment Break Format found !!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var formatDetail = breakFormats.data[0].BreakFormatDetail;
        var newSchedulerData = [...schedulerData];

        while (formatDetail.length > 0) {
            var breakFormatDetail = formatDetail.sort((a, b) => a.BreakNumber - b.BreakNumber)[0];

            formatDetail = formatDetail.filter(
                (obj) => obj.BreakNumber != breakFormatDetail.BreakNumber
            );

            if (breakFormatDetail.BreakNumber == 0) continue;

            const breakCommercialData = await SchedulingHelper.getCommercialPromoBreak(
                utility.convertStringWithFramesToMilliseconds(breakFormatDetail.CommercialDuration),
                MEDIACATEGORIES.Commercial,
                MEDIACATEGORYTYPES.Commercial
            );

            const breakPromoData = await SchedulingHelper.getCommercialPromoBreak(
                utility.convertStringWithFramesToMilliseconds(breakFormatDetail.PromoDuration),
                MEDIACATEGORIES.Interstitial,
                MEDIACATEGORYTYPES.Promo
            );

            if (parentSegments && parentSegments.length > 0) {
                var dropIndex = newSchedulerData.findIndex((x) => x.Type == SCHEDULETYPE.Segment && x.BreakNo == parseInt(breakFormatDetail.BreakNumber));

                const dataToSaveInSchedulerGrid = createInsertBreakData(breakCommercialData, dropIndex, 'COM');
                newSchedulerData = [...newSchedulerData.slice(0, dropIndex + 1), ...dataToSaveInSchedulerGrid, ...newSchedulerData.slice(dropIndex + 1, newSchedulerData.length)]

                if (utility.convertStringWithFramesToMilliseconds(breakFormatDetail.PromoDuration) > 0) {
                    const dataToSaveInSchedulerGrid = createInsertBreakData(breakPromoData, dropIndex, 'PROM');
                    newSchedulerData = [...newSchedulerData.slice(0, dropIndex + 1), ...dataToSaveInSchedulerGrid, ...newSchedulerData.slice(dropIndex + 1, newSchedulerData.length)]
                }

            }
        }

        setSchedulerData(getRippleChildrens(newSchedulerData))
    }

    const isValid = () => {

        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!(dataItem.SegmentType) || !(dataItem.Channel)) {
            toast.error(`${lang.please_select_segment_type_and_channel_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.SlotDuration || utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration) == 0) {
            toast.error(`${lang.please_enter_slot_duration_error}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (schedulerData.length == 0) {
            toast.error(`${lang.please_add_programs_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration) < footerDetails.actualDuration) {
            toast.error(`${lang.actual_duration_slot_duration_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onSave = async () => {
        if (isValid()) {
            console.log(dataItem);
            let saveData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Channel: {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName
                },
                SegmentType: {
                    _id: dataItem.SegmentType._id,
                    SID: dataItem.SegmentType.SID,
                    Description: dataItem.SegmentType.Description,
                    SegmentCount: dataItem.SegmentType.SegmentCount
                },
                SlotDuration: utility.convertStringWithFramesToMilliseconds(dataItem.SlotDuration),
                FormatDetail: schedulerData,
                BreakFormat: Object.keys(dataItem.BreakFormat).length > 1 && dataItem.BreakFormat.Name.length > 0 ? { _id: dataItem.BreakFormat._id, Name: dataItem.BreakFormat.Name } : {},
                Archive: dataItem.Archive ?? false,
                IsDefault: dataItem.IsDefault ?? false
            }
            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.Format, saveData);
            if (!res.success) return;
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.FORMAT, data: res.data, message: res.message };
            API.SaveLogs(logData);
            onClose();
        }
    };

    const openBreakFormatPopup = () => {

        if (schedulerData.length == 0) {
            toast.info(`${lang.please_generate_segments_first_media_episode_version_error_message} First`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        setOpenBreakFormatSelection(true)
    }

    const MySecondaryEventCountCell = (props) => {
        const IconsStyle = (color) => {
            return {
                color: color ?? "#E28C00",
                fontSize: "1.6em",
                size: "2.6em",
                marginRight: "0.12em",
            };
        };

        return (
            <td
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{
                    [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                }}
                {...useTableKeyboardNavigation(props.id)}
                style={props?.style ?? {}}
            >
                <SecondaryEventIcon style={IconsStyle(props.style.color)} />
                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    {props.dataItem?.Prototypes?.length ?? 0}
                </span>
            </td>
        );
    };

    const MyFixedCell = (props) => {

        let value = props.dataItem?.VariationType == FORMATVARIATIONTYPE.FixedFamily ? 'Fixed Family' : props.dataItem?.VariationType == FORMATVARIATIONTYPE.Variation ? 'Variation' : 'Fixed';

        return (
            <td
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{
                    [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                }}
                {...useTableKeyboardNavigation(props.id)}
                style={props?.style ?? {}}
            >
                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                    {value}
                </span>
            </td>
        );
    };

    const onEditItem = (item) => {
        if (item.Type == SCHEDULETYPE.Segment) {
            setEditDuration(utility.convertMilisecondsToStringWithFrames(item.Duration));
            setShowEditSlotDuration(true);
        } else {
            if (item?.Variation_id && item?.VariationType == FORMATVARIATIONTYPE.Variation) {
                window.open(`/home/Variation/${item?.mediaEpisode?.SID}`, '_blank');
            } else {
                window.open(`/home/MediaEpisode/${item?.mediaEpisode?.MediaCategory?.Description}/${item?.mediaEpisode?.SID}`, '_blank');
            }
        }
    }

    const onClose = () => {
        if(loc.state && loc.state.prevPath){
            navigate(loc.state.prevPath)
        } else {
            navigate(-1);
        }
    }

    return <div className="FormatScheduleWrapper">
        <EditPageHeader title={`${lang.format_label} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_title_editpageheader}`)} onSubmit={onSave} onCancel={onClose} />

        <div className="row mt-3" style={{ fontSize: "10px" }}>
            <div className="col-2" style={{ padding: "0px 0px 0px 15px" }}>
                <div className="form-group">
                    <label htmlFor="">{lang.name_label} *</label>
                    <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name || ""} onChange={onChange} required />
                </div>
            </div>
            <div className="col-2" style={{ padding: "0px 0px 0px 15px" }}>
                <div className="form-group">
                    <label htmlFor="TabView">{lang.segment_type_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={segmentType}
                        name="SegmentType"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.SegmentType}
                        onChange={onChange}
                        validator={(value) => value ? "" : "Please select the value"}
                    />
                </div>
            </div>
            <div className="col-2" style={{ padding: "0px 0px 0px 15px" }}>
                <div className="form-group">
                    <label htmlFor="TabView">{lang.channel_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={channel}
                        name="Channel"
                        textField="FullChannelName"
                        dataItemKey="_id"
                        value={dataItem.Channel}
                        onChange={onChange}
                        validator={(value) => value ? "" : "Please select the value"}
                    />
                </div>
            </div>
            <div className="col-2" style={{ padding: "0px 0px 0px 15px" }}>
                <div className="form-group">
                    <label htmlFor="TabView">{lang.slot_duration_label} *</label>
                    <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="SlotDuration"
                        value={dataItem.SlotDuration}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="col">
                <div className='row'>
                    <div className="col-4" style={{ padding: "0px 0px 0px 16px" }}>
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.break_format_sub_menu}</label>
                            <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.BreakFormat.Name} onChange={() => ""} disabled={true} />
                        </div>
                    </div>
                    <div className="col" style={{ padding: "20px 0px 0px 0px" }}>
                        <ActionButton btnColor={'success'} title={lang.select_break_format__label} icon={'ellipsis'} onClick={openBreakFormatPopup} />
                        <ActionButton title={lang.generate_segments_button_text} name={''} icon={'bars'} onClick={handleGenerateSegmentClick} />
                        <ActionButton title={lang.insert_break_label} name={''} icon={'share-from-square'} onClick={onAutomaticBreakDistribution} />
                        <span className="ml-2 mt-1">
                            <input type="checkbox" name="IsDefault" value={dataItem.IsDefault} checked={dataItem.IsDefault} onChange={onChange} />
                            <label className="pl-1" htmlFor="IsDefault">{lang.is_default_label}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <Splitter
            panes={horizontalPanes}
            onChange={(e) => {
                setHorizontalPanes(e.newState)
            }}
            style={{ height: "90%" }}
        >
            <Splitter
                className="verticalSplitterSecondaryEvents"
                panes={verticalPanes}
                orientation={"vertical"}
                onChange={(e) => {
                    setVerticalPanes(e.newState);
                }}
            >
                <div style={{ height: "100%" }}>

                    {/* parent grid */}
                    <Grid style={{
                        userSelect: "none",
                        height: "100%",
                        width: '100%',
                    }}
                        data={orderBy(schedulerData.map(item => {
                            item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                            return item;
                        }), [
                            {
                                field: "SlotDateTime",
                                dir: "asc",
                            },
                        ])}
                        onSelectionChange={onSelectionChange}
                        expandField="expanded"
                        rowRender={GridRowRenderSchedule}
                        selectedField={SELECTED_FIELD}
                        dataItemKey={DATA_ITEM_KEY}
                        selectable={{
                            enabled: true,
                            drag: true,
                            cell: false,
                            mode: {
                                value: 'multiple',
                                label: 'Multiple selection mode'
                            },
                        }}

                    >
                        <Column width="140px" field="SlotDateTime" title={lang.slot_time_column} cell={TimeCell} />
                        <Column width="180px" field="mediaEpisode.Title" title={lang.title_column} />
                        {/* <Column field="SID" title="SID" />  */}
                        <Column width="140px" field="mediaEpisode.AssetId" title={lang.assetId_column} />
                        <Column width="140px" field="mediaEpisode.MediaCategory.Description" title={lang.media_category_column} />
                        <Column width="140px" field="Duration" title={lang.duration_column} cell={TimeCell} />
                        <Column width="180px" field="VariationType" title={lang.variation_type_column} cell={MyFixedCell} />
                        <Column width="140px" field="Type" title={lang.type_column} cell={ScheduleTypeCell} />
                        <Column width="140px" field="Prototypes" title={lang.prototypes_column} cell={MySecondaryEventCountCell} />
                    </Grid>

                    <ContextMenu
                        ref={cm}
                        model={[
                            {
                                label: `Edit`, icon: 'pi pi-fw pi-pencil', command: () => { onEditItem(dataItemForContextMenuRef.current) }
                            },
                            {
                                label: `${lang.delete_actionbutton_menutext}`, icon: 'pi pi-fw pi-trash', command: () => {
                                    formatDeleteItem(selectedStateRef.current);
                                }
                            }
                        ]} />

                    <div>
                    </div>
                </div>

                {/* Secondary Evennts */}
                <SchedulingSecondaryEvent selectedState={selectedState} />
            </Splitter>
            <Box sx={{ width: '100%', height: "76vh", typography: 'body1' }}>
                <TabContext value={tabNumber}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant="scrollable" scrollButtons="auto" onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label={lang.media_label} value="1" />
                            <Tab label={lang.breaks_label} value="2" />
                            <Tab label={lang.prototypes_label} value="3" />
                            <Tab label={lang.variation_label} value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value={"1"}>
                        {Object.keys(mediaLibraryPayload).length != 0 ? <ScheduleMediaCollection title={lang.media_library_dialog_header} entityname={ENTITYNAME.MediaEpisode} gridRowRender={GridRowRenderMediaLibrary} payload={mediaLibraryPayload} filterByDate={false} /> : <div>* {lang.please_generate_segment_to_load_media_label}</div>}
                    </TabPanel>
                    <TabPanel value={"2"}>
                        {Object.keys(mediaLibraryPayload).length != 0 ?
                            <ScheduleMediaCollection title={lang.media_library_dialog_header} entityname={ENTITYNAME.MediaEpisode} gridRowRender={GridRowRenderMediaLibrary} payload={mediaLibraryPayload} filterByDate={false} IsBreak={true} /> :
                            <div>* {lang.please_generate_segment_to_load_media_label}</div>}
                    </TabPanel>
                    <TabPanel value={"3"}>
                        {Object.keys(mediaLibraryPayload).length != 0 ?
                            <div
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    padding: "5px",
                                    marginTop: "-12px",
                                }}
                            >
                                <Collection
                                    entityname="prototype"
                                    hideArchive={true}
                                    gridstyle={{
                                        height: "56vh",
                                        width: "100%",
                                    }}
                                    title={prototypeTitle}
                                    showCommandCell={false}
                                    showAdd={false}
                                    showPreferenceButton={true}
                                    showBack={false}
                                    gridRowRender={GridRowRenderPrototypes}
                                    showTitle={false}
                                    wherestatement={["Channel.SID", "=", dataItem.Channel.SID]}
                                />
                            </div> : <div>* {lang.please_generate_segment_to_load_media_label}</div>}
                    </TabPanel>
                    <TabPanel value={"4"}>
                        {Object.keys(mediaLibraryPayload).length != 0 ?
                            <FormatVariationCollection channelSID={dataItem?.Channel?.SID} /> :
                            <div>* {lang.please_generate_segment_to_load_media_label}</div>}
                    </TabPanel>
                </TabContext>
            </Box>
        </Splitter>
        {/* footer */}
        <div className={isMinified ? "scheduleFooter scheduleFooterMinified text-white" : 'scheduleFooter text-white'}>
            <div className="row mr-2">
                <div className="col-9" >
                    <div className="flex-container" style={{ alignItems: "left", justifyContent: "left", fontSize: "12px" }}>
                        <div className="iconBase2 ml-2">
                            {lang.actual_duration_label} : {utility.convertMilisecondsToStringWithFrames(footerDetails.actualDuration)}
                        </div>
                        <div className="iconBase2 ml-2">
                            {lang.total_segment_duration_lable} : {utility.convertMilisecondsToStringWithFrames(footerDetails.totalDuration)}
                        </div>
                        <div className="iconBase2 ml-2">
                            {lang.other_label} : {utility.convertMilisecondsToStringWithFrames(footerDetails.otherDuration)}
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="flex-container" style={{ alignItems: "right", justifyContent: "right", fontSize: "12px", marginTop: '5px' }}>
                        {statusMessage}
                    </div>
                </div>
            </div>
        </div>

        {showEditSlotDuration && <BossDialog
            title={'Edit Duration'}
            onClose={() => setShowEditSlotDuration(false)}
            width={"30%"}
        >
            <div className="row">
                <div className="col">
                    <EditPageHeader title={""} onSubmit={() => editItemDuration(dataItemForContextMenuRef.current)} onCancel={() => setShowEditSlotDuration(false)} showTitle={false} />
                    <div className="form-group mt-1">
                        <label htmlFor="TabView">{lang.duration_label}</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Duration"
                            value={editDuration}
                            onChange={(e) => { setEditDuration(e.target.value) }}
                        />
                    </div>
                </div>

            </div>
        </BossDialog>}

        {openBreakFormatSelection && (<CollectionSelection
            title={lang.select_break_format__label}
            entityname={ENTITYNAME.BreakFormat}
            wherestatement={[
                ['Channel.SID', '=', dataItem.Channel.SID],
                ['SegmentCount', '=', schedulerData.filter((x) => x.Type == SCHEDULETYPE.Segment).length.toString()]
            ]}
            setDataList={(dataList) => { setDataItem({ ...dataItem, BreakFormat: dataList[0] }) }}
            mode={"single"}
            closeForm={() => { setOpenBreakFormatSelection(false) }}
        />)}
    </div>
}

export default Format;