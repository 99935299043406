/* eslint-disable */
import React from 'react'
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileCardLayout from './MobileCardLayout';
import { CONTENT_TYPE } from '../../../framework/constant/constant';
import RefreshButton from '../../../framework/forms/helpercomponents/buttons/RefreshButton';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';

export const DigitalSignMobileContentCollection = (props) => {

    const { gridData = [], setOnEditPopup, onRefresh, isLoading, onDeleteContent, setShowPreview, setSelectedItem } = props;
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div className='d-flex ml-2' style={{ alignItems: "center", flexWrap: "wrap" }} >
                <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px", }}></i>
                <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}> Total Contents - {gridData?.length} </span>
            </div>
            <div className="flex-container mt-2 mb-2 ml-1">
                <BackRoundButton onClick={() => navigate(-1)} />
                <AddRoundButton onClick={() => setOnEditPopup(true)} />
                <RefreshButton onClick={() => onRefresh({ isSilent: false })} />
            </div>
            {isLoading ? <Loader /> :
                <div className='mb-5 ml-2 mr-2' style={{ flex: 1, overflowY: "auto" }}>
                    {gridData.length == 0 ?
                        <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                            <AddRoundButton onClick={() => setOnEditPopup(true)} /> Add Content
                        </div>
                        : gridData.map((content) => {
                            const contextMenuItem = [
                                {
                                    label: 'Delete',
                                    command: () => ConfirmDeleteAlert(() => onDeleteContent(content), () => { }),
                                    icon: 'pi pi-trash'
                                },
                                {
                                    label: 'Preview',
                                    command: () => { setSelectedItem(content); setShowPreview(true) },
                                    icon: 'pi pi-eye'
                                }
                            ]
                            return <MobileCardLayout
                                title={content.fileName}
                                description={content.bytes}
                                image={content.PosterUrl}
                                badge={content?.content_type === CONTENT_TYPE.Stream
                                    ? 'fa-stream'
                                    : content?.content_type === CONTENT_TYPE.Image
                                        ? 'fa-picture-o'
                                        : content?.content_type === CONTENT_TYPE.Video
                                            ? 'fa-video-camera'
                                            : 'fa-youtube'}
                                contextMenuItem={contextMenuItem}
                                showContextMenu={true}
                                style={{ margin: '30px' }}
                            />
                        })}
                </div>}
        </div>
    );
}
