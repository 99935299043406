import React, { useEffect , useState } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { toast } from 'react-toastify';
import * as DIGITAL_SIGN_API from '../../framework/API/api_digitalSign';
import { DIGISIGN_LOGTYPE,  FILEURL } from '../../framework/constant/constant';
import {LocalDateOnlyCell, LocalDateTimeColumn } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import BossDialog from '../../components/BossDialog';
import DigitalSignMobileAsrunErrorReport from './mobileView/DigitalSignMobileAsrunErrorReport';

const DigitalSignAsrunErrorReport = (props) => {
    console.log(props);
    const lang = useTranslation();
    const [gridData, setGridData] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); 
        };
        updateScreenType();
        window.addEventListener('resize', updateScreenType);
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    useEffect(() => {
        loadData();
    }, [props.item]);

    const loadData = async () => {
        setLoadingStatus(lang.please_wait_loading_error_message);
        let time = new Date(new Date().setDate(new Date().getDate() - 25));
        let payload = {
            serialNumber: props?.item?.SerialNumber,
            time: time.getTime(),
            isExport: false,
            logType: [DIGISIGN_LOGTYPE.ASRUN_ERROR]
        }
        let res = await DIGITAL_SIGN_API.getDigitalSignAsrunErrorReport(payload);
        console.log(res);
        if (res.success) {
            if (res.data.length == 0) {
                setGridData([]);
                setLoadingStatus(lang.no_record_found_error_message);
                return;
            }
            setGridData(res.data);
            setLoadingStatus(lang.data_load_successfully_message);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const downloadExcel = async () => {
        if (gridData.length == 0) {
            toast.info(lang.no_data_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let time = new Date(new Date().setDate(new Date().getDate() - 25));
        let payload = {
            serialNumber: props?.item?.SerialNumber,
            time: time.getTime(),
            isExport: true,
            logType: [DIGISIGN_LOGTYPE.ASRUN_ERROR]
        }
        let res = await DIGITAL_SIGN_API.getDigitalSignAsrunErrorReport(payload);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const LocalTimeOnlyCell = (props) => (
            <LocalDateTimeColumn {...props} format="HH:mm:ss" />
        );

    return (
        <>
            {isMobile ? <DigitalSignMobileAsrunErrorReport downloadExcel={downloadExcel}  gridData={gridData} title={'(' + props?.item?.Name + ')' + " - " + lang.asrun_error_report_dialog_header} cancelEdit={props.cancelEdit} loadingStatus={loadingStatus}/>:
                <BossDialog
                    title={'(' + props?.item?.Name + ')' + " - " + lang.asrun_error_report_dialog_header}
                    onClose={props.cancelEdit}
                    width={"1000px"}
                >
                    <div className="row" style={{ marginTop: '-30px' }}>
                        <div className="col-12">
                            <div className="flex-container">
                                <div className="mt-4">
                                    <RoundButton icon='download' onClick={() => downloadExcel()} title={lang.download_button_tooltip} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Grid data={gridData} resizable={true} style={{ height: '70vh' }}>
                                        <GridNoRecords>{loadingStatus}</GridNoRecords>
                                        <Column field="time" cell={LocalDateOnlyCell} title={lang.date_column} width={160} />
                                        <Column field="time" cell={LocalTimeOnlyCell} title={lang.time_column} width={160} />
                                        <Column field="error" title={lang.error_log_column} cell={(props) => <p style={{ color: 'red', margin: '5px' }}>{props.dataItem.error}</p>} />
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </BossDialog>
            }
        </>
    )
}

export default DigitalSignAsrunErrorReport
