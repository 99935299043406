import './App.css';
import './Styles/smartadmin-production.min.css'
import './Styles/smartadmin-production-plugins.min.css'
import './Styles/your_style.css'
import './Styles/BookingStatusDashboardStyle.css'
import Routes from './routes';
import { initializeGA } from './analytics/analytics';
import { utility } from './framework/utility/utilityProvider';

function App() {
  if(utility.isAutomateB())initializeGA();
  return <Routes />
}

export default App;