/* eslint-disable */
import React from 'react';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileGridCard from './MobileGridCard';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../../framework/utility/utilityProvider';
import { ENTITYNAME } from '../../../framework/constant/constant';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';

const DigitalSignMobileCollection = (props) => {

  const { gridData = [], showTitle = true, showAdd = true, showBack = true, onEnterEdit, myTitle, columns = [], handleDelete, handleArchive, entityName, isLoading } = props;
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {showTitle && (
        <div className='d-flex ml-1' style={{ alignItems: "center", flexWrap: "wrap" }} >
          <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px", }}></i>
          <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}>{myTitle}</span>
        </div>
      )}
      <div className="flex-container mt-2 mb-2">
        {showBack && (
          <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
        )}
        {showAdd && (
          <AddRoundButton onClick={onEnterEdit} />
        )}
      </div>
      {isLoading ? <Loader /> :
      <div className='mb-5' style={{ flex: 1, overflowY: "auto" }}>
        {gridData.length == 0 ?
          <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}><AddRoundButton onClick={onEnterEdit} /> Add</div>
          : gridData.map((item, index) => {
            const titleColumn = columns?.find(col => col.name === "Name" || col.name === "Title" || col.name === "Description" || col.name === "plan.Name");
            const descriptionColumn = columns.find(col => col.name === "Text" || col.name === "AspectRatio" || col.name === "Price" || col.name === "BaseUrl" || col.name === "plan.Price");
            const label1Column = columns.find(col => col.name === "OverlayType.Description"  || col.name === "Tenure" || col.name === "DigiSignBaseUrl" || col.name === "plan.Tenure");
            const label2Column = columns.find(col => col.name === "plan.screens" || col.name === "screens");
            const bottomRightColumn = columns.find(col => col.name === "plan.storage" || col.name === "storage");
            const contextMenuItem = [
              {
                label: 'Edit',
                command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit(item) },
                icon: 'pi pi-pencil'
              },
              {
                label: 'Create From',
                command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit({ ...item, copy: true }) },
                icon: 'pi pi-copy'
              },
              {
                label: 'Archive',
                command: (e) => { e.originalEvent.stopPropagation(); handleArchive(item) },
                icon: 'pi pi-inbox'
              },
              entityName == ENTITYNAME.DigitalSignOverlay && {
                label: 'Delete',
                command: (e) => { e.originalEvent.stopPropagation(); handleDelete(item) },
                icon: 'pi pi-trash'
              }
            ].filter(Boolean);
            return <MobileGridCard
              key={index}
              title={utility.getNestedValue(item, titleColumn?.name)}
              description={utility.getNestedValue(item, descriptionColumn?.name)}
              label1={utility.getNestedValue(item, label1Column?.name)}
              label2={utility.getNestedValue(item, label2Column?.name)}
              bottomRight={utility.getNestedValue(item, bottomRightColumn?.name)}
              contextMenuItem={contextMenuItem}
              showContextMenu={true}
              height={entityName == ENTITYNAME.Instance ? "95px" : entityName == ENTITYNAME.DigitalSignCompanyPlans ? "110px" : entityName == ENTITYNAME.DigitalSignPlan ? "110px" : entityName == ENTITYNAME.DigitalSignOverlay ? " 90px " : "75px"}
              handleItemClick={onEnterEdit}
              data={item}
            />
          })}
      </div>}
    </div>
  )
}

export default DigitalSignMobileCollection;
