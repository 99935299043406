/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import * as API from '../../../framework/API/api';
import * as API_DIGITAL_SIGN from '../../../framework/API/api_digitalSign';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../../locale/useTranslation';
import { toast, ToastContainer } from 'react-toastify';
import DigitalSignWelcomeForm from './DigitalSignWelcomeForm';
import { useNavigate } from 'react-router-dom';
import image from '../../../img/The-Power-of-Billboard-Advertising-1.jpg';
import logo from '../../../img/automatbLogo.png';
import { DigitalSignMobileSignUpForm } from '../mobileView/DigitalSignMobileSignUpForm';
import { utility } from '../../../framework/utility/utilityProvider';
import { DigitalSignHelper } from '../helper/DigitalSignHelper';
import { LOCALSTORAGE_KEY, PRODUCTS } from '../../../framework/constant/constant';
import ReactGA from "react-ga4";

const DigitalSignSignUpForm = () => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location?.search ?? "");
    const welcomeScreen = urlParams?.get('welcomeScreen');
    let activationCode = urlParams.get('activationCode');
    const [isMobile, setIsMobile] = useState(false);
    const activationInProgress = useRef(false);
    const callBackUrl = utility.getValue(LOCALSTORAGE_KEY.CALL_BACK_URL); // Retrieve callback URL

    // Check if callBackUrl exists and contains an activationCode
    if (callBackUrl) {
        const callBackUrlParams = new URLSearchParams(callBackUrl.split('?')[1]);
        const callBackActivationCode = callBackUrlParams.get('activationCode');

        if (callBackActivationCode) {
            activationCode = callBackActivationCode;
        } else {
            activationCode = null;
        }
    }

    useEffect(() => {
        // Function to calculate screen orientation and size
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait); // Set true for portrait mode
        };
        // Initial check
        updateScreenType();
        // Add event listener for window resize
        window.addEventListener('resize', updateScreenType);

        if(utility.isAutomateB()){
            // google analytics
            ReactGA.event({
                category: "Page View",
                action: "Signup Form Loaded",
                label: window.location.pathname,
            });
        }
        

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    useEffect(() => {
        if (welcomeScreen) {
            setIsShowWelcomeForm(true);
        } else {
            setIsShowWelcomeForm(false);
        }
    }, [welcomeScreen]);

    const blankDataItem = {
        name: '',
        email: '',
        password: '',
        companyName: '',
        countryCode: '',
        confirmPassword: '',
        phone: '',
        industry: {},
        // networkScreenSize: {},
        // howDidYouFoundUs: {},
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isShowWelcomeForm, setIsShowWelcomeForm] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const [dataValue, setDataValue] = useState({
        countryCodes: [],
        industries: [],
        networkScreenSizes: [],
        howDidYouFoundUs: [],
    });

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let res = await API_DIGITAL_SIGN.getSignupMasters();
        if (res.success) {
            setDataValue(res.data);
            setDataItem({ ...dataItem, countryCode: res.data.countryCodes[0].Value, networkScreenSize: res.data.networkScreenSizes[0] });
        } else {
            toast.error(res.message);
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.name == undefined || dataItem.name == '' || !dataItem.name?.toString().trim()) {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.email == undefined || dataItem.email == "" || !emailRegex.test(dataItem.email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.phone == undefined || dataItem.phone == '' || !dataItem.phone?.toString().trim()) {
            toast.error(lang.please_enter_phone_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.password == undefined || dataItem.password == '' || !dataItem.password?.toString().trim()) {
            toast.error(lang.please_enter_password_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.confirmPassword == undefined || dataItem.confirmPassword == '' || !dataItem.confirmPassword?.toString().trim()) {
            toast.error(lang.please_enter_confirm_password_users_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.password !== dataItem.confirmPassword) {
            toast.error(lang.confirm_password_do_not_match_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        // if (dataItem.companyName == undefined || dataItem.companyName == '' || !dataItem.companyName?.toString().trim()) {
        //     toast.error(lang.please_enter_company_name_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // if (dataItem.countryCode == undefined || dataItem.countryCode == "") {
        //     toast.error(lang.please_select_country_code_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // let countryData = dataValue.countryCodes.find((item) => item.Value == dataItem.countryCode);
        // if (dataItem.phone?.length < countryData?.NoLength) {
        //     toast.error(lang.please_enter_correct_phone_number_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // if (dataItem.industry == undefined || Object.keys(dataItem.industry).length == 0) {
        //     toast.error(lang.please_select_industry_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // if (dataItem.networkScreenSize == undefined || Object.keys(dataItem.networkScreenSize).length == 0) {
        //     toast.error(lang.please_select_network_screen_size_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // if (dataItem.howDidYouFoundUs == undefined || Object.keys(dataItem.howDidYouFoundUs).length == 0) {
        //     toast.error(lang.please_select_how_did_you_found_us_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        return true;
    }

    const handleActivationScreen = async () => {
        if (activationInProgress.current) {
            console.warn("Activation is already in progress");
            return;
        }
        activationInProgress.current = true;
        try {
            const activateScreenRes = await activateScreen();
            navigate(`/canActivateDisplay?success=${encodeURIComponent(activateScreenRes.success)}&message=${encodeURIComponent(activateScreenRes.message)}&activationCode=${encodeURIComponent(activationCode)}${activateScreenRes.code ? `&code=${encodeURIComponent(activateScreenRes.code)}` : ""}`);
        } finally {
            activationInProgress.current = false;
        }
    }

    const checkIfCanActivateScreen = async () => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        let canActivateScreenRes = await API_DIGITAL_SIGN.canActivateScreen(companyId);
        if (!canActivateScreenRes.success && canActivateScreenRes?.data?.length > 0) {
            return { success: true, code: canActivateScreenRes?.code, data: canActivateScreenRes.data, message: canActivateScreenRes.message };
        }
        else if (!canActivateScreenRes.success) {
            return { success: false, code: canActivateScreenRes?.code, data: null, message: canActivateScreenRes.message };
        }
        // if all screens are consumed
        else if (canActivateScreenRes.success && canActivateScreenRes.data != null && canActivateScreenRes.data.length == 0) {
            return { success: false, code: canActivateScreenRes?.code, data: null, message: canActivateScreenRes.message };
        }
        else {
            return { success: true, code: canActivateScreenRes?.code, data: canActivateScreenRes.data, message: canActivateScreenRes.message };
        }
    }

    const activateScreen = async () => {
        const res = await checkIfCanActivateScreen();
        const finalCompanyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;

        if (!res.success) {
            return res;
        }

        let selectedPlanId = null;
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
            if (res.data?.length == 1) {
                selectedPlanId = res.data[0]?._id
            } else {
                navigate(`/canActivateDisplay`, { state: { isChoosePlan: true, plans: res.data, activationCode: activationCode } });
                return;
            }
        } else {
            selectedPlanId = res.data?._id;
        }
        let activateScreenRes = await DigitalSignHelper.activateScreenFunc({ activationCode: activationCode, name: "Screen", selectedPlanId: selectedPlanId, companyId: finalCompanyId });
        return activateScreenRes;

    };

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!isValid()) return;

        try {
            if(utility.isAutomateB()){
                ReactGA.event({
                    category: "Button Click",
                    action: "Signup Button Clicked",
                    label: window.location.pathname,
                });
            }
            
        } catch (error) {
            console.log("error", error);
        }



        let finalData = {
            name: dataItem.name,
            email: dataItem.email?.toLowerCase(),
            password: dataItem.password,
            companyName: dataItem.name,
            countryCode: "IN",
            countryPrefix: "IN",
            currencyCode: "INR",
            phone: dataItem.phone,
            // industry: dataItem.industry ? {
            //     _id: dataItem.industry?._id,
            //     Description: dataItem.industry?.Description
            // } : {},
            // networkScreenSize: dataItem.networkScreenSize ? {
            //     _id: dataItem.networkScreenSize?._id,
            //     Description: dataItem.networkScreenSize?.Description
            // } : {},
            // howDidYouFoundUs: dataItem.howDidYouFoundUs ? {
            //     _id: dataItem.howDidYouFoundUs?._id,
            //     Description: dataItem.howDidYouFoundUs?.Description
            // } : {},
        }
        console.log(finalData);
        let response = await API_DIGITAL_SIGN.onDigitalSignup(finalData);
        if (response.success) {
            // Handle post-signup login
            const loginResFunc = async () => {
                const loginRes = await API.login(finalData.email?.toLowerCase(), finalData.password);
                const loginDataRes = await utility.setLoginData(loginRes);

                const hasSingleDigitalSignageProduct =
                    loginDataRes?.data?.Company?.Products?.length === 1 &&
                    loginDataRes?.data.Company.Products.some(x => x === PRODUCTS.DigitalSignage);

                if (hasSingleDigitalSignageProduct) {
                    return true; // Indicates navigation to Digital Signage dashboard
                }
                return false; // Default navigation to home
            };

            if (activationCode) {
                const isDigitalSignage = await loginResFunc();
                if (isDigitalSignage) {
                    handleActivationScreen();
                } else {
                    navigate('/home');
                }
            } else {
                navigate('?welcomeScreen=true');
            }
        } else {
            toast.error(response.message);
        }
    };

    const labelStyle = {
        color: '#7b838a',
        textTransform: 'uppercase',
        letterSpacing: '.1rem'
    };

    return (
        <>
            {isMobile ? <DigitalSignMobileSignUpForm logo={logo} image={image} dataItem={dataItem} onChange={onChange} handleSignup={handleSignup} dataValue={dataValue} labelStyle={labelStyle} showPassword={showPassword} setShowPassword={setShowPassword} showConfirmPassword={showConfirmPassword} setShowConfirmPassword={setShowConfirmPassword} /> :
                <div className="container-fluid vh-100 d-flex digital-sign-login-container">
                    <div
                        className="position-absolute w-100 h-100 digital-sign-login-background"
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'blur(10px)',
                            zIndex: -1,
                            position: 'relative',
                        }}
                    ></div>
                    <div className="d-flex justify-content-end align-items-center w-100 text-center digital-sign-login-heading">
                        <div>
                            Manage Your Digital Signage Efficiently, Anytime & from Anywhere
                            {/* Your Gateway to <span style={{fontSize: '60px', color: '#FC6701'}}>Seamless Signage</span> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center w-100 h-100 digital-sign-login-content">
                        <div className="col-auto d-flex flex-column justify-content-end align-items-start p-5 bg-white digital-sign-up-login-form" style={{ borderRadius: '5px' }}>
                            <img src={logo} alt="Logo" className="mb-2" style={{ width: '200px' }} />
                            <h2 style={{ fontSize: '3rem', marginBottom: '10px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }}>{lang.sign_up_label}</h2>
                            <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="name">{lang.name_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="name"
                                    placeholder="Enter name"
                                    value={dataItem.name}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="email">{lang.email_address_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type="email"
                                    className="form-control form-control-sm"
                                    name="email"
                                    placeholder="Enter email"
                                    value={dataItem.email}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="phone">{lang.phone_no_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type="number"
                                    className="form-control form-control-sm"
                                    name="phone"
                                    placeholder="Enter phone number"
                                    value={dataItem.phone}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-2 w-100 position-relative">
                                <label style={labelStyle} htmlFor="password">{lang.password_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-control form-control-sm"
                                    name="password"
                                    placeholder="Password"
                                    value={dataItem.password}
                                    onChange={onChange}
                                />
                                <i
                                    style={{ color: '#7b838a', position: "absolute", top: "70%", right: "10px", cursor: "pointer", transform: "translateY(-50%)" }}
                                    className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                            <div className="mb-2 w-100 position-relative">
                                <label style={labelStyle} htmlFor="confirmPassword">{lang.confirm_password_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className="form-control form-control-sm"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={dataItem.confirmPassword}
                                    onChange={onChange}
                                />
                                <i
                                    style={{ color: '#7b838a', position: "absolute", top: "70%", right: "10px", cursor: "pointer", transform: "translateY(-50%)" }}
                                    className={`icon-append fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                />
                            </div>
                            {/* <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="companyName">{lang.company_name_label} *</label>
                                <input
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="companyName"
                                    placeholder="Enter Company Name"
                                    value={dataItem.companyName}
                                    onChange={onChange}
                                />
                            </div> */}
                            {/* <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="countryCode">{lang.country_code_label} *</label>
                                <select
                                    style={{ padding: "10px 20px", display: 'inline-block' }}
                                    className="form-control form-control-sm"
                                    name="countryCode"
                                    value={dataItem.countryCode}
                                    onChange={onChange}
                                >
                                    {dataValue.countryCodes.map((country) => {
                                        return (<option key={country.SID} value={country.Value}>
                                            {country.Description} ({country.Value})
                                        </option>)
                                    })
                                    }
                                </select>
                            </div> */}
                            {/* <div className="mb-2 w-100">
                                <label style={labelStyle} htmlFor="industry">{lang.industries_label} *</label>
                                <br />
                                <DropDownList
                                    style={{ backgroundColor: 'white', width: "300px" }}
                                    data={dataValue.industries}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='industry'
                                    value={dataItem.industry}
                                    onChange={onChange}
                                />
                            </div> */}
                            {/* <div className="mb-2 w-100">
                                <label htmlFor="networkScreenSize">{lang.network_screen_size_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.networkScreenSizes}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='networkScreenSize'
                                    value={dataItem.networkScreenSize}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-2 w-100">
                                <label htmlFor="howYouFoundUs">{lang.how_did_you_found_us_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.howDidYouFoundUs}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='howDidYouFoundUs'
                                    value={dataItem.howDidYouFoundUs}
                                    onChange={onChange}
                                />
                            </div> */}
                            <p className="mt-2 mb-2">{lang.already_have_an_account_label} <a onClick={() => navigate('/')} className="text-primary cursor-pointer">{lang.sign_in_label}</a></p>
                            <button type="submit" className="btn btn-primary btn-block" onClick={handleSignup}>{lang.sign_up_label}</button>
                        </div>
                    </div>

                </div>}
            <ToastContainer />
            {isShowWelcomeForm && <DigitalSignWelcomeForm email={dataItem.email} password={dataItem.password} />}
        </>
    )
}

export default DigitalSignSignUpForm;