import React, { useEffect, useRef, useState } from 'react'
import BossDialog from '../../../components/BossDialog';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-data-query';
import { useTranslation } from '../../../locale/useTranslation';
import * as API from '../../../framework/API/api';
import { DateOnlyCell, LocalDateTimeColumn } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { TimeCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { EnumCell } from '../../../framework/forms/helpercomponents/CustomGridCells/EnumCell';
import { MAM_ASSET_REPORT_STATUS } from '../../../framework/constant/constant';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';
import { utility } from '../../../framework/utility/utilityProvider';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { toast } from 'react-toastify';

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ScheduleMAMAssetReportPopup = (props) => {

    const lang = useTranslation();

    const [gridData, setGridData] = useState([]);
    const [filterGridData, setFilterGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [dataItem, setDataItem] = useState({ 
        Status : { _id: 0, Description: "All" } ,
        MediaType : { _id: 0, Description: "All" } 
    });
    const [selectedState, setSelectedState] = useState({});
    const _export = useRef(null);

    useEffect(() => {
        getScheduleMAMAssetMediaReport(props.date, props.offsetTime, props.channel.SID);
    }, [])

    const onChange = (e) => {
        let newItem = { ...dataItem, [e.target.name]: e.target.value }; 
        setDataItem(newItem);

        if(newItem.Status._id == 0 && newItem.MediaType._id == 0){
            setFilterGridData(gridData);
        } else {

            if (e.target.name == "Status" && newItem.MediaType._id == 0) setFilterGridData(gridData.filter((x) => x.MamAssetStatus == e.target.value._id));
            if (e.target.name == "Status" && newItem.MediaType._id != 0) {
                if(newItem.Status._id == 0){
                    setFilterGridData(gridData.filter((x) => x.MediaCategory.isLong == (newItem.MediaType.Description == "Long Form")));
                } else {
                    setFilterGridData(gridData.filter((x) => 
                        x.MamAssetStatus == e.target.value._id && 
                        x.MediaCategory.isLong == (newItem.MediaType.Description == "Long Form")
                    ));
                }
            }
            if (e.target.name == "MediaType" && newItem.Status._id == 0) setFilterGridData(gridData.filter((x) => x.MediaCategory.isLong == (e.target.value.Description == "Long Form")));
            if (e.target.name == "MediaType" && newItem.Status._id != 0){
                if(newItem.MediaType._id == 0){
                    setFilterGridData(gridData.filter((x) => x.MamAssetStatus == dataItem.Status._id));
                } else {
                    setFilterGridData(gridData.filter((x) => 
                        x.MediaCategory.isLong == (e.target.value.Description == "Long Form") && 
                        x.MamAssetStatus == dataItem.Status._id
                    ));
                }
            }

        }
        
    }
    

    const getScheduleMAMAssetMediaReport = async (scheduleDate, offsetTime, channelSID) => {

        // Schedule Payload
        let payload = {
            ScheduleDate: (new Date(scheduleDate)).setHours(0, 0, 0, 0) + offsetTime,
            ScheduleEndDate: (new Date(scheduleDate)).setHours(23, 59, 59) + offsetTime,
            channelSID: channelSID
        }

        let res = await API.getScheduleMAMAssetMediaReport(payload);

        if (res.success) {
            setGridData(res.data);
            setFilterGridData(res.data);
            setIsLoading(false);
        } else {
            toast.error(res.error);
        }

    }

    const MamAssetStatus = (props) => {

        var field = props.field || "";

        const style = {
            backgroundColor:
                props.dataItem[field] == MAM_ASSET_REPORT_STATUS.TXREADY ? "#5CB338" :
                    props.dataItem[field] == MAM_ASSET_REPORT_STATUS.MISMATCH_DURATION ? "#D39D55" :
                        props.dataItem[field] == MAM_ASSET_REPORT_STATUS.MISSING ? "#FB4141" :
                            "#FFFFFF",
            border: "1px solid lightgrey",
            color: "white",
            textAlign: 'center'
        };

        return <td style={style}> {
            utility.getKeyByValue(MAM_ASSET_REPORT_STATUS, props.dataItem[field])
        } </td>
    };

    const downloadExcel = () => {
        if (filterGridData.length == 0) {
            return;
        }
        if (_export.current !== null) {
            _export.current.save();
        }
    };



    // const onMediaEpisodeSelection = async (dataList) => {
    //     setDataItem({ ...dataItem, MediaEpisode: dataList[0] })

    //     //FETCH SEGMENTS
    //     let segmentRes = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: [["MediaEpisodeSID", "=", dataList[0].SID]] });
    //     if (segmentRes.success) {
    //         console.log(segmentRes)
    //         setSegments(segmentRes.data.map((x) => { return { ...x,Description : x.SegmentType?.Description } }));
    //     } else {
    //         toast.error(segmentRes.message);
    //     }
    // }


    return (
        <BossDialog title={"Schedule MAM Asset Report"} onClose={props.onClose} width={"1250px"} >
            <div className="flex-container">
                <div className="m-1" style={{ paddingTop: "6px" }}>
                    <RoundButton
                        icon={"download"}
                        btnColor={"info"}
                        style={{ color: "white" }}
                        // iconColor={"white"}
                        title={lang.export_button_tooltip}
                        onClick={downloadExcel}
                    />
                </div>
                <div className="flex-container-reverse flex-item-auto">

                    <div className="d-inline col-3 mb-2" style={{ marginTop: '-9px', padding: '0px' }}>
                        <label htmlFor="Status">{lang.status_label}</label>
                        <DropDownList
                            data={[{ _id: 0, Description: 'All' }, ...Object.keys(MAM_ASSET_REPORT_STATUS).map((key) => { return { _id: MAM_ASSET_REPORT_STATUS[key], Description: key } })]}
                            style={{ backgroundColor: 'white' }}
                            name="Status"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Status}
                            onChange={onChange}
                        />
                    </div>

                    <div className="d-inline col-3 mb-2" style={{ marginTop: '-9px', padding: '0px 15px 0px 0px' }}>
                        <label htmlFor="MediaType">{"Media Type"}</label>
                        <DropDownList
                            data={[{ _id: 0, Description: 'All' }, { _id: 1, Description: 'Long Form' }, { _id: 2, Description: 'Short Form' }]}
                            style={{ backgroundColor: 'white' }}
                            name="MediaType"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.MediaType}
                            onChange={onChange}
                        />

                    </div>

                </div>
            </div>

            <div className="row" style={{ margin: "0.5px" }}>
                <ExcelExport data={filterGridData} ref={_export} fileName={`${props.channel.FullChannelName}_ScheduleMAMAssetReport`}>
                    <Grid
                        data={filterGridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "single",
                        }}
                        onSelectionChange={() => { }}
                        resizable={true}
                        style={{ height: "43vh" }}

                    >
                        {isLoading && <GridNoRecords><i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i></GridNoRecords>}
                        <GridColumn field="Title" title={lang.title_column} />
                        <GridColumn field="AssetId" title={lang.assetId_column} />
                        <GridColumn field="MediaCategory.Description" title={lang.media_category_column} />
                        <GridColumn field="Duration" title={lang.duration_column} />
                        <GridColumn field="MamAssetDuration" title={"MAM Asset Duration"} />
                        <GridColumn field="MamAssetStatus" title={lang.status_column} cell={MamAssetStatus} />
                    </Grid>
                </ExcelExport>

            </div>
            <div className='text-right mt-3' style={{ position: 'fixed', height: '30px', width: '1250px', marginLeft: '-20px', padding: '5px', background: "#3A3633" }}>
                <span style={{ color: 'white' }}>{
                    `Total : ${gridData.length}  |  
                    TX Ready : ${gridData.filter((x) => x.MamAssetStatus == MAM_ASSET_REPORT_STATUS.TXREADY).length}  |  
                    Mismatch Duration : ${gridData.filter((x) => x.MamAssetStatus == MAM_ASSET_REPORT_STATUS.MISMATCH_DURATION).length}  |  
                    Missing Media : ${gridData.filter((x) => x.MamAssetStatus == MAM_ASSET_REPORT_STATUS.MISSING).length} `} </span>
            </div>
        </BossDialog>
    )
}

export default ScheduleMAMAssetReportPopup