/* eslint-disable */
import React from 'react';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileGridCard from './MobileGridCard';
import { useNavigate } from 'react-router-dom';

const DigitalSignMobileScreenGroupCollection = (props) => {

  const { gridData = [], showTitle = true, showAdd = true, showBack = true, onEnterEdit, myTitle, handleArchive } = props;
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {showTitle && (
        <div className='d-flex ml-1' style={{ alignItems: "center", flexWrap: "wrap" }} >
          <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px", }}></i>
          <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}>{myTitle}</span>
        </div>
      )}
      <div className="flex-container mt-2 mb-2">
        {showBack && (
          <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
        )}
        {showAdd && (
          <AddRoundButton onClick={onEnterEdit} />
        )}
      </div>
      <div className='mb-5' style={{ flex: 1, overflowY: "auto" }}>
        {gridData.length == 0 ?
          <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
            <AddRoundButton onClick={onEnterEdit} /> Add Screen Group
          </div>
          : gridData.map((item, index) => {
            const contextMenuItem = [
              {
                label: 'Edit',
                command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit(item) },
                icon: 'pi pi-pencil'
              },
              {
                label: 'Create From',
                command: (e) => { e.originalEvent.stopPropagation(); onEnterEdit({ ...item, copy: true }) },
                icon: 'pi pi-copy'
              },
              {
                label: 'Archive',
                command: (e) => { e.originalEvent.stopPropagation(); handleArchive(item) },
                icon: 'pi pi-inbox'
              },
            ];
            return <MobileGridCard
              key={index}
              title={item.Name}
              description={item.Description}
              contextMenuItem={contextMenuItem}
              showContextMenu={true}
              height="75px"
              handleItemClick={onEnterEdit}
              data={item}
            />
          })}
      </div>
    </div>
  )
}

export default DigitalSignMobileScreenGroupCollection;
