/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import * as API from "../../../framework/API/api_digitalSign";
import { toast } from "react-toastify";
import SaveButton from "../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../framework/forms/helpercomponents/buttons/CancelButton";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import BossDialog from "../../../components/BossDialog";
import { LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import { isArray } from "underscore";

const DigitalSignMobileScreenGroupCollectionSelection = memo((props) => {

    const lang = useTranslation();

    let wherestatement = props?.wherestatement;
    let andWhereStatement = props?.andWhereStatement;
    let Company_id = props?.Company_id ? isArray(props?.Company_id) ? props?.Company_id : [props?.Company_id] : utility.getValue(LOCALSTORAGE_KEY.COMPANIES).map(x => x?._id);

    const mode = props.mode ?? "multiple";
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [selectedState, setSelectedState] = useState(
        props.seletedState ? utility.getSelectedStateCollectionObject(props.seletedState, "_id") : {}
    );
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [searchText, setSearchText] = useState("");

    const page_take = props.page_take ?? 50;
    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    useEffect(() => {
        loadData({ per_page: page.take, page: 1 }, wherestatement, andWhereStatement, "", Company_id);
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText == null) return;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                loadData({ per_page: page.take, current_page: 1 }, wherestatement, andWhereStatement, searchText);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setPage({ skip: 0, take: page_take });
            loadData({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText);
        }
    }, [searchText]);

    const loadData = async (pagination, wherestatement, andWhereStatement, searchText, Company_id) => {
        setIsLoading(true);
        try {
            let json = await API.getScreenGroup({ perPage: pagination?.per_page, page: pagination?.page, query: wherestatement }, searchText, Company_id);
            console.log(json);
            setGridData(json.data);
            setCount(json.pagination.total);
        } catch (error) {
            toast.error(lang.error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setIsLoading(false);

    };

    const handleCheckboxChange = (id) => {
        setSelectedState((prevState) => {
            if (mode === "single") {
                return { [id]: true }; // Allow only one selection
            }
            return {
                ...prevState,
                [id]: !prevState[id],
            };
        });
    };

    const handleSelectAllChange = () => {
        setIsAllSelected((prev) => !prev); // Toggle select all state
        if (!isAllSelected) {
            const allSelected = gridData.reduce((acc, item) => {
                acc[item._id] = true;
                return acc;
            }, {});
            setSelectedState(allSelected);
        } else {
            setSelectedState({});
        }
    };

    const onSelection = () => {
        const selectedDataList = gridData.filter((item) => selectedState[item._id]);
        if (selectedDataList.length === 0) {
            toast.info(lang.please_select_first_collection_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        props.setDataList(selectedDataList);
        props.closeForm();
    };

    return (
        <BossDialog
            title={props.title ?? lang.add_dialog_header}
            onClose={props.closeForm}
            width={props.width ?? "95%"}
            height={props.height ?? "auto"}
        >
            <div className="row align-items-center mb-3" style={{ marginLeft: "-25px" }}>
                <div className="col-auto">
                    <SaveButton
                        title={props.addButtonTitle ?? lang.add_button_text}
                        style={{ backgroundColor: "green", borderRadius: "4px" }}
                        onClick={onSelection}
                    />
                    <CancelButton onClick={props.closeForm} />
                </div>
                <div className="col text-right mt-1">{`${lang.total_label} : ${count}`}</div>
            </div>

            <div className="mb-2 mt-2 d-flex">
                <input
                    type="text"
                    className="form-control shadow-inset-2 pt-0 pb-0"
                    id="searchInput"
                    placeholder={lang.search_button_text}
                    onChange={onSearch}
                    onFocus={(e) => {
                        if (props.setOutSideFocus) {
                            props.setOutSideFocus(true);
                        }
                    }}
                    onBlur={(e) => {
                        if (props.setOutSideFocus) {
                            props.setOutSideFocus(false);
                        }
                    }}
                />
                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                        </span>
                    </div>
                </button>
            </div>

            {mode === "multiple" &&
                <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px" }}>{lang.select_all_label}</label>
                    <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                        style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer"
                        }}
                    />
                </div>
            }
            
            <div className="card-container" style={{ height: '50vh', overflowY: 'auto' }}>
                {isLoading ? <Loader height='50vh' /> :
                    gridData.length == 0 ? <div style={{ height: "50vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>{lang.no_record_found_error_message}</div> :
                    gridData.map((item) => {
                        return (
                            <div
                            key={item._id}
                            onClick={() => handleCheckboxChange(item._id)}
                            className="mb-3 mt-2"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                overflow: "hidden",
                                padding: "10px",
                                borderRadius: "10px",
                                border: selectedState[item._id] ? "2px solid #4caf50" : "1px solid #ccc",
                                boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="ml-3">
                                    <h3 style={{ margin: "0", fontSize: "16px" }}>{utility.subString(item?.Name, 18)}</h3>
                                    <p style={{ margin: "5px 0 0", color: "#555" }}>{item?.Description}</p>
                                </div>
                            </div>
                            <input
                                type="checkbox"
                                checked={selectedState[item._id] || false}
                                readOnly
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    marginLeft: "10px" 
                                }}
                            />
                        </div>
                        );
                    })
                }
            </div>
        </BossDialog>
    );
});

export default DigitalSignMobileScreenGroupCollectionSelection;
