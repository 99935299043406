/* eslint-disable */
import React, { useState } from 'react';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileGridCard from './MobileGridCard';
import { TimeCellWithOutFrames } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { useTranslation } from '../../../locale/useTranslation';
import { DigitalSign_ContentColumns, DigitalSign_ContentGroupColumns, DigitalSign_OverlaysColumns } from '../../../framework/utility/customColumns';
import { ENTITYNAME } from '../../../framework/constant/constant';
import { utility } from '../../../framework/utility/utilityProvider';
import DigitalSignMobileCollectionSelection from './DigitalSignMobileCollectionSelection';
import * as API_DIGI_SIGN from '../../../framework/API/api';
import { toast } from 'react-toastify';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';
import { PlaylistHelper } from '../Playlist/PaylistHelper';

export const DigitalSignMobilePlaylistWrapper = (props) => {

    const { playlist, isPlaylistLoading, setPlaylistData, companyId, deletePlaylist } = props;
    const [showForm, setShowForm] = useState({ isVisible: false, isContentGroup: false });
    const [showOverlaysForm, setShowOverlaysForm] = useState({ isVisible: false, item: {} });
    const lang = useTranslation();

    const handleMobileSelectedData = async (dataList, isContentGroup = false) => {
        const contentData = isContentGroup
            ? dataList.flatMap(({ Content }) =>
                Content.map((d, i) => ({
                    content: { ...d, url: utility.getContentUrl(d) },
                    playlist_id: playlist?._id,
                    content_id: d._id,
                    overlay: [],
                    sequenceIndex: playlist?.children?.length + i,
                    duration: d.duration ?? 10 * 1000,
                }))
            )
            : dataList.map((item, i) => ({
                content: { ...item, url: utility.getContentUrl(item) },
                playlist_id: playlist?._id,
                content_id: item._id,
                overlay: [],
                sequenceIndex: playlist?.children?.length + (i + 1),
                duration: item.duration ?? 10 * 1000,
            }));

        const updatedChildren = [...playlist.children, ...contentData];
        setPlaylistData({ ...playlist, children: updatedChildren });

        let finalData = PlaylistHelper.onSavePlaylistItem(updatedChildren);
        const saveRes = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, finalData);
        console.log(saveRes);
        if (saveRes.success) {
            saveRes.data.forEach((d, i) => {
                if (d.message === 'inserted') {
                    updatedChildren[i]._id = d.response.insertedId;
                }
            });
            setPlaylistData({ ...playlist, children: updatedChildren });
        } else {
            toast.error(saveRes.message, { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const onMobileMoveItem = async (item, index, isUp = true) => {
        const temp = [...playlist.children];
        const newIndex = isUp ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= temp.length) return;
        temp.splice(index, 1);
        temp.splice(newIndex, 0, item);
        temp.forEach((child, idx) => {
            child.sequenceIndex = idx + 1;
        });
        setPlaylistData({ ...playlist, children: temp });
        const finalData = PlaylistHelper.onSavePlaylistItem(temp);
        await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, finalData);
    };
    

    const handleSelectedMobileOverlays = async (dataList) => {
        const overlays = dataList.map(x => x?._id);
        const children = [...playlist.children];

        const index = children.findIndex(item => item._id === showOverlaysForm.item._id);

        const updatedItem = {
            ...children[index],
            overlay: [...new Set([...(children[index]?.overlay || []), ...overlays])],
        };

        let finalData = PlaylistHelper.onSavePlaylistItem(updatedItem);
        await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, finalData);

        children[index] = updatedItem;
        setPlaylistData({ ...playlist, children: children });
    };


    const handleDeleteMobileOverlay = async (item) => {

        const children = [...playlist.children];
        const index = children.findIndex(child => child._id === item._id);

        const updatedItem = { ...children[index], overlay: [] };
        let finalData = PlaylistHelper.onSavePlaylistItem(updatedItem);
        await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, finalData);
        children[index] = updatedItem;
        setPlaylistData({ ...playlist, children: children });
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div className="flex-container mt-2 mb-2">
                <AddRoundButton title={lang.content_label} onClick={() => setShowForm({ isVisible: true, isContentGroup: false })} />
                <RoundButton icon="clipboard" title={lang.content_group_label} onClick={() => setShowForm({ isVisible: true, isContentGroup: true })} />
            </div>

            {isPlaylistLoading ? (
                <Loader />
            ) : (
                <div className='mb-5' style={{ flex: 1, overflowY: "auto" }}>
                    {playlist?.children?.filter(item => !item?.isDummy)?.length === 0 ? (
                        <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                            <AddRoundButton onClick={() => setShowForm({ isVisible: true, isContentGroup: false })} />Add Content
                        </div>
                    ) : (
                        playlist?.children?.sort((a,b)=> a?.sequenceIndex - b?.sequenceIndex).map((item, index) => {
                            if (item?.isDummy) return null;
                            const contextMenuItem = [
                                {
                                    label: 'Move Up',
                                    command: () => onMobileMoveItem(item, index, true),
                                    icon: 'pi pi-arrow-up'
                                },
                                {
                                    label: 'Move Down',
                                    command: () => onMobileMoveItem(item, index, false),
                                    icon: 'pi pi-arrow-down'
                                },
                                {
                                    label: 'Add Overlay',
                                    command: () => setShowOverlaysForm({ isVisible: true, item: item }),
                                    icon: 'pi pi-sitemap'
                                },
                                {
                                    label: 'Delete Overlay',
                                    command: () => handleDeleteMobileOverlay(item),
                                    icon: 'pi pi-trash'
                                },
                                {
                                    label: 'Delete',
                                    command: () => deletePlaylist([item]),
                                    icon: 'pi pi-trash'
                                },
                            ];

                            return (
                                <MobileGridCard
                                    key={index}
                                    title={item?.content?.fileName}
                                    imageUrl={item?.content?.thumbnailUrl || "https://media.comicbook.com/files/img/default-movie.png"}
                                    description={item?.content?.resource_type}
                                    label1={`Overlays: ${item?.overlay?.length || 0}`}
                                    bottomRight={TimeCellWithOutFrames({ field: 'duration', dataItem: item })}
                                    contextMenuItem={contextMenuItem}
                                    showContextMenu={true}
                                    height='120px'
                                />
                            );
                        })
                    )}
                </div>
            )}

            {showForm.isVisible && (
                <DigitalSignMobileCollectionSelection
                    setDataList={(dataList) => handleMobileSelectedData(dataList, showForm.isContentGroup)}
                    customColumns={showForm.isContentGroup ? DigitalSign_ContentGroupColumns : DigitalSign_ContentColumns}
                    title={showForm.isContentGroup ? lang.digital_sign_add_content_group_dialog_header : lang.digital_sign_add_content_dialog_header}
                    entityname={showForm.isContentGroup ? ENTITYNAME.DigitalSignContentGroup : ENTITYNAME.DigitalSignContent}
                    closeForm={() => setShowForm({ isVisible: false, isContentGroup: false })}
                    width={"95%"}
                />
            )}
            {showOverlaysForm.isVisible && <DigitalSignMobileCollectionSelection
                setDataList={handleSelectedMobileOverlays}
                customColumns={DigitalSign_OverlaysColumns}
                title={lang.digital_sign_add_overlays_dialog_header}
                entityname={ENTITYNAME.DigitalSignOverlay}
                closeForm={() => setShowOverlaysForm(false)}
                width={"95%"}
            />}
        </div>
    );
};
