/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Form, FormElement } from '@progress/kendo-react-form'
import { EditPageHeader } from '../../../components/EditPageHeader'
import * as API from "../../../framework/API/api"
import { ENTITYNAME } from '../../../framework/constant/constant'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { UpdateBookingLineNumberCommandCell } from './UpdateBookingCommandCell'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import { TimeRangePicker } from '../../../framework/forms/helpercomponents/timepicker/TimeRangePicker'
import { utility } from '../../../framework/utility/utilityProvider'
import { WrappedTextCell } from '../../../framework/forms/helpercomponents/CustomGridCells/WrappedTextCell'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from '../../../locale/useTranslation'
import { RadioButton } from "@progress/kendo-react-inputs";
import moment from 'moment'
import BossDialog from '../../../components/BossDialog'

const DATA_ITEM_KEY = "LineNumber";
const editField = "inEdit";

const MyNumericCell = (props) => {
    var field = props.field || "";
    var saveField = field.split('.');

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: saveField[0],
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    var daySpots = 0;
    var isScheduled = false;
    if (props.dataItem[saveField[0]] != undefined) {
        daySpots = props.dataItem[saveField[0]]['daySpots'];
        isScheduled = props.dataItem[saveField[0]].isScheduled;
    }

    const style = {
        backgroundColor: isScheduled ? "#F55050" : "#C9F4AA",
        border: "1px solid lightgrey",
        textAlign: 'center'
    };

    return <td style={style}> {
        isScheduled ? daySpots : props.dataItem.inEdit ? (<NumericTextBox name={saveField[0]} value={daySpots} onChange={onChange} min={0} style={{ zIndex: 0 }} />) : daySpots
    } </td>
};

const UpdateBookingLineNumberEditForm = (props) => {

    const [dayGridData, setDayGridData] = useState([props.dataItem])

    var blankDataItem = {
        ...props.dataItem,
        TimeRangeFrom: utility.convertMilisecondsToStringWithFrames(props.dataItem.TimeRangeFrom).slice(0, 5),
        TimeRangeTo: utility.convertMilisecondsToStringWithFrames(props.dataItem.TimeRangeTo).slice(0, 5),
        //SpotRate : props.dataItem.IsRate10SecBooked ? props.dataItem.Rate10Sec : props.dataItem.SpotRate  
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [position, setPosition] = useState([]);
    const [channel, setChannel] = useState([]);
    const [editItemSwallowCopy, setEditItemSwallowCopy] = useState([]);
    const lang = useTranslation();
    const deletedItemsRef = useRef([]);
    const [rateType, setRateType] = props.dataItem.IsRate10SecBooked ? useState("Rate10Sec") : useState("Rate");

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channel = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channel.data);
        var position = await API.getDataLookup(ENTITYNAME.Position, { sort: { Description: 1 } });
        setPosition(position.data);
    }

    const onChange = (e) => {

        var local = dayGridData[0];

        if (e.target.name == 'SpotRate' || e.target.name == 'Rate10Sec') {
            if(e.target.name == 'SpotRate') {
                local.TotalAmount = parseFloat(e.target.value) * local.TotalSpots;
                setDataItem({ ...dataItem, SpotRate: (parseFloat(e.target.value)), Rate10Sec: ((parseFloat(e.target.value)/(dataItem.MediaEpisode.Duration/1000))* 10)});
            } else {
                local.TotalAmount = ((parseFloat(e.target.value)/10)* (dataItem.MediaEpisode.Duration/1000))* local.TotalSpots;
                setDataItem({ ...dataItem, Rate10Sec: (parseFloat(e.target.value)), SpotRate: ((parseFloat(e.target.value)/10)* (dataItem.MediaEpisode.Duration/1000))});
            }
        } else if (e.target.name == 'MediaEpisode') {
            local.TotalAmount = parseFloat(dataItem.SpotRate) * local.TotalSpots;
            local.TotalSeconds = local.TotalSpots * e.target.value.Duration / 1000;
            setDataItem({ ...dataItem,[e.target.name]: e.target.value, Rate10Sec: (dataItem.SpotRate/(e.target.value.Duration / 1000))*10});
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }


        dayGridData[0] = local;
        setDayGridData(dayGridData);
    }

    const DateDayCell = (props) => {
        const title = props.title || "";
        const displayValue = title.split(' ');
        return (
            <div colSpan={props.colSpan} style={{ padding: "0px", fontSize: '13px', textAlign: "center" }}>
                {displayValue[0]}<br />
                {`(${displayValue[1]})`}
            </div>
        );
    };


    // Functions to modify grid data
    const onInLineRemoveBooking = async (dataItem) => {
        //ui

        console.log(dataItem);


        let isAlreadyScehduled = isAlreadyScehdule(dataItem);

        if (isAlreadyScehduled.isContaimSchedule) {
            toast.success("Cannot delete, Some booking items are already scheduled at " + isAlreadyScehduled.keyName, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        deletedItemsRef.current.push(dataItem);
        var filteredGridData = dayGridData.filter((obj) => obj._id != dataItem._id)
        setDayGridData(filteredGridData);
        toast.success("Press save to final delete", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const isAlreadyScehdule = (dataItem) => {
        let keys = Object.keys(dataItem)
        let isContaimSchedule = false;
        let keyName = ""
        keys.map((key) => {

            if (dataItem[key]?.isScheduled) {
                console.log(dataItem[key]);
                keyName = key
                isContaimSchedule = true;
            }
        })
        return { isContaimSchedule, keyName };
    }

    const onSaveDeleteItemFromDB = () => {

        deletedItemsRef.current.map(async (deletedItem) => {
            var deleteRes = await API.deleteBookingLine(deletedItem);
            if (!deleteRes.success) {
                toast.warn(deleteRes.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        })
    }

    const onInLineUpdateBooking = (dataItem) => {
        dataItem.inEdit = false;
        let index = dayGridData.findIndex((record) => record._id === dataItem._id);

        var updatedTotalSpots = 0;

        props.days.map((day) => {
            let spot = 0;
            if (dataItem[day] != undefined) {

                spot = dataItem[day]['daySpots'] == null || dataItem[day]['daySpots'] < 0 ? 0 : dataItem[day]['daySpots'];
                dataItem[day]['daySpots'] = spot;
            }

            updatedTotalSpots = updatedTotalSpots + spot;
        })

        //updating total spot, fct and amount
        dataItem.TotalSpots = updatedTotalSpots;
        dataItem.TotalAmount = parseInt(dataItem.SpotRate) * updatedTotalSpots
        dataItem.TotalSeconds = updatedTotalSpots * dataItem.MediaEpisode.Duration / 1000

        dayGridData[index] = dataItem
        setDayGridData(dayGridData);
        inEditOff(dataItem);

    };


    const inEditOff = (dataItem) => {
        const originalItem = dayGridData.find(
            (p) => p._id === dataItem._id
        );
        const newData = dayGridData.map((item) =>
            item._id === originalItem._id ? originalItem : item
        );
        setDayGridData(newData);
    };

    const onInLineEditBooking = (dataItem) => {
        setEditItemSwallowCopy(() => dataItem);

        let newData = dayGridData.map((item) =>
            item._id === dataItem._id
                ? {
                    ...item,
                    inEdit: true,
                }
                : item
        );
        setDayGridData(newData);
    };

    const onInLineCancelBooking = (dataItem) => {
        dataItem.inEdit = false;
        let index = dayGridData.findIndex((record) => record._id === dataItem._id);
        dayGridData[index] = editItemSwallowCopy;
        inEditOff(dataItem);
    }

    const itemChange = (event) => {

        var field = event.field || "";
        var saveField = field.split('.');
        var isScheduled = false;

        if (event.dataItem[saveField[0]] != undefined) {
            isScheduled = event.dataItem[saveField[0]].isScheduled
        }

        const newData = dayGridData.map((item) =>
            item.LineNumber === event.dataItem.LineNumber ? {
                ...item, [saveField[0]]: { daySpots: event.value, isScheduled: isScheduled },
            }
                : item);
        setDayGridData(newData);
    };



    const UpdateBookingCommandCell = (props) => (
        <UpdateBookingLineNumberCommandCell
            {...props}
            onEdit={onInLineEditBooking}
            onRemove={onInLineRemoveBooking}
            onUpdate={onInLineUpdateBooking}
            onCancel={onInLineCancelBooking}
            editField={editField}
            hideDelete={true}
        />
    )

    const onSave = () => {

        //CHECK IF LINE IS IN EDIT MODE
        var editModeData = dayGridData.filter((x) => x.inEdit);
        if (editModeData.length > 0) {
            toast.warning(`Either cancel or save your changes for line number ${editModeData[0].LineNumber} first !!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //CHECK IF LINE HAS SPOTS
        if (!utility.checkLinesWithZeroSpots(dayGridData, props.days)) return;

        //CHECK IF SPOT THAT ARE FILLED ARE IN CAMPAIGN DATE RANGE
        if (!utility.checkSpotsInCampaignDateRange(dayGridData, props.days, props.selectedCampaign)) return;

        //deleted line
        if (deletedItemsRef.current.length > 0) {
            onSaveDeleteItemFromDB();

        }

        var durationInSeconds = dataItem.MediaEpisode.Duration / 1000;
        var spotRate = rateType == 'Rate' ? dataItem.SpotRate : dataItem.Rate10Sec / 10 * durationInSeconds;
        var rate10Sec = rateType == 'Rate' ? (dataItem.SpotRate / durationInSeconds) * 10 : dataItem.Rate10Sec;
        var isRate10Sec = rateType == 'Rate' ? false : true;

        // // console.log('rate 10 sec' );
        // // console.log(dataItem.SpotRate);

        var final = {
            ...dayGridData[0],
            MediaEpisode: dataItem.MediaEpisode,
            MediaEpisode_Id: dataItem.MediaEpisode._id,
            SpotRate: parseFloat(spotRate).toFixed(2),
            Rate10Sec: parseFloat(rate10Sec).toFixed(2),
            TimeRangeFrom: dataItem.TimeRangeFrom,
            TimeRangeTo: dataItem.TimeRangeTo,
            EstimateCode: dataItem.EstimateCode,
            Position: dataItem.Position,
            IsRate10SecBooked: isRate10Sec,
            Campaign_Id: dataItem.Campaign_Id
        }

        dataItem.inEdit = false;
        // console.log(final);
        deletedItemsRef.current = [];
        props.onUpdateBookingLine(final);

    }



    return (
        <BossDialog
            title={lang.edit_booking_dialog_header}
            onClose={props.onClose}
            width={"60%"}
            height={"515px"}
        >
            <EditPageHeader title={""} onSubmit={onSave} onCancel={props.onClose} showTitle={false} />
            <Form
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="row mt-2">
                            <div className="col-12">
                                <label>{lang.channel_label}</label>
                                <DropDownList
                                    data={channel}
                                    name="Channel"
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4">
                                <label>{lang.commercial_label}</label>
                                <DropDownList
                                    data={props.selectedCampaign.Commercials ?? []}
                                    name="MediaEpisode"
                                    textField="Title"
                                    dataItemKey="_id"
                                    value={dataItem.MediaEpisode}
                                    onChange={onChange}
                                    disabled={props.editDisable}
                                    validator={(value) => value ? "" : "Please select the value"}
                                />
                            </div>

                            <div className="col-2">
                                <label>{lang.commercial_duration_lable}</label><br></br>
                                <label>{utility.convertMilisecondsToStringWithFrames(dataItem.MediaEpisode.Duration)}</label>
                            </div>

                            <div className="col-6">
                                <div style={{ margin: "-3px" }}>
                                    <RadioButton
                                        name="RateType"
                                        value="Rate"
                                        checked={rateType === "Rate"}
                                        label={lang.rate_label}
                                        onChange={(e) => { onChange(e); setRateType(e.value) }}
                                    />
                                    <RadioButton
                                        style={{ marginLeft: "5px" }}
                                        name="RateType"
                                        value="Rate10Sec"
                                        checked={rateType === "Rate10Sec"}
                                        label={lang.rate10sec_label}
                                        onChange={(e) => { onChange(e); setRateType(e.value) }}
                                    />
                                </div>
                                {/* <label>{lang.spot_rate_label}</label> */}
                                {rateType == "Rate" ?
                                    <input type="number" className="form-control form-control-sm"
                                        name="SpotRate"
                                        value={dataItem.SpotRate}
                                        disabled={props.editDisable}
                                        onChange={onChange}
                                        style={{ marginTop: "5px" }} />

                                    // <label>{dataItem.SpotRate}</label>

                                    :
                                    <input type="number" className="form-control form-control-sm"
                                        name="Rate10Sec"
                                        value={dataItem.Rate10Sec}
                                        disabled={props.editDisable}
                                        onChange={onChange}
                                        style={{ marginTop: "5px" }} />

                                    //  <label>{dataItem.Rate10Sec}</label>

                                }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.time_range_from_label}</label>
                                <TimeRangePicker
                                    className="form-control form-control-sm"
                                    name="TimeRangeFrom"
                                    value={dataItem.TimeRangeFrom}
                                    disabled={props.editDisable}
                                    onChange={onChange}
                                />
                            </div>

                            <div className="col-6">
                                <label>{lang.time_range_to_label}</label>
                                <TimeRangePicker
                                    className="form-control form-control-sm"
                                    name="TimeRangeTo"
                                    value={dataItem.TimeRangeTo}
                                    disabled={props.editDisable}
                                    onChange={onChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.estimate_code_label}</label>
                                <input
                                    className="form-control form-control-sm"
                                    type={'text'}
                                    name="EstimateCode"
                                    value={dataItem.EstimateCode}
                                    disabled={props.editDisable}
                                    onChange={onChange}
                                />
                            </div>

                            <div className="col-6">
                                <label>{lang.position_label}</label>
                                <DropDownList
                                    data={position}
                                    name="Position"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Position}
                                    onChange={onChange}
                                    disabled={props.editDisable}
                                    validator={(value) => value ? "" : "Please select the value"}
                                />
                            </div>
                        </div>
                    </FormElement>
                )}
            />
            <div className='mt-3'>
                <Grid data={dayGridData} dataItemKey={DATA_ITEM_KEY} style={{ height: "140px" }} onItemChange={itemChange} editField={editField}>
                    <GridColumn cell={UpdateBookingCommandCell} width="100px" locked={true} />
                    <GridColumn field="TotalSpots" title={lang.total_spot_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalSeconds" title={lang.total_fct_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalAmount" title={lang.total_amount_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    {props.days.map((day, index) => {
                        return (
                            <GridColumn key={index} field={`${day}.daySpots`} title={`${day}`} width={"120px"} headerCell={DateDayCell} cell={MyNumericCell} />
                        )
                    })}
                </Grid>
            </div>
        </BossDialog>
    )
}

export default UpdateBookingLineNumberEditForm