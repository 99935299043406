import React, { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useLocation, useNavigate } from 'react-router-dom';
import image from '../../../img/The-Power-of-Billboard-Advertising-1.jpg';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ActionButton from '../../../framework/forms/helpercomponents/buttons/ActionButton';
import { utility } from '../../../framework/utility/utilityProvider';
import { ENTITYNAME, LOCALSTORAGE_KEY } from '../../../framework/constant/constant';
import { DigitalSignHelper } from '../helper/DigitalSignHelper';
import { DigitalSignChoosePlanForm } from '../plan/DigitalSignChoosePlanForm';
import { getData } from '../../../framework/API/api_digitalSign';

export const DigitalSignCanActivateScreenForm = () => {
    const loc = useLocation();
    const urlParams = new URLSearchParams(loc.search ?? "");
    const success = urlParams.get('success') === 'true';
    const message = urlParams.get('message');
    const activationCode = urlParams.get('activationCode');
    const code = urlParams.get('code');
    const [selectedPlan, setSelectedPlan] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    const navigate = useNavigate();

    const handleActivationScreen = async () => {
        setIsLoading(true);
        const finalCompanyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        let activateScreenRes = {};
        if (code == 402) {
            let selectedPlanRes = await getData(ENTITYNAME.DigitalSignCompanyPlans, { query: ['company_id', '=', finalCompanyId] });
            let selectedPlanId = selectedPlanRes?.data?.length > 0 ? selectedPlanRes?.data?.[0]?._id : null;
            activateScreenRes = await DigitalSignHelper.activateScreenFunc({
                activationCode: activationCode,
                name: "Screen",
                selectedPlanId: selectedPlanId,
                companyId: finalCompanyId,
            });
        } else {
            activateScreenRes = await DigitalSignHelper.activateScreenFunc({
                activationCode: loc?.state?.activationCode,
                name: "Screen",
                selectedPlanId: selectedPlan?._id,
                companyId: finalCompanyId,
            });
        }
        navigate(`/canActivateDisplay?success=${activateScreenRes.success}&message=${activateScreenRes.message}`);
        setIsLoading(false);
    };

    return (
        <>
            <div className="container-fluid vh-100 d-flex digital-sign-login-container">
                <div
                    className="position-absolute w-100 h-100 digital-sign-login-background"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(10px)',
                        zIndex: -1,
                        position: 'relative',
                    }}
                ></div>
                <div className="d-flex justify-content-center align-items-center w-100 h-100 digital-sign-login-content">
                    <div className="col-auto d-flex flex-column justify-content-center align-items-start p-4 bg-white digital-sign-login-form" style={{ borderRadius: '5px' }}>
                        <div className="digital-sign-login-form">
                            {/* if code == 402 then showing add plan popUp */}
                            {code == 402 ? (
                                <DigitalSignChoosePlanForm userData={userData} onClose={() => { handleActivationScreen(); }} />
                            ) : loc?.state?.isChoosePlan ? (
                                <>
                                    <div>
                                        <label htmlFor="plan">Choose a plan:</label>
                                        {selectedPlan?.balanceScreens && <span className="float-right"> Balance Screens: {selectedPlan?.balanceScreens ?? 0} </span>}
                                        <DropDownList
                                            data={loc?.state?.plans}
                                            name="Plans"
                                            textField="plan.Name"
                                            dataItemKey="_id"
                                            value={selectedPlan}
                                            onChange={(e) => setSelectedPlan(e.target.value)}
                                        />
                                    </div>
                                    <ActionButton
                                        style={{ marginTop: '12px', marginLeft: '0px', borderRadius: '5px' }}
                                        name="Activate"
                                        title="Activate"
                                        isLoading={isLoading}
                                        onClick={handleActivationScreen}
                                    />
                                </>
                            ) : (
                                <>
                                    {success && <ConfettiExplosion zIndex={10005} force={0.8} duration={3000} particleCount={550} width={5000} />}
                                    <h5
                                        style={{
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            margin: '10px 0',
                                        }}
                                        className={success ? 'text-success' : 'text-danger'}
                                    >
                                        {success ? (
                                            <>
                                                Congratulations 🎉 <br />
                                                <p className="mt-1 font-weight-normal" style={{ fontSize: '1.1rem', color: 'grey' }}>
                                                    Your screen has been activated.
                                                </p>
                                            </>
                                        ) : (
                                            message
                                        )}
                                    </h5>
                                    {/* Add User Guide */}
                                    {success && (
                                        <div style={{ marginTop: '20px' }}>
                                            <h6 style={{ fontWeight: 'bold' }}>Let's take 1 minute to finish optimizing your display</h6>
                                            <ul>
                                                <li>
                                                    <strong>Enable the auto-start permission</strong>: <br /> Go to your player settings and allow automateB to start automatically on boot.
                                                </li>
                                                <li className='mt-2'>
                                                    <strong>Grant permission to display over other apps</strong>: <br /> Ensure automateB is enabled in the "Display Over Other Apps" settings.
                                                </li>
                                                <img src={require("../../../img/user-guide.png")} style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
                                            </ul>
                                            <button
                                                className="btn btn-success"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                }}
                                                onClick={() => navigate('/home/digitalsignDashboard')}
                                            >
                                                {"Go to home page"}
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
