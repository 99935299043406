/* eslint-disable */
import React from 'react'
import BossDialog from '../../../components/BossDialog'
import { useTranslation } from '../../../locale/useTranslation'
import { EditPageHeader } from '../../../components/EditPageHeader'
import { DropDownList } from '@progress/kendo-react-dropdowns'

const InstanceMobileEditForm = (props) => {
    const { title, onClose, handleSubmit, onChange, dataItem, storageDestinations, STORAGE_DESTINATION } = props
    const lang = useTranslation()
    const borderStyle = {
        border: "1px solid #ced4da",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        height:(dataItem.StorageDestination._id == STORAGE_DESTINATION.Cloudinary ? "33vh" : dataItem.StorageDestination._id == STORAGE_DESTINATION.BossCloudMedia ? "23vh" : "38vh")
    }

    return (
        <BossDialog
            title={title}
            onClose={onClose}
            width={"46vh"}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={onClose} showTitle={false} />
                    <div className="row mt-2" >
                        <div className="col-6">
                            <label>{lang.name_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="Name"
                                onChange={onChange}
                                value={dataItem.Name}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.description_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="Description"
                                onChange={onChange}
                                value={dataItem.Description}
                            />
                        </div>
                    </div>
                    <div className="row mt-2" >
                        <div className="col-6">
                            <label>{lang.db_name_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="DbName"
                                onChange={onChange}
                                value={dataItem.DbName}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.base_url_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="BaseUrl"
                                onChange={onChange}
                                value={dataItem.BaseUrl}
                            />
                        </div>
                    </div>
                    <div className="row mt-2 mb-2" >
                        <div className="col-6">
                            <label>{lang.socket_base_url_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="SocketBaseUrl"
                                onChange={onChange}
                                value={dataItem.SocketBaseUrl}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.digisign_base_url_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="DigiSignBaseUrl"
                                onChange={onChange}
                                value={dataItem.DigiSignBaseUrl}
                            />
                        </div>
                    </div>
                    <div className="row mt-2 mb-2" >
                        <div className="col-12">
                            <label>{lang.job_schedule_base_url_label} *</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="JobScheduleBaseUrl"
                                onChange={onChange}
                                value={dataItem.JobScheduleBaseUrl}
                            />
                        </div>

                    </div>
                    <span className="font-weight-bold  fs-1">{lang.file_label}</span>
                    <div className="border form-group p-2 with-3d-effect" style={borderStyle}>
                        <div className="row mt-1">
                            <div className="col-12">
                                <label>{lang.storage_destination_label} </label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={storageDestinations}
                                    name="StorageDestination"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.StorageDestination}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.quality_label} *</label>
                                <input
                                    type="number"
                                    name="Quality"
                                    className="form-control form-control-sm"
                                    value={dataItem.Quality}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6 mt-4">
                                <input
                                    type="checkbox"
                                    name="KeepOrg"
                                    value={dataItem.KeepOrg}
                                    checked={dataItem.KeepOrg}
                                    onChange={onChange}
                                />
                                <label className="mr-1 pl-1">
                                    {lang.keep_org_label}
                                </label>
                            </div>
                        </div>
                        {dataItem.StorageDestination._id == STORAGE_DESTINATION.Cloudinary &&
                            <div>
                                <div className="row mt-3" >
                                    <div className="col-6">
                                        <label>{lang.cloud_name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="cloud_name"
                                            onChange={onChange}
                                            value={dataItem.cloud_name}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>{lang.api_key_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="api_key"
                                            onChange={onChange}
                                            value={dataItem.api_key}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3" >
                                    <div className="col-6">
                                        <label>{lang.api_secret_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="api_secret"
                                            onChange={onChange}
                                            value={dataItem.api_secret}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>{lang.preset_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="preset"
                                            onChange={onChange}
                                            value={dataItem.preset}
                                        />
                                    </div>
                                </div>
                            </div>}
                        {dataItem.StorageDestination._id == STORAGE_DESTINATION.S3 &&
                            <div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label>{lang.access_key_id_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="accessKeyId"
                                            onChange={onChange}
                                            value={dataItem.accessKeyId}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>{lang.secret_access_key_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="secretAccessKey"
                                            onChange={onChange}
                                            value={dataItem.secretAccessKey}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label>{lang.region_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="region"
                                            onChange={onChange}
                                            value={dataItem.region}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>{lang.bucket_name_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="bucketName"
                                            onChange={onChange}
                                            value={dataItem.bucketName}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 mt-2">
                                        <label>{lang.cdn_url_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="CdnUrl"
                                            onChange={onChange}
                                            value={dataItem.CdnUrl}
                                        />
                                    </div>
                                </div>
                            </div>}
                        {dataItem.StorageDestination._id == STORAGE_DESTINATION.BossCloudMedia &&
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label>{lang.api_key_label} *</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="api_key"
                                        onChange={onChange}
                                        value={dataItem.api_key}
                                    />
                                </div>
                            </div>}
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <input
                                type="checkbox"
                                name="Archive"
                                value={dataItem.Archive}
                                checked={dataItem.Archive}
                                onChange={onChange}
                            />
                            <label className="mr-1 pl-1">
                                {lang.archive}
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </BossDialog>
    )
}

export default InstanceMobileEditForm
