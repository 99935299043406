/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import * as API_DIGITAL_SIGN from "../../../framework/API/api_digitalSign";
import { toast } from "react-toastify";
import SaveButton from "../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../framework/forms/helpercomponents/buttons/CancelButton";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import BossDialog from "../../../components/BossDialog";
import { ENTITYNAME, LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import DigiSignContentCollection from "../FileUpload/DigiSignContentCollection";

const DigitalSignMobileCollectionSelection = memo((props) => {

    const lang = useTranslation();
    const mode = props.mode ?? "multiple";
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [selectedState, setSelectedState] = useState({});
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [columns, setColumns] = useState(props.customColumns ?? []);
    const [searchText, setSearchText] = useState('');
    const [showUploadOption, setShowUploadOption] = useState(false);

    const page_take = props.page_take ?? 50;
    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    useEffect(() => {
        loadData(
            { current_page: 1, take: page_take },
            props.wherestatement,
            props.andWhereStatement,
            ""
        );
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText == null) return;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                loadData({ per_page: page.take, current_page: 1 }, props.wherestatement, props.andWhereStatement, searchText);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setPage({ skip: 0, take: page_take });
            loadData({ per_page: page_take, current_page: 1 }, props.wherestatement, props.andWhereStatement, searchText);
        }
    }, [searchText]);

    const loadData = async (pagination, wherestatement, andWhereStatement, searchText) => {
        setIsLoading(true);
        try {
            let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            let columns = await API_DIGITAL_SIGN.getFormData(props.entityname, currentUserId);
            if (props?.data?.length > 0) {
                setGridData(props?.data);
                setCount(props?.data?.length ?? 0);
                setColumns(props?.customColumns ?? columns);
                setIsLoading(false);
                return;
            }
            let json = {};
            if (props?.entityname == ENTITYNAME.DigitalSignContentGroup) {
                json = await API_DIGITAL_SIGN.getContentGroup({ page: pagination.current_page, perPage: pagination.per_page, query: wherestatement }, columns, searchText)
            } else {
                json = await API_DIGITAL_SIGN.getEntitiesWithSearch(props.entityname, { perPage: pagination.take, page: pagination.current_page, query: wherestatement }, columns, searchText);
            }
            console.log(json);
            setGridData(json.data);
            setCount(json?.pagination?.total ?? 0);
            setColumns(columns);
        } catch (error) {
            console.log("error", error);
        }
        setIsLoading(false);
    };

    const handleCheckboxChange = (id) => {
        setSelectedState((prevState) => {
            if (mode === "single") {
                return { [id]: true }; // Allow only one selection
            }
            const newState = { ...prevState, [id]: !prevState[id] };

            const allSelected = gridData.every((item) => newState[item._id]);
            setIsAllSelected(allSelected);

            return newState;
        });
    };

    const handleSelectAllChange = () => {
        setIsAllSelected((prev) => !prev); // Toggle select all state
        if (!isAllSelected) {
            const allSelected = gridData.reduce((acc, item) => {
                acc[item._id] = true;
                return acc;
            }, {});
            setSelectedState(allSelected);
        } else {
            setSelectedState({});
        }
    };

    const onSelection = () => {
        const selectedDataList = gridData.filter((item) => selectedState[item._id]);
        if (selectedDataList.length === 0) {
            toast.info(lang.please_select_first_collection_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        props.setDataList(selectedDataList);
        props.closeForm();
    };

    const onRefresh = () => {
        loadData(
            { current_page: 1, take: page_take },
            props.wherestatement,
            props.andWhereStatement,
            ""
        );
    }

    return (
        <BossDialog
            title={props.title ?? lang.add_dialog_header}
            onClose={props.closeForm}
            width={props.width ?? "95%"}
            height={props.height ?? "auto"}
        >
            <div className="row align-items-center mb-3" style={{ marginLeft: "-25px" }}>
                <div className="col-auto">
                    <SaveButton
                        title={props.addButtonTitle ?? lang.add_button_text}
                        style={{ backgroundColor: "green", borderRadius: "4px" }}
                        onClick={onSelection}
                    />
                    <CancelButton onClick={props.closeForm} />
                    {/* Add upload button to upload image or vider  */}
                    {props?.entityname == ENTITYNAME.DigitalSignContent && (
                        <ActionButton title="Upload" name="Upload" onClick={() => setShowUploadOption(true)} />
                    )}
                </div>
                <div className="col text-right mt-1">{`${lang.total_label} : ${count}`}</div>
            </div>

            <div className="mb-2 mt-2 d-flex">
                <input
                    type="text"
                    className="form-control shadow-inset-2 pt-0 pb-0"
                    id="searchInput"
                    placeholder={lang.search_button_text}
                    onChange={onSearch}
                    onFocus={(e) => {
                        if (props.setOutSideFocus) {
                            props.setOutSideFocus(true);
                        }
                    }}
                    onBlur={(e) => {
                        if (props.setOutSideFocus) {
                            props.setOutSideFocus(false);
                        }
                    }}
                />
                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                        </span>
                    </div>
                </button>
            </div>

            {mode === "multiple" &&
                <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <label style={{ fontSize: "15px" }}>{lang.select_all_label}</label>
                    <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                        style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer"
                        }}
                    />
                </div>
            }

            <div className="card-container mt-2" style={{ height: '50vh', overflowY: 'auto' }}>
                {isLoading ? <Loader height='50vh' /> :
                    gridData.length === 0 ? (
                        <div style={{ height: "50vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>{lang.no_record_found_error_message}</div>
                    ) : (
                        gridData.map((item) => {
                            let image = (item?.thumbnailUrl || item?.image || item?.url) ?? null;
                            let title = (item?.fileName || item?.Name || item?.title || item?.text || item?.plan?.Name) ?? '';
                            let description = (item?.overlayType?.description || item?.Description || item?.description || item?.format || item?.balanceScreens) ?? '';
                            let label1 = (item?.bytes && utility.bytesToSize(item?.bytes)) ?? '';
                            return (
                                <div
                                    key={item._id}
                                    onClick={() => handleCheckboxChange(item._id)}
                                    className="mb-3 mt-2"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        overflow: "hidden",
                                        padding: "10px",
                                        borderRadius: "10px",
                                        border: selectedState[item._id] ? "2px solid #4caf50" : "1px solid #ccc",
                                        boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {image && (
                                            <img
                                                src={image}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://media.comicbook.com/files/img/default-movie.png";
                                                }}
                                                style={{
                                                    width: "70px",
                                                    height: "80px",
                                                    objectFit: "cover",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                        )}
                                        <div className="ml-3">
                                            <h3 style={{ margin: "0", fontSize: "16px" }}>{utility.subString(title, 18)}</h3>
                                            <p style={{ margin: "5px 0 0", color: "#555" }}>{description}</p>
                                            <p style={{ margin: "5px 0 0", color: "#555" }}>{label1}</p>
                                        </div>
                                    </div>
                                    <input
                                        type="checkbox"
                                        checked={selectedState[item._id] || false}
                                        readOnly
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            marginLeft: "10px" 
                                        }}
                                    />
                                </div>
                            );
                        })
                    )
                }
            </div>
            {showUploadOption && <DigiSignContentCollection onRefresh={onRefresh} isUploadContent={true} onClose={() => setShowUploadOption(false)} />}
        </BossDialog>
    );
});

export default DigitalSignMobileCollectionSelection;
