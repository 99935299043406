import React, { useEffect, useRef, useState } from 'react';
import { getMyPlansWithAllPlans } from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { DIGITALSIGN_TRANSACTION_STATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import { getData } from '../../framework/API/api';
import moment from 'moment';
import * as API from '../../framework/API/api_digitalSign'
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';
import { DigitalSignChoosePlanForm } from '../DigitalSign/plan/DigitalSignChoosePlanForm';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import DigitalSignMobileMyPlanPopup from '../DigitalSign/mobileView/DigitalSignMobileMyPlanPopup';
import { useLocation } from 'react-router-dom';
import { OverScreenPopup } from '../../framework/forms/helpercomponents/alert/OverScreenPopup';

const MyPlansPopup = ({ onClose }) => {

    const loc = useLocation();
    console.log(loc);
    const [loading, setLoading] = useState(false);
    const [myPlans, setMyPlans] = useState([]);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [showPurchasePlan, setShowPurchasePlan] = useState(false);
    const [userData, setUserData] = useState({});
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);

    const lang = useTranslation();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateScreenType = () => {
            const isPortrait = window.innerHeight > window.innerWidth;
            setIsMobile(isPortrait);
        };
        updateScreenType();
        window.addEventListener('resize', updateScreenType);
        return () => window.removeEventListener('resize', updateScreenType);
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        setUserData(userData);
        let res = await getMyPlansWithAllPlans();
        if (!res.success) {
            toast.error(res.message);
        } else {
            setMyPlans(res.data?.myPlans ?? []);
        }
        setLoading(false);
    }

    const showTransactionHistoryPopup = async () => {
        setShowTransactionHistory(true);
        let res = await getData(ENTITYNAME.DigitalSignTransactionHistory, { query: ["userID", "=", userData._id] });
        if (res.success) {
            setTransactionHistoryData(res.data);
        } else {
            setShowTransactionHistory(false);
            toast.error(res.message);
        }
    }

    const downloadInvoice = async (trHistory) => {
        setShowLoadingPopup(true);
        let res = await API.paymentInvoice(trHistory);
        console.log(res);
        if (res.success) {
            let url = FILEURL.BASEURL + 'downloadReport/' + res.data;
            setTimeout(() => {
                window.open(url, "_blank");
                setShowLoadingPopup(false);
                toast.success(lang.successfully_download_success_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }, 6000);
        }
        else {
            setShowLoadingPopup(false);
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    return (
        <>
            {isMobile ? <DigitalSignMobileMyPlanPopup userData={userData} myPlans={myPlans} onClose={onClose} showTransactionHistoryPopup={showTransactionHistoryPopup} setShowPurchasePlan={setShowPurchasePlan} showTransactionHistory={showTransactionHistory} setShowTransactionHistory={setShowTransactionHistory} loading={loading} Loader={Loader} showPurchasePlan={showPurchasePlan} loadData={loadData} transactionHistoryData={transactionHistoryData} DIGITALSIGN_TRANSACTION_STATUS={DIGITALSIGN_TRANSACTION_STATUS} /> :
                <BossDialog
                    onClose={onClose}
                    showHeader={true}
                    style={{ background: "transparent" }}
                    width={showTransactionHistory ? "950px" : showPurchasePlan ? "800px" : "650px"}

                >
                    {
                        loading ?
                            <Loader height="53vh" />
                            : showPurchasePlan ?
                                <DigitalSignChoosePlanForm userData={userData} onClose={() => { setShowPurchasePlan(false); loadData(); }} />
                                : showTransactionHistory ?
                                    <div>
                                        <div className='d-flex'>
                                            <div className='mr-auto mt-3 '><SaveButton tooltip="OK" title="OK" onClick={() => { setShowTransactionHistory(false) }} style={{ borderRadius: "5px", borderColor: "white" }} /></div>
                                            <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>Transaction History</h1>
                                        </div>
                                        <div className='mt-4' style={{ height: "350px", overflow: "scroll" }}>
                                            <table className="table border" style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <thead className='border'>
                                                    <tr>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}></th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Date</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Order ID</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Plan</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Screens</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Storage</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Tenure(Days)</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Status</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='border'>
                                                    {transactionHistoryData.map(trHistory => <tr key={trHistory._id}>
                                                        <td className='border'>{trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Confirmed && <i className='fa fa-download cursor-pointer' onClick={() => downloadInvoice(trHistory)}></i>}</td>
                                                        <td className='border' style={{ width: "100px" }}>{moment(trHistory.orderCreatedOn).format("ll")}</td>
                                                        <td className='border'>{trHistory.orderID.replace("order_", "")}</td>
                                                        <td className='border' style={{ width: "200px" }}>{trHistory.plan.Name}</td>
                                                        <td className='border'>{trHistory.plan.screens}</td>
                                                        <td className='border'>{trHistory.plan.storage}</td>
                                                        <td className='border'>{trHistory.plan.Tenure}</td>
                                                        <td className='border' style={{ textAlign: "center", color: "white" }}><div style={{ backgroundColor: trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Cancelled ? "#f4511e" : trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Pending ? "#deb633" : "#03a782", display: "inline-block", padding: "2px 8px", borderRadius: "5px", borderColor: "white", fontSize: "12px" }}>{Object.keys(DIGITALSIGN_TRANSACTION_STATUS)[Object.values(DIGITALSIGN_TRANSACTION_STATUS).indexOf(trHistory.status)]}</div></td>
                                                        <td className='border' style={{ width: "100px" }}>{trHistory.currency + " " + trHistory.amount}</td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                        <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>My Plan</h1>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div>
                                                <span style={{ fontWeight: "bold", paddingTop: "5px", fontSize: "18px", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.name ?? "")}</span>
                                                <span style={{ fontWeight: "bold", paddingTop: 0, marginTop: 0, fontSize: "14px", color: "grey", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.Company?.Name ?? "")}</span>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }} className='text-success'> {parseInt(myPlans.reduce((acc, plan) => acc + plan.plan.screens, 0)) - parseInt(myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0))}</span>
                                                    <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Active  Screens</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }}> {myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0)}</span>
                                                    <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Balance  Screens</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-4' style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}>
                                            <table className="table" style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Plan</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Total Screens</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Screens Left</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Storage</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Status</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Price</th>
                                                        <th style={{ border: "1px solid #ddd", whiteSpace: "nowrap" }}>Tenure</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {myPlans.map(plan => (
                                                        <tr key={plan._id}>
                                                            <td style={{ border: "1px solid #ddd" }}>{plan.plan.Name}</td>
                                                            <td style={{ border: "1px solid #ddd" }}>{plan.plan.screens}</td>
                                                            <td style={{ border: "1px solid #ddd" }}>{plan.balanceScreens}</td>
                                                            <td style={{ border: "1px solid #ddd" }}>{plan.plan.storage}MB</td>
                                                            <td
                                                                style={{ border: "1px solid #ddd" }}
                                                                className={`${plan.balanceScreens > 0 ? "text-success" : "text-danger"}`}
                                                            >
                                                                {plan.balanceScreens > 0 ? "Active" : "Inactive"}
                                                            </td>
                                                            <td style={{ border: "1px solid #ddd" }}>
                                                                {userData?.Company?.SignUpData?.currency ?? 'INR' + " " + plan?.plan?.Price}
                                                            </td>
                                                            <td style={{ border: "1px solid #ddd" }}>{plan.plan.Tenure}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                            <CancelButton onClick={onClose} style={{ borderColor: "white", marginLeft: 0, }} />
                                            <SaveButton tooltip="Transaction History" title="Transaction History" onClick={showTransactionHistoryPopup} style={{ borderRadius: "5px", backgroundColor: "#03a782", borderColor: "white" }} />
                                            <SaveButton tooltip="Purchase Plan" title="Purchase Plan" onClick={() => {
                                                setShowPurchasePlan(true);
                                            }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                        </div>
                                    </div>}
                </BossDialog>
            }
            {showLoadingPopup && <OverScreenPopup message="Preparing Receipt..." />}
        </>
    );
}


export default MyPlansPopup;
