/* eslint-disable */
import { Splitter } from '@progress/kendo-react-layout'
import { useState } from "react";
import React from 'react'
import { TournamentList } from './components/touramentList';
import TournamentMatchList from './components/tournamentMatchList';
import * as API from '../../framework/API/api'
import LiveEventFooter from './components/LiveEventFooter';

export const LiveEventsMain = () => {

  const [horizontalPanes, setHorizontalPanes] = useState([
    {
        size: "25%",
        collapsible: true
    },
    {},

]);
const [selectedItem, setSelectedItem] = useState({});
const [selectedTournamentMatches,setSelectedTournamentMatches] = useState([]);
const [isMatchLoading,setIsMatchLoading] = useState(false);

const [statusMessage,setStatusMesssage] = useState('');

const onHorizontalSplitterChange = (event) => {
  setHorizontalPanes(event.newState);
};

const onSelection = async(item)=>{

  setIsMatchLoading(true);
  setSelectedItem(item);
  
  //call API on selection
  const tournamentMatchListData = await API.getLiveEventsTournamentMatches(item._id,{
      FromDate: item.StartDate,
      ToDate: item.EndDate,
      Type: { SID: 0, Description: "All" },
      Round: { SID: 0, Description: "All" },
      Publish: "All",
  });
  console.log(tournamentMatchListData.data);
  if(tournamentMatchListData.success){
    setSelectedTournamentMatches(tournamentMatchListData.data);
    setIsMatchLoading(false);
  }
}

  return (
    <>
    <Splitter
        panes={horizontalPanes}
        onChange={onHorizontalSplitterChange}
        style={{ height: '94%' }}>
        <TournamentList onSelection={onSelection} setStatusMesssage={setStatusMesssage}/>
        <TournamentMatchList  selectedTournamentMatches={selectedTournamentMatches} selectedTournament = {selectedItem} isMatchLoading={isMatchLoading} setStatusMesssage={setStatusMesssage}/>
    </Splitter>
    <LiveEventFooter statusMessage = {statusMessage}/>
    </>
    
  )
}
