/* eslint-disable */
import React, { useState } from 'react';
import BossDialog from '../../../components/BossDialog';
import { utility } from '../../../framework/utility/utilityProvider';
import MyStatusCell from '../../../components/MyStatusCell';
import MobileGridCard from './MobileGridCard';
import CancelButton from '../../../framework/forms/helpercomponents/buttons/CancelButton';
import SaveButton from '../../../framework/forms/helpercomponents/buttons/SaveButton';
import { DigitalSignChoosePlanForm } from '../plan/DigitalSignChoosePlanForm';
import { useTranslation } from '../../../locale/useTranslation';


const DigitalSignMobileMyPlanPopup = (props) => {
    const lang = useTranslation()
    const { onClose, userData, myPlans, showTransactionHistoryPopup, setShowPurchasePlan, loading, Loader, showPurchasePlan, loadData, transactionHistoryData, DIGITALSIGN_TRANSACTION_STATUS } = props;

    const [showTransactionHistory, setShowHistory] = useState(false);

    const activeScreens = parseInt(myPlans.reduce((acc, plan) => acc + plan?.plan?.screens, 0)) - parseInt(myPlans.reduce((acc, plan) => acc + plan?.balanceScreens, 0));

    const balanceScreens = myPlans.reduce((acc, plan) => acc + plan?.balanceScreens, 0);

    const handleShowTransactionHistory = () => {
        setShowHistory(true);
        showTransactionHistoryPopup();
    };

    const handleCloseTransactionHistory = () => {
        setShowHistory(false);
    };

    return (
        <>
            <BossDialog
                onClose={onClose}
                title={showTransactionHistory ? "Transaction History" : showPurchasePlan ? "Purchase Plan" : "My Plan"}
                width={'90vw'}
                style={{ background: "transparent" }}
                padding={"15px"}
            >
                {
                    loading ?
                        <Loader height="53vh" />
                        : showPurchasePlan ?
                            <DigitalSignChoosePlanForm userData={userData} onClose={() => { setShowPurchasePlan(false); loadData(); }} />
                            : showTransactionHistory ?
                                <div>
                                    <div className='mt-2' style={{ height: "70vh", overflowY: "auto" }}>
                                        {myPlans.length == 0 ?
                                            <div className='border' style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                                                {lang.no_record_found_error_message}
                                            </div> :
                                            transactionHistoryData.map((item, index) => {
                                                let tileBackColor = item.status == DIGITALSIGN_TRANSACTION_STATUS.Cancelled ? "#f4511e" : item.status == DIGITALSIGN_TRANSACTION_STATUS.Pending ? "#deb633" : "#03a782";
                                                let status = Object.keys(DIGITALSIGN_TRANSACTION_STATUS)[Object.values(DIGITALSIGN_TRANSACTION_STATUS).indexOf(item.status)];

                                                return (
                                                    <MobileGridCard
                                                        key={index}
                                                        title={item.plan.Name}
                                                        label1={`Screens : ${item.plan.screens}`}
                                                        label2={`Price : ${item.currency + " " + item.amount}`}
                                                        description={`Tenure : ${item.plan.Tenure}`}
                                                        topRight={<MyStatusCell title={status} tileBackColor={tileBackColor} />}
                                                        bottomRight={
                                                            <p className='m-0 p-0' style={{ fontSize: '12px', color: "gray" }}>Storage: {utility.bytesToSize(item.plan.storage * 1024 * 1024)}</p>
                                                        }
                                                        height="110px"
                                                    />
                                                );
                                            })
                                        }
                                    </div>

                                    <div style={{ float: 'right', marginTop: '10px' }}>
                                        <SaveButton tooltip="OK" title="OK" onClick={handleCloseTransactionHistory} style={{ borderRadius: "5px", borderColor: "white" }} />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center" }}>
                                            <div style={{ marginBottom: "20px", marginLeft: "12px" }}>
                                                <span style={{ fontWeight: "bold", fontSize: "18px", display: "block", marginBottom: "4px", }}>
                                                    {utility.convertFirstLetterToUpperCase(userData?.name ?? "")}</span>
                                                <span style={{ fontWeight: "bold", fontSize: "14px", color: "grey" }}>
                                                    {utility.convertFirstLetterToUpperCase(userData?.Company?.Name ?? "")}
                                                </span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-around", width: "100%", marginLeft: "35px" }}>
                                                <div> <span style={{ fontSize: "28px", fontWeight: "bold", color: "green", display: "block" }}>{activeScreens} </span>
                                                    <span style={{ fontSize: "12px", color: "grey", fontWeight: "bold" }}> Active Screens </span>
                                                </div>
                                                <div> <span style={{ fontSize: "28px", fontWeight: "bold", display: "block" }}> {balanceScreens}</span>
                                                    <span style={{ fontSize: "12px", color: "grey", fontWeight: "bold" }}>Balance Screens</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-2' style={{ height: "48vh", overflowY: "auto" }}>
                                        {myPlans.length == 0 ?
                                            <div className='border' style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>
                                                {lang.no_record_found_error_message}
                                            </div> :
                                            myPlans.map((item, index) => {
                                                let tileBackColor = item.balanceScreens > 0 ? "#63aa43" : "#E72929";
                                                let status = item.balanceScreens > 0 ? "Active" : "Consume";

                                                return (
                                                    <MobileGridCard
                                                        key={index}
                                                        title={item.plan.Name}
                                                        label1={`Total Screens : ${item.plan.screens}`}
                                                        label2={`Screens Left : ${item.balanceScreens}`}
                                                        description={`Tenure : ${item.plan.Tenure}`}
                                                        topRight={<MyStatusCell title={status} tileBackColor={tileBackColor} style={{ marginTop: "3px" }} />}
                                                        bottomRight={
                                                            <p className='m-0 p-0' style={{ fontSize: '12px', color: "gray" }}>Storage: {utility.bytesToSize(item.plan.storage * 1024 * 1024)}</p>
                                                        }
                                                        height="110px"
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                    <div class="d-flex mr-auto justify-content-between">
                                        <button type="button" class="btn btn-success rounded" onClick={handleShowTransactionHistory}>Transaction History</button>
                                        <button type="button" class="btn btn-primary rounded" onClick={() => { setShowPurchasePlan(true) }}>Purchase Plan</button>
                                    </div>
                                    <div className=''>
                                        <button type="button" class="btn btn-danger rounded mt-2" onClick={onClose} style={{ width: "81vw" }}>Cancel</button>
                                    </div>
                                </div>
                }
            </BossDialog>
        </>
    );
};

export default DigitalSignMobileMyPlanPopup;

