/* eslint-disable */
import React from 'react'
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { utility } from '../../../framework/utility/utilityProvider';
import { PLAYLIST_STATUS } from '../../../framework/constant/constant';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';

export const DigitalSignMobilePlaylistHeader = (props) => {
    const { fileMenu, nameRef, name, setName, status, playlistData, onPlaylistAction, onPlaylistRefresh, onPlaylistNameChange, handlePlaylistRefresh, isPlayStopLoading, setIsPlayStopLoading, showPlayingOnce, showPlayingLoop, showStop, showPublishUnPublish, publish } = props;
    const menuItems = fileMenu.slice(1, fileMenu.length)
    const navigate = useNavigate();
    return (
        <>
            <div className='mb-4 mt-2' style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="page-edit-title txt-color-blueDark" style={{ margin: '0px 0px 0px 0px', fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BackRoundButton onClick={() => navigate("/home/Playlist")}></BackRoundButton>
                        <i className="fa-regular fa-calendar-days ml-1" style={{ margin: '0px 5px 0px 0px' }}></i>
                        <input id="dynamicWidthInput" ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                nameRef.current.blur();
                            }
                        }} onBlur={() => { onPlaylistNameChange(name); }} style={{ flex: '1 1 auto' }} />

                        <div id="textWidthTester" style={{ position: "absolute", visibility: "hidden", height: 0, overflow: "hidden", whiteSpace: "nowrap" }}></div>
                    </div>
                    <div className="badge text-success mr-3 pt-2 pb-2 pl-0" style={{ fontSize: '13px', fontWeight: "bold", whiteSpace: 'nowrap' }}>
                        - {(utility.convertFirstLetterToUpperCase(utility.getKeyByValue(PLAYLIST_STATUS, status)))}
                    </div>
                </h3>
            </div>

            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", position: 'absolute', right: 0, fontSize: '20px' , marginTop: '-5px' }}>
                <i className={`cursor-pointer fa fa-${isPlayStopLoading ? 'spinner ' : (showPlayingOnce || showPlayingLoop) ? 'play' : showStop ? 'stop' : (showPublishUnPublish && publish) ? 'download' : 'upload'} ${isPlayStopLoading ? ' fa-spin' : ''}`} style={{ marginTop: '12px', pointerEvents: isPlayStopLoading ? "none" : "auto" }} aria-hidden="true" onClick={
                    async () => {
                        setIsPlayStopLoading(true);
                        if (showPlayingOnce) {
                            await onPlaylistAction(playlistData, PLAYLIST_STATUS.PlayingOnce);
                        } else if (showPlayingLoop) {
                            await onPlaylistAction(playlistData, PLAYLIST_STATUS.Playing);
                        } else if (showStop) {
                            await onPlaylistAction(playlistData, PLAYLIST_STATUS.Standby);
                        } else if (showPublishUnPublish) {
                            await onPlaylistAction(playlistData, publish ? PLAYLIST_STATUS.UnPublished : PLAYLIST_STATUS.Published);
                        }
                        setIsPlayStopLoading(false);
                    }
                }
                    title={showPlayingOnce ? 'Play Once' : showPlayingLoop ? 'Play' : showStop ? 'Stop' : publish ? 'Un-Publish' : 'Publish'}
                />
                <i className="ml-3 mr-2 fa fa-repeat cursor-pointer" style={{ marginTop: '12px' }} title="Refresh Screen" aria-hidden="true" onClick={() => {
                    onPlaylistRefresh();
                    handlePlaylistRefresh(playlistData)
                }}></i>
                <Menubar style={{ height: "40px", background: "transparent", border: "none", padding: '8px 8px 8px 0px', marginTop: '2px' }} model={menuItems} />
            </div>
        </>
    )
}
