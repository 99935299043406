/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import * as API from "../../framework/API/api"
import { ENTITYNAME, IMDBTYPES } from "../../framework/constant/constant";
import { getter } from "@progress/kendo-data-query";
import CustomPaginationDropDown from "../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import BossDialog from "../../components/BossDialog";
import { utility } from "../../framework/utility/utilityProvider";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { toast } from "react-toastify";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";

const MONTHS = {
    0: 'Jan',
    1: "Feb",
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
}

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const SportsEventEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        SearchName: "",
        TournamentType : {},
    }

    const getDataWithIMDBId = async (imdbId) => {
        let res = await API.getIMDBDetails(imdbId);
        if (res.success) {
            setGridData([res.data]);
            setDataItem({ ...dataItem, SearchName: res.data.Title });
            setSelectedItemDetails(res.data);
            setSelectedState({ [imdbId]: true });
        }
    }

    useEffect(() => {
        loadCombo();
        // if (props?.IMDbID) {
        //     getDataWithIMDBId(props?.IMDbID);
        // }
    }, [props?.IMDbID]);

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [selectedStateMatch, setSelectedStateMatch] = useState({});

    const [selectedItemDetails, setSelectedItemDetails] = useState([]);
    const initialDataState = {
        skip: 0,
        take: 25,
    };
    const [page, setPage] = useState(initialDataState);
    const [total, setTotal] = useState(1);
    const [tournamentType, setTournamentType] = useState([]);
    const [matchType, setMatchType] = useState([]);

    const loadCombo = async () => {
        let tournamentType = await API.getDataLookup(ENTITYNAME.TournamentType, { sort: { Description: 1 } });
        setTournamentType(tournamentType.data);
        let matchType = await API.getDataLookup(ENTITYNAME.MatchType, { sort: { Description: 1 } });
        setMatchType(matchType.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSearch = async () => {

        // if (!dataItem.SearchName || dataItem.SearchName == "") {
        //     toast.error(lang.please_enter_search_name_error_message);
        //     return;
        // }

        // if (!dataItem.TournamentType || Object.keys(dataItem.TournamentType).length == 0) {
        //     toast.error(lang.please_select_type_error_message);
        //     return;
        // }
        // if (!dataItem.MatchType || Object.keys(dataItem.MatchType).length == 0) {
        //     toast.error(lang.please_select_type_error_message);
        //     return;
        // }
        let payload = {
            FromDate: new Date(dataItem.FromDate).getTime(),
            ToDate: new Date(dataItem.ToDate).getTime(),
            TournamentType: dataItem.TournamentType?.Description,
            MediaType: dataItem.MediaType?.Description,
            Page: (page.skip / page.take),
        };

        let res = await API.getSportsEventTournaments(payload);
        console.log(res);
        if (res.success) {
            setPage(initialDataState);
            setGridData(res.data);
            setTotal(res.pagination?.total);
            setSelectedState({});
            setSelectedItemDetails({});
        } else {
            toast.error(res.message);
        }
    }

    const onSelectionChange = useCallback(
        async (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            console.log(gridData.find((item) => newSelectedState[item.id]))
            let tournamentId = gridData.find((item) => newSelectedState[item.id])?.id;
            let res = await API.getSportsEventTournamentMatches(tournamentId);
            console.log(res);
            if (res.success) {
                setSelectedItemDetails(res.data);
            }
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onMatchSelectionChange = useCallback(
        async (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedStateMatch(newSelectedState);
        },
        [selectedStateMatch]
    );

    const pageChange = async (e) => {

        let payload = {
            FromDate: new Date(dataItem.FromDate).getTime(),
            ToDate: new Date(dataItem.ToDate).getTime(),
            TournamentType: dataItem.TournamentType?.Description,
            MediaType: dataItem.MediaType?.Description,
            Page: (e.page.skip / e.page.take),
        }
        let res = await API.getSportsEventTournaments(payload);
        if (res.success) {
            setGridData(res.data);
            setSelectedState({});
            setSelectedItemDetails({});
            setPage(e.page);
        } else {
            toast.error(res.message);
        }
    };

    const handleSubmit = async () => {
        if (gridData.filter((item) => selectedState[idGetter(item)]).length == 0) {
            toast.error(lang.please_select_item_first_error_message);
            return;
        }
        props.sportsEventList(selectedItemDetails);
        props.cancelEdit();
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    };

    const changeEndDateFormatToStartDateFormat = (startDate,endDate) => {
        
        const startYear = parseInt(startDate.split("-")[0], 10);
        const startMonth = parseInt(startDate.split("-")[1], 10);
        
        let [month, day] = endDate.split(" ");
        day = day.padStart(2, "0");

        const endMonth = parseInt(utility.getKeyByValue(MONTHS,month), 10);
        
        const endYear = endMonth+1 < startMonth ? startYear + 1 : startYear;
        
        endDate = `${endYear}-${endMonth+1}-${day}`;
        
        return endDate
        
    }

    const onIngest = (data) => {

        let tournament = {
            Name: data.name,
            StartDate: utility.convertStringDatetoMilliseconds(data.startDate),
            EndDate: utility.convertStringDatetoMilliseconds(changeEndDateFormatToStartDateFormat(data.startDate,data.endDate)),
            // Channels: [
            //     {
            //         "_id": "64392f23ff126e61cfe5ebd5",
            //         "SID": NumberInt(2),
            //         "FullChannelName": "BMS Channel"
            //     }
            // ],
            TournamentID: data.id,
            TournamentType: dataItem.TournamentType,
            isFromSportsAPI : true,
            fetchAllMatches : true,
            PosterUrl: "",
            Archive: false
        }

        props.sportsTournamentList(tournament);
        props.cancelEdit()

    }

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
          <RoundButton icon={"save"} name={"Ingest Tournament"} title={"Ingest Tournament"} onClick={() =>onIngest(props.dataItem)} />
        </div>
    )


    return (
        <BossDialog
            title={lang.sports_event_label}
            onClose={props.cancelEdit}
            width={"70%"}
        >
            <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={props.cancelEdit} />

            <div className="row mt-2">
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.from_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} max={dataItem.ToDate} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.to_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                    </div>
                </div>
                <div className="col-2">
                    <label>{lang.tournament_type_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={tournamentType}
                        name="TournamentType"
                        textField="Description"
                        dataItemKey="SID"
                        value={dataItem.TournamentType}
                        onChange={onChange}
                    // disabled={props?.mediaCategory ? true : false}
                    />
                </div>
                <div className="col-2">
                    <label>{lang.match_type_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={matchType}
                        name="MatchType"
                        textField="Description"
                        dataItemKey="SID"
                        value={dataItem.MatchType}
                        onChange={onChange}
                    // disabled={props?.mediaCategory ? true : false}
                    />
                </div>

                <div className="col" style={{ margin: '20px 0px 0px 0px' }}>
                    <RoundButton icon={"search"} name={lang.search_button_text} title={lang.search_button_tooltip} onClick={onSearch} />
                </div>
                <div className="col-3">
                    <label>{lang.search_button_text}</label>
                    <div className="d-flex">
                        <input
                            type="text"
                            className="form-control form-control-sm shadow-inset-2"
                            id="searchInput"
                            name="SearchName" value={dataItem.SearchName}
                            onChange={(e) => setDataItem({ ...dataItem, SearchName: e.target.value })}
                            onKeyDown={handleKeyDown}
                            placeholder={lang.search_name_label}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "30px" }} >
                            <div className="input-group-append" style={{ height: '32px' }}>
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row mt-3 imdb">
                <div className={(gridData?.filter((item) => selectedState[idGetter(item)]).length != 0) ? "col-5" : "col-12"}>
                    <Grid
                        data={gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "single",
                        }}
                        pageable={true}
                        skip={page.skip}
                        take={page.take}
                        total={total}
                        onPageChange={pageChange}
                        onSelectionChange={onSelectionChange}
                        resizable={true}
                        style={{ height: "480px" }}
                        pager={(props) => <CustomPaginationDropDown {...props} />}

                    >
                        <GridColumn cell={MyCommandCell} width={"40px"} locked={true} />
                        <GridColumn field="name" title={lang.name_column} />
                        <GridColumn field="startDate" title={lang.start_date_column} />
                        <GridColumn field="endDate" title={lang.end_date_column} />
                        {/* <GridColumn field="imdbID" title={lang.imdb_id_column} /> */}
                        {/* <GridColumn field="matchType" title={lang.match_type_label} /> */}
                    </Grid>
                </div>
                {(gridData.filter((item) => selectedState[idGetter(item)]).length != 0) && <div className="col-7 border">
                    <div className="flex-container">
                        <div className="martb">
                            <ActionButton icon={"save"} name={lang.ingest_button_text} title={lang.ingest_button_tooltip} onClick={() => { }} />
                        </div>

                        <div className="flex-container-reverse flex-item-auto">

                            <div className="mt-1" style={{ paddingTop: "12px" }}>
                                <RoundButton
                                    icon={"xmark"}
                                    // btnColor={"warning"}
                                    // style={{ margin: "0px 03px" }}
                                    // iconColor={"white"}
                                    title={lang.cancel_button_text}
                                    onClick={() => { setSelectedState({}); setSelectedItemDetails({}) }}
                                />
                            </div>


                        </div>
                    </div>
                    <div className="row mt-2">
                        <Grid
                            data={selectedItemDetails.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            // data={selectedItemDetails}
                            
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "single",
                        }}
                        onSelectionChange={onMatchSelectionChange}
                        resizable={true}
                        style={{ height: "43vh" }}

                        >
                            <GridColumn field="teamA.img" title={lang.team_a_label} width={80} cell={ImageCell} />
                            <GridColumn field="teamB.img" title={lang.team_b_label} width={80} cell={ImageCell} />
                            <GridColumn field="teamA.name" title={"Team A"} />
                            <GridColumn field="teamB.name" title={"Team B"} />
                            <GridColumn field="dateTimeGMT" title={lang.start_date_column} />
                            <GridColumn field="matchType" title={lang.match_type_label} />
                            <GridColumn field="venue" title={lang.venue_label} />
                        </Grid>

                    </div>
                </div>}
            </div>

        </BossDialog >
    )
}

export default SportsEventEditForm;