/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../../framework/utility/utilityProvider';
import { Popup } from "@progress/kendo-react-popup";
import { useTranslation } from '../../../locale/useTranslation';

const MobileHeader = (props) => {

    const { logo, userMenu, user = {}, onLogout, onClickProfile, show, setShow, isEnterpriseLogin, showMyPlansPopup, setShowChangePassword, version, handleAddToHomeScreen, isAppInstalled = false } = props;
    const navigate = useNavigate();
    const anchor = useRef(null);
    const lang = useTranslation();

    const handleNavigation = (link) => {
        navigate(link);
        const navbar = document.getElementById('navbarSupportedContent');
        if (navbar) {
            navbar.classList.remove('show');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor.current && !anchor.current.contains(event.target)) {
                setShow(false);
            }
        };
        // Attach the event listener to the body
        document.body.addEventListener('click', handleClickOutside);
        // Clean up the event listener on component unmount
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [show]);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#3a3633" }}>
            <div className="container-fluid" style={{ padding: '5px' }}>
                <img style={{ width: '40%' }} src={logo} alt="Logo" onClick={() => navigate(utility.getHomePath())} className="navbar-brand" />
                {/* Add profile view */}
                <div className='d-flex justify-content-end flex-grow-1 mr-1'>
                    {/* Add to Home Screen */}
                    {!isAppInstalled && (<button title='Add to Home screen' className="btn btn-circle btn-md text-uppercase mr-2"
                        style={{
                            backgroundColor: 'white',
                            width: '25px',
                            height: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={handleAddToHomeScreen}>
                        <i className="fa fa-mobile" style={{ color: 'black', fontSize: '18px', fontWeight: '600' }}></i>
                    </button>)}

                    <button className="btn btn-circle btn-md text-uppercase" onClick={onClickProfile} ref={anchor} title="Profile" style={{
                        backgroundColor: 'white',
                        fontWeight: '600',
                        width: '25px',
                        height: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px'
                    }}>{user?.name?.charAt(0)}</button>
                    <Popup anchor={anchor.current} show={show} popupClass={"popup-content"} style={{ width: '70vw', marginTop: '15px' }}>
                        <div className="row m-1 mb-4">
                            <div className="col-12">
                                <div className="d-flex mt-1">
                                    <span>Version - {version?.Version ?? ""}</span>
                                    {utility.isAutomateB() && <a className="ml-auto" href="https://automateb.com/privacy-policy/" target="_blank">{lang.privacy_policy_label}</a>}
                                </div>

                                <h3 style={{ fontWeight: '900' }}>{lang.account_label}</h3>
                                <div className="row">
                                    <div className="col-1"><b className="btn btn-circle btn-md bg-success mt-2 text-uppercase" style={{ color: 'white', fontWeight: '600' }}>{user?.name?.charAt(0)}</b></div>
                                    <div className="col ml-2">
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{utility.subString(user?.name, 25)}</span>
                                        <div>{utility.subString(user?.userName, 22)}</div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { setShowChangePassword(true); setShow(false) }}>{lang.change_password_label}</div>
                                    <hr />
                                    {(utility.isAutomateB() && !isEnterpriseLogin) && <><div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { showMyPlansPopup(); setShow(false) }}>My Plans</div><hr /></>}

                                    <div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { onLogout(); setShow(false) }}>{lang.log_out_label}</div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fa fa-bars" style={{ color: 'white' }} aria-hidden="true"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {userMenu?.length > 0 ? (
                            userMenu.map((item) => (
                                <li className="nav-item" key={item.modulesid}>
                                    <a
                                        className="nav-link"
                                        aria-current="page"
                                        onClick={() => handleNavigation(item.link)}
                                        style={{ cursor: 'pointer', color: 'white' }}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))
                        ) : (
                            <li className="nav-item">
                                <span className="nav-link">No menu items available</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default MobileHeader;
