/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import BossDialog from "../../components/BossDialog";

const ContentCategoryEditForm = (props) => {

    const blankDataItem = {
        ...props.item,
        SID: props.item.SID ?? 0,
        Name: props.item.copy ? "copy of " + props.item.Name : props.item.Name ?? "",
        Language: props?.item?.Language ?? {},
        Archive: props?.item?.Archive ?? false
    };

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState({});
    const [languages, setLanguages] = useState([]);
    const lang = useTranslation();

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == "parent") {
            setParent(e.target.value);
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        loadData();
        loadCombo();
    }, [])

    const loadData = async () => {

        var parentRes = await API.getDataLookup(ENTITYNAME.ContentCategory);
        if (parentRes.success) {
            const nullItem = { _id: null, SID: Math.random(), Archive: false, Name: "null", parent: null };
            setParents([nullItem, ...parentRes.data]);
            if (props.item.copy || props.item.SID > 0) {
                setParent(props.item.parent == null ? nullItem : parentRes.data.find(x => x._id == props.item.parent));
            }
        } else {
            toast.error(parentRes.message);
        }
    }

    const loadCombo = async () => {
        let languagesRes = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguages(languagesRes.data);
    };

    const handleSubmit = async (e) => {

        const saveData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name ?? "",
            parent: parent._id,
            Language: dataItem?.Language && Object.keys(dataItem?.Language)?.length > 0 ? {
                _id: dataItem?.Language?._id,
                ISOCode: dataItem?.Language?.ISOCode,
                Description: dataItem?.Language?.Description
            } : {},
            Archive: dataItem.Archive ?? false,
        }
        console.log(saveData);
        props.onSubmit(e, saveData);
        props.refresh();
        props.cancelEdit();
    }

    return (
        <>
            <BossDialog
                title={props.item.SID > 0 ? props.item.Name : "Content Category"}
                onClose={props.cancelEdit}
                width={"300px"}
                height={"max-content"}
            >
                <Form
                    render={() => (
                        <FormElement>
                            <div className="row">
                                <div className="col-12">

                                    <div className="row mt-2">
                                        <div className="col">
                                            <label>{lang.name_label}</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.Name}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <label>{lang.parent_label}</label>
                                            <DropDownList
                                                data={parents}
                                                name={"parent"}
                                                textField="Name"
                                                dataItemKey="_id"
                                                value={parent}
                                                onChange={onChange}
                                            />
                                            <p className="list-unstyled blockquote-footer">{lang.note_for_parent_select_null_label}</p>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label>{lang.language_label}:</label>
                                            <DropDownList
                                                style={{ backgroundColor: "white" }}
                                                data={languages}
                                                name="Language"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.Language}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mt-3">
                                            <Checkbox
                                                name={"Archive"}
                                                style={{ marginLeft: "5px", marginBottom: "20px" }}
                                                value={dataItem.Archive}
                                                onChange={onChange}
                                            />
                                            <label className="pl-1">{lang.archive}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </FormElement>
                    )}
                />
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
            </BossDialog>
        </>)
};

export default ContentCategoryEditForm;