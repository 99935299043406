/* eslint-disable */
import React from 'react';
import BackRoundButton from '../../../framework/forms/helpercomponents/buttons/BackRoundButton';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import MobileGridCard from './MobileGridCard';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { LocalDateTimeColumn } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';

const DigitalSignMobileAdminCompanyCollection = (props) => {

    const { isLoading = false, gridData = [], showTitle = true, showAdd = true, showBack = true, onEnterEdit, myTitle, handleDelete } = props;
    const navigate = useNavigate();

    const handleItemClick = (data) => {
        return navigate(`/home/digitalSignDashboard?companyId=${data._id}`);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {showTitle && (
                <div
                    className="d-flex ml-1"
                    style={{ alignItems: "center", flexWrap: "wrap", marginBottom: "10px" }}
                >
                    <i
                        className="fa fa-table"
                        style={{
                            fontSize: "large",
                            color: "GrayText",
                            marginRight: "5px",
                        }}
                    ></i>
                    <span
                        style={{
                            fontSize: "large",
                            flex: "1",
                            wordBreak: "break-word",
                        }}
                    >
                        {myTitle}
                    </span>
                </div>
            )}
            <div className="flex-container mt-2 mb-2">
                {showBack && <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>}
                {showAdd && <AddRoundButton onClick={onEnterEdit} />}
            </div>

            <div className='mb-5' style={{ flex: 1, overflowY: "auto" }}>
                {isLoading ? (
                    <Loader />
                ) : gridData.length === 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "65vh",
                            fontWeight: "500",
                            color: "grey",
                        }}
                    >
                        Add Company
                    </div>
                ) : (
                    gridData
                        .sort((a, b) => b?.SID - a?.SID)
                        .map((item, index) => {

                            const whatsAppPhone = item?.SignUpData?.PhoneNo && item?.SignUpData?.PhoneNo.length >= 10 ? item.SignUpData.PhoneNo.slice(-10) : item?.SignUpData?.PhoneNo;


                            const contextMenuItem = [
                                {
                                    label: "Edit",
                                    command: (e) => {
                                        e.originalEvent.stopPropagation();
                                        onEnterEdit(item);
                                    },
                                    icon: "pi pi-pencil",
                                },
                                {
                                    label: "Delete",
                                    command: (e) => {
                                        e.originalEvent.stopPropagation();
                                        ConfirmDeleteAlert(() => handleDelete(item), ()=>{});
                                    },
                                    icon: "pi pi-trash",
                                },
                            ];
                            return (
                                <MobileGridCard
                                    key={index}
                                    title={item?.Name}
                                    imageUrl={
                                        item?.LogoUrl ??
                                        "https://media.comicbook.com/files/img/default-movie.png"
                                    }
                                    description={item?.SignUpData?.PhoneNo}
                                    contextMenuItem={contextMenuItem}
                                    topRight={
                                        <i
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                window.open(
                                                    `https://api.whatsapp.com/send?phone=${whatsAppPhone}&text=Hello ${item?.Name}`,
                                                    "_blank"
                                                );
                                            }}
                                            className="fa fa-whatsapp cursor-pointer"
                                            style={{ fontSize: "20px", color: "green" }}
                                        ></i>
                                    }
                                    label1={"Plan: " + item?.plansCount}
                                    label2={<LocalDateTimeColumn field="addOn" dataItem={item} />}
                                    bottomRight={"User: " + item?.usersCount}
                                    showContextMenu={true}
                                    handleItemClick={handleItemClick}
                                    height="120px"
                                    data={item}
                                />
                            );
                        })
                )}
            </div>
        </div>
    )
}

export default DigitalSignMobileAdminCompanyCollection;
