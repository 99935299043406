/* eslint-disable */
import React from 'react';

const DigiSignDisplay = ({ selectedData, buttonName, handleNextClick, handleSkipClick, handlePreviousClick, isMobile=false }) => {
    return (
        <div className="col-12 col-md-9 bg-secondary text-white p-4">
        <h2>{selectedData?.title}</h2>
        <p className="mt-2" style={{ fontSize: '15px' }}>
            {selectedData?.content}
        </p>
        <img
            src={selectedData?.image ?? ''}
            alt="Demo"
            style={{ width: '100%', borderRadius: '5px', marginBottom: '15px' }}
        />
        <div className="content-buttons d-flex justify-content-between mt-2">
            {/* Skip button aligned to the left */}
            <button className="btn btn-secondary" onClick={handleSkipClick}>
                Skip
            </button>
            <div className="d-flex gap-2">
                {isMobile && <button className="btn btn-primary mr-2" onClick={handlePreviousClick}>
                    Previous
                </button>}
                <button className="btn btn-success" onClick={handleNextClick}>
                    {buttonName}
                </button>
            </div>
        </div>
    </div>
    
    );
};

export default DigiSignDisplay;
