
import { LOCALSTORAGE_KEY, MODULE, PRODUCTS, ROLETYPE } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
const menuModel = (lang, userMenus, user) => {
    // FOR DEFAULT MENU
    var subMenus = userMenus;
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const disableLiveEventSection = company && company?.disableLiveEventSection != undefined && company?.disableLiveEventSection
    subMenus = GetUserFavMenu();
    subMenus.push({
        type: 0,
        // onclick: openUserFavMenuDialog(),
        link: 'UserFavourite',
        modulesid: MODULE.USER_FAVOURITE,
        name: lang.user_favourite_add_button_title
    });
    const digitalSignProduct = company && company?.Products?.length == 1 && company?.Products.some(x => x == PRODUCTS.DigitalSignage);
    let digitalSignMenu = utility.getDigitalSignageMenu(digitalSignProduct, lang, user)

    let planningSubMenu = utility.getPlanningMenu(company, lang);

    return [
        {
            type: 0,
            link: 'Dashboard',
            modulesid: MODULE.DASHBOARD,
            name: lang.dashboard,
            icon: 'fa fa-lg fa-fw fa-tachometer-alt'
        },
        {
            type: 1,
            title: lang.user_favourite_menu,
            icon: 'fa fa-lg fa-fw fa-heart',
            modulesid: MODULE.CONTARCT_MENU,
            name: lang.user_favourite_menu,
            subMenu: subMenus

        },
        {
            type: 1,
            title: lang.contract_menu,
            icon: 'fa fa-lg fa-fw fa-file-pen ',
            modulesid: MODULE.CONTARCT_MENU,
            name: lang.contract_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'Contract',
                    modulesid: MODULE.CONTRACT,
                    name: lang.contract_sub_menu
                }
            ]
        },
        {
            type: 0,
            link: 'manageContent',
            modulesid: MODULE.MANAGE_CONTENT,
            name: lang.manage_content_menu,
            icon: 'fa fa-lg fa-fw fa-tv'

        },
        {
            type: 1,
            title: lang.media_management_menu,
            icon: 'fa fa-lg fa-fw fa-clapperboard',
            modulesid: MODULE.MEDIA_MANAGEMENT_MENU,
            name: lang.media_management_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'MediaEpisode/Series',
                    modulesid: MODULE.MEDIA_EPISODE_SERIES,
                    name: lang.series_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Season',
                    modulesid: MODULE.MEDIA_EPISODE_SEASON,
                    name: lang.season_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/OrphanEpisode',
                    modulesid: MODULE.MEDIA_EPISODE_SEASON,
                    name: lang.episode_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Program',
                    modulesid: MODULE.MEDIA_EPISODE_PROGRAM,
                    name: lang.program_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Movie',
                    modulesid: MODULE.MEDIA_EPISODE_MOVIE,
                    name: lang.movie_sub_menu
                },
                !disableLiveEventSection && {
                    type: 0,
                    link: "LiveEvents",
                    modulesid: MODULE.LIVE_EVENTS,
                    name: lang.live_events_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Trailer',
                    modulesid: MODULE.MEDIA_EPISODE_TRAILER,
                    name: lang.trailer_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Interstitial',
                    modulesid: MODULE.MEDIA_EPISODE_INTERSTITIAL,
                    name: lang.interstitial_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Commercial',
                    modulesid: MODULE.MEDIA_EPISODE_COMMERCIAL,
                    name: lang.commercial_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaEpisode/Filler',
                    modulesid: MODULE.MEDIA_EPISODE_FILLER,
                    name: lang.filler_sub_menu
                },
                {
                    type: 1,
                    title: lang.music_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.MEDIA_MANAGEMENT_MENU,
                    name: lang.music_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'MediaEpisode/Music',
                            modulesid: MODULE.MEDIA_EPISODE_MUSIC,
                            name: lang.music_sub_menu
                        },
                        {
                            type: 0,
                            link: 'MusicCategory',
                            modulesid: MODULE.MUSIC_CATEGORY,
                            name: lang.music_category_sub_menu
                        }
                    ]
                },
                {
                    type: 1,
                    title: lang.media_others_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.MEDIA_MANAGEMENT_MENU,
                    name: lang.media_others_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'MediaEpisode/Break',
                            modulesid: MODULE.MEDIA_EPISODE_BREAK,
                            name: lang.break_sub_menu
                        },
                        {
                            type: 0,
                            link: 'MediaEpisode/Dummy',
                            modulesid: MODULE.MEDIA_EPISODE_DUMMY,
                            name: lang.dummy_sub_menu
                        },
                        {
                            type: 0,
                            link: "MediaEpisodeGroup",
                            modulesid: MODULE.MEDIA_EPISODE_GROUP,
                            name: lang.media_episode_group_sub_menu
                        },
                        {
                            type: 0,
                            link: "ProgramGroup",
                            modulesid: MODULE.PROGRAM_GROUP,
                            name: lang.program_group_sub_menu
                        },
                        {
                            type: 0,
                            link: "InterstitialGroup",
                            modulesid: MODULE.INTERSTITIAL_GROUP,
                            name: lang.interstitial_group_sub_menu
                        },
                        {
                            type: 0,
                            link: 'mamAssets',
                            modulesid: MODULE.MEDIA_EPISODE_MAMASSETS,
                            name: lang.mam_assets_sub_menu
                        },

                    ]
                },
                {
                    type: 0,
                    link: 'mediaTemplate',
                    modulesid: MODULE.Media_Template,
                    name: lang.media_template_sub_menu
                },
            ]

        },
        {
            type: 1,
            title: lang.planning_menu,
            //link: 'planning',
            modulesid: MODULE.PLANNING_MENU,
            name: lang.planning_menu,
            icon: 'fa fa-lg fa-fw fa-calendar-alt',
            subMenu: planningSubMenu
        },
        {
            type: 1,
            title: lang.scheduling_menu,
            //link: 'planning',
            modulesid: MODULE.SCHEDULING_MENU,
            name: lang.scheduling_menu,
            icon: 'fa fa-lg fa-fw fa-calendar-alt',
            subMenu: [
                {
                    type: 0,
                    link: 'Scheduling',
                    title: lang.scheduling_menu,
                    modulesid: MODULE.SCHEDULING,
                    target: '_blank',
                    //icon: 'fa fa-lg fa-fw fa-calendar-day',
                    name: lang.scheduling_menu,
                },
                {
                    type: 0,
                    link: 'missingmediareport',
                    modulesid: MODULE.MISSINGMEDIAREPORT,
                    name: lang.missing_media_report_sub_menu
                },
                {
                    type: 0,
                    link: 'breakConfiguration',
                    modulesid: MODULE.BREAKCONFIGURATION,
                    name: lang.break_configuration_sub_menu
                },

            ]
        },
        ...digitalSignMenu,
        {
            type: 0,
            link: 'timeline',
            title: lang.timeline_sub_menu,
            modulesid: MODULE.TIMELINE,
            name: lang.timeline_sub_menu,
            icon: 'fa fa-lg fa-fw fa-timeline'
        },
        {
            type: 1,
            //link: '/OttPosters',
            title: lang.ingestion_menu,
            modulesid: MODULE.INGESTION_MENU,
            icon: 'fa fa-lg fa-fw fa-thin fa-indent',
            name: lang.ingestion_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'IngestionTemplate',
                    modulesid: MODULE.INGESTION_TEMPLATE,
                    name: lang.ingestion_template_sub_menu
                },
                {
                    type: 0,
                    link: 'PartnerIngetion',
                    modulesid: MODULE.PARTNER_INGESTION,
                    name: lang.partner_ingestion_sub_menu
                },
                {
                    type: 0,
                    link: 'Jobs',
                    modulesid: MODULE.JOBS,
                    name: lang.jobs_sub_menu
                },
                {
                    type: 0,
                    link: 'jobsSchedule',
                    modulesid: MODULE.JOBS_SCHEDULE,
                    name: lang.jobs_schedule_sub_menu
                },
                {
                    type: 0,
                    link: 'ingestionLogs',
                    modulesid: MODULE.INGESTION_LOGS,
                    name: lang.ingestion_logs_sub_menu
                }
            ]
        },
        {
            type: 1,
            title: lang.sales_menu,
            modulesid: MODULE.SALES_MENU,
            icon: 'fa fa-lg fa-fw fa-chart-bar',
            name: lang.sales_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'Agency',
                    modulesid: MODULE.AGENCY,
                    name: lang.agency_sub_menu
                },
                {
                    type: 0,
                    link: 'Client',
                    modulesid: MODULE.CLIENT,
                    name: lang.client_sub_menu
                },
                {
                    type: 0,
                    link: 'Campaign',
                    modulesid: MODULE.CAMPAIGN,
                    name: lang.campaign_sub_menu
                },
                {
                    type: 1,
                    title: lang.booking_sub_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.BOOKING,
                    name: lang.booking_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'Booking',
                            modulesid: MODULE.BOOKING,
                            name: lang.booking_sub_menu
                        },
                        {
                            type: 0,
                            link: 'YearlyBooking',
                            modulesid: MODULE.BOOKING,
                            name: lang.booking_yearly_sub_menu
                        },
                        {
                            type: 0,
                            link: 'BookingTransfer',
                            modulesid: MODULE.BOOKING_TRANSFER,
                            name: lang.booking_transfer_sub_menu
                        },
                        {
                            type: 0,
                            link: 'UpdateBookingLineNumber',
                            modulesid: MODULE.UPDATE_BOOKING_LINE_NUMBER,
                            name: lang.update_booking_line_number_sub_menu
                        },
                        {
                            type: 0,
                            link: 'MakeGoodSpot',
                            modulesid: MODULE.MAKE_GOOD_SPOT,
                            name: lang.makegoodspot_sub_menu
                        },

                    ]
                },
                {
                    type: 0,
                    link: 'Brand',
                    modulesid: MODULE.BRAND,
                    name: lang.brand_sub_menu
                },
                {
                    type: 0,
                    link: 'Product',
                    modulesid: MODULE.PRODUCT,
                    name: lang.product_sub_menu
                },
                {
                    type: 0,
                    link: 'Optimisation',
                    modulesid: MODULE.OPTIMISATION,
                    name: lang.optimisation_sun_menu
                },
                {
                    type: 0,
                    link: 'PendingApproval',
                    modulesid: MODULE.PENDING_APPROVAL,
                    name: lang.pending_approval_sun_menu
                },
                {
                    type: 0,
                    link: 'SalesTarget',
                    modulesid: MODULE.SALES_TARGET,
                    name: lang.sales_target_sub_menu
                },
            ]
        },
        {
            type: 1,
            title: lang.reconciliation_menu,
            icon: 'fa fa-lg fa-fw fa-download ',
            modulesid: MODULE.IMPORT_MENU,
            name: lang.reconciliation_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'ScheduleReconciliation',
                    modulesid: MODULE.SCHEDULE_RECONCILIATION,
                    name: lang.as_run_reconciliation_sub_menu
                },
                {
                    type: 0,
                    link: 'AnnouncementReconcile',
                    modulesid: MODULE.ANNOUNCEMENT_RECONCILE,
                    name: lang.announcement_reconcile_sun_menu
                },
                {
                    type: 0,
                    link: 'ImportAsRun',
                    modulesid: MODULE.IMPORT_AS_RUN,
                    name: lang.import_asRun_sub_menu
                },
                {
                    type: 0,
                    link: 'ManageAsRun',
                    modulesid: MODULE.MANAGE_AS_RUN,
                    name: lang.manage_asRun_sub_menu
                }
            ]
        },
        {
            type: 1,
            title: lang.secondary_event_menu,
            icon: 'fa fa-lg fa-fw fa-thin fa-pager',
            modulesid: MODULE.SECONDARY_EVENT_MENU,
            name: lang.secondary_event_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'SecondaryEventPrototype',
                    modulesid: MODULE.SECONDARY_EVENT_PROTOTYPE,
                    name: lang.prototype_sub_menu
                },
                {
                    type: 0,
                    link: 'SecondaryEventStyleSheet',
                    modulesid: MODULE.SECONDARY_EVENT_STYLE_SHEET,
                    name: lang.styleSheet_sub_menu
                },
                {
                    type: 0,
                    link: 'DefaultSecondaryEventPrototype',
                    modulesid: MODULE.DEFAULT_SECONDARY_EVENT_PROTOTYPR,
                    name: lang.default_prototype_sub_menu
                },
                {
                    type: 0,
                    link: 'ProtoTypeSource',
                    modulesid: MODULE.PROTO_TYPE_SOURCE,
                    name: lang.protoType_source_sub_menu
                },
                {
                    type: 0,
                    link: 'SecondaryEventDynamicRule',
                    modulesid: MODULE.SECONDARY_EVENT_DYNAMIC_RULE,
                    name: lang.secondary_event_dynamic_rule_sub_menu
                }

            ]
        },
        {
            type: 1,
            title: lang.billing_menu,
            modulesid: MODULE.BILLING_MENU,
            icon: 'fa fa-lg fa-fw fa-thin fa-pager',
            name: lang.billing_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'BillingCollection',
                    modulesid: MODULE.BILLING,
                    name: lang.billing_sub_menu
                },

                {
                    type: 0,
                    link: 'GeneratedBillCollection',
                    modulesid: MODULE.GENERATE_BILL,
                    name: lang.generated_bills_sub_menu
                },
                {
                    type: 0,
                    link: 'billingsummaryreport',
                    modulesid: MODULE.BILLINGSUMMARYREPORT,
                    name: lang.billing_summary_report_sub_menu
                }

            ]
        },
        {
            type: 1,
            title: lang.curate_menu,
            name: lang.curate_menu,
            modulesid: MODULE.CURATE_MENU,
            icon: 'fa fa-lg fa-fw fa-thin fa-sitemap',
            subMenu: [
                {
                    type: 0,
                    icon: 'fa fa-lg fa-fw fa-thin fa-pager ',
                    link: 'OttMenu',
                    modulesid: MODULE.OTTMENU,
                    name: lang.ottmenu_sub_menu
                },
                {
                    type: 0,
                    icon: 'fa fa-lg fa-fw fa-thin fa-pager ',
                    link: 'Page',
                    modulesid: MODULE.PAGE,
                    name: lang.pages_sub_menu
                },
                {
                    type: 0,
                    icon: 'fa fa-lg fa-fw fa-thin fa-trailer ',
                    link: 'Rails',
                    modulesid: MODULE.RAILS,
                    name: lang.rails_sub_menu
                },
                {
                    type: 0,
                    link: 'OttAssets',
                    modulesid: MODULE.OTT_ASSETS,
                    name: lang.assets_sub_menu
                },
                {
                    type: 0,
                    link: 'OttCampaign',
                    modulesid: MODULE.OTT_CAMPAIGN,
                    name: lang.ott_campaign_sub_menu
                },
                {
                    type: 0,
                    link: 'OttPosters',
                    modulesid: MODULE.OTT_POSTERS,
                    name: lang.posters_sub_menu
                },
                {
                    type: 0,
                    link: 'document',
                    modulesid: MODULE.DOCUMENT,
                    name: lang.document_sub_menu
                },

                {
                    type: 1,
                    title: 'Preview',
                    name: 'Preview',
                    modulesid: MODULE.TV,
                    icon: 'fa fa-lg fa-fw fa-thin fa-eye',
                    subMenu: [
                        {
                            type: 0,
                            title: lang.tv_preview_sub_menu,
                            modulesid: MODULE.TV,
                            name: lang.tv_preview_sub_menu,
                            link: 'tv'
                        },
                        {
                            type: 0,
                            title: lang.mobile_preview_sub_menu,
                            modulesid: MODULE.MOBILE,
                            name: lang.mobile_preview_sub_menu,
                            link: 'mobile'
                        },
                        {
                            type: 0,
                            title: lang.web_preview_sub_menu,
                            modulesid: MODULE.TV,
                            name: lang.web_preview_sub_menu,
                            link: 'tv'
                        },
                    ]

                },
                {
                    type: 0,
                    link: 'faq',
                    modulesid: MODULE.FAQ,
                    name: lang.faq_sub_menu
                },
                {
                    type: 0,
                    link: 'tutorialvideos',
                    modulesid: MODULE.TUTORIAL_VIDEOS,
                    name: lang.tutorial_videos_sub_menu
                },
                {
                    type: 0,
                    link: 'marketingScreens/1',
                    modulesid: MODULE.MARKETINGSCREENS,
                    name: lang.marketing_screens_sub_menu
                },
                {
                    type: 0,
                    link: 'promotionalScreens/1',
                    modulesid: MODULE.PROMOTIONALSCREENS,
                    name: lang.promotional_screens_sub_menu
                },
                {
                    type: 0,
                    icon: 'fa fa-lg fa-fw fa-thin fa-pager ',
                    link: 'WebAssets',
                    modulesid: MODULE.WEB_ASSETS,
                    name: lang.web_assets_sub_menu
                },
                {
                    type: 0,
                    link: 'screensaver/7',
                    modulesid: MODULE.SCREEN_SAVER,
                    name: lang.screen_saver_sub_menu
                },
                {
                    type: 0,
                    link: 'theme',
                    modulesid: MODULE.THEME,
                    name: lang.theme_sub_menu
                },
                {
                    type: 0,
                    icon: 'fa fa-lg fa-fw fa-thin fa-pager ',
                    link: 'OttSetting',
                    modulesid: MODULE.OTTSETTING,
                    name: lang.ott_setting_sub_menu
                },
                //  Not Complete yet

                {
                    type: 0,
                    link: 'pipagesetting',
                    modulesid: MODULE.PI_PAGE_SETTING,
                    name: lang.pi_page_setting_sub_menu
                },
            ]
        },
        {
            type: 1,
            title: lang.monetization_menu,
            modulesid: MODULE.MONETIZATION,
            icon: 'fa fa-lg fa-fw fa-th-list',
            name: lang.monetization_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'OttProduct',
                    modulesid: MODULE.OTT_PRODUCT,
                    name: lang.ott_product_sub_menu
                },
                {
                    type: 0,
                    link: 'PaymentPlan',
                    modulesid: MODULE.PAYMENT_PLAN,
                    name: lang.paymentPlan_sub_menu
                },
                {
                    type: 0,
                    link: 'Coupon',
                    modulesid: MODULE.COUPON,
                    name: lang.coupon_sub_menu
                },
                {
                    type: 0,
                    link: 'Subscriber',
                    modulesid: MODULE.SUBSCRIBER,
                    name: lang.subscriber_sub_menu
                },
                {
                    type: 0,
                    link: 'OttPeriod',
                    modulesid: MODULE.OTT_PERIOD,
                    name: lang.ott_period_sub_menu
                },
                {
                    type: 0,
                    link: "PaymentProvider",
                    modulesid: MODULE.PAYMENT_PROVIDER,
                    name: lang.payment_provider_sub_menu
                },
                {
                    type: 0,
                    link: "AvodConfiguration",
                    modulesid: MODULE.AVOD_CONFIGURATION,
                    name: lang.avod_Configuration_sub_menu
                },
                {
                    type: 0,
                    link: "analyticsProvider",
                    modulesid: MODULE.ANALYTICS_PROVIDER,
                    name: lang.analytics_providers_sub_menu
                },
                {
                    type: 0,
                    link: "analyticsEvents",
                    modulesid: MODULE.ANALYTICS_EVENTS,
                    name: lang.analytics_events_sub_menu
                },
            ]
        },
        {
            type: 1,
            title: lang.service_menu,
            icon: 'fa fa-lg fa-fw fa-servicestack ',
            modulesid: MODULE.SERVICE_MENU,
            name: lang.service_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'amazondeliverygroup',
                    modulesid: MODULE.AMAZONDELIVERYGROUP,
                    name: lang.amazon_delivery_group_sub_menu
                },
                {
                    type: 0,
                    link: 'ottpackagedeliveryschedule',
                    modulesid: MODULE.OTT_PACKAGE_DELIVERY_SCHEDULE,
                    name: lang.ott_package_delivery_schedule_menu
                },
                {
                    type: 0,
                    link: 'scheduleautofillinterstitial',
                    modulesid: MODULE.SCHEDULE_AUTO_FILL_INTERSTITIAL,
                    name: lang.schedule_auto_fill_interstitial_sub_menu
                },
            ]
        },
        {
            type: 1,
            title: lang.export_menu,
            icon: 'fa fa-lg fa-fw fa-file-export ',
            modulesid: MODULE.EXPORT_MENU,
            name: lang.export_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'exportepgreport',
                    modulesid: MODULE.EXPORT_EPG_REPORT,
                    name: lang.epg_sub_menu
                },
                {
                    type: 0,
                    link: 'exportepgtemplate',
                    modulesid: MODULE.EXPORT_EPG_TEMPLATE,
                    name: lang.epg_template_sub_menu
                },
                {
                    type: 0,
                    link: 'exportmanagecontenttemplate',
                    modulesid: MODULE.MANAGE_CONTENT_EXPORT_TEMPLATE,
                    name: lang.manage_content_template_sub_menu
                },
            ]
        },
        {
            type: 1,
            title: lang.master_menu_title,
            modulesid: MODULE.MASTER_MENU,
            icon: 'fa fa-lg fa-fw fa-box',
            name: lang.masters_menu,
            subMenu: [
                {
                    type: 1,
                    title: lang.traffic_sub_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.MEDIA_EPISODE_SERIES,
                    // modulesid: MODULE.TRAFFIC_SUB_MENU,
                    name: lang.traffic_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'AudioCodec',
                            modulesid: MODULE.AUDIOCODEC,
                            name: lang.audio_codec_sub_menu
                        },
                        {
                            type: 0,
                            link: "BreakFormat",
                            modulesid: MODULE.BREAKFORMAT,
                            name: lang.break_format_sub_menu
                        },
                        {
                            type: 0,
                            link: 'casttype',
                            modulesid: MODULE.CASTTYPE,
                            name: lang.cast_Type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'censorRating',
                            modulesid: MODULE.CENSORRATING,
                            name: lang.censor_rating_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Channel',
                            modulesid: MODULE.CHANNEL,
                            name: lang.channel_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Content',
                            modulesid: MODULE.CONTENT,
                            name: lang.content_sub_menu
                        },
                        {
                            type: 0,
                            link: 'ContentCategory',
                            modulesid: MODULE.CONTENT_CATEGORY,
                            name: lang.content_category_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Countries',
                            modulesid: MODULE.COUNTRIES,
                            name: lang.countries_sub_menu
                        },
                        {
                            type: 0,
                            link: 'dayPart',
                            modulesid: MODULE.DAYPART,
                            name: lang.day_part_sub_menu
                        },
                        {
                            type: 0,
                            link: 'DeliveryMethod',
                            modulesid: MODULE.DELIVERY_METHOD,
                            name: lang.delivery_method_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Department',
                            modulesid: MODULE.DEPARTMENT,
                            name: lang.department_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Genre',
                            modulesid: MODULE.GENRE,
                            name: lang.genre_sub_menu_
                        },
                        {
                            type: 0,
                            link: 'Language',
                            modulesid: MODULE.LANGAUGE,
                            name: lang.language_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Licensor',
                            modulesid: MODULE.LICENSOR,
                            name: lang.licensor_sub_menu
                        },
                        {
                            type: 0,
                            link: 'LiveEventRound',
                            modulesid: MODULE.LiveEventRound,
                            name: lang.LiveEventRound_sub_menu
                        },
                        {
                            type: 0,
                            link: 'LiveEventType',
                            modulesid: MODULE.LiveEventType,
                            name: lang.LiveEventType_sub_menu
                        },
                        {
                            type: 0,
                            link: 'matchType',
                            modulesid: MODULE.MATCHTYPE,
                            name: lang.match_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'MaterialContent',
                            modulesid: MODULE.MATERIALCONTENT,
                            name: lang.material_content_sub_menu
                        },
                        {
                            type: 0,
                            link: 'MediaCategoryType',
                            modulesid: MODULE.MEDIACATEGORYTYPE,
                            name: lang.media_category_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'PGRating',
                            modulesid: MODULE.PGRATING,
                            name: lang.pgrating_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Pincodes',
                            modulesid: MODULE.PINCODS,
                            name: lang.pincode_sub_menu
                        },
                        {
                            type: 0,
                            link: 'protoTypeContent',
                            modulesid: MODULE.PROTO_TYPE_CONTENT,
                            name: lang.prototypecontent_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Region',
                            modulesid: MODULE.REGION,
                            name: lang.region_sub_menu
                        },
                        {
                            type: 0,
                            link: 'resolution',
                            modulesid: MODULE.RESOLUTION,
                            name: lang.resolution_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Role',
                            modulesid: MODULE.ROLE,
                            name: lang.role_sub_menu
                        },
                        {
                            type: 0,
                            link: "ScheduleConstraints",
                            modulesid: MODULE.SCHEDULE_CONSTRAINTS,
                            name: lang.schedule_constraints_sub_menu
                        },
                        {
                            type: 0,
                            link: 'SegmentType',
                            modulesid: MODULE.SEGMENT_TYPE,
                            name: lang.segemnt_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'States',
                            modulesid: MODULE.STATES,
                            name: lang.states_sub_menu
                        },
                        {
                            type: 0,
                            link: 'TechnicalDetailPrototype',
                            modulesid: MODULE.TECHNICAL_DETAILS_PROTOTYPE,
                            name: lang.technical_details_prototype_sub_menu
                        },
                        {
                            type: 0,
                            link: 'timezone',
                            modulesid: MODULE.TIMEZONE,
                            name: lang.time_zone_sub_menu
                        },
                        {
                            type: 0,
                            link: 'TournamentType',
                            modulesid: MODULE.TournamentType,
                            name: lang.TournamentType_sub_menu
                        },
                        {
                            type: 0,
                            link: 'tournamentGroup',
                            modulesid: MODULE.TOURNAMENTGROUP,
                            name: lang.tournament_group_sub_menu
                        },
                        {
                            type: 0,
                            link: 'TxMode',
                            modulesid: MODULE.TX_MODE,
                            name: lang.tx_mode_sub_menu
                        },
                        {
                            type: 0,
                            link: 'TxStatus',
                            modulesid: MODULE.TX_STATUS,
                            name: lang.tx_status_sub_menu
                        },
                        {
                            type: 0,
                            link: 'users',
                            modulesid: MODULE.USERS,
                            name: lang.users_sub_menu
                        },
                        {
                            type: 0,
                            link: "Variation",
                            modulesid: MODULE.VARIATION,
                            name: lang.variation_sub_menu
                        },
                        {
                            type: 0,
                            link: "variance",
                            modulesid: MODULE.VARIANCE,
                            name: lang.variance_sub_menu
                        },
                        {
                            type: 0,
                            link: "Version",
                            modulesid: MODULE.VERSION,
                            name: lang.version_sub_menu
                        },
                        {
                            type: 0,
                            link: "VideoAspectRatio",
                            modulesid: MODULE.VIDEO_ASPECT_RATIO,
                            name: lang.video_aspect_ratio_sub_menu
                        },
                        {
                            type: 0,
                            link: "VideoCodec",
                            modulesid: MODULE.VIDEOCODEC,
                            name: lang.video_codec_sub_menu
                        },
                        {
                            type: 0,
                            link: "VideoType",
                            modulesid: MODULE.VIDEOTYPE,
                            name: lang.video_type_sub_menu
                        },
                    ]
                },
                {
                    type: 1,
                    title: lang.adSales_sub_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.BOOKING,
                    // modulesid: MODULE.AD_SALES_SUB_MENU,
                    name: lang.adSales_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'AgencyType',
                            modulesid: MODULE.AGENCY_TYPE,
                            name: lang.agency_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'BankAccount',
                            modulesid: MODULE.BANK_ACCOUNT,
                            name: lang.bank_account_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Brand',
                            modulesid: MODULE.BRAND,
                            name: lang.brand_sub_menu
                        },
                        {
                            type: 0,
                            link: 'campaigntype',
                            modulesid: MODULE.CAMPAIGNTYPE,
                            name: lang.campaigntype_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Currency',
                            modulesid: MODULE.CURRENCY,
                            name: lang.currency_sub_menu
                        },
                        {
                            type: 0,
                            link: 'dealtype',
                            modulesid: MODULE.DEALTYPE,
                            name: lang.dealtype_sub_menu
                        },
                        {
                            type: 0,
                            link: 'FinancialYear',
                            modulesid: MODULE.FINANCIAL_YEAR,
                            name: lang.financial_year_sub_menu
                        },
                        {
                            type: 0,
                            link: 'InvoiceContent',
                            modulesid: MODULE.INVOICE_CONTENT,
                            name: lang.invoice_content_sub_menu
                        },
                        {
                            type: 0,
                            link: 'location',
                            modulesid: MODULE.LOCATION,
                            name: lang.location_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Product',
                            modulesid: MODULE.PRODUCT,
                            name: lang.product_sub_menu
                        },
                        {
                            type: 0,
                            link: 'ratecard',
                            modulesid: MODULE.RATECARD,
                            name: lang.ratecard_sun_menu
                        },
                        {
                            type: 0,
                            link: 'SalesTeam',
                            modulesid: MODULE.SALES_TEAM,
                            name: lang.sales_team_sub_menu
                        },
                        {
                            type: 0,
                            link: 'users/SalesExecutive',
                            modulesid: MODULE.SALES_EXECUTIVE,
                            name: lang.sales_executive_sub_menu
                        },
                        {
                            type: 0,
                            link: "SponsorType",
                            modulesid: MODULE.SPONSOR_TYPE,
                            name: lang.sponsor_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'Tax',
                            modulesid: MODULE.TAX,
                            name: lang.tax_sub_menu
                        },
                        {
                            type: 0,
                            link: 'timerange',
                            modulesid: MODULE.TIME_RANGE,
                            name: lang.time_range_sub_menu
                        }
                    ]
                },
                {
                    type: 1,
                    title: lang.curate_sub_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.OTT_ASSETS,
                    // modulesid: MODULE.CMS_SUB_MENU,
                    name: lang.curate_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: 'OttAssetType',
                            modulesid: MODULE.ASSETS_TYPE,
                            name: lang.assets_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'faqcategory',
                            modulesid: MODULE.FAQCATEGORY,
                            name: lang.faq_category_sub_menu
                        },
                        {
                            type: 0,
                            link: 'OttPosterType',
                            modulesid: MODULE.POSTERS_TYPE,
                            name: lang.posters_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'ottprovider',
                            modulesid: MODULE.OTT_PROVIDER,
                            name: lang.provider_sub_menu
                        },
                        {
                            type: 0,
                            link: 'ottPlatform',
                            modulesid: MODULE.PLATFORM,
                            name: lang.ott_platform_sub_menu
                        },
                    ]
                },
                {
                    type: 1,
                    title: lang.monetize_sub_menu,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    modulesid: MODULE.PAYMENT_PLAN,
                    // modulesid: MODULE.MONETIZE_SUB_MENU,
                    name: lang.monetize_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: "OttPeriodType",
                            modulesid: MODULE.OTT_PERIOD_TYPE,
                            name: lang.ott_period_type_sub_menu
                        },
                        {
                            type: 0,
                            link: "PaymentType",
                            modulesid: MODULE.PAYMENT_TYPE,
                            name: lang.payment_type_sub_menu
                        },
                        {
                            type: 0,
                            link: 'OttSegment',
                            modulesid: MODULE.OTT_SEGMENT,
                            name: lang.ott_segment_sub_menu
                        },
                    ]
                },
                {
                    type: 1,
                    title: lang.message_sub_menu,
                    modulesid: MODULE.MESSAGE_SUB_MENU,
                    icon: 'fa fa-lg fa-fw fa-plus-square-o',
                    name: lang.message_sub_menu,
                    subMenu: [
                        {
                            type: 0,
                            link: "messageProvider",
                            modulesid: MODULE.MESSAGE_PROVIDER,
                            name: lang.message_provider_sub_menu
                        },
                        {
                            type: 0,
                            link: "messageTemplate",
                            modulesid: MODULE.MESSAGE_TEMPLATE,
                            name: lang.message_template_sub_menu
                        },
                        {
                            type: 0,
                            link: "messageConfiguration",
                            modulesid: MODULE.MESSAGE_CONFIGURATION,
                            name: lang.message_configuration_sub_menu
                        }

                    ]
                }
            ]
        },
        {
            type: 1,
            title: lang.report_menu,
            icon: 'fa fa-lg fa-fw fa-clipboard',
            modulesid: MODULE.REPORTS_MENU,
            name: lang.report_menu,
            subMenu: [
                {
                    type: 0,
                    link: 'airtimeconsumptionreport',
                    modulesid: MODULE.AIRTIMECONSUMPTIONREPORT,
                    name: lang.air_time_consumption_report_sub_menu
                },
                {
                    type: 0,
                    link: 'BookingScheduleStatus',
                    modulesid: MODULE.BOOKING_SCHEDULE_STATUS,
                    name: lang.booking_schedule_status_sub_menu
                },
                {
                    type: 0,
                    link: 'clientagencybookingreport',
                    modulesid: MODULE.CLIENTAGENCYBOOKINGREPORT,
                    name: lang.client_agency_booking_report_sub_menu
                },
                {
                    type: 0,
                    link: 'creditlinereport',
                    modulesid: MODULE.CREDITLINEREPORT,
                    name: lang.credit_line_report_sub_menu
                },
                {
                    type: 0,
                    link: 'interstitialhistoryreport',
                    modulesid: MODULE.INTERSTITIAL_HISTORY_REPORT,
                    name: lang.interstitial_history_sub_menu
                },
                {
                    type: 0,
                    link: 'MamAssetReport',
                    modulesid: MODULE.MAM_ASSET_REPORT,
                    name: lang.mam_asset_report_sub_menu
                },
                {
                    type: 0,
                    link: 'MediaMasterReport',
                    modulesid: MODULE.MEDIA_MASTER_REPORT,
                    name: lang.media_master_report_sub_menu
                },
                {
                    type: 0,
                    link: 'exportmediacontentreport',
                    // modulesid: MODULE.EXPORT_EPG_REPORT,
                    modulesid: MODULE.MEDIA_MASTER_REPORT,
                    name: lang.media_content_report_sub_menu
                },
                {
                    type: 0,
                    link: 'musicReport',
                    modulesid: MODULE.MUSIC_REPORT,
                    name: lang.music_report_sub_menu
                },
                {
                    type: 0,
                    link: 'prelogpostlogreport',
                    modulesid: MODULE.PRELOGPOSTLOGREPORT,
                    name: lang.prelog_postlog_report_sub_menu
                },
                {
                    type: 0,
                    link: 'programTimeConsumptionReport',
                    modulesid: MODULE.PROGRAM_TIME_CONSUMPTION_REPORT,
                    name: lang.program_time_consumption_report_sub_menu
                },
                {
                    type: 0,
                    link: 'purgespotreport',
                    modulesid: MODULE.PURGESPOTREPORT,
                    name: lang.purge_Spot_report_sub_menu
                },
                {
                    type: 0,
                    link: 'ingestionReport',
                    modulesid: MODULE.INGESTION_REPORT,
                    name: lang.ingestion_report_sub_menu
                }

            ]
        },
        {
            type: 1,
            title: "Utility",
            icon: "fa-lg fa-fw fa-solid fa-gear",
            name: "Utility",
            subMenu: utility.getUtilitySubMenuList(lang, user)
        },
    ];

}

const GetUserFavMenu = () => {
    // console.log("Getting menus from local storage");
    const userFavoriteMenus = utility.getValue(LOCALSTORAGE_KEY.userfavouritemenu);
    // console.log( lang);
    var menus = [];
    if (userFavoriteMenus != undefined && userFavoriteMenus.length > 0) {
        // console.log("Getting menus from local storage");
        menus = userFavoriteMenus.map((menu) => {
            return { type: 0, link: menu.URL, modulesid: menu.Menu.SID, name: menu.Name }
        });
    }

    return menus;
}


export default menuModel;

