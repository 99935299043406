/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { utility } from '../../../framework/utility/utilityProvider';
import { LOCALSTORAGE_KEY } from '../../../framework/constant/constant';

export const DigitalSignMobileSignUpForm = (props) => {

    const { image, logo, labelStyle, onChange, handleSignup, dataItem, dataValue, setShowConfirmPassword, showConfirmPassword, showPassword, setShowPassword } = props;
    const navigate = useNavigate();
    const callBackUrl = utility.getValue(LOCALSTORAGE_KEY.CALL_BACK_URL);
    const lang = useTranslation();
    const newStyle = {
        ...labelStyle,
        fontSize: '12px'
    }

    return (
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100" style={{ backgroundColor:'rgba(0, 0, 0, 0.6)' }}>
            <div
                className="position-absolute w-100 h-100"
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(10px)',
                    zIndex: -1,
                }}
            ></div>
            <div className="row justify-content-center align-items-center" style={{width: '100%'}}>
                <div className="col-md-6 col-lg-4 bg-white p-4 rounded digital-sign-up-login-form shadow mb-4" >
                    <img src={logo} alt="Logo" className="mb-2" style={{ width: '200px' }} />
                    <h2 style={{ fontSize: '1.5rem', marginBottom: '10px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem', marginTop: '5px' }}>{lang.sign_up_label}</h2>
                    <div>
                        <label style={newStyle} htmlFor="name">{lang.name_label} *</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter name"
                            value={dataItem.name}
                            onChange={onChange}
                        />
                    </div>
                    <div className='mt-2'>
                        <label style={newStyle} htmlFor="email">{lang.email_address_label} *</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            value={dataItem.email}
                            onChange={onChange}
                        />
                    </div>
                    <div className='mt-2'>
                        <label style={newStyle} htmlFor="phone">{lang.phone_no_label} *</label>
                        <input
                            type="number"
                            className="form-control"
                            name="phone"
                            placeholder="Enter phone number"
                            value={dataItem.phone}
                            onChange={onChange}
                        />
                    </div>
                    <div className="row">
                        <div className='col-12 col-md-6 mt-2'>
                            <label style={newStyle} htmlFor="password">{lang.password_label} *</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                style={{ padding: "10px 20px" }}
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                value={dataItem.password}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "25px", cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)} />
                        </div>
                        <div className='col-12 col-md-6 mt-2'>
                            <label style={newStyle} htmlFor="confirm password">{lang.confirm_password_label} *</label>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                style={{ padding: "10px 20px" }}
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                                value={dataItem.confirmPassword}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "25px", cursor: "pointer" }} className={`icon-append fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        </div>
                    </div>
                    {/* <div>
                        <label style={newStyle} htmlFor="companyName">{lang.company_name_label} *</label>
                        <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            placeholder="Enter Company Name"
                            value={dataItem.companyName}
                            onChange={onChange}
                        />
                    </div> */}
                    {/* <div>
                        <label style={newStyle} htmlFor="countryCode">{lang.country_code_label} *</label>
                        <select
                            className="form-control"
                            name="countryCode"
                            value={dataItem.countryCode}
                            onChange={onChange}
                        >
                            {dataValue.countryCodes.map((country) => {
                                return (<option key={country.SID} value={country.Value}>
                                    {country.Description} ({country.Value})
                                </option>)
                            })
                            }
                        </select>
                    </div> */}
                    {/* <div>
                        <label style={newStyle} htmlFor="industry">{lang.industries_label} *</label>
                        <br />
                        <DropDownList
                            className='w-100'
                            style={{ backgroundColor: 'white' }}
                            data={dataValue.industries}
                            dataItemKey="_id"
                            textField="Description"
                            name='industry'
                            value={dataItem.industry}
                            onChange={onChange}
                        />
                    </div> */}
                    {/* <div>
                                <label htmlFor="networkScreenSize">{lang.network_screen_size_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.networkScreenSizes}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='networkScreenSize'
                                    value={dataItem.networkScreenSize}
                                    onChange={onChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="howYouFoundUs">{lang.how_did_you_found_us_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.howDidYouFoundUs}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='howDidYouFoundUs'
                                    value={dataItem.howDidYouFoundUs}
                                    onChange={onChange}
                                />
                            </div> */}
                    {/* <p className='text-center mt-1 mb-1'>{lang.already_have_an_account_label} <a onClick={() => navigate('/')} className="text-primary cursor-pointer">{lang.sign_in_label}</a></p>
                        <button type="submit" className="btn btn-primary btn-block" onClick={handleSignup} >{lang.sign_up_label}</button> */}
                    <p className="text-center mt-2">
                        {lang.already_have_an_account_label}{' '}
                        <a onClick={() => navigate(callBackUrl)} className="text-primary cursor-pointer">
                            {lang.sign_in_label}
                        </a>
                    </p>
                    <button style={{ marginTop: '-8px' }} type="submit" className="btn btn-primary btn-block" onClick={handleSignup}>
                        {lang.sign_up_label}
                    </button>
                </div>
            </div>
        </div>
    )
}
