import React, { useEffect, useRef, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ImageBrowser } from '../forms/helpercomponents/selectFile/ImageBrowser'
import { Form, FormElement } from '@progress/kendo-react-form'
import { ENTITYNAME, LOGEVENT, MODULE } from '../constant/constant'
import * as API from '../API/api'
import { utility } from '../utility/utilityProvider'
import JoditEditor from 'jodit-react'

const WebAssetsEditForm = (props) => {
  const lang = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { SID } = useParams();


  const [dataItem, setDataItem] = useState({});
  const [imageSelected, setImageSelected] = useState(false)
  const [thumbFiles, setThumbFiles] = useState(new Set());
  const [banFiles, setBanFiles] = useState(new Set());
  const [appFiles, setAppFiles] = useState(new Set());
  const editor = useRef(null);

  var isFromCopy = location.state?.copy;

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, [])

  const loadEditData = async () => {
    var res = await API.getEntity(ENTITYNAME.WebAssets, parseInt(SID));
    if (res.success) {
      let data = {
        ...res.data,
        SID: res.data.SID ?? 0,
        Name: isFromCopy ? 'copy of ' + res.data.Name : res.data.Name,
        Description: res.data.Description ?? "",
        Slug: res.data.Slug ?? "",
        ThumbImg: res.data.ThumbImg ?? "",
        BanImg: res.data.BanImg ?? "",
        AppImg: res.data.AppImg ?? "",
        Tags: res.data.Tags ?? "",
        ShowBuybtn: res.data.ShowBuybtn ?? false,
        ShowInApp: res.data.ShowInApp ?? false,
        Archive: res.data.Archive ?? false,

      }

      console.log(res.data)

      if (isFromCopy) {
        data.SID = 0;
        delete data['_id']
      }

      setDataItem(data);
    }
  }

  const isValid = () => {
    if (dataItem.Name == undefined || dataItem.Name == "") {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Description == undefined || dataItem.Description == "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Slug == undefined || dataItem.Slug == "") {
      toast.error(`${lang.please_enter_slug_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  }


  const handleSubmit = async () => {
    if (!isValid()) return;

    let imageThumbUrl = dataItem.ThumbImg;
    if (thumbFiles.size > 0) {
      let resUrl = await API.uploadImage(thumbFiles);
      if (!resUrl.success) {
        toast.error(`${lang.upload_fail_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      imageThumbUrl = resUrl.data;
      dataItem.imageThumbUrl = imageThumbUrl;
    }

    let imageBanUrl = dataItem.BanImg;
    if (banFiles.size > 0) {
      let resUrl = await API.uploadImage(banFiles);
      if (!resUrl.success) {
        toast.error(`${lang.upload_fail_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      imageBanUrl = resUrl.data;
      dataItem.imageBanUrl = imageBanUrl;
    }

    let imageAppUrl = dataItem.AppImg;
    if (appFiles.size > 0) {
      let resUrl = await API.uploadImage(appFiles);
      if (!resUrl.success) {
        toast.error(`${lang.upload_fail_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      imageAppUrl = resUrl.data;
      dataItem.imageAppUrl = imageAppUrl;
    }

    let saveData = {
      _id: dataItem._id,
      SID: dataItem.SID ?? 0,
      Name: dataItem.Name,
      Description: dataItem.Description,
      Slug: dataItem.Slug,
      ThumbImg: imageThumbUrl,
      BanImg: imageBanUrl,
      AppImg: imageAppUrl,
      Tags: dataItem.Tags,
      ShowBuybtn: dataItem.ShowBuybtn,
      ShowInApp: dataItem.ShowInApp,
      Archive: dataItem.Archive
    }
    let res = await API.saveData(ENTITYNAME.WebAssets, saveData);
    console.log(res);

    if (res.success) {
      let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_WEB_ASSETS : LOGEVENT.UPDATE_WEB_ASSETS, module: MODULE.WEB_ASSETS, data: res.data, message: res.message };
      API.SaveLogs(logData);
      utility.deleteLocalStorageItem(ENTITYNAME.WebAssets)
      onClose();
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }


  const onChange = (e) => {
    if (e.target.name == "ShowBuybtn") {
      setDataItem({ ...dataItem, ShowBuybtn: !dataItem.ShowBuybtn });
    }
    else if (e.target.name == "ShowInApp") {
      setDataItem({ ...dataItem, ShowInApp: !dataItem.ShowInApp });
    }
    else if (e.target.name == 'Archive') {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == 'ThumbImg' && e.target?.files) {
      if (e.target.files != undefined) {
        setThumbFiles(e.target.files[0]);
        setDataItem({ ...dataItem, ThumbImg: e.target.value });
      } else {
        setThumbFiles(new Set());
        setDataItem({ ...dataItem, ThumbImg: e.target.value });
      }
    }
    else if (e.target.name == 'BanImg' && e.target?.files) {
      if (e.target.files != undefined) {
        setBanFiles(e.target.files[0]);
        setDataItem({ ...dataItem, BanImg: e.target.value });
      } else {
        setBanFiles(new Set());
        setDataItem({ ...dataItem, BanImg: e.target.value });
      }
    }
    else if (e.target.name == 'AppImg' && e.target?.files) {
      if (e.target.files != undefined) {
        setAppFiles(e.target.files[0]);
        setDataItem({ ...dataItem, AppImg: e.target.value });
      } else {
        setAppFiles(new Set());
        setDataItem({ ...dataItem, AppImg: e.target.value });
      }
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }
  }

  const onClose = () => {
    if (location.state && location.state.prevPath) {
      navigate(location.state.prevPath)
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      <Form
        initialValues={dataItem}
        render={(formRenderProps) => (
          <FormElement>
            <EditPageHeader title={`${lang.web_assets_page_editpageheader} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={onClose} />
            <div className='row'>
              <div className='col-6'>
                <div className='row mt-2'>
                  <div className='col'>
                    <label>{lang.name_label} *</label>
                    <input type='text' className="form-control form-control-sm" name='Name' value={dataItem.Name} onChange={onChange} />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col'>
                    <lable>{lang.slug_label} *</lable>
                    <input type='text' className="form-control form-control-sm" name='Slug' value={dataItem.Slug} onChange={onChange} />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className="col">
                    <label htmlFor="">{lang.thumb_img_label}</label>
                    <ImageBrowser name={'ThumbImg'} value={dataItem.ThumbImg} onChange={onChange} setImageSelected={setImageSelected} hidePosterLibrary={true} />
                  </div>
                </div>

                <div className='row'>
                  <div className="col">
                    <label htmlFor="">{lang.ban_img_label}</label>
                    <ImageBrowser name={'BanImg'} value={dataItem.BanImg} onChange={onChange} setImageSelected={setImageSelected} hidePosterLibrary={true} />
                  </div>
                </div>
                <div className='row'>
                  <div className="col">
                    <label htmlFor="">{lang.app_img_label}</label>
                    <ImageBrowser name={'AppImg'} value={dataItem.AppImg} onChange={onChange} setImageSelected={setImageSelected} hidePosterLibrary={true} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <label>{lang.tags_label}</label>
                    <input type='text' className="form-control form-control-sm" name='Tags' value={dataItem.Tags} onChange={onChange} />
                    <p className=" text-muted" style={{ fontSize: "10px" }}>{lang.comma_seprated_value_should_be_enter_label}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3'>
                    <input
                      type={"checkbox"}
                      name={"ShowBuybtn"}
                      style={{ marginTop: "14px", marginLeft: "5px" }}
                      onChange={() => { }}
                      value={dataItem.ShowBuybtn}
                      checked={dataItem.ShowBuybtn}
                      onClick={onChange}
                    />
                    <label className="ml-1">{lang.show_buy_button_label}</label>
                  </div>
                  <div className='col-3'>
                    <input
                      type={"checkbox"}
                      name={"ShowInApp"}
                      style={{ marginTop: "14px", marginLeft: "5px" }}
                      onChange={() => { }}
                      value={dataItem.ShowInApp}
                      checked={dataItem.ShowInApp}
                      onClick={onChange}
                    />
                    <label className="ml-1">{lang.show_in_app_label}</label>
                  </div>
                  <div className='col-3 mt-2'>
                    <input
                      type={"checkbox"}
                      name={"Archive"}
                      style={{ marginTop: "10px" }}
                      onChange={(e) => onChange(e)}
                      value={dataItem.Archive}
                      checked={dataItem.Archive}
                    />
                    <label className="ml-1">{lang.archive}</label>
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label>{lang.description_label} *</label>
                <JoditEditor ref={editor} value={dataItem.Description} onChange={(value) => setDataItem({ ...dataItem, Description: value })} />
              </div>
            </div>

          </FormElement>
        )}
      />
    </>
  )
}

export default WebAssetsEditForm
