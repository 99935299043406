/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import * as API from "../../framework/API/api_digitalSign";
import Card from "../../components/Card";
import { ENTITYNAME, IMAGE_FORMAT, LOCALSTORAGE_KEY, LOGEVENT, MODULE, orientations, PLAY_MODE, PLAYLIST_STATUS, SOCKET_ACTION, SOCKET_EVENTS, VIDEO_FORMAT } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import MyStatusCell from "../../components/MyStatusCell";
import { utility } from "../../framework/utility/utilityProvider";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { DigitalSignHelper } from "./helper/DigitalSignHelper";
import useDigiSocket from "../../framework/socket/useDigiFSocket";
import digiSocket from "../../framework/socket/digiSocket";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import DigitalSignScreenGroupCollectionSelection from "./DigitalSIgnScreenGroupCollectionSelection";
import BossDialog from "../../components/BossDialog";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Box, Slider, Switch } from "@mui/material";
import { EditPageHeader } from "../../components/EditPageHeader";
import { BASEURL } from "../../framework/constant/baseurl";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import * as APIRES from "../../framework/API/api"
import DigitalSignAsrunReport from "./DigitalSignAsrunReport";
import { TimeCellWithOutFrames } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import StorageCard from "./component/StorageCard";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import DigitalSignMobileScreenGroupCollectionSelection from "./mobileView/DigitalSignMobileScreenGroupCollectionSelection";

const ScreenEditForm = (props) => {

  const { SID } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();
  const lang = useTranslation();

  const blankDataItem = {
    SID: 0,
    Name: "",
    screenGroup: {},
    SerialNumber: "",
    playlist: [],
    playlistItem: [],
    Orientation: 0,
    reboot: {
      isRebootEnabled: false,
      isAutoRestartEnabled: false,
      rebootTime: 0,
      autoRestartDuration: 0
    },
    autoRestartPercent: 90,
    debugMode: false,
    enablePlayBackMode: false,
    playMode: { _id: PLAY_MODE["HYBRID ( ONLINE/OFFLINE )"], Description: utility.getKeyByValue(PLAY_MODE, PLAY_MODE["HYBRID ( ONLINE/OFFLINE )"]) },
    archive: false,
    enableAsrunLog: false,
    takePeriodicScreenshots: false,
    enableNotification: false,
    email: "",
    imageFormat: { _id: IMAGE_FORMAT.WebP, Description: utility.getKeyByValue(IMAGE_FORMAT, IMAGE_FORMAT.WebP) },
    videoFormat: { _id: VIDEO_FORMAT.WebM, Description: utility.getKeyByValue(VIDEO_FORMAT, VIDEO_FORMAT.WebM) }
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
  const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);
  const [selectedOrientation, setSelectedOrientation] = useState(orientations[0]);
  const [isEdit, setIsEdit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [volume, setVolume] = useState(50);
  const [brightness, setBrightness] = useState(100);
  const [isModified, setIsModified] = useState(false);
  const [isTakingScreenShotLoader, setIsTakingScreenShotLoader] = useState(false);
  const [nowPlayingIndex, setNowPlayingIndex] = useState(null);
  const [showAsrunReport, setShowAsrunReport] = useState(false);
  const [isPlayModeModified, setIsPlayModeModified] = useState(false);
  const [playModes] = useState(Object.keys(PLAY_MODE).map(x => { return { _id: PLAY_MODE[x], Description: x } }));
  const [imageFormats] = useState(Object.keys(IMAGE_FORMAT).map(x => { return { _id: IMAGE_FORMAT[x], Description: x } }));
  const [videoFormats] = useState(Object.keys(VIDEO_FORMAT).map(x => { return { _id: VIDEO_FORMAT[x], Description: x } }));
  const [name, setName] = useState();
  const nameRef = useRef();

  useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {
    if (socketData['serialNumber'] != dataItem.SerialNumber) return;
    switch (socketData.action) {
      case SOCKET_ACTION.DIGI_SCREEN_ON:
      case SOCKET_ACTION.DIGI_SCREEN_OFF:
        loadEditData();
        break;
      case SOCKET_ACTION.DIGI_SCREEN_REFRESH:
        console.log("socketData----------------");
        console.log(socketData);
        // Set socket data
        setSocketData(socketData);
        setNowPlayingIndex(socketData['nowPlayingIndex'] ?? null);
        break;
      case SOCKET_ACTION.STORAGE_CLEANED:
        toast.success(socketData['deletedLength'] + " items deleted from storage. Total storage size reduced by " + socketData['deletedSize'], {
          position: toast.POSITION.TOP_RIGHT
        })
        break;
      default:
        break;
    }
  })

  const setSocketData = async (data) => {
    setDataItem({ ...dataItem, screenDetail: data?.screenDetail ?? {} });
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to calculate screen orientation and size
    const updateScreenType = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      setIsMobile(isPortrait); // Set true for portrait mode
    };

    // Initial check
    updateScreenType();

    // Add event listener for window resize
    window.addEventListener('resize', updateScreenType);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', updateScreenType);
  }, []);

  useEffect(() => {
    let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
    digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
    return () => {
      digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
    }
  }, []);

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadData = async (screenId) => {
    setIsLoading(true);
    let json = await API.getDigitalSignScreen(
      { query: ["_id", "=", screenId] }
    );
    if (json.success && json.data.length > 0) {
      let data = json.data[0];
      console.log(data);
      setDataItem({
        ...data,
        playMode: { _id: data?.playMode ?? PLAY_MODE["HYBRID ( ONLINE/OFFLINE )"], Description: utility.getKeyByValue(PLAY_MODE, data?.playMode ?? PLAY_MODE["HYBRID ( ONLINE/OFFLINE )"]) },
        imageFormat: { _id: data?.imageFormat ?? IMAGE_FORMAT.WebP, Description: utility.getKeyByValue(IMAGE_FORMAT, data?.imageFormat ?? IMAGE_FORMAT.WebP) },
        videoFormat: { _id: data?.videoFormat ?? VIDEO_FORMAT.WebM, Description: utility.getKeyByValue(VIDEO_FORMAT, data?.videoFormat ?? VIDEO_FORMAT.WebM) },
        reboot: data?.reboot ?? dataItem?.reboot, activeAgo: utility.timeAgo(data?.activeAgo ?? 0)
      });
      setName(data.Name);
      setIsActive(data.active);
      setBrightness(data?.brightness ?? 100);
      setVolume(data?.volume ?? 50);
      setSelectedOrientation(orientations.filter((o) => o.value === (data?.orientation ?? 0))[0]);
    }
    setIsLoading(false);
  }

  const loadEditData = async () => {
    let res = loc?.state?.item ?? {};
    if (res._id) {
      loadData(res._id);
    } else {
      delete res?.screenGroupId;
      setDataItem(res);
      if (res.reboot) {
        setDataItem({ ...dataItem, reboot: res?.reboot ?? dataItem?.reboot });
      }
      setName(res?.Name ?? "");
      setIsActive(res?.active);
      setBrightness(res?.brightness ?? 100);
      setVolume(res?.volume ?? 50);
      setSelectedOrientation(orientations.filter((o) => o.value == res?.orientation ?? 0)[0]);
    }
  }

  const handleInputClick = (value) => {
    setSelectedOrientation(value);
    onChange({ target: { name: 'Orientation', value } });
    setDataItem({ ...dataItem, Orientation: value });
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Orientation: value });
    API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
  };

  const onChange = (e) => {
    if (e.target.name === "isRebootEnabled") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, isRebootEnabled: !dataItem?.reboot?.isRebootEnabled } });
    }
    else if (e.target.name === "isAutoRestartEnabled") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, isAutoRestartEnabled: !dataItem?.reboot?.isAutoRestartEnabled } });
    }
    else if (e.target.name === "rebootTime" || e.target.name === "autoRestartDuration") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, [e.target.name]: utility.convertStringWithFramesToMilliseconds(e.target.value) } });
    }
    else if (e.target.name === "debugMode") {
      setIsModified(true)
      setDataItem({ ...dataItem, debugMode: !dataItem.debugMode });
    }
    else if (e.target.name === "autoRestartPercent") {
      setIsModified(true)
      setDataItem({ ...dataItem, autoRestartPercent: e.target.value });
    }
    else if (e.target.name === "playMode") {
      setIsModified(true)
      setIsPlayModeModified(true)
      setDataItem({ ...dataItem, playMode: e.target.value });
    }
    else if (e.target.name === "enableAsrunLog") {
      setIsModified(true)
      setDataItem({ ...dataItem, enableAsrunLog: !dataItem.enableAsrunLog });
    }
    else if (e.target.name === "takePeriodicScreenshots") {
      setIsModified(true)
      setDataItem({ ...dataItem, takePeriodicScreenshots: !dataItem.takePeriodicScreenshots });
    }
    else if (e.target.name === "enableNotification") {
      setIsModified(true)
      setDataItem({ ...dataItem, enableNotification: !dataItem.enableNotification });
    }
    else if (e.target.name === "email") {
      if (!isModified) {
        setIsModified(true)
      }
      setDataItem({ ...dataItem, email: e.target.value });
    }
    else if (e.target.name === "imageFormat") {
      setIsModified(true)
      setDataItem({ ...dataItem, imageFormat: e.target.value });
    }
    else if (e.target.name === "videoFormat") {
      setIsModified(true)
      setDataItem({ ...dataItem, videoFormat: e.target.value });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const borderStyle = {
    border: "1px solid #ced4da", /* Your existing border styles */
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
    backgroundColor: "#FAFAFA",
    borderRadius: "8px",
  }

  const colorText = {
    color: "#919496",
    letterSpacing: '1px',
    textTransform: "uppercase"
  }

  const onNameChange = async (name) => {
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Name: name });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      toast.success(lang.update_successfully_label, {
        position: toast.POSITION.TOP_RIGHT
      });
      setDataItem({ ...dataItem, Name: name });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleSelectedGroup = async (data) => {

    console.log(data);

    let selectedGroupValue = data && data.length > 0 ? data[0] : {};

    if (dataItem.screenGroup._id == selectedGroupValue._id) {
      toast.error(lang.same_screen_group_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, screenGroup: selectedGroupValue });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      let playlist = await API.getData(ENTITYNAME.DigitalSignPlaylist, { query: ["screenGroup", "in", [selectedGroupValue._id]] });
      setDataItem({ ...finalData, playlist: playlist.data });
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DIGITAL_SIGN_SCREEN, data: res.data, message: res.message };
      APIRES.SaveLogs(logData);
      toast.success(lang.update_successfully_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      loadEditData();
      await API.sendEvent(res.data.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setShowScreenGroupPopup(false);
  }

  const myCommandCell = (props) => {
    return (
      <td>
        <RoundButton icon="external-link" onClick={() => window.open(`/home/PlaylistItem?_id=${props.dataItem._id}`)} />
      </td>
    );
  }

  const MyPlaylistStatuscell = (props) => (
    <div>
      <MyStatusCell style={{ width: '100px' }} title={utility.getKeyByValue(PLAYLIST_STATUS, props.dataItem.Status)} tileBackColor="#508D4E" />
    </div>
  )

  const downloadedCell = (props) => {
    let playlistItem = props.dataItem;
    let downloadedFiles = dataItem?.screenDetail?.DownloadedFiles ?? [];
    let isDownloaded = downloadedFiles?.some(contentId => contentId == playlistItem.content._id);
    return (
      <td>
        {/* play icon */}
        {<div title={isDownloaded ? "Downloaded" : "Not Downloaded"} style={{ fontSize: '20px', borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", width: "30px", height: "30px", }}>
          <i className={`fa fa-${isDownloaded ? 'download' : 'cloud'}`} />
        </div>}
      </td>
    );
  }

  const isValidDataToSave = (finalData) => {
    if (finalData.enableNotification) {
      if (finalData.email == null || finalData.email == "") {
        toast.error(`Please enter email`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      let isValidEmail = utility.isValidEmail(finalData.email);
      if (!isValidEmail) {
        toast.error(`Please enter valid email`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }
    return true;
  }


  const screenControlSave = async () => {
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, orientation: selectedOrientation.value, volume: volume, brightness: brightness });
    if (!isValidDataToSave(finalData)) return;
    console.log(finalData);
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      // if play mode is modified then delete the storage
      if (isPlayModeModified && finalData.playMode == PLAY_MODE.ONLINE) {
        await handleDeleteStorage();
        setIsPlayModeModified(false);
      }
      setIsModified(false);
      toast.success('Screen settings updated', {
        position: toast.POSITION.TOP_RIGHT
      });
      await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.DIGI_PLAY_PLAYLIST, module: MODULE.ALL, data: null });

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const restartScreen = async () => {
    await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
  }

  const takeScreenShot = async () => {
    setIsTakingScreenShotLoader(true);
    await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.LIVE_SCREENSHOT, module: MODULE.ALL, data: null });
    setTimeout(() => {
      setIsTakingScreenShotLoader(false);
      loadEditData();
    }, 5000);
  }

  const nowPlayingCell = (props) => {
    return (
      <td>
        {/* play icon */}
        {props.dataIndex == nowPlayingIndex && <div title="Now Playing" style={{ backgroundColor: "red", color: "white", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", width: "30px", height: "30px", }}>
          <i className="fa fa-play" style={{ position: "relative", top: "1px", left: "2px" }} />
        </div>}
      </td>
    );
  }

  const handleDeleteStorage = async () => {
    await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.CLEAN_STORAGE, module: MODULE.ALL, data: null });
    toast.info("Clean storage request sent", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const Box3d = useCallback(({ children, scale = true, className, height = '110px', width = '100%', style, onClick = () => { }, title = '' }) => {
    const [isHover, setIsHover] = useState(false);
    const isMouseHover = (enter) => {
      if (!scale) return
      if (enter) {
        setIsHover(true);
      } else {
        setIsHover(false);
      }
    }
    return <div title={title} className={`border form-group with-3d-effect mb-0 ${className}`} style={{
      display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", border: "1px solid #ced4da", boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff",
      borderRadius: "8px", cursor: "pointer", overflow: "auto", transform: `scale3d(${isHover ? 0.95 : 1}, ${isHover ? 0.95 : 1}, ${isHover ? 0.95 : 1})`, transition: "transform 0.15s ease", width: width, height: height, ...style,

    }} onMouseEnter={() => isMouseHover(true)} onMouseLeave={() => isMouseHover(false)} onClick={onClick}>
      {children}
    </div>
  }, []);
  return (<>
    <div className="pb-5" style={{ position: "relative" }}>
      {/* header */}
      <>
        <div className="page-title txt-color-blueDark d-flex mb-2">
          {/* back button and screen name */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackRoundButton onClick={() => navigate("/home/digitalsignScreen")} style={{ marginLeft: "-10px", alignItems: "center" }} />
            <i
              className="fa fa-tv marlr"
              style={{ fontSize: "20px", color: "GrayText" }}
            />
            <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
              if (e.key === "Enter") {
                nameRef.current.blur();
              }
            }}
              onFocus={() => setIsEdit(true)}
              onBlur={() => {
                onNameChange(name);
                setIsEdit(false);
              }}
              style={isEdit ? { border: "1px solid grey", borderRadius: "5px" } : {}}
            />
          </div>
          {/* active status and asrun report */}
          <div
            style={{ marginLeft: "auto", display: "flex", gap: "6px", justifyContent: "center", alignItems: "center" }} >
            <div style={{ fontSize: "15px", padding: "5px 10px", letterSpacing: "1px" }} className={`badge badge-${isActive ? "success" : "danger"}`} >{isActive ? "Active" : "Inactive"} </div>

            <RoundButton icon="fa-solid fa fa-tasks" title={lang.asrun_report_tooltip} onClick={() => setShowAsrunReport(true)} />
          </div>
        </div>
        {isModified && <div>
          <SaveButton onClick={screenControlSave} style={{ marginLeft: 0 }} />
          <CancelButton onClick={() => navigate("/home/digitalsignScreen")} />
        </div>}
      </>
      <div style={isMobile ? { overflowY: "auto", height: "78vh", overflowX: "hidden", marginTop: "12px" } : {}}>
        {/* screen top */}
        <div className={isMobile ? "fs-sm" : "d-flex"}>
          <div className={isMobile ? "p-0" : "col-5 p-0"}>
            {/* tv screen */}
            <>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "max-content" }}>
                <p className="m-0">Last ScreenShot</p>
                <i className={`fa fa-arrows-rotate fa-fw img-circle`} style={{ cursor: "pointer" }} onClick={loadEditData}></i>
              </div>
              <div style={{ width: "100%", height: "280px", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", background: "black", borderRadius: "5px", }}>
                {(dataItem?.screenShotUrl == null || dataItem?.screenShotUrl.length < 10 || dataItem?.screenShotUrl == undefined || isLoading) ? <p style={{ color: "white", fontSize: "20px", textAlign: "center" }}>{isLoading ? <i className="fa fa-spinner fa-pulse" /> : "No ScreenShot"}</p> : <img src={BASEURL + '/' + dataItem?.screenShotUrl + '?' + new Date().getTime()} style={{ aspectRatio: "16/9", objectFit: "contain", maxHeight: "100%", maxWidth: "100%", }} />}
              </div>
            </>
            <>
              {/* restart app, take screenshot */}
              <div className="d-flex mt-2">
                <Box3d className="mr-1" onClick={() => { restartScreen() }}>
                  <i className="fa fa-power-off" style={{ fontSize: "20px", color: "purple" }}></i>
                  <div style={{ textTransform: "uppercase" }}>{lang.restart_screen_label}</div>
                </Box3d>
                <Box3d className="ml-1" onClick={() => { takeScreenShot() }} style={{ pointerEvents: isTakingScreenShotLoader ? "none" : "auto" }}>
                  <i className={`fa fa-${isTakingScreenShotLoader ? "spinner fa-pulse" : "camera"}`} style={{ fontSize: "20px", color: "purple" }}></i>
                  <div style={{ textTransform: "uppercase" }}>Take ScreenShot</div>
                </Box3d>
              </div>
              {/* playlist, screen group, player */}
              <div className="d-flex mt-2">
                <Box3d scale={false} style={{ cursor: "default" }}>
                  <div className="d-flex" style={{ width: "100%" }}>
                    <div className="col-3 text-center cursor-pointer" onClick={() => setShowPlaylistPopup(true)}>
                      <label style={colorText}>{lang.playlist_label}</label>
                      <div >{dataItem?.playlist?.length ?? 0}</div>
                    </div>
                    <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                    <div className="col-6 text-center cursor-pointer" onClick={() => setShowScreenGroupPopup(true)}>
                      <label style={colorText}>{lang.screen_group_label}</label>
                      <div>{dataItem?.screenGroup?.Name ?? ''}</div>
                    </div>
                    <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                    <div className="col-3 text-center">
                      <label style={colorText}>{lang.player_label}</label>
                      <div>{dataItem?.screenDetail?.Os ?? ''}</div>
                    </div>
                  </div>
                </Box3d>
              </div>
              {/* orientation, volume, brightness */}
              <div className={isMobile ? "fs-sm" : "d-flex mt-2"}>
                <Box3d scale={false} style={{ cursor: "default" }} className="pr-2">
                  <div className="d-flex" style={{ width: "100%" }}>
                    <div className="col-md-4">
                      <label style={{ ...colorText, marginBottom: '0.5rem' }}>Orientation</label>
                      <DropDownList
                        data={orientations}
                        textField="text"
                        dataItemKey="value"
                        value={selectedOrientation}
                        onChange={(e) => {
                          if (!isModified) {
                            setIsModified(true);
                          }
                          setSelectedOrientation(e.target.value);
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label style={{ ...colorText, marginBottom: '0.5rem' }}>Volume</label>
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        value={volume}
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          if (!isModified) {
                            setIsModified(true);
                          }
                          setVolume(e.target.value)
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label style={{ ...colorText, marginBottom: '0.5rem' }}>Brightness</label>
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        value={brightness}
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          if (!isModified) {
                            setIsModified(true);
                          }
                          setBrightness(e.target.value)
                        }
                        }
                        style={{ width: '100%', color: 'orange' }}
                        trackColor="orange"
                      />
                    </div>
                  </div>
                </Box3d>
              </div>
            </>
          </div>
          {/* playlist grid */}
          <div className={isMobile ? "p-0 pt-2" : "col-7 p-0 pl-2"}>
            <p className="m-0 pl-2" style={{ color: "gray", height: '20px' }}>Last Active - {dataItem.activeAgo}</p>
            <Box3d className="p-2" scale={false} height={isMobile ? "400px" : "calc(100% - 20px)"} style={{ cursor: "default", display: "block" }}>
              <div style={{ ...colorText, height: '40px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {lang.playing_now_label}
                {dataItem?.active && dataItem?.playlistItem.length > 0 && <div className='float-right mt-1'>
                  <RoundButton style={{ float: 'right' }} icon="external-link" onClick={() => window.open(`/home/PlaylistItem/${dataItem?.playlistId}`)} />
                </div>}
              </div>

              {dataItem?.active ?
                <Grid data={dataItem?.playlistItem?.sort((a, b) => a.sequenceIndex - b.sequenceIndex)} resizable={true} style={{ height: "calc(100% - 40px)" }}>
                  <GridColumn cell={nowPlayingCell} width={"50px"} />
                  <GridColumn title={<i className="fas fa-broadcast-tower" style={{ paddingLeft: '10px' }}></i>} cell={downloadedCell} width={"50px"} />
                  <GridColumn field="content.thumbnailUrl" title="Thumbnail" cell={ImageCell} width={"80px"} />
                  <GridColumn field="content.fileName" title="Title" width={"200px"} />
                  <GridColumn field="content.fileName" title="Description" width={"200px"} />
                  <GridColumn field="duration" title={lang.duration_column} cell={TimeCellWithOutFrames} width={"180px"} />
                  <GridColumn field="overlay.length" title="Overlays" width={"80px"} />
                  <GridColumn field="content.resource_type" title="Type" width={"100px"} />
                </Grid>
                : <p style={{ ...colorText, fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: "calc(100% - 60px)" }}>Screen is InActive</p>}
            </Box3d>
          </div>
        </div>
        {/* screen middle */}
        <div className={isMobile ? "fs-sm" : "d-flex"}>
          <div className={isMobile ? "p-0" : "col-5 p-0"}>
            {/* image format, video format */}
            <div className="d-flex">
              <Box3d scale={false} className="mt-2 mr-1 pl-3 pr-2">
                <div style={{ width: "100%" }}>
                  <span className="" style={{ ...colorText, fontSize: isMobile ? "14px" : "16px", fontWeight: "initial" }}>{lang.image_format_label}</span>
                  <div>
                    <DropDownList
                      data={imageFormats}
                      name="imageFormat"
                      textField="Description"
                      dataItemKey="_id"
                      value={dataItem?.imageFormat}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </Box3d>
              <Box3d scale={false} className="mt-2 ml-1 pl-3 pr-2">
                <div style={{ width: "100%" }}>
                  <span className="" style={{ ...colorText, fontSize: isMobile ? "14px" : "16px", fontWeight: "initial" }}>{lang.video_format_label}</span>
                  <div>
                    <DropDownList
                      data={videoFormats}
                      name="videoFormat"
                      textField="Description"
                      dataItemKey="_id"
                      value={dataItem?.videoFormat}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </Box3d>
            </div>
            {/* daily reboot, auto restart */}
            <div className={isMobile ? "fs-sm" : "d-flex"}>
              <Box3d scale={false} className="mt-2 mr-1 pl-3 pr-2">
                <div style={{ width: "100%" }}>
                  <div className="d-flex align-items-center">
                    <Switch name={"isRebootEnabled"}
                      size={"lg"}
                      onChange={onChange}
                      checked={dataItem?.reboot?.isRebootEnabled}
                      color="success"
                      trackColor="white"
                    />
                    <span className="" style={{ ...colorText, fontSize: isMobile ? "14px" : "16px", fontWeight: "initial" }}>{lang.daily_reboot_label}</span>
                  </div>
                  <div className={isMobile ? "fs-sm" : "d-flex"}>
                    <span style={{ ...colorText, marginTop: '5px', fontSize: isMobile ? "14px" : "16px" }}>{lang.restart_time_label} :</span>
                    <TimePickerWithFormat
                      isWithFrames={false}
                      className="form-control-sm"
                      name="rebootTime"
                      value={utility.convertMilisecondsToStringWithFrames(dataItem?.reboot?.rebootTime ?? 0)}
                      onChange={onChange}
                      style={{ width: "90px" }}
                      disabled={!dataItem?.reboot?.isRebootEnabled}
                    />
                  </div>
                </div>
              </Box3d>
              <Box3d scale={false} className="mt-2 ml-1 pl-3 pr-2">
                <div style={{ width: "100%" }}>
                  <div className="d-flex align-items-center">
                    <Switch name={"isAutoRestartEnabled"}
                      size={"lg"}
                      onChange={onChange}
                      checked={dataItem?.reboot?.isAutoRestartEnabled}
                      color="success"
                    />
                    <span className="" style={{ ...colorText, fontSize: isMobile ? "14px" : "16px", fontWeight: "initial" }}>{lang.is_auto_restart_enabled_label}</span>
                  </div>
                  <div className="d-flex">
                    <span style={{ ...colorText, marginTop: '5px' }}>{lang.every_label} :</span>
                    <TimePickerWithFormat
                      isWithFrames={false}
                      className="form-control-sm"
                      name="autoRestartDuration"
                      value={utility.convertMilisecondsToStringWithFrames(dataItem?.reboot?.autoRestartDuration ?? 0)}
                      onChange={onChange}
                      style={{ width: "90px" }}
                      disabled={!dataItem?.reboot?.isAutoRestartEnabled}
                    />
                  </div>
                </div>
              </Box3d>
            </div>
            {/* play mode, auto restart percentage */}
            <div className={isMobile ? "fs-sm" : "d-flex"}>
              <Box3d scale={false} className="mt-2 pl-3 pr-2 mr-1" height="70px">
                <div style={{ width: "100%" }}>
                  <span className="" style={{ ...colorText, marginBottom: '0.5rem', fontSize: isMobile ? "14px" : "15px", fontWeight: "initial" }}>{lang.play_mode_label}</span>
                  <DropDownList
                    style={{ backgroundColor: 'white' }}
                    data={playModes}
                    name="playMode"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem?.playMode}
                    onChange={onChange}
                  />
                </div>
              </Box3d>
              <Box3d scale={false} className="mt-2 ml-1 pl-3 pr-2" height="70px">
                <div className="d-flex align-items-center" style={{ width: "100%" }}>
                  <input style={{ width: "55px" }} className="form-control form-control-sm" type="number" min={40} max={99} name="autoRestartPercent" value={dataItem.autoRestartPercent} onChange={onChange} />
                  <span style={{ ...colorText, marginTop: '5px', marginLeft: '5px', fontSize: isMobile ? "14px" : "15px", fontWeight: "initial" }}>{lang.auto_restart_percent_label + "(%)"}</span>
                </div>
              </Box3d>
            </div>
            {/* enable notification */}
            <div className={isMobile ? "fs-sm" : "d-flex"}>
              <Box3d scale={false} className="mt-2 px-2" height="70px">
                <div className="d-flex align-items-center" style={{ width: "100%" }}>
                  <Switch
                    name="enableNotification"
                    size={"lg"}
                    onChange={onChange}
                    checked={dataItem.enableNotification}
                    color="success"
                    trackColor="white"
                  />
                  <span style={{ ...colorText, marginBottom: '0.5rem', marginTop: '8px', fontSize: "14px", fontWeight: "initial" }}>Enable Notification</span>

                  {/* email input */}
                  <div className="d-flex align-items-center ml-2" style={{ width: "100%" }}>
                    <input
                      className="form-control form-control-sm"
                      type="email"
                      name="email"
                      style={{ cursor: dataItem.enableNotification ? "pointer" : "not-allowed" }}
                      disabled={!dataItem.enableNotification}
                      value={dataItem.email}
                      onChange={onChange}
                      placeholder="Email"
                    />
                  </div>
                </div>
              </Box3d>
            </div>
          </div>
          <div className={isMobile ? "p-0 pt-2" : "col-7 p-0 pl-2 pt-2"}>
            {/* storage, memory */}
            <>
              <StorageCard showDeleteStorage={true} title={lang.storage_label} showFreeMemory={true} totalStorage={(dataItem?.screenDetail && dataItem?.screenDetail["Total Storage"]) ? dataItem?.screenDetail["Total Storage"] : 0} freeStorage={(dataItem?.screenDetail && dataItem?.screenDetail["Free Storage"]) ? dataItem?.screenDetail["Free Storage"] : 0} usedStorage={(dataItem?.screenDetail && dataItem?.screenDetail["Used Storage"]) ? dataItem?.screenDetail["Used Storage"] : 0} handleDeleteStorage={handleDeleteStorage} />
              <StorageCard title={lang.memory_label} totalStorage={(dataItem?.screenDetail && dataItem?.screenDetail["TotalRam"]) ? dataItem?.screenDetail["TotalRam"] : 0} usedStorage={(dataItem?.screenDetail && dataItem?.screenDetail["UsedRam"]) ? dataItem?.screenDetail["UsedRam"] : 0} />
            </>
            {/* debug mode, enable asrunlog, periodic screenshots */}
            <div className={isMobile ? "fs-sm" : "d-flex"}>
              <Box3d scale={false} className="mr-1 pl-3 pr-2" height="70px">
                <div className="d-flex">
                  <div className="d-flex align-items-center" style={{ width: "100%" }}>
                    <Switch
                      name="debugMode"
                      size={"lg"}
                      onChange={onChange}
                      checked={dataItem.debugMode}
                      color="success"
                      trackColor="white"
                    />
                    <span style={{ ...colorText, fontSize: isMobile ? "12px" : "15px", fontWeight: "initial" }}>{lang.debug_mode_label}</span>
                  </div>
                  <div className="d-flex align-items-center" style={{ width: "100%" }}>
                    <Switch
                      name="enableAsrunLog"
                      size={"lg"}
                      onChange={onChange}
                      checked={dataItem.enableAsrunLog}
                      color="success"
                      trackColor="white"
                    />
                    <span style={{ ...colorText, marginBottom: '0.5rem', marginTop: '10px', fontSize: isMobile ? "12px" : "15px", fontWeight: "initial" }}>{lang.enable_asrunlog_label}</span>
                  </div>
                  <div className="d-flex align-items-center" style={{ width: "100%" }}>
                    <Switch
                      name="takePeriodicScreenshots"
                      size={"lg"}
                      onChange={onChange}
                      checked={dataItem.takePeriodicScreenshots}
                      color="success"
                      trackColor="white"
                    />
                    <span style={{ ...colorText, marginBottom: '0.5rem', marginTop: '8px', fontSize: isMobile ? "12px" : "15px", fontWeight: "initial" }}>{lang.take_periodic_screenshots_label}</span>
                  </div>
                </div>
              </Box3d>
            </div>
          </div>
        </div>
        {/* screen bottom */}
        <div className="row pt-2" style={{ backgroundColor: "#f4f5fa" }} >
          {Object?.keys(dataItem?.screenDetail ?? {})?.filter(key => key != "DownloadedFiles")?.map((key, index) => (
            <div className={isMobile ? "col-6" : "col-3"} key={index}>
              <Card header={key} description={dataItem?.screenDetail[key]} />
            </div>
          ))}
        </div>
      </div>
    </div >

    {/* popups */}
    <>
      {showScreenGroupPopup && (
        isMobile ?
          <DigitalSignMobileScreenGroupCollectionSelection title={lang.add_screen_group_dialog_header} entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "=", false]} closeForm={() => setShowScreenGroupPopup(false)} setDataList={handleSelectedGroup} seletedState={[dataItem?.screenGroup]} mode="single" />
          : <DigitalSignScreenGroupCollectionSelection title={lang.add_screen_group_dialog_header} entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "=", false]} closeForm={() => setShowScreenGroupPopup(false)} setDataList={handleSelectedGroup} seletedState={[dataItem?.screenGroup]} width="600px" Height="300px" mode="single" />)
      }
      {
        showPlaylistPopup && <BossDialog title={lang.playlist_dialog_header} onClose={() => setShowPlaylistPopup(false)} width="650px">
          <Grid
            data={dataItem?.playlist}
            style={{ height: "350px" }}
          >
            <GridColumn cell={myCommandCell} width="60px" />
            <GridColumn title="Name" field="Name" />
            <GridColumn title="Status" field="Status" width={200} cell={MyPlaylistStatuscell} />
          </Grid>
        </BossDialog>
      }

      {showAsrunReport && <DigitalSignAsrunReport item={dataItem} cancelEdit={() => setShowAsrunReport(false)} />}
    </>
  </>);
}
export default ScreenEditForm