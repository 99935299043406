/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { utility } from '../../../framework/utility/utilityProvider';

const MobileCardLayout = ({ image, title, description, activeAgo, badge, modelNo, topRight, showContextMenu = false, contextMenuItem = [], topLeft, handleItemClick, item, bottomRight }) => {
    const isFontAwesomeBadge = typeof badge === 'string' && badge.startsWith('fa-');
    const menuRef = useRef(null);
    const ellipsisRef = useRef(null);

    useEffect(() => {
        if (ellipsisRef.current) {
            const computedStyle = window.getComputedStyle(ellipsisRef.current);
            const backgroundColor = computedStyle.backgroundColor;

            // Extract RGB values from the background color
            const rgb = backgroundColor.match(/\d+/g);
            if (rgb) {
                const [r, g, b] = rgb.map(Number);

                // Calculate brightness based on RGB values
                const brightness = (r * 0.299 + g * 0.587 + b * 0.114) / 255;

                // Adjust the color of the ellipsis icon based on brightness
                if (brightness < 0.5) {
                    // Dark background, set icon color to white
                    ellipsisRef.current.style.color = 'white';
                } else {
                    // Light background, set icon color to black
                    ellipsisRef.current.style.color = 'black';
                }
            }
        }
    }, []);

    const onEllipsisClick = (event) => {
        event.preventDefault();
        event.stopPropagation(); 
        menuRef.current.toggle(event);
    };

    return (
        <div
            className="card shadow rounded-4 position-relative mt-3 custom-card mb-4 cursor-pointer"
            onClick={(e) => {e.preventDefault(); handleItemClick && handleItemClick(item)}}
            style={{ border: "1px solid #ced4da", boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff" }}
        >
            <div className="position-relative overflow-hidden">
                <img
                    src={image}
                    alt={title}
                    className="card-img-top img-fluid"
                    style={{ width: '100%', height: "180px", objectFit: "cover" }}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://media.comicbook.com/files/img/default-movie.png";
                    }}
                />
                <div style={{ top: 0, left: 0, padding: '3px', position: 'absolute',}}>
                    {topLeft}
                </div>
                <div className="d-flex" style={{ position: 'absolute', padding: '3px', top: 0, right: 0 }}
                >
                    {topRight}
                    {showContextMenu && (
                        // <i
                        //     ref={ellipsisRef}
                        //     className="fa fa-ellipsis-v pl-3 pr-2 pt-1 cursor-pointer"
                        //     aria-hidden="true"
                        //     onClick={onEllipsisClick}
                        // ></i>
                        <button style={{ boxShadow: "none" }} type="button" onClick={onEllipsisClick} className={`btn btn-default btn-circle btn-my`} >
                            <i className="fa fa-ellipsis-v cursor-pointer fa-fw img-circle" style={{ fontSize: '16px' }} />
                        </button>
                    )}
                    <Menu
                        model={contextMenuItem}
                        popup
                        ref={menuRef}
                    />
                </div>
                {badge && (
                    <span className="badge custom-badge position-absolute" style={isFontAwesomeBadge ? { padding: '0px' } : {}}>
                        {isFontAwesomeBadge ? (
                            <i className={`fa ${badge} m-2`} />
                        ) : (
                            <img style={{ width: modelNo === "AFTT" ? '30px' : '55px', padding: "3px" }} src={badge} alt="Badge" />
                        )}
                    </span>
                )}
            </div>
            <div className="p-2 bg-light">
                <p className="p-0 m-0 mb-1" style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#000000',
                    overflowWrap: "break-word"
                }}>{utility.subString(title, 30)} {bottomRight && <span style={{ marginTop: '-2px' }} className="cursor-pointer float-right">{bottomRight}</span>}
                </p>
                <p className="p-0 m-0 mb-1" style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#000000',
                    overflowWrap: "break-word"
                }}>{description}</p>
                {activeAgo && (
                    <div className="d-flex justify-content-end align-items-center">
                        <span className="text-muted small fst-italic" style={{ fontSize: '12px' }}>
                            {activeAgo}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileCardLayout;
