/* eslint-disable */
import React, { useRef, useState } from 'react';
import { useTranslation } from '../../../locale/useTranslation';
import { useNavigate } from 'react-router-dom';
import { Popup } from "@progress/kendo-react-popup";
import MyStatusCell from '../../../components/MyStatusCell';
import MobileGridCard from './MobileGridCard';
import { ContentArrayImageCell } from '../../../framework/forms/helpercomponents/CustomGridCells/ContentArrayImageCell';
import { utility } from '../../../framework/utility/utilityProvider';
import { PLAYLIST_STATUS } from '../../../framework/constant/constant';
import { AppIcon } from '../../../components/AppIcons';
import ActionCard from '../../../components/ActionCard';


const DigitalSignMobileDashboardForm = (props) => {

    const { cards = [], screenData = [], playlistData = [], companyId, screenCount, contentCount, playlistCount } = props;
    const lang = useTranslation();
    const anchor = useRef();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    return (
        <>
            <div className='d-flex'>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                    <i className="fa fa-table" style={{ fontSize: "large", color: "GrayText", marginRight: "5px" }}></i>
                    <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word", }}> {lang.digital_sign_dashboard_page_breadcrumb} </span>
                </div>
                <div className='flex-container-reverse flex-item-auto'>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="d-flex justify-content-end flex-grow-1">
                            {/* create button  fa-arrow-down + lang.create_button_text show popup in mobile view*/}
                            <button
                                ref={anchor}
                                style={{ fontSize: '12px', borderRadius: '5px' }}
                                className="btn btn-primary"
                                onClick={() => setShow(!show)}
                            >
                                <i className="fa fa-plus mr-1" style={{ fontSize: '12px' }}></i> {lang.create_button_text}
                            </button>
                            <Popup
                                show={show}
                                anchor={anchor.current}
                                popupClass="popup-content"
                                style={{ width: 'auto', minWidth: '200px' }}
                            >
                                <div
                                    className="cursor-pointer d-flex align-items-center p-2" style={{ fontSize: 'large' }}
                                    onClick={() => navigate("/home/digitalsignScreen", { state: { openNewEditForm: true } })}
                                >
                                    <i className="fa fa-desktop fa-fw text-primary mr-2"></i>
                                    {lang.new_screen_button_title}
                                </div>
                                <hr />
                                <div
                                    className="cursor-pointer d-flex align-items-center p-2" style={{ fontSize: 'large' }}
                                    onClick={() => navigate("/home/digitalSignContent", { state: { openNewEditForm: true } })}
                                >
                                    <i className="fa fa-clipboard fa-fw text-primary mr-2"></i>
                                    {lang.new_content_button_title}
                                </div>
                                <hr />
                                <div
                                    className="cursor-pointer d-flex align-items-center p-2" style={{ fontSize: 'large' }}
                                    onClick={() => navigate("/home/Playlist", { state: { openNewEditForm: true } })}
                                >
                                    <i className="fa fa-play fa-fw text-primary mr-2"></i>
                                    {lang.new_playlist_button_title}
                                </div>
                                <hr />
                                <div
                                    className="cursor-pointer d-flex align-items-center p-2" style={{ fontSize: 'large' }}
                                    onClick={() => navigate("/home/digitalSignScreenGroup", { state: { openNewEditForm: true } })}
                                >
                                    <i className="fa fa-object-ungroup fa-fw text-primary mr-2"></i>
                                    {lang.new_screen_group_button_title}
                                </div>
                            </Popup>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ margin: "11px 0px 6px 0px" }}>
                {screenCount == 0 ? <ActionCard icon={cards?.icon ?? "desktop"} iconColor="#20c997" title={lang.please_create_screen_first_label} Description={lang.screen_create_description_label} buttonAction={() => navigate("/home/digitalsignScreen", { state: { openNewEditForm: true } })} buttonName={lang.add_screen_button_label} /> :
                    contentCount == 0 ? <ActionCard icon={cards?.icon ?? "clipboard"} iconColor="#25A3F2" title={lang.please_create_content_label} Description={lang.content_create_description_label} buttonAction={() => navigate("/home/digitalSignContent", { state: { openNewEditForm: true } })} buttonName={lang.add_content_button_label} /> :
                        playlistCount === 0 ? <ActionCard icon={cards?.icon ?? "play"} iconColor="#FC0256" title={lang.please_create_playlist_label} Description={lang.playlist_create_description_label} buttonAction={() => navigate("/home/Playlist", { state: { openNewEditForm: true } })} buttonName={lang.add_playlist_button_label} iconFontSize={"41px"} /> : ""}
            </div>

            <div className="d-flex justify-content-around mt-3">
                <div className="d-flex flex-column align-items-center">
                    <AppIcon title="Screen" path={companyId ? `/home/digitalsignScreen?companyId=${companyId}` : '/home/digitalsignScreen'} iconName="desktop" appStyle={{ backgroundColor: "#20c997" }} iconStyle={{ color: 'white' }} />
                </div>
                <div className="d-flex flex-column align-items-center">
                    <AppIcon title="Content" path={companyId ? `/home/digitalSignContent?companyId=${companyId}` : '/home/digitalSignContent'} iconName="clipboard" appStyle={{ backgroundColor: "#25A3F2" }} iconStyle={{ color: 'white' }} />
                </div>
                <div className="d-flex flex-column align-items-center">
                    <AppIcon title="Playlist" path={companyId ? `/home/Playlist?companyId=${companyId}` : '/home/Playlist'} iconName="play" appStyle={{ backgroundColor: "#FC0256" }} iconStyle={{ color: 'white' }} />
                </div>
                <div className="d-flex flex-column align-items-center">
                    <AppIcon title="Screen Group" path={companyId ? `/home/digitalSignScreenGroup?companyId=${companyId}` : '/home/digitalSignScreenGroup'} iconName="object-ungroup" appStyle={{ backgroundColor: "#DAB231" }} iconStyle={{ color: 'white' }} />
                </div>
            </div>

            <div className={(screenData.length == 0 && playlistData.length == 0) && 'mb-5'}>
                {cards.map((card, index) => (
                    <div className="d-flex justify-content-center align-items-center align-self-center align-content-around flex-wrap mt-3" key={index} >
                        <div className="p-3" style={{ border: "1px solid #ced4da", boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", borderRadius: "8px", width: "100%" }}>
                            {/* Header Section */}
                            <div className="d-flex justify-content-between align-items-center">
                                {/* Icon Section */}
                                <div className="d-flex justify-content-center align-items-center"
                                    style={{ backgroundColor: card?.backgroundColor ?? "#6f42c1", width: "70px", height: "60px", borderRadius: "14px" }}>
                                    <i className={`fa fa-${card?.icon ?? "desktop"} text-white`} style={{ fontSize: "25px" }} ></i>
                                </div>
                                {/* Text Section */}
                                <div className="text-end">
                                    <small className="text-muted" style={{ fontSize: "14px" }}> {card?.name} </small>
                                    <h4 className="mb-0 text-right" style={{ fontWeight: "bold", fontSize: 'large' }}>
                                        {String(card?.count).includes('/') ? (
                                            <>
                                                {String(card?.count).split('/')[0]}/
                                                <span style={{ display: 'inline-block', fontSize: 'large' }}>
                                                    {String(card?.count).split('/')[1]}
                                                </span>
                                            </>
                                        ) : (<span className="text-right" style={{ color: "black", fontWeight: "bold", fontSize: 'large' }}>{card?.count}</span>)}
                                    </h4>
                                </div>
                            </div>
                            {/* Divider */}
                            <hr className="my-3" />
                            {/* Footer Section */}
                            {card?.showDetails && (
                                <div className="d-flex justify-content-between align-items-center p-3" onClick={() => navigate(companyId ? `${card?.route}?companyId=${companyId}` : card?.route)} style={{ border: "1px solid #ced4da", backgroundColor: "#fff", borderRadius: "8px" }} >
                                    <span className="text-muted" style={{ fontSize: 'large' }}>{card?.description ?? "View Details"} </span>
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{ backgroundColor: card?.backgroundColor ?? "#6f42c1", width: "30px", height: "30px", borderRadius: "40px", }}
                                    >
                                        <i className="fa fa-arrow-right text-white"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {screenData.length > 0 &&
                <div className={playlistData.length == 0 ? 'mt-2 mb-5' : 'mt-2'}>
                    <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word" }}>SCREEN/PLAYERS</span>
                    {
                        screenData.map((item, index) => {
                            let tileBackColor = item.active ? "#63aa43" : "#E72929";
                            let status = item.active ? "Active" : "Inactive";
                            return <MobileGridCard
                                key={index}
                                title={item.Name}
                                description={item?.screenGroup?.Name}
                                topRight={<MyStatusCell title={status} tileBackColor={tileBackColor} />}
                                bottomRight={<p className='m-0 p-0' style={{ fontSize: '12px', color: "gray" }}>{item.activeAgo}</p>}
                                height="120px"
                            />
                        })
                    }
                </div>
            }

            {playlistData.length > 0 &&
                <div className='mt-2 mb-5'>
                    <span style={{ fontSize: "large", flex: "1", wordBreak: "break-word" }}>PLAYLISTS</span>
                    {
                        playlistData.map((item, index) => {
                            return <MobileGridCard
                                key={index}
                                title={item.Name}
                                topRight={<MyStatusCell titleFontSize='10px' titleHeight='20px' style={{ width: '60px', position: "relative" }} title={utility.convertCamelCaseToWords(utility.getKeyByValue(PLAYLIST_STATUS, item.Status))} tileBackColor="#508D4E" />}
                                description={item?.screenGroup?.map(x => x?.Name)?.join(', ')}
                                label2={<ContentArrayImageCell field="playlistItem" dataItem={item} />}
                                label2Style={{ marginLeft: '-10px', marginTop: '10px' }}
                                height='128px'
                            />
                        })
                    }
                </div>
            }
        </>
    );
}


export default DigitalSignMobileDashboardForm;