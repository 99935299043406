/* eslint-disable */
import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { useTranslation } from '../../../locale/useTranslation'
import { EditPageHeader } from '../../../components/EditPageHeader'

const DigitalSignMobileOverlayEditForm = (props) => {
    const lang = useTranslation()
    const { title, handleSubmit, dataItem, onChange, onClose, OVERLAYTYPE, POSITION, isEnterpriseLogin, companies, selectedCompany, setSelectedCompany } = props

    return (
        <>
            <div className='row mt-2'>
                <div className="col-12">
                    <EditPageHeader title={title} onSubmit={handleSubmit} onCancel={onClose} />
                    {isEnterpriseLogin && <div className="row mt-3">
                        <div className="col-12">
                            <label>{lang.company_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={companies}
                                name="Company"
                                textField="Name"
                                dataItemKey="_id"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}
                            />
                        </div>
                    </div>}
                    <div className="row mt-3">
                        <div className="col-12">
                            <label>{lang.name_label} *</label>
                            <input
                                name="Name"
                                type="text"
                                className="form-control form-control-sm"
                                onChange={onChange}
                                value={dataItem.Name}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.layer_label}</label>
                            <input
                                type="number"
                                name="Layer"
                                className="form-control form-control-sm"
                                value={dataItem.Layer}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.overlay_type_label} </label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={Object.keys(OVERLAYTYPE).map((x) => {
                                    return {
                                        _id: OVERLAYTYPE[x], Description: x
                                    }
                                })}
                                name="OverlayType"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.OverlayType}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    {dataItem.OverlayType._id == OVERLAYTYPE.Image && (
                        <>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label>{lang.height_label}</label>
                                    <div className='d-flex '>
                                        <input
                                            type="number"
                                            name="ShapeHeight"
                                            className="form-control form-control-sm"
                                            value={dataItem.ShapeHeight}
                                            onChange={onChange}
                                        />
                                        <span className="ml-2">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12">
                                    <label>{lang.width_label}</label>
                                    <div className='d-flex '>
                                        <input
                                            type="number"
                                            name="ShapeWidth"
                                            className="form-control form-control-sm"
                                            value={dataItem.ShapeWidth}
                                            onChange={onChange}
                                        />
                                        <span className="ml-2">%</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.position_label}</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={Object.keys(POSITION).map((x) => {
                                    return {
                                        _id: POSITION[x], Description: x
                                    }
                                })}
                                name="Position"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.Position}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.opacity_label}</label>
                            <input
                                type="number"
                                name="Opacity"
                                className="form-control form-control-sm"
                                value={dataItem.Opacity}
                                onChange={onChange}
                                max={1}
                                min={0}
                            />
                        </div>
                        <div className="col-12 d-flex align-items-center">
                            <span className="small">{lang.opacity_note}</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 d-flex">
                            <input
                                type="checkbox"
                                name="Archive"
                                value={dataItem.Archive}
                                checked={dataItem.Archive}
                                onChange={onChange}
                            />
                            <label className="ml-2">{lang.archive}</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSignMobileOverlayEditForm