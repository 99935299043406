export const MatchesMenuModel = (props) => {

  const { dataIndex ,dataItem, onEditMatch, onQuickEditMatch , onScheduleMatch,onUnPublish,onPlanningDistribution } = props;

  const isPublished = dataItem?.ScheduleStatus?.length == 0 ? false : true;

  var menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: "fa-solid fa-pencil", command: () => { onEditMatch(dataIndex,dataItem) }},
        { label: 'Quick Edit', icon: "fa-solid fa-pen-to-square", command: () => { onQuickEditMatch(dataIndex,dataItem) }},
        { label: isPublished ? `Un-Publish Match` : `Publish Match`, icon: "fa-solid fa-calendar-days", command: () => { isPublished ? onUnPublish(dataIndex,dataItem)  : onScheduleMatch(dataIndex,dataItem) }},
      ]
    }
  ]

    if(isPublished){
      menu[0].items.push({ label: 'Distribute Planning', icon: "fa-solid fa-sliders", command: () => { onPlanningDistribution(dataIndex,dataItem) }})
    }
    return menu;
  }